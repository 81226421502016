import React, { PropsWithChildren } from 'react';
import { ControlsStringAssets } from '../../../assets/stringAssets';
import { Box, BoxProps, Button, ButtonProps } from '@mui/material';
import Form from '../Form/Form';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/styles';

/*** Using the Material UI Emotion Styling library, declare 'styled' instances for each area/object. 
 *** NOTE: These must be declared outside of the React Functional Component to ensure that the styled 
 *** objects will be properly rendered within the DOM. 
 ***/

// a styled Box (equivalent to a <div>), providing an area to display the Action Buttons Area
const StyledBoxForActionButtonsArea = styled((props: BoxProps) => (
  <Box
    {...props}
  />
))(({ theme }) => ({
  marginTop: theme.spacing(2),
  flexDirection: 'column',
}));

// a styled Button for the 'Proceed to Sign-in' Button
const StyledButtonForProceedToSigninButton = styled((props: ButtonProps) => (
  <Button
    fullWidth
    {...props}
  />
))(({ theme }) => ({
  padding: theme.spacing(1, 2),
  color: theme.secondaryButton.textColor,
  border: theme.secondaryButton.border,
  background: theme.secondaryButton.background,
  '&:hover': {
    border: theme.secondaryButton.hoverBorder,
    background: theme.secondaryButton.hoverBackground,
    color: theme.secondaryButton.hoverTextColor,
  },
  '&:disabled': {
    border: theme.secondaryButton.disabledBorder,
    background: theme.secondaryButton.disabledBackground,
    color: theme.secondaryButton.disabledTextColor,
  },
}));

export interface IRecoverEmailResultsFormProps extends PropsWithChildren<unknown> {
  /**
   * @property {boolean} recoverEmailSuccessful Whether the recover email process was successful
   */
  recoverEmailSuccessful: boolean,
}

const RecoverEmailResultsForm: React.FC<IRecoverEmailResultsFormProps> = (props: IRecoverEmailResultsFormProps) => {
  RecoverEmailResultsForm.displayName = 'Email Verification Results Form';

  const { recoverEmailSuccessful } = props;

  const navigate = useNavigate();

  // handles a login (submit) request from the form
  const handleSubmitStub = () => {

    // do nothing in this method
  }

  return (
    <>
      <Form
        onSubmit={handleSubmitStub}
      >
        {recoverEmailSuccessful &&
          <p>MyDigiBrain has successfully recovered your email address!
            You may now proceed to the sign-in page by pressing the button below.
          </p>
        }

        {!recoverEmailSuccessful &&
          <>
            <p>The attempt to recover your email address was unsuccessful. This is likely due to an invalid or expired code.
            </p>
            <p>
              It is also possible that your email address has already been recovered. You can test this by attempting to sign in.
              If you are able to sign in, that indicates that your email address has been recovered and no further steps are required.
            </p>
          </>
        }

        <StyledBoxForActionButtonsArea>
          <StyledButtonForProceedToSigninButton
            onClick={() => navigate('/login')}
          >
            {ControlsStringAssets.proceedToSigninButtonText}
          </StyledButtonForProceedToSigninButton>
        </StyledBoxForActionButtonsArea>
      </Form>
    </>

  );
}

export default RecoverEmailResultsForm;