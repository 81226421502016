import React, { PropsWithChildren, useCallback, useEffect, useState } from 'react';
import { Box, BoxProps } from '@mui/material';
import { styled } from '@mui/styles';
import { IImageLink } from '../../../dataObjects/models/digitalMedia/ImageLink';
import { enumCollectionViewType, enumDigitalMediaDisplayEnvironment, enumImageFileSize, enumImageLinkType } from '../../../dataObjects/enums';
import { getDownloadUrlsForImageSizes } from '../../../firebaseServices/cloudStorageServices/cloudStorageFilenameUtilities';
import { cardGridViewItemStyles, cardGridViewStyles, listViewItemStyles } from '../../styles';
import { doesCloudStorageFileExist } from '../../../firebaseServices/cloudStorageServices/cloudStorageFileExistence/doesCloudStorageFileExist';
import { useGetCollectionViewType } from '../../customHooks/useGetCollectionViewType';
import { convertGoogleDriveFileUrlForFileLink, urlIsAGoogleDriveLink } from '../../utilities/digitalMediaUtilities/externalSourceUtilities/googleDriveUtilities';
import { MdbError } from '../../../errorObjects/MdbError';
import { DigitalMediaNotAvailableDisplay } from '../DigitalMediaNotAvailableDisplay/DigitalMediaNotAvailableDisplay';
import { urlHasAValidImageFileExtension } from '../../utilities/digitalMediaUtilities/generalDigitalMediaUtilities/imageFileUtilities';
import { MessagesStringAssets } from '../../../assets/stringAssets';


/*** Using the Material UI Emotion Styling library, declare 'styled' instances for each area/object. 
 *** NOTE: These must be declared outside of the React Functional Component to ensure that the styled 
 *** objects will be properly rendered within the DOM. 
 ***/

// a styled Box (equivalent to a <div>) that will serve as a base container in a Card View
const StyledContainerForCardView = styled((props: BoxProps) => (
  <Box
    {...props}
  />
))(({ theme }) => ({
  margin: theme.spacing(0, 0, 2, 0),
  border: `2px solid ${theme.palette.primary.main}`,
  borderRadius: '1px', 
  display: 'flex',
  justifyContent: 'center',
  flexGrow: 1,
  position: 'relative',
  height: '200px',
  width: 'auto'
}));

// a styled Box (equivalent to a <div>) that will serve as a base container in a Card View
const StyledContainerForCardViewNoBorder = styled((props: BoxProps) => (
  <Box
    {...props}
  />
))(({ theme }) => ({
  margin: theme.spacing(0, 0, 2, 0),
  // border: `2px solid ${theme.palette.primary.main}`,
  borderRadius: '1px', 
  display: 'flex',
  justifyContent: 'center',
  flexGrow: 1,
  position: 'relative',
  height: '250px',
  width: 'auto'
}));

// a styled Box (equivalent to a <div>) that will serve as a base container in a Form View
const StyledContainerForFormView = styled((props: BoxProps) => (
  <Box
    {...props}
  />
))(({ theme }) => ({
  margin: theme.spacing(2, 0),
  border: `2px solid ${theme.palette.primary.main}`,
  borderRadius: '1px', 
  display: 'flex',
  justifyContent: 'center',
  flexGrow: 1,
  position: 'relative',
  height: '250px',
  width: 'auto'
}));

// a styled Box (equivalent to a <div>) that will serve as a base container in a List View
const StyledContainerForListView = styled((props: BoxProps) => (
  <Box
    {...props}
  />
))(({ theme }) => ({
  margin: theme.spacing(0, 0, 2, 0),
  border: `2px solid ${theme.palette.primary.main}`,
  borderRadius: '1px',
  display: 'flex',
  justifyContent: 'center',
  alignContent: 'center',
  flexGrow: 1,
  position: 'relative',
  height: '100%',
  width: '100%',

  [theme.breakpoints.down('xs')]: {
    maxWidth: '300px',
  },
  [theme.breakpoints.up('sm')]: {
    maxWidth: '350px',
  },
  [theme.breakpoints.up('md')]: {
    maxWidth: '500px',
  },
}));

// a styled Box (equivalent to a <div>) that will serve as a base container in a List View
const StyledContainerForListViewNoBorder = styled((props: BoxProps) => (
  <Box
    {...props}
  />
))(({ theme }) => ({
  margin: theme.spacing(0, 0, 2, 0),
  // border: `2px solid ${theme.palette.primary.main}`,
  borderRadius: '1px', // to give it completely rounded sides
  display: 'flex',
  justifyContent: 'center',
  alignContent: 'center',
  flexGrow: 1,
  position: 'relative',
  height: '100%',
  width: '100%',

  [theme.breakpoints.down('xs')]: {
    maxWidth: '300px',
  },
  [theme.breakpoints.up('sm')]: {
    maxWidth: '350px',
  },
  [theme.breakpoints.up('md')]: {
    maxWidth: '500px',
  },
}));

// a styled Box (equivalent to a <div>), providing a background for a not available message
const ImageNotAvailableBox = styled((props: BoxProps) => (
  <Box
    {...props}
  />
))(({ theme }) => ({
  background: `linear-gradient(135deg, ${theme.palette.primary.light}, #BBB, #EEE, ${theme.palette.primary.light}, #BBB, #EEE, ${theme.palette.primary.light})`,
  display: 'flex',
  justifyContent: 'center',
}));

const TIMEOUT_DELAY: number = 500; // The delay for a timeout, in milliseconds, when checking for the existence of the image to be displayed
const MAX_IMAGE_CHECK_ATTEMPTS = 3; // Maximum number of attempts to use in checking for the existence of the image to be displayed

/**
 * @interface IImageMediaDisplayProps declares any input properties required for this component.
 */
export interface IImageMediaDisplayProps extends PropsWithChildren<unknown> {
  imageLink: IImageLink;
  displayEnvironment: enumDigitalMediaDisplayEnvironment;
  displayImageSize: enumImageFileSize;
  displayImageSizeWhenImageClicked: enumImageFileSize;
}

/**
 * @function ImageMediaDisplay is a React functional component for displaying a simple image (via an IImageLink).
 * @param {} props are the properties passed into the component (currently no input properties).
 */
export const ImageMediaDisplay: React.FC<IImageMediaDisplayProps> = (props: IImageMediaDisplayProps) => {
  ImageMediaDisplay.displayName = 'Image Media Display';

  // whether to display console logs (displayConsoleLogs && console.log statements)
  const displayConsoleLogs: boolean = false;

  const { imageLink, displayEnvironment, displayImageSize, displayImageSizeWhenImageClicked } = props;

  // whether the image source is supported
  const [supportedImageSource, setSupportedImageSource] = useState<boolean>(false);

  // the height of the image display component
  const [componentHeight, setComponentHeight] = useState<string>('3rem');

  // the URL that will be set for the image display object if the image is from an External Source
  const [imageUrl, setImageUrl] = useState<string>('');

  // the URL that will be set for the image display object's href property
  const [hrefUrl, setHRefUrl] = useState<string>('');

  // whether to apply the crossOrigin='anonymous' property to the <img> tag
  const [crossOriginAnonymous, setCrossOriginAnonymous] = useState<boolean>(false);

  // call custom hook to get the current view type, so the value can be used to initialize the View Type state variable
  const collectionViewType: enumCollectionViewType = useGetCollectionViewType();

  const classes: Record<string, string> = (collectionViewType === enumCollectionViewType.CardsGridView) ? cardGridViewItemStyles() : listViewItemStyles();

  // for an existing imageLink, the Map collection of imageSizes and their downloadUrls for the different possible sizes of images
  const [imageUrls, setImageUrls] = useState<Map<string, string> | undefined>(undefined);

  // whether the image to be displayed exists
  const [imageToBeDisplayedExists, setImageToBeDisplayedExists] = useState<boolean>(false);

  // number of attempts made in checking for the existence of the image to be displayed
  const [attemptsMadeInCheckingForImage, setAttemptsMadeInCheckingForImage] = useState<number>(0);

  const imageNotAvailableWidth: string = `100%`;
  const imageNotAvailableHeight: string = `100%`;

  // method to get & set the download Urls for the image
  const setDownloadUrlsForImageStoragePath: (baseStoragePath: string) => void = useCallback(
    (baseStoragePath: string): void => {
      const imageSizes: Array<enumImageFileSize> = [enumImageFileSize.Thumbnail, enumImageFileSize.Small, enumImageFileSize.Medium, enumImageFileSize.Large];
      // const downLoadUrls: Map<string, string> | undefined = undefined;
      getDownloadUrlsForImageSizes(imageLink.baseStoragePath, imageSizes).then((downloadUrlsForImage) => {
        // set the local state for the Image URLs
        setImageUrls(downloadUrlsForImage);

        // get the URL for the image that will be displayed -- we will be testing for the existence of that image
        const imageFileToDisplayUrl: string | undefined = downloadUrlsForImage.get(displayImageSize);
        if (imageFileToDisplayUrl) {
          doesCloudStorageFileExist(imageFileToDisplayUrl).then((imageExists) => {
            setImageToBeDisplayedExists(imageExists);
          }).catch((error) => {
            throw error;
          });
        }
      }).catch((error) => {
        throw error;
      });
    },
    [imageLink.baseStoragePath]
  );

  // if this is an existing imageLink (imageLink.downloadUrl is non-blank), execute logic based on the imageLink.baseStoragePath
  useEffect(() => {
    if (imageLink.downloadUrl && imageLink.baseStoragePath) {
      // get the downloadUrls for all different file sizes
      setDownloadUrlsForImageStoragePath(imageLink.baseStoragePath);
    }
  }, [imageLink, setDownloadUrlsForImageStoragePath]);

  // checks for the existence of the image to be displayed
  const checkForDisplayImageExistence = () => {
    // increment the counter of attempts
    setAttemptsMadeInCheckingForImage(attemptsMadeInCheckingForImage + 1);

    // if the imageUrls map has been created
    if (imageUrls && imageUrls.get(displayImageSize)) {
      const imageFileToDisplayUrl: string | undefined = imageUrls.get(displayImageSize);

      if (imageFileToDisplayUrl !== undefined) {
        // call method to determine whether the file exists in Cloud Storage
        doesCloudStorageFileExist(imageFileToDisplayUrl).then((imageFileExists) => {
          // if the image file exists...
          if (imageFileExists) {
            // set state variable to indicate image file exists
            setImageToBeDisplayedExists(imageFileExists);
          } else {
            if (attemptsMadeInCheckingForImage < MAX_IMAGE_CHECK_ATTEMPTS) {
              // Try again: check for the existence of the image again, after a delay
              // useTimeout(checkForDisplayImageExistence, TIMEOUT_DELAY);
              setTimeout(checkForDisplayImageExistence, TIMEOUT_DELAY);
            }
          }
        }).catch((error) => {
          throw error;
        });

      }
    }
  }

  // useEffect based upon changes to the imageUrls
  useEffect(() => {
    // set a timeout interval to check for the existence of the image to be displayed
    setTimeout(checkForDisplayImageExistence, TIMEOUT_DELAY / 2);
  }, [imageUrls]);

  // useEffect to establish container and image display component parameters based on the imageLink and display environment
  useEffect(() => {
    // The variables that can change are the:
    //   - Whether the Url provided is from a supported image source
    //   - Url of the image being displayed
    //   - Height of the container
    //   - Whether to apply the crossOrigin='anonymous' property
    let localSupportedImageSource: boolean = false;
    let localImageUrl: string = imageLink.downloadUrl;
    let localHrefUrl: string = imageLink.downloadUrl;
    let localComponentHeight: string = '3rem'; // default height
    let localCrossOriginAnonymous: boolean = false;

    switch (imageLink.imageLinkType) {
      // if the currently selected Image Type is file upload (GoogleCloudStorage)...
      case enumImageLinkType.GoogleCloudStorage:
        if (imageUrls) {
          localImageUrl = imageUrls.get(enumImageFileSize.Small) ?? '';
          localHrefUrl = imageUrls.get(enumImageFileSize.Large) ?? '';
        }
        localSupportedImageSource = true;
        localComponentHeight = '250px';
        break;

      // if the currently selected Image Type is from an external source...
      case enumImageLinkType.ExternalSource:
        // if the Url is a known supported URL Address where no Url conversion is required: 
        // An image file...
        if (urlHasAValidImageFileExtension(imageLink.downloadUrl)) {
          localSupportedImageSource = true;
          localComponentHeight = '250px';
        } else
          // if the Url is a Google Drive address...
          if (urlIsAGoogleDriveLink(imageLink.downloadUrl)) {

            // we need to convert the Url 
            localImageUrl = convertGoogleDriveFileUrlForFileLink(imageLink.downloadUrl);

            localSupportedImageSource = true;
            localCrossOriginAnonymous = true;
            localComponentHeight = '250px';
          }

        break;

      default:
        throw new MdbError(`Unhandled image type (${imageLink.imageLinkType}) in ImageMediaDisplay`);
    }

    displayConsoleLogs && console.log(`ImageMediaDisplay. useEffect for setting component specs. componentHeight to be set to: ${localComponentHeight}`);

    setSupportedImageSource(localSupportedImageSource);
    setImageUrl(localImageUrl);
    setHRefUrl(localHrefUrl);
    setComponentHeight(localComponentHeight);
    setCrossOriginAnonymous(localCrossOriginAnonymous);

  }, [imageLink.imageLinkType, imageLink.downloadUrl, imageUrls]);


  /**
   * @function renderImageElement Renders the JSX element of the image object to be displayed on the UI.
   */
  function renderImageElement(): JSX.Element {

    // default the image element to displaying no image
    let imageElement: JSX.Element =
      <>
        <ImageNotAvailableBox
          sx={{ width: imageNotAvailableWidth, height: imageNotAvailableHeight }}
        />
      </>;

    // if there's an established Url for the object, perform logic
    if (imageUrl) {

      if (supportedImageSource) {
        if (crossOriginAnonymous) {
          imageElement =
            <>
              <a target="_blank" rel="noopener noreferrer" href={hrefUrl} >
                <img
                  className={classes.digitalMediaObject}
                  crossOrigin="anonymous"
                  src={imageUrl}
                  alt={imageLink.description.substring(0, 20) + '...'}
                  height='100%'
                  width='100%'
                />
              </a>
            </>
        } else {
          imageElement =
            <>
              <a target="_blank" rel="noopener noreferrer" href={hrefUrl} >
                <img
                  className={classes.digitalMediaObject}
                  src={imageUrl}
                  alt={imageLink.description.substring(0, 20) + '...'}
                  height='100%'
                  width='100%'
                />
              </a>
            </>
        }
      } else {
        imageElement =
          <>
            <DigitalMediaNotAvailableDisplay displayText={MessagesStringAssets.imageSourceOrFormatNotSupported} />
          </>
      }
    }

    return imageElement;
  }

  /**
   * @function renderImageContainerAndImageDisplay Renders the JSX element of the image element container and image element to be displayed on the UI.
   */
  function renderImageContainerAndImageDisplay(): JSX.Element {

    let imageContainerAndImageDisplay: JSX.Element =
      <>
      </>

    displayConsoleLogs && console.log(`ImageMediaDisplay.renderImageContainerAndImageDisplay(). displayEnvironment: ${displayEnvironment}; componentHeight: ${componentHeight}`);

    switch (displayEnvironment) {
      case enumDigitalMediaDisplayEnvironment.CardView:
        if (supportedImageSource) {
          imageContainerAndImageDisplay =
            <StyledContainerForCardViewNoBorder sx={{ height: componentHeight }} >
              {renderImageElement()}
            </StyledContainerForCardViewNoBorder>
        } else {
          imageContainerAndImageDisplay =
            <StyledContainerForCardView sx={{ height: componentHeight }} >
              {renderImageElement()}
            </StyledContainerForCardView>

        }
        break;

      case enumDigitalMediaDisplayEnvironment.FormView:
        imageContainerAndImageDisplay =
          <StyledContainerForFormView sx={{ height: componentHeight }} >
            {renderImageElement()}
          </StyledContainerForFormView>
        break;

      case enumDigitalMediaDisplayEnvironment.ListView:
        if (supportedImageSource) {
          imageContainerAndImageDisplay =
            <StyledContainerForListViewNoBorder sx={{ height: componentHeight }} >
              {renderImageElement()}
            </StyledContainerForListViewNoBorder>
        } else {
          imageContainerAndImageDisplay =
            <StyledContainerForListView sx={{ height: componentHeight }} >
              {renderImageElement()}
            </StyledContainerForListView>
        }
        break;

      default:
        throw new MdbError(`Unhandled display environoment (${displayEnvironment}) in ImageMediaDisplay`);
    }

    return imageContainerAndImageDisplay;
  }
  return (
    <>
      {renderImageContainerAndImageDisplay()}
    </>
  )
}
