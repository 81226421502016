import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, ButtonProps } from '@mui/material';
import {
  Cancel as CancelIcon,
} from '@mui/icons-material';
import { styled } from '@mui/styles';


/*** Using the Material UI Emotion Styling library, declare 'styled' instances for each area/object. 
 *** NOTE: These must be declared outside of the React Functional Component to ensure that the styled 
 *** objects will be properly rendered within the DOM. 
 ***/

// a styled Button
const StyledButton = styled((props: ButtonProps) => (
  <Button
    variant="outlined"
    {...props}
  />
))(({ theme }) => ({
  padding: theme.spacing(1, 2),
  color: theme.form.cancelButton.color,
  border: theme.form.cancelButton.border,
  background: theme.form.cancelButton.background,
  '&:hover': {
    border: theme.form.cancelButton.hoverBorder,
    background: theme.form.cancelButton.hoverBackground,
    color: theme.form.cancelButton.hoverColor,
  },
  '&:disabled': {
    border: theme.form.cancelButton.disabledBorder,
    background: theme.form.cancelButton.disabledBackground,
    color: theme.form.cancelButton.disabledColor,
  },
}));

export interface ICancelButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  onCancel?: () => void;
}

const CancelButton: React.FC<ICancelButtonProps> = (props) => {

  // const { children, onCancel, ...otherProps } = props;
  const { children, onCancel } = props;

  const navigate = useNavigate();

  function handleCancelDefault() {
    navigate(-1);
  }

  return (
    <StyledButton
      startIcon={<CancelIcon />}
      // {...otherProps}
      // if an onCancel handler was provided, call it; otherwise, call the default method for cancelling
      onClick={onCancel ? () => onCancel() : () => handleCancelDefault()}
    >
      {/* if there are any children to pass on (ie, children !== undefined), pass them on; otherwise, pass an empty React fragment */}
      <>
        {children && children}
      </>
    </StyledButton>
  );
}

export default CancelButton;