/**
 * @enum enumSubscriptionType provides an enumeration of subscription types.
 * (Note: Try to keep the enumerations in alphabetical order to simplify viewing.)
 */
export enum enumSubscriptionType {
  BetaTesting = 'BetaTesting',
  IndividualPaidAnnually = 'IndividualPaidAnnually',
  IndividualPaidMonthly = 'IndividualPaidMonthly',
  GroupPaidAnnually = 'GroupPaidAnnually',
  GroupPaidMonthly = 'GroupPaidMonthly'
}
