import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IMdbError } from '../../../errorObjects/MdbError';

// export interface IFetchUserRedemptionCodeRequestsFailureState {
//   value: IMdbError | null
// }

const initialState: IMdbError | null = null;

export const fetchUserRedemptionCodeRequestsFailureSlice = createSlice({
  name: 'fetchUserRedemptionCodeRequestsFailure',
  initialState,
  reducers: {
    setFetchUserRedemptionCodeRequestsFailure: (state: IMdbError | null, action: PayloadAction<IMdbError>) => {
      state = action.payload;
    },
    clearFetchUserRedemptionCodeRequestsFailure: (state: IMdbError | null) => {
      state = null;
    },
  },
})

// Action creators are generated for each function in reducer)
export const { setFetchUserRedemptionCodeRequestsFailure, clearFetchUserRedemptionCodeRequestsFailure } = fetchUserRedemptionCodeRequestsFailureSlice.actions;

// export the core reducer for the slice
export const fetchUserRedemptionCodeRequestsFailureReducer = fetchUserRedemptionCodeRequestsFailureSlice.reducer;

export default fetchUserRedemptionCodeRequestsFailureSlice.reducer;