import React, { PropsWithChildren } from 'react';
import Form from '../Form/Form';
import FormActionButtonsBarWithProgress from '../../controls/actionBars/FormActionButtonsBarWithProgress/FormActionButtonsBarWithProgress';
import { Box, BoxProps } from '@mui/material';
import { styled } from '@mui/styles';


/*** Using the Material UI Emotion Styling library, declare 'styled' instances for each area/object. 
 *** NOTE: These must be declared outside of the React Functional Component to ensure that the styled 
 *** objects will be properly rendered within the DOM. 
 ***/

// a styled Box (equivalent to a <div>), providing an area to display the Action Bar Separator
const StyledBoxForActionBarSeparator = styled((props: BoxProps) => (
  <Box
    {...props}
  />
))(({ theme }) => ({
  marginTop: theme.spacing(2),
}));

// export interface IFormWithActionBarProps extends React.FormHTMLAttributes<HTMLFormElement> {
export interface IFormWithActionBarProps extends PropsWithChildren<unknown> {
  // if provided, the label to display for the 'Submit' button
  submitButtonLabel?: string;
  // if provided, the label to display for the 'Cancel' button
  cancelButtonLabel?: string;
  // if provided, whether to display a progress indicator
  actionInProgress?: boolean;
  // if provided, a label to display beneath the progress indicator
  actionInProgressLabel?: string;
  // if provided, a number to indicate the percent completion to display in a finite progress indicator
  finiteActionInProgressPercentComplete?: number;
  // if provided, a number to indicate a label to present for the finite progress indicator
  finiteActionInProgressLabel?: string;
  // if provided, a boolean to indicate whether the form is valid for submission
  formIsValid?: boolean;
  // method to call upon form submission
  onSubmit: (data?: any) => void;
  // if provided, the function to be called upon clicking the 'Cancel' button
  onCancel?: () => void;
  // 
  // props?: Record<string, string>
}

// const FormWithActionBar: React.FC<IFormWithActionBarProps> = ({ children, onSubmit, ...props }) => {
const FormWithActionBar: React.FC<IFormWithActionBarProps> = (props: IFormWithActionBarProps) => {

  const { children, submitButtonLabel, cancelButtonLabel, actionInProgress, actionInProgressLabel, formIsValid, onSubmit, onCancel } = props;
  // console.info(`FormActionButtonsBarWithProgress. actionInProgress=${actionInProgress}`);
  // console.info(`FormActionButtonsBarWithProgress. formIsValid=${formIsValid}`);

  return (
    <Form onSubmit={onSubmit} >
      {/* if there are any children to pass on (ie, children !== undefined), pass them on; otherwise, pass an empty React fragment */}
      <>
        {children && children}
        <StyledBoxForActionBarSeparator />
        <FormActionButtonsBarWithProgress
          onCancel={onCancel}
          submitButtonLabel={submitButtonLabel}
          cancelButtonLabel={cancelButtonLabel}
          actionInProgress={actionInProgress}
          actionInProgressLabel={actionInProgressLabel}
          formIsValid={formIsValid}
        />
      </>
    </Form>
  );

}

export default FormWithActionBar;