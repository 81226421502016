import { DocumentData, Firestore, QuerySnapshot } from 'firebase/firestore';
import { enumSharableObjectType, enumSharingRequestStatus } from '../../../../dataObjects/enums';
import { FirestoreObjectSharingRequestTrackerRepository_Ext, IFirestoreObjectSharingRequestTrackerRepository_Ext } from '../../../cloudServices/googleFirebaseServices/database/firestore/FirestoreObjectSharingRequestTrackerRepository';
import { FirebaseAppSingleton } from "../../../cloudServices/googleFirebaseServices/FirebaseAppSingleton";
import { FirestoreUserRedemptionCodeRequestRepository_Ext, IFirestoreUserRedemptionCodeRequestRepository_Ext } from '../../../cloudServices/googleFirebaseServices/database/firestore/FirestoreUserRedemptionCodeRequestRepository';


/**
 * @method getAllUserRedemptionCodeRequests_onSnapshot Makes an onSnapshot() request to firestore for retrieving all User Redemption Code Requests from the database. 
 *     A firebase onSnapshot() request sets up a subscription to firebase to dynamically update the results.
 * @param {(snapshot: QuerySnapshot<DocumentData>) => void} callback A callback method that receives document data as a parm and has a void return value 
 * @returns {() => void} An 'unsubscribe callback' method that is to be called when the snapshot is no longer needed.
 */
export function getAllUserRedemptionCodeRequests_onSnapshot(callback: (snapshot: QuerySnapshot<DocumentData>) => void): Promise<() => void> {
  return new Promise<() => void>(async (resolve, reject) => {
    try {
      // initialize a Firestore object instance
      const firestoreObj: Firestore = FirebaseAppSingleton.firestore;

      const firestoreChannelRepository_Ext: IFirestoreUserRedemptionCodeRequestRepository_Ext = new FirestoreUserRedemptionCodeRequestRepository_Ext(firestoreObj);

      const unsubscribeCallback: () => void = await firestoreChannelRepository_Ext.getAllUserRedemptionCodeRequests_onSnapshot(callback);

      resolve(unsubscribeCallback);
    }
    catch (error: any) {
      reject(error);
    }
  });
}
