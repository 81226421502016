import { typeUniqueId } from "../../../types";
import { RandomId } from "../../../utilities/RandomId";
import { enumFileUploadType } from "../../../enums";
import { IFileForUpload } from ".";

/** 
 * @class FileForUpload Represents information about a file that has been selected for upload.
 */
export class FileForUpload implements IFileForUpload {
  /**
   * @method Constructor method
   * @param {File} file Information about the file to be uploaded.
   * @param {enumFileUploadType} fileClass The class of file being uploaded (will be used as a subfolder for storing the file).
   */
  constructor(
    file: File,
    fileClass: enumFileUploadType
  ) {
    this.id = RandomId.newId();
    this._file = file;
    this._fileClass = fileClass;
  }

  /*-----------------------------------------------*/
  /**
   * @property {typeUniqueId} _id A unique Id for the instance
   */
  private _id: typeUniqueId = RandomId.newId();

  /**
   * @method id Returns the value of the instance's 'id'.
   */
  get id(): typeUniqueId {
    return this._id;
  }

  /**
   * @method id Sets the value of the 'id'.
   * @param {typeUniqueId} value The value to be used to set the 'id'.
   */
  set id(value: typeUniqueId) {
    this._id = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @property {File} _file A unique Id for the instance
   */
  private _file: File;

  /**
   * @method file Returns the value of the instance's 'file'.
   */
  get file(): File {
    return this._file;
  }

  /**
   * @method file Sets the value of the 'file'.
   * @param {File} value The value to be used to set the 'file'.
   */
  set file(value: File) {
    this._file = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @property {enumFileUploadType} _fileClass The type of file
   */
  private _fileClass: enumFileUploadType;

  /**
   * @method imageUrl Getter method for '_fileClass'
   */
  get fileClass(): enumFileUploadType {
    return this._fileClass;
  }

  /**
   * @method fileClass Setter method for '_fileClass'
   * @param {enumFileUploadType} value The value for setting '_fileClass'
   */
  set fileClass(value: enumFileUploadType) {
    this._fileClass = value;
  }
  /*-----------------------------------------------*/

}
