import React, { PropsWithChildren } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  IconButton,
  Tooltip,
  Typography
} from '@mui/material';
import {
  Delete as DeleteIcon,
  Edit as EditIcon,
  List as ListIcon,
} from '@mui/icons-material';
import moment from 'moment';
import { ITopicItem } from '../../../../dataObjects/models/topics/TopicItem';
import { cardGridViewItemStyles } from '../../../styles/views/cardGridViewItemStyles';
import { TooltipStringAssets } from '../../../../assets/stringAssets';
import { IUserAccessPermissionsForObject } from '../../../../dataObjects/models/collaboration/ObjectUserPermissions';

export interface ITopicItemsCardGridViewItemProps extends PropsWithChildren<unknown> {
  topicItem: ITopicItem;
  userPermissions: IUserAccessPermissionsForObject;
  onDelete: (topicItem: ITopicItem) => void;
}


export const TopicItemsCardGridViewItem: React.FC<ITopicItemsCardGridViewItemProps> = (props: ITopicItemsCardGridViewItemProps) => {

  const { onDelete, userPermissions, topicItem } = props;
  const { id, name, description, useTimestamp, occurrenceTimestamp } = topicItem;

  const classes = cardGridViewItemStyles();

  // we're going to apply the 'useNavigate' hook to navigate the browser
  const navigate = useNavigate();

  return (
    <>
      {/* <Card classes={{ root: classes.card }} elevation={6}> */}
      <Card elevation={6} className={classes.card}>
        {/* Note: Couldn't get proper behavior from <CardHeader>, so will just display a title via <Typography> */}
        <Typography className={classes.cardTitle} variant='h6' align='center'>
          {name}
        </Typography>

        <CardContent>
          <div>
            <Typography className={classes.cardOccurrenceTimestamp} variant="body1">
              {/* if using timestamp, then show it; otherwise, blank out the timestamp div */}
              { useTimestamp ?
                moment(occurrenceTimestamp).calendar()
                :
                <br></br>
              }
            </Typography>
          </div>
          <div>
            <Typography className={classes.cardDescription} variant="body1">
              {description}
            </Typography>
          </div>

          {/* <div className={classes.drillDownButton}> */}
          <div className={classes.drillDownButtonContainer}>
            <Tooltip
              // the tooltip for the drilldown button will either be "Drill down" (if user has sufficient permission) or "Drill down disallowed" (insufficient permission)
              title={!userPermissions.hasViewPermission ? TooltipStringAssets.drillDown_Disallowed : TooltipStringAssets.drillDown}
              arrow
            >
              <span>
                <Button
                  // className={classes.drillDownIconButton}
                  className={classes.drillDownButton}
                  aria-label="drill down to digital media"
                  variant='contained'
                  // color='primary'
                  size='medium'
                  startIcon={<ListIcon />}
                  disabled={!userPermissions.hasViewPermission}
                  onClick={() => navigate(`/${id}/digitalMedia`)}
                >
                  {TooltipStringAssets.digitalMedia_View}
                </Button>
              </span>
            </Tooltip>
          </div>
        </CardContent>

        <CardActions className={classes.cardActionsContainer}>
          <Tooltip
            // the tooltip for the edit button will either be "Edit" (if user has sufficient permission) or "Edit disallowed" (insufficient permission)
            title={!userPermissions.hasEditPermission ? TooltipStringAssets.edit_Disallowed : TooltipStringAssets.edit}
            arrow
          >
            <span>
              <IconButton
                className={`${classes.actionIconButton} ${classes.editIconButton}`}
                size="small"
                aria-label="edit"
                disabled={!userPermissions.hasEditPermission}
                onClick={() => navigate(`/topicItem/${id}`)}>
                <EditIcon />
              </IconButton>
            </span>
          </Tooltip>

          <Tooltip
            // the tooltip for the delete button will either be "Delete" (if user has sufficient permission) or "Delete disallowed" (insufficient permission)
            title={!userPermissions.hasEditPermission ? TooltipStringAssets.delete_Disallowed : TooltipStringAssets.delete}
            arrow
          >
            <span>
              <IconButton
                className={`${classes.actionIconButton} ${classes.deleteIconButton}`}
                size="small"
                aria-label="delete"
                disabled={!userPermissions.hasEditPermission}
                onClick={() => topicItem && onDelete(topicItem)} >
                <DeleteIcon />
              </IconButton>
            </span>
          </Tooltip>
        </CardActions>
      </Card>
    </>
  );

}