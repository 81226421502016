import React, { PropsWithChildren } from 'react';
import { Typography, TypographyProps } from '@mui/material';
import { styled } from '@mui/styles';


/*** Using the Material UI Emotion Styling library, declare 'styled' instances for each area/object. 
 *** NOTE: These must be declared outside of the React Functional Component to ensure that the styled 
 *** objects will be properly rendered within the DOM. 
 ***/

// a styled Typography for the Page Subtitle
const StyledTypographyForPageSubtitle = styled((props: TypographyProps) => (
  <Typography
    variant="h6"
    {...props}
  />
))(({ theme }) => ({
  color: theme.page.titleFontColor,
  fontWeight: 'normal',

  [theme.breakpoints.up('xs')]: {
    fontSize: '1.0rem',
  },
  [theme.breakpoints.up('sm')]: {
    fontSize: '1.2rem',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '1.35rem',
  },
  [theme.breakpoints.up('lg')]: {
    fontSize: '1.5rem',
  },
  [theme.breakpoints.up('xl')]: {
    fontSize: '1.7rem',
  },
}));


/**
 * @interface IPageSubtitleProps declares any input properties required for this component.
 */
export interface IPageSubtitleProps extends PropsWithChildren<unknown> {
  subTitle: string;
}

/**
 * @function PageSubtitle is a React functional component, representing a subtitle that will appear on a page.
 * @param {IPageSubtitleProps} props are the properties passed into the component and, in this case.
 */
export const PageSubtitle: React.FC<IPageSubtitleProps> = (props: IPageSubtitleProps) => {
  PageSubtitle.displayName = 'Page Subtitle';

  const { subTitle } = props;

  return (
    <>
      <StyledTypographyForPageSubtitle>
        {subTitle}
      </StyledTypographyForPageSubtitle>
    </>
  )
}