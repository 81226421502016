import { Store, UnknownAction } from '@reduxjs/toolkit';
import { Persistor } from 'redux-persist';
import { enumDeploymentPlatformType } from "../../dataObjects/enums";
import { StoreConfiguratorSingleton } from './StoreConfigurator/StoreConfiguratorSingleton';
import { IStoreState } from './';
import { MdbError } from '../../errorObjects/MdbError';
import { useDispatch, useSelector } from 'react-redux';


// whether to display console logs (displayConsoleLogs && console.log statements)
const displayConsoleLogs: boolean = false;

displayConsoleLogs && console.log('%c In configureStore module', 'background: #00B; color: #fff');

// determine the deployment environment based on an environment variable
let deploymentEnvironment: enumDeploymentPlatformType;
if (process.env.NODE_ENV === "production") {
  deploymentEnvironment = enumDeploymentPlatformType.Production;
} else {
  deploymentEnvironment = enumDeploymentPlatformType.Development;
}

displayConsoleLogs && console.log(`%c In configureStore module. deploymentEnvironment: ${deploymentEnvironment}`, 'background: #00B; color: #fff');

// We use a singleton to configure and manage the 'store' and 'persistor' for the app, so that we don't have to
// worry about multiple configuration instances being created and hanging around.
// Get an instance of the StoreConfiguratorSingleton class. If the singleton has already been initialized, get the 
// singleton instance. If the singleton hasn't been initialized, make a call to initialize it based on the deployment
// environment, and then obtain the singleton instance (returned from the initialize() method).
const storeConfigurator: StoreConfiguratorSingleton = StoreConfiguratorSingleton.isInitialized() ?
  StoreConfiguratorSingleton.getInstance() :
  StoreConfiguratorSingleton.initialize(deploymentEnvironment);

displayConsoleLogs && console.log(`%c In configureStore module. storeConfigurator has been set up.`, 'background: #00B; color: #fff');

// obtain a reference to the 'store' object within the configurator
const store: Store<IStoreState, UnknownAction, unknown> | undefined = storeConfigurator.store;
// if the store is not valid (is undefined), throw an error, since we can't continue
if (!store) {
  throw new MdbError(`Cannot proceed without a valid 'store' object`);
}

// obtain a reference to the 'persistor' object within the configurator
const persistor: Persistor | undefined = storeConfigurator.persistor;
// if the persistor is not valid (is undefined), throw an error, since we can't continue
if (!persistor) {
  throw new MdbError(`Cannot proceed without a valid 'persistor' object`);
}

export function configureStore(): { store: Store<IStoreState, UnknownAction, unknown> | undefined, persistor: Persistor | undefined } {
  return { store: storeConfigurator.store, persistor: storeConfigurator.persistor };
}

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch;

// Export a hook that can be reused to resolve types when dispatching a redux request
export const useAppDispatch = useDispatch.withTypes<AppDispatch>();

// Export a hook that can be reused for selecting an item from store state
export const useAppSelector = useSelector.withTypes<RootState>();
