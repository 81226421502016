import { enumSubscriptionType } from '../';
import { MdbError } from '../../../errorObjects/MdbError';
import { enumMdbErrorType } from '../../../errorObjects/enums';

/**
 * @class enumSubscriptionTypeConvert Provides to/from string conversions for enumSubscriptionType values.
 */
export class enumSubscriptionTypeConvert {
  public static toString(SubscriptionTypeEnum: enumSubscriptionType): string {
    return SubscriptionTypeEnum.toString();
  }

  public static fromString(SubscriptionTypeString: string): enumSubscriptionType {
    let SubscriptionTypeEnum: enumSubscriptionType = enumSubscriptionType.BetaTesting;

    switch (SubscriptionTypeString) {
      case enumSubscriptionTypeConvert.toString(enumSubscriptionType.BetaTesting):
        SubscriptionTypeEnum = enumSubscriptionType.BetaTesting;
        break;

      case enumSubscriptionTypeConvert.toString(enumSubscriptionType.IndividualPaidAnnually):
        SubscriptionTypeEnum = enumSubscriptionType.IndividualPaidAnnually;
        break;

      case enumSubscriptionTypeConvert.toString(enumSubscriptionType.IndividualPaidMonthly):
        SubscriptionTypeEnum = enumSubscriptionType.IndividualPaidMonthly;
        break;

      case enumSubscriptionTypeConvert.toString(enumSubscriptionType.GroupPaidAnnually):
        SubscriptionTypeEnum = enumSubscriptionType.GroupPaidAnnually;
        break;

      case enumSubscriptionTypeConvert.toString(enumSubscriptionType.GroupPaidMonthly):
        SubscriptionTypeEnum = enumSubscriptionType.GroupPaidMonthly;
        break;

      default:
        throw new MdbError(`enumSubscriptionTypeConvert.fromString. Unrecognized/unsupported enumSubscriptionType string: ${SubscriptionTypeString}`, enumMdbErrorType.InvalidOperation);
    }

    return SubscriptionTypeEnum;
  }
}