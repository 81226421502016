import { RandomId } from '../../../utilities/RandomId';
import { typeUniqueId, typePersistableParentObjectType, typeUniqueIdWithUndefinedOption } from '../../../types';
import { enumObjectPersistenceState, enumPersistableObjectType, enumPersistableObjectClassName, enumSharingPermission } from '../../../enums';
import { IChannel, IChannelAsJson } from '.';
import { IChannelMetadata, ChannelMetadata } from '../ChannelMetada';
import { VersionAwarePersistable } from '../../persistence/VersionAwarePersistable';
import { JsonConverter } from '../../../utilities/JsonConverter';
import { IUserPersistenceData } from '../../persistence/UserPersistenceData';
import { IUserSharingPermissions } from '../../collaboration/UserSharingPermissions';
import { enumMdbErrorType } from '../../../../errorObjects/enums';
import { MdbError } from '../../../../errorObjects/MdbError';


const PRIVATE_CHANNEL_SUFFIX: string = '-Private';

/**
 * @class Channel Represents a collection of channel of information (topics, topic items, digital media, etc.)
 * {@linkcode IChannel}
 */
export class Channel extends VersionAwarePersistable implements IChannel {

  /**
   * @method Constructor method
   * @param {typeUniqueId} ownerId The Id of the owner (user or channel) of the instance
   * @param {typeUniqueId} id Unique Id of the instance
   * @param {typePersistableParentObjectType} parentObjectType The Parent's object type
   * @param {typeUniqueIdWithUndefinedOption} parentId Id of the object's parent
   * @param {enumObjectPersistenceState} objectState The state of the object since it was last persisted.
   * @param {string} name The name of the channel
   * @param {string} description (optional) A description for the channel
   * @param {IUserPersistenceData} userPersistenceData (optional) User-related persistence data
   * @param {string} topicNameAliasSingular (optional) The singular form of the alias for Topic members (eg, "Topic", "Lesson", "Tournament")
   * @param {string} topicNameAliasPlural (optional) The plural form of the alias for Topic members (eg, "Topics", "Lessons", "Tournaments")
   * @param {typeUniqueIdWithUndefinedOption} categoryId (optional) A category with which the channel is associated, if user wishes to organize channels into categories
   */
  constructor(
    ownerId: typeUniqueId,
    id: typeUniqueId = RandomId.newId(),
    parentObjectType: typePersistableParentObjectType,
    parentId: typeUniqueIdWithUndefinedOption,
    objectState: enumObjectPersistenceState,
    name: string,
    description?: string,
    userSharingPermissions?: Array<IUserSharingPermissions>,
    userPersistenceData?: IUserPersistenceData,
    topicNameAliasSingular?: string,
    topicNameAliasPlural?: string,
    categoryId?: typeUniqueIdWithUndefinedOption
  ) {
    super(ownerId, enumPersistableObjectClassName.Channel, enumPersistableObjectType.Channel, id, parentObjectType, parentId, objectState, userPersistenceData);

    this.name = name;

    if (description) {
      this.description = description;
    }

    if (topicNameAliasSingular) {
      this.topicNameAliasSingular = topicNameAliasSingular;
    }

    if (topicNameAliasPlural) {
      this.topicNameAliasPlural = topicNameAliasPlural;
    }

    if (categoryId) {
      this.categoryId = categoryId;
    }

    if (userSharingPermissions && userSharingPermissions.length > 0) {
      this.userSharingPermissions = [...userSharingPermissions];
    }

    // initlalize the ChannelMetadata
    this._channelMetadata = new ChannelMetadata(this.ownerId, RandomId.newId(), this.objectType, this.id, enumObjectPersistenceState.New);
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @property {string} name represents the name of the channel
   */
  private _name: string = '';

  // getter for _propName
  get name() {
    return this._name;
  }

  // setter for _propName
  set name(value) {
    this._name = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @property {string} _description property is the description for the ChannelItem
   */
  private _description: string = '';

  /**
   * @method description is an optional getter method for _description
   */
  get description() {
    return this._description;
  }

  /**
   * @method description is an optional setter method for _description
   * @param {string} value is the input value for setting _description
   */
  set description(value) {
    this._description = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @property {string} _topicNameAliasSingular The singular form of the alias for Topic members (eg, "Topic", "Lesson", "Tournament")
   */
  private _topicNameAliasSingular: string = '';

  /**
   * @method topicNameAliasSingular Getter method for _topicNameAliasSingular
   */
  get topicNameAliasSingular() {
    return this._topicNameAliasSingular;
  }

  /**
   * @method topicNameAliasSingular Setter method for _topicNameAliasSingular
   * @param {string} value Input value for setting _topicNameAliasSingular
   */
  set topicNameAliasSingular(value) {
    this._topicNameAliasSingular = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @property {string} _topicNameAliasPlural The plural form of the alias for Topic members (eg, "Topics", "Lessons", "Tournaments")
   */
  private _topicNameAliasPlural: string = '';

  /**
   * @method topicNameAliasPlural Getter method for _topicNameAliasPlural
   */
  get topicNameAliasPlural() {
    return this._topicNameAliasPlural;
  }

  /**
   * @method topicNameAliasPlural Setter method for _topicNameAliasPlural
   * @param {string} value Input value for setting _topicNameAliasPlural
   */
  set topicNameAliasPlural(value) {
    this._topicNameAliasPlural = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @property {typeUniqueIdWithUndefinedOption} _categoryId The Id of the category with which the channel is associated
   */
  private _categoryId: typeUniqueIdWithUndefinedOption = undefined;

  /**
   * @method categoryId Getter method for _categoryId
   */
  get categoryId() {
    return this._categoryId;
  }

  /**
   * @method categoryId Setter method for _categoryId
   * @param {typeUniqueIdWithUndefinedOption} value Input value for setting _categoryId
   */
  set categoryId(value) {
    this._categoryId = value;
  }
  /*-----------------------------------------------*/

  // /*-----------------------------------------------*/
  // /**
  //  * @property {Array<typeUniqueId>} _sharedUsers is a collection of userIds that represents users who are members of the channel
  //  */
  // private _sharedUsers: Array<typeUniqueId> = [];

  // // getter for _sharedUsers
  // get sharedUsers() {
  //   return this._sharedUsers;
  // }

  // // setter for _sharedUsers
  // set sharedUsers(value) {
  //   this._sharedUsers = value;
  // }
  // /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @property {Array<IUserSharingPermissions>} _userSharingPermissions is a collection of UserIds/SharingPermissions of users who are authorized to share the channel
   */
  private _userSharingPermissions: Array<IUserSharingPermissions> = [];

  // getter for _userSharingPermissions
  get userSharingPermissions(): Array<IUserSharingPermissions> {
    return this._userSharingPermissions;
  }

  // setter for _userSharingPermissions
  set userSharingPermissions(value: Array<IUserSharingPermissions>) {
    this._userSharingPermissions = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @property {IChannelMetadata} _channelMetadata property (complete the description)
   */
  private _channelMetadata: IChannelMetadata;

  /**
   * @method channelMetadata is an optional getter method for _channelMetadata
   */
  get channelMetadata(): IChannelMetadata {
    return this._channelMetadata;
  }

  /**
   * @method channelMetadata is an optional setter method for _channelMetadata
   * @param {IChannelMetadata} value is the input value for setting _channelMetadata
   */
  set channelMetadata(value: IChannelMetadata) {
    // ensure that the 'parentObject', 'parentObjectType' and 'parentId' are set to this instance's 'this', 'objectType' and 'id', respectively.
    value.parentObject = this;
    value.parentObjectType = this.objectType;
    value.parentId = this.id;
    this._channelMetadata = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method addUserAsChannelMember Adds a user to be a member to share the channel.
   * @param {IUserSharingPermissions} userSharingPermissions The Id of the user that will become a sharing member of the channel.
   * @returns {boolean} Whether the user was added as a member of the channel. (Note: If the user is already a member before calling this method, 'false' will be returned.)
   */
  addUserAsChannelMember(userSharingPermissions: IUserSharingPermissions): boolean {
    let userAddedToChannel: boolean = false;

    // determine whether the given userId already exists within the sharedUsers array
    // const idxUserId: number = this.sharedUsers.findIndex((element: string) => element === userId);
    const idxUserId: number = this.userSharingPermissions.findIndex((element: IUserSharingPermissions) => element.userId === userSharingPermissions.userId);
    // if the userId was not found in the array (if the returned index value === -1)
    if (idxUserId === -1) {
      // add the userId to the sharedUsers array (using the Array.push() method)
      this.userSharingPermissions.push(userSharingPermissions);
      userAddedToChannel = true;
    }

    return userAddedToChannel;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method removeUserAsChannelMember Removes a user as a member of the channel.
   * @param {typeUniqueId} userId The Id of the user that is to be removed from being a member of the channel.
   * @returns {boolean} Whether the user was removed as a member of the channel. (Note: If the user is not a member before calling this method, 'false' will be returned.)
   */
  removeUserAsChannelMember(userId: typeUniqueId): boolean {
    let userRemovedFromChannel = false;

    // determine whether the given userId already exists within the sharedUsers array
    const idxUserSharingPermissions: number = this.userSharingPermissions.findIndex((element: IUserSharingPermissions) => element.userId === userId);
    // if the userId was found in the array (if the returned index value !== -1)
    if (idxUserSharingPermissions !== -1) {
      // remove the userId from the sharedUsers array (using the Array.splice() method, where the first parm is the index of the value to be removed and
      // the second parm is the number of values to be removed)
      this.userSharingPermissions.splice(idxUserSharingPermissions, 1);
      userRemovedFromChannel = true;
    }

    return userRemovedFromChannel;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method getUserSharingPermission Gets the sharing permission for a given user, per the userId parameter.
   * @param {typeUniqueId} userId The Id of the user for which sharing permission within the channel is to be obtained.
   * @returns {enumSharingPermission | undefined} Either an enumSharingPermission value, if the user has been granted sharing permission 
   *                                              to the channel, or 'undefined' if the user has not been granted sharing permission to the 
   *                                              channel.
   */
  getUserSharingPermission(userId: typeUniqueId): enumSharingPermission {
    let sharingPermission: enumSharingPermission = enumSharingPermission.None;

    // if the user is the owner of the Channel, ...
    if (userId === this.ownerId) {
      // the user has implied Admin permission to the Channel
      sharingPermission = enumSharingPermission.Admin;
    } else {
      // otherwise, we need to determine the sharee permission granted to the user
      // determine whether the given userId exists within the userSharingPermissions array
      const idxUserSharingPermissions: number = this.userSharingPermissions.findIndex((element: IUserSharingPermissions) => element.userId === userId);
      // if the userId was found in the array (if the returned index value !== -1), ...
      if (idxUserSharingPermissions !== -1) {
        // fetch the sharingPermission value for the user
        sharingPermission = this.userSharingPermissions[idxUserSharingPermissions].sharingPermission;
      }
    }

    return sharingPermission;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method setUserSharingPermissions Sets the sharing permission for a given user, per the userId and sharingPermission parameters.
   * @param {typeUniqueId} userId The Id of the user for which sharing permission within the channel is to be obtained.
   * @param {enumSharingPermission} sharingPermission The SharingPermission value to be set for the user within the channel
   * @returns {boolean} Indicates whether the permission value has been set (depends on whether the given userId has sharing permission)
   */
  setUserSharingPermission(userId: typeUniqueId, sharingPermission: enumSharingPermission): boolean {
    let sharingPermissionHasBeenChanged: boolean = false;

    // search for the given userId within the userSharingPermissions array
    const idxUserSharingPermissions: number = this.userSharingPermissions.findIndex((element: IUserSharingPermissions) => element.userId === userId);
    // if the userId was found in the array (if the returned index value !== -1), ...
    if (idxUserSharingPermissions !== -1) {
      // if the sharingPermission is different from what's already set...
      if (this.userSharingPermissions[idxUserSharingPermissions].sharingPermission !== sharingPermission) {
        // set the sharingPermission value for the user
        this.userSharingPermissions[idxUserSharingPermissions].sharingPermission = sharingPermission;

        sharingPermissionHasBeenChanged = true;
      }
    }

    return sharingPermissionHasBeenChanged;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method copy Performs a "deep copy" of the instance, which includes a copy of all contained objects.
   * @returns {IChannel} A "deep copy" of the object instance, including a "deep copy" of all contained objects.
   */
  copy(): IChannel {
    // use Object.create() to create a new instance, and then Object.assign() to assign all core properties
    let copyOfObject: IChannel = Object.create(Channel.prototype);
    Object.assign(copyOfObject, this);

    // copy the contained objects
    if (this.channelMetadata !== undefined) {
      copyOfObject.channelMetadata = Object.create(ChannelMetadata.prototype);
      copyOfObject.channelMetadata = this.channelMetadata.copy();
    }

    if (this.userSharingPermissions !== undefined) {
      copyOfObject.userSharingPermissions = [...this.userSharingPermissions];
    }

    return copyOfObject;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method toJSON Serializes an instance of this class to a JSON object, including contained
   * objects (if requested).
   * @param {boolean} includeContainedObjects A boolean flag indicating whether to include contained objects.
   * @returns A JSON object with serialized data from 'this' class instance.
   */
  toJSON(includeContainedObjects: boolean = true): IChannelAsJson {
    try {
      // prepare  JSON object for return, starting with a call to the direct parent base 
      // class to get its members added to the JSON object
      const jsonObject: IChannelAsJson = super.toJSON(includeContainedObjects);

      // copy any additional field values to the json object 
      jsonObject.name = this.name;
      jsonObject.description = this.description;
      jsonObject.topicNameAliasSingular = this.topicNameAliasSingular;
      jsonObject.topicNameAliasPlural = this.topicNameAliasPlural;
      jsonObject.categoryId = this.categoryId === undefined ? 'undefined' : this.categoryId;

      // if requested to include contained objects, serialize contained objects
      if (includeContainedObjects) {
        jsonObject.channelMetadata = this.channelMetadata.toJSON(true);

        if (this.userSharingPermissions) {
          // jsonObject.userSharingPermissions = JsonConverter.toJSONArray(this.userSharingPermissions);

          // We're going to serialize the array of SharingPermissions to an object with each SharingPermission as a sub-object, 
          // with the userId of the SharingPermission object serving as the object name.
          // example:
          // userSharingPermissions[userSharingPermission1, userSharingPermission2, userSharingPermission3] will become
          // userSharingPermissionsObject = { 'userSharingPermission1.userId': { userId: userSharingPermission1.userId, sharingPermission: userSharingPermission1.sharingPermission}, 
          //                                  'userSharingPermission2.userId': { userId: userSharingPermission2.userId, sharingPermission: userSharingPermission2.sharingPermission}, 
          //                                  'userSharingPermission3.userId': { userId: userSharingPermission3.userId, sharingPermission: userSharingPermission3.sharingPermission}, 
          //                                }
          //
          // In addition, we're going to create an array of userIds for the shared users, which will be used by the backend to easily and quickly query
          // for users with whom the channel has been shared (the owning user will always be a shared user, including for the owner's private channel)


          // start with an empty object for the userSharingPermissions
          let userSharingPermissionsObject: {} = {};

          // start with an empty array for the sharedUsers
          let sharedUsers: Array<string> = [];

          // traverse the objects in the array and create a sub-object
          this.userSharingPermissions.forEach((userSharingPermission: IUserSharingPermissions, index: number) => {
            // add the current userSharingPermission as a new sub-object
            userSharingPermissionsObject[`${userSharingPermission.userId}`] = { userId: userSharingPermission.userId, sharingPermission: userSharingPermission.sharingPermission };

            // add the userId as an array element to the sharedUsers array
            sharedUsers.push(userSharingPermission.userId);
          });

          // copy the local userSharingPermissionsObject, with sub-objects, to the jsonObject.userSharingPermissions
          jsonObject.userSharingPermissions = { ...userSharingPermissionsObject };

          // copy the array of userIds for sharedUsers to the jsonObject.sharedUsers
          jsonObject.sharedUsers = [...sharedUsers];


          // // Because our backend storage won't support storing an array of objects, we're going to normalize each array element (an object) to be a stringified 
          // // version of the object.

          // // start with an empty array
          // let userSharingPermissionsStringArray: Array<string> = [];

          // // traverse the objects in the original objects array...
          // this.userSharingPermissions.forEach((userSharingPermission: IUserSharingPermissions, index: number) => {
          //   // ... stringify the userSharingPermission object ...
          //   const stringifiedSharingPermission: string = JSON.stringify(userSharingPermission);

          //   // ... and push the stringified object to the string array
          //   userSharingPermissionsStringArray.push(stringifiedSharingPermission);
          // });

          // // now, assign the resulting array to the jsonObject
          // jsonObject.userSharingPermissions = userSharingPermissionsStringArray;
        }
      }

      return jsonObject;

    } catch (error: any) {
      // TODO: log error
      // re-throw error
      throw error;
    }
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method fromJSON Derializes an instance of this class from a JSON object, along with any contained 
   * objects (if requested).
   * @param {IChannelAsJson} jsonObject A JSON version of a class instance.
   * @param {boolean} includeContainedObjects A boolean flag indicating whether to include contained objects.
   * @returns A ImageLink instance with values copied from the jsonObject
   */
  static fromJSON(jsonObject: IChannelAsJson, includeContainedObjects: boolean = true): IChannel {
    try {
      // create a new instance of this class
      let channelObject: Channel = Object.create(Channel.prototype);

      // call the 'fromJSONProtected()' method on the immediate base to get its property values loaded
      channelObject = super.fromJSONProtected(channelObject, jsonObject, includeContainedObjects);

      // copy any additional field values from the json object 
      if (jsonObject.name) {
        channelObject.name = jsonObject.name;
      }

      if (jsonObject.description) {
        channelObject.description = jsonObject.description;
      }

      if (jsonObject.topicNameAliasSingular) {
        channelObject.topicNameAliasSingular = jsonObject.topicNameAliasSingular;
      }

      if (jsonObject.topicNameAliasPlural) {
        channelObject.topicNameAliasPlural = jsonObject.topicNameAliasPlural;
      }

      channelObject.categoryId = (jsonObject.categoryId === 'undefined' ? undefined : jsonObject.categoryId)

      // if request is to include contained objects, copy additional fields
      if (includeContainedObjects) {
        if (jsonObject.channelMetadata) {
          channelObject.channelMetadata = JsonConverter.fromJSON(ChannelMetadata, jsonObject.channelMetadata);
        }

        // start with an empty array for userSharingPermissions
        let userSharingPermissionsArray: Array<IUserSharingPermissions> = [];

        // if the jsonObject.userSharingPermissions object is not 'undefined' and it has some subObjects (some properties)...
        if (jsonObject.userSharingPermissions && Object.entries(jsonObject.userSharingPermissions).length > 0) {
          // channelObject.userSharingPermissions = JsonConverter.arrayFromJSONArray(UserSharingPermissions, jsonObject.userSharingPermissions);

          // We need to reverse the process used when serializing to JSON. When serializing, we mapped an array of IUserSharingPermissions objects into
          // an object with a series of IUserSharingPermissions objects, each keyed with the 'userId' property of the underlying IUserSharingPermissions object.
          // So, we need to traverse the collection of subObjects and create an array of IUserSharingPermissions objects from them.

          // using 'for ... in' loop, traverse the subObjects in the jsonObject.userSharingPermissions meta object
          for (var userSharingPermissionSubObjectKey in jsonObject.userSharingPermissions) {
            // only consider properties that are part of the meta object, not underlying parent objects
            if (jsonObject.userSharingPermissions.hasOwnProperty(userSharingPermissionSubObjectKey)) {
              // get the subObject by using the key from the 'for ... in' loop declaration and add the subObject, using the spread operator to copy it, to the array
              const subObject: IUserSharingPermissions = { ...jsonObject.userSharingPermissions[userSharingPermissionSubObjectKey] };
              userSharingPermissionsArray.push(subObject);
            }
          }
        }

        // use the spread operator to set the channel object's userSharingPermissions array to be a copy of the elements in the local userSharingPermissionsArray
        channelObject.userSharingPermissions = [...userSharingPermissionsArray];

        // NOTE: We do NOT need to de-serialize the sharedUsers array, since it isn't needed in the IChannel object (it's only needed when serializing for the backend)


        // if (jsonObject.userSharingPermissions) {

        //   // We need to reverse the process used when serializing to JSON. When serializing, we mapped an array of IUserSharingPermissions objects into
        //   // an array of stringified objects. So, we need to traverse the array of stringified objects and turn it into an array of IUserSharingPermissions objects.

        // // start with an empty array for userSharingPermissions
        // let userSharingPermissionsArray: Array<IUserSharingPermissions> = [];

        //   // traverse the objects in the original objects array...
        //   jsonObject.userSharingPermissions.forEach((stringifiedUserSharingPermission: string, index: number) => {
        //     // ... unstringify the userSharingPermission object ...
        //     const userSharingPermission: IUserSharingPermissions = JSON.parse(stringifiedUserSharingPermission);

        //     // ... and push the unstringified object to the string array
        //     userSharingPermissionsArray.push({...userSharingPermission});
        //   });

        //   // now, assign the resulting array to the channelObject
        //   channelObject.userSharingPermissions = [...userSharingPermissionsArray];
        // }

      }

      return channelObject;

    } catch (error: any) {
      // TODO: log error
      // re-throw error
      throw error;
    }
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method userPrivateChannelIdFromUserId Derives a Channel Id from a User Id. (Format: UserId + "-Private", which stands for "Private Channel".)
   * @param {typeUniqueId} userId The Id of a user for which a Channel Id is to be derived.
   * @returns {typeUniqueId} The generated Private Channel Id.
   */
  static userPrivateChannelIdFromUserId(userId: typeUniqueId): typeUniqueId {
    return userId + PRIVATE_CHANNEL_SUFFIX;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method userPrivateChannelIdFromNonPrivateId Derives a Channel Id from an Id. (Format: ChanneldId + "-Private", which stands for "Private Channel".)
   * @param {typeUniqueId} channelId The Id of a channel for which a Private Channel Id is to be derived.
   * @returns {typeUniqueId} The generated Private Channel Id.
   */
  static userPrivateChannelIdFromNonPrivateId(channelId: typeUniqueId): typeUniqueId {
    return channelId + PRIVATE_CHANNEL_SUFFIX;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method userIdFromUserPrivateChannelId Derives a User Id from a user's Private Channel Id. (Format: UserId + "-Private", which stands for "Private Channel".)
   * @param {typeUniqueId} privateChannelId The Id of a Private Channel for which a User Id is to be derived.
   * @returns {typeUniqueId} The derived User Id.
   */
  static userIdFromUserPrivateChannelId(privateChannelId: typeUniqueId): typeUniqueId {
    const idxSubStringLocation = privateChannelId.lastIndexOf(PRIVATE_CHANNEL_SUFFIX);
    if (idxSubStringLocation === -1) {
      // didn't find the suffix "-Private"
      throw new MdbError(`Channel Id does not have a '${PRIVATE_CHANNEL_SUFFIX}' suffix`, enumMdbErrorType.InconsistentData);
    }

    const userId = privateChannelId.slice(0, idxSubStringLocation);

    return userId;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method isChannelIdForPrivateChannel Determines whether the given channelId represents a user's Private Channel.
   * @param {typeUniqueId} channelId The Id of a Channel for which an evaluation is to be performed.
   * @returns {boolean} Whether the given channelId represents a user's Private Channel.
   */
  static isChannelIdForPrivateChannel(channelId: typeUniqueId): boolean {
    // if the channelId ends with the Private Channel Suffix, it is representative of a Private Channel
    let channelIdIsForPrivateChannel = channelId.endsWith(PRIVATE_CHANNEL_SUFFIX)

    return channelIdIsForPrivateChannel;
  }

  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method coreChannelIdFromChannelId Derives a core Channel Id from either a normal channel or from a Private Channel Id. 
   *                                    (Format for a private channel: ChannelId + "-Private", which indicates a user's "Private Channel".)
   * @param {typeUniqueId} channelId The Id of a Channel, which could be a normal ChannelId or a Private ChannelId.
   * @returns {typeUniqueId} The resulting ChannelId.
   */
  static coreChannelIdFromChannelId(channelId: typeUniqueId): typeUniqueId {
    let channelIdToReturn: typeUniqueId = channelId;

    if (Channel.isChannelIdForPrivateChannel(channelId)) {
      const idxSubStringLocation = channelId.lastIndexOf(PRIVATE_CHANNEL_SUFFIX);
      if (idxSubStringLocation === -1) {
        // didn't find the suffix "-Private"
        throw new MdbError(`Given Channel Id (${channelId}) does not have a '${PRIVATE_CHANNEL_SUFFIX}' suffix`, enumMdbErrorType.InconsistentData);
      }

      channelIdToReturn = channelId.slice(0, idxSubStringLocation);
    }

    return channelIdToReturn;
  }
  /*-----------------------------------------------*/

}
