import React, { PropsWithChildren } from 'react';
import { Box, BoxProps, Dialog, DialogContent, LinearProgress, LinearProgressProps, Typography, TypographyProps } from '@mui/material';
import { styled } from '@mui/styles';

/*** Using the Material UI Emotion Styling library, declare 'styled' instances for each area/object. 
 *** NOTE: These must be declared outside of the React Functional Component to ensure that the styled 
 *** objects will be properly rendered within the DOM. 
 ***/

// a styled Box (equivalent to a <div>), providing an area to display the overall Progress Bar
const StyledBoxForProgressBarArea = styled((props: BoxProps) => (
  <Box
    {...props}
  />
))(({ theme }) => ({
  paddingRight: theme.spacing(3),
  display: "flex",
  flexGrow: 1,
  flexDirection: "column",
  justifyContent: "center",
  justifyItems: 'center',
  [theme.breakpoints.up('xs')]: {
    width: '60vw',
  },
  [theme.breakpoints.up('sm')]: {
    width: '50vw',
  },
  [theme.breakpoints.up('md')]: {
    width: '40vw',
  },
  [theme.breakpoints.up('lg')]: {
    width: '30vw',
  },
  [theme.breakpoints.up('xl')]: {
    width: '25vw',
  },
}));

// a styled LinearProgress to display the Progress Bar
const StyledLinearProgress = styled((props: LinearProgressProps) => (
  <LinearProgress
    {...props}
  />
))(({ theme }) => ({
  width: "100%",
}));

// a styled Box (equivalent to a <div>), providing an area to display the Progress Label
const StyledBoxForProgressLabelArea = styled((props: BoxProps) => (
  <Box
    {...props}
  />
))(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  width: "100%",
}));

// a styled Typography to display the Progress Label
const StyledTypopgraphyForProgressLabel = styled((props: TypographyProps) => (
  <Typography
    variant="caption"
    {...props}
  />
))(({ theme }) => ({
}));

export interface IActivityIndicatorDialogProps extends PropsWithChildren<unknown> {
  activityLabel?: string;
}

const ActivityIndicatorDialog: React.FC<IActivityIndicatorDialogProps> = (props) => {

  ActivityIndicatorDialog.displayName = 'ActivityIndicatorDialog';

  const { activityLabel: progressLabel } = props;

  return (
    <>
      <Dialog open={true} >
        <DialogContent>
          <StyledBoxForProgressBarArea>
            <StyledLinearProgress />
            <StyledBoxForProgressLabelArea>
              {/* if an action progress label has been provided, display it */}
              {progressLabel &&
                <StyledTypopgraphyForProgressLabel>{progressLabel}</StyledTypopgraphyForProgressLabel>
              }
            </StyledBoxForProgressLabelArea>
          </StyledBoxForProgressBarArea>
        </DialogContent>
      </Dialog>
    </>
  );

}

export default ActivityIndicatorDialog;
