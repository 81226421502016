import React, { PropsWithChildren, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Box, BoxProps, Checkbox, CheckboxProps, FormControlLabel, TextField, Typography, TypographyProps } from '@mui/material';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import FormWithActionBar from '../FormWithActionBar/FormWithActionBar';
import { ControlsStringAssets, KeyValuePairsStringAssets, MessagesStringAssets } from '../../../assets/stringAssets';
import { styled } from '@mui/styles';
import { IUserCategoriesAndChannelsContextData, useUserCategoriesAndChannelsContext } from '../../providersAndContexts/userCategoriesAndChannels';
import { IChannelViewModel } from '../../../dataObjects/viewModels/channelViewModel';
import { enumCategoriesAndChannelsDataPreparationStatus } from '../../enums';
import { enumDigitalMediaType } from '../../../dataObjects/enums';
import { IDigitalMediaSearchCriteria } from '../../../dataObjects/models/digitalMediaSearch/digitalMediaSearchCriteria';

// an interface to be used within this component to capture Channel View Models and their selected state
interface IChannelViewModelWithSelectedState {
  channelViewModel: IChannelViewModel; // a channel view model
  selected: boolean; // whether the channel view model has been selected for search
}

// an interface to be used within this component to capture Digital Media Types and their selected state
interface IDigitalMediaTypeWithSelectedState {
  digitalMediaTypeDisplayName: string; // a string representing the display name of the digital media type
  digitalMediaType: string; // a string identifying a specific digital media type
  selected: boolean; // whether the digital media type has been selected for search
}

interface IDigitalMediaSearchCriteriaFormValues {
  searchPhrase: string;
}

// using 'yup', set up a schema for the form field values
const schema = yup.object().shape({
  searchPhrase: yup
    .string()
    .required(ControlsStringAssets.digitalMediaSearchCriteriaSearchPhraseRequired),
});

/*** Using the Material UI Emotion Styling library, declare 'styled' instances for each area/object. 
 *** NOTE: These must be declared outside of the React Functional Component to ensure that the styled 
 *** objects will be properly rendered within the DOM. 
 ***/

// a styled Box (equivalent to a <div>), providing a fieldset control to surround details for selections
const StyledBoxForFieldSet = styled((props: BoxProps) => (
  <Box
    component='fieldset'
    {...props}
  />
))(({ theme }) => ({
  border: theme.fieldSet.border,
  borderRadius: theme.fieldSet.borderRadius,
  marginTop: '0.3rem',
}));

// a styled Box (equivalent to a <div>), providing a legend control to associate with the fieldset
const StyledBoxForLegend = styled((props: BoxProps) => (
  <Box
    component='legend'
    {...props}
  />
))(({ theme }) => ({
  color: theme.fieldSet.legendFontColor,
  fontSize: theme.fieldSet.legendFontSize,
}));

// a styled Box (equivalent to a <div>), providing an area for displaying channel selections
const StyledBoxForChannelSelections = styled((props: BoxProps) => (
  <Box
    {...props}
  />
))(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  border: theme.scrollableDiv.border,
  borderRadius: theme.scrollableDiv.borderRadius,
  height: '6rem',
  overflow: 'auto',
  padding: '0.25rem 0.5rem'
}));

// a styled Checkbox for displaying lists of checkboxes
const StyledCheckboxForCheckboxList = styled((props: CheckboxProps) => (
  <Checkbox
    // boxsizing='border-box'
    size='small'
    {...props}
  />
))(({ theme }) => ({
  height: '1rem',
  // color: 'green'
}));

// a styled Typography for displaying lists of checkboxes
const StyledTypographyForCheckboxList = styled((props: TypographyProps) => (
  <Typography
    variant="body2"
    {...props}
  />
))(({ theme }) => ({
}));


// a styled Box (equivalent to a <div>), providing an area for displaying digital media type selections
const StyledBoxForDigitalMediaTypeSelections = styled((props: BoxProps) => (
  <Box
    {...props}
  />
))(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  border: theme.scrollableDiv.border,
  borderRadius: theme.scrollableDiv.borderRadius,
  height: '6rem',
  overflow: 'auto',
  padding: '0.25rem 0.5rem'
}));

export interface IDigitalMediaSearchCriteriaFormProps extends PropsWithChildren<unknown> {
  /**
   * @property {IDigitalMediaSearchCriteria} digitalMediaSearchCriteria (optional) Default serch criteria passed in
   */
  digitalMediaSearchCriteria: IDigitalMediaSearchCriteria,

  /**
   * @property {(topic: ITopicItem) => Promise<void>} onSubmit Method to call for submitting the form for a save operation
   */
  onSubmit: (digitalMediaSearchCriteria: IDigitalMediaSearchCriteria) => Promise<void>,
}

const DigitalMediaSearchCriteriaForm: React.FC<IDigitalMediaSearchCriteriaFormProps> = (props: IDigitalMediaSearchCriteriaFormProps) => {
  DigitalMediaSearchCriteriaForm.displayName = 'Search Digital Media Criteria Form';

  // whether to display console logs (console.log statements)
  const displayConsoleLogs: boolean = false;

  displayConsoleLogs && console.log(`Entered/Refreshed DigitalMediaSearchCriteriaForm component. props.digitalMediaSearchCriteria: ${JSON.stringify(props.digitalMediaSearchCriteria)}`);

  // get required arguments from props
  const { digitalMediaSearchCriteria, onSubmit } = props;

  // set up details for ReactHookForm
  const { register, formState, formState: { errors }, handleSubmit } = useForm({
    defaultValues: {
      searchPhrase: digitalMediaSearchCriteria.searchPhrase,
    },
    // mode: "onBlur",
    mode: "all",
    resolver: yupResolver(schema)
  });

  const { ref: searchPhraseReg, ...searchPhraseProps } = register("searchPhrase", { required: true });

  // get UserCategoriesAndChannelsContextData from the UserCategoriesAndChannelsProvider using a custom 
  // hook (useUserCategoriesAndChannelsContext) so that we can determine the channels that are accessible by
  // the current user
  const userCategoriesAndChannelsContext: IUserCategoriesAndChannelsContextData = useUserCategoriesAndChannelsContext();

  // // Channel View Models we'll use as a reference within the page, indicating channels accessible by the current user
  // // This is merely a simplification of the data contained in the userCategoriesAndChannelsContext
  // const [channelViewModels, setChannelViewModels] = useState<Array<IChannelViewModel>>([]);

  // Current value of the search phrase. Capture this to provide flexibility in assessing the state of the form.
  const [currentSearchPhrase, setCurrentSearchPhrase] = useState<string>(digitalMediaSearchCriteria.searchPhrase);

  // to keep track of which channels have been selected to be included in a search 
  const [channelViewModelsWithSelectedState, setChannelViewModelsWithSelectedState] = useState<Array<IChannelViewModelWithSelectedState>>([]);

  // to keep track of which digital media types have been selected to be included in a search 
  const [digitalMediaTypesWithSelectedState, setDigitalMediaTypesWithSelectedState] = useState<Array<IDigitalMediaTypeWithSelectedState>>([]);

  // whether to search all channels accessible to the current user
  const [searchAllAccessibleChannels, setSearchAllAccessibleChannels] = useState<boolean>(false);

  // whether to include all digital media types in the search
  const [includeAllDigitalMediaTypes, setIncludeAllDigitalMediaTypes] = useState<boolean>(false);

  // a useEffect hook for initiation of component
  useEffect(() => {
    // get the collection of all digital media types from the enumDigitalMediaType enum
    const digitalMediaTypes: Array<string> = Object.values(enumDigitalMediaType);

    // displayConsoleLogs && console.log(`In DigitalMediaSearchCriteriaForm. digitalMediaTypes: ${JSON.stringify(digitalMediaTypes)}`);

    // if the lengths differ between the local digitalMediaTypes variable and the component state variable digitalMediaTypesWithSelectedState,
    // then we need to prepare the data for setting digitalMediaTypesWithSelectedState variable
    if (digitalMediaTypes.length !== digitalMediaTypesWithSelectedState.length) {
      // create an array of IDigitalMediaTypeWithSelectedState objects for tracking which digital media types to include in a search
      const newDigitalMediaTypesWithSelectedState: Array<IDigitalMediaTypeWithSelectedState> = [];
      // default each digital media type to 'false', but will be set to true if indicated in the  digitalMediaSearchCriteria passed in
      digitalMediaTypes.map(digitalMediaType => {
        const digitalMediaTypeDisplayName: string | undefined = KeyValuePairsStringAssets.getValueFromKey(KeyValuePairsStringAssets.digitalMediaTypeNamePluralizedKeyValuePairs, digitalMediaType);
        newDigitalMediaTypesWithSelectedState.push({ digitalMediaTypeDisplayName: digitalMediaTypeDisplayName ?? digitalMediaType, digitalMediaType: digitalMediaType, selected: false });
      })

      // using the digitalMediaSearchCriteria passed in, set the selected state of the digital media types
      newDigitalMediaTypesWithSelectedState.map(digitalMediaTypeWithSelectedState => {
        // if the flag is set to indicate that all digital media types should be selected, set the current one to 'true'
        if (digitalMediaSearchCriteria.includeAllDigitalMediaTypes) {
          digitalMediaTypeWithSelectedState.selected = true;
        } else {
          // if we find the current digital media type in the array, set the current one to 'true'
          if (digitalMediaSearchCriteria.digitalMediaTypes.find(mediaType => mediaType === digitalMediaTypeWithSelectedState.digitalMediaType) !== undefined) {
            digitalMediaTypeWithSelectedState.selected = true;
          }
        }
      });

      setDigitalMediaTypesWithSelectedState(newDigitalMediaTypesWithSelectedState);
    }

  });

  // a useEffect hook to respond to changes in digitalMediaTypesWithSelectedState
  useEffect(() => {
    // determine whether the 'include all digital media types' needs to be selected (if already at its proper state, do nothing)
    if (allDigitalMediaTypesHaveSameCheckedState(digitalMediaTypesWithSelectedState, true)) {
      if (!includeAllDigitalMediaTypes) {
        setIncludeAllDigitalMediaTypes(true);
      }
    } else {
      if (includeAllDigitalMediaTypes) {
        setIncludeAllDigitalMediaTypes(false);
      }
    }

    evaluateWhetherFormIsReadyForSearch();
  }, [digitalMediaTypesWithSelectedState]);

  // a useEffect hook to respond to changes in the status of the Categories and Channels data
  useEffect(() => {
    // when the data preparation is complete, set all data variables
    if (userCategoriesAndChannelsContext.state.status === enumCategoriesAndChannelsDataPreparationStatus.DataPreparationComplete) {
      // create an array of IChannelViewModelWithSelectedState objects, one for each Channel View Model
      const newChannelViewModelsWithSelectedState: Array<IChannelViewModelWithSelectedState> = [];
      if (userCategoriesAndChannelsContext.state.channelViewModels && (userCategoriesAndChannelsContext.state.channelViewModels?.length > 0)) {
        // traverse through the latest set of Channel View Models using the map operation
        userCategoriesAndChannelsContext.state.channelViewModels.map(channelViewModel => {
          // default the selected state of the current channel view model to false
          let selectedState: boolean = false;

          // using the digital media search criteria passed in, if the flag is set to indicate that all channels 
          // should be selected, set the current one to 'true'
          if (digitalMediaSearchCriteria.searchAllAccessibleChannels) {
            selectedState = true;
          } else {
            // using the digital media search criteria passed in, if the Id of the current channelViewModel.channel object is found
            // in the array of selected channel Ids for the digital media search criteria passed in, set the selected state to true
            if (digitalMediaSearchCriteria.channelIds.find(channelId => channelId === channelViewModel.channel.id) !== undefined) {
              selectedState = true;
            }
          }

          // create an object that is a Channel View Model with selected state
          let channelViewModelWithSelectedState = { channelViewModel: channelViewModel, selected: selectedState };

          // add the current Channel View Model to the array with its selected state
          newChannelViewModelsWithSelectedState.push(channelViewModelWithSelectedState);

        });

        setChannelViewModelsWithSelectedState(newChannelViewModelsWithSelectedState);
      }
    }
  }, [userCategoriesAndChannelsContext, userCategoriesAndChannelsContext.state.status]);

  // a useEffect hook to respond to changes in channelViewModelsWithSelectedState
  useEffect(() => {
    // determine whether the 'search all accessible channels' needs to be selected (if already at its proper state, do nothing)
    if (allChannelsHaveSameCheckedState(channelViewModelsWithSelectedState, true)) {
      if (!searchAllAccessibleChannels) {
        setSearchAllAccessibleChannels(true);
      }
    } else {
      if (searchAllAccessibleChannels) {
        setSearchAllAccessibleChannels(false);
      }
    }

    evaluateWhetherFormIsReadyForSearch();
  }, [channelViewModelsWithSelectedState]);

  // a useEffect hook to respond to changes in includeAllDigitalMediaTypes & searchAllAccessibleChannels
  useEffect(() => {
    // evaluate whether to indicate that form is ready to perform a search
    evaluateWhetherFormIsReadyForSearch();
  }, [currentSearchPhrase, includeAllDigitalMediaTypes, searchAllAccessibleChannels]);

  // for testing whether the form is in a valid state (cast 'isValid' to 'formIsValid')
  const { isValid: formIsValid } = formState;

  const [formReadyForSearch, setFormReadyForSearch] = useState<boolean>(false);

  // handles a save/submit request from the form
  const handleSearchSubmit = async (data: IDigitalMediaSearchCriteriaFormValues) => {

    // fill in name & description of the Topic object passed in
    digitalMediaSearchCriteria.searchPhrase = data.searchPhrase;
    digitalMediaSearchCriteria.searchAllAccessibleChannels = searchAllAccessibleChannels;
    digitalMediaSearchCriteria.includeAllDigitalMediaTypes = includeAllDigitalMediaTypes;
    digitalMediaSearchCriteria.channelIds = createArrayOfSelectedChannelIds();
    digitalMediaSearchCriteria.digitalMediaTypes = createArrayOfSelectedDigitalMediaTypes();

    displayConsoleLogs && console.log(`In DigitalMediaSearchCriteriaForm.handleSearchSubmit. digitalMediaSearchCriteria: ${JSON.stringify(digitalMediaSearchCriteria)}`);

    // call the onSubmit handler passed in, supplying the Topic object
    await onSubmit(digitalMediaSearchCriteria);
  }

  // handles whether the search phrase value has changed
  function handleSearchPhraseChanged(event: React.ChangeEvent<HTMLInputElement>) {
    const newSearchPhrase: string = event.target.value.trim();
    setCurrentSearchPhrase(newSearchPhrase);

    // evaluate whether the form is ready for performing a search
    if (newSearchPhrase.length === 0) {
      setFormReadyForSearch(false);
    } else {
      evaluateWhetherFormIsReadyForSearch();
    }
  }

  // handles a checked state change for the "All Accessible Channels" checkbox
  function handleSelectAllChannelsStateChanged(newCheckedState: boolean): void {
    setSearchAllAccessibleChannels(newCheckedState);
    setCheckedStateForAllChannels(newCheckedState);
  }

  // sets the checked state for all channels to the value provided
  function setCheckedStateForAllChannels(newCheckedState: boolean): void {
    // copy the array of IChannelViewModelWithSelectedState objects to a new array
    const newChannelViewModelsWithSelectedState: Array<IChannelViewModelWithSelectedState> = [];

    channelViewModelsWithSelectedState.map(channelViewModelWithSelectedState => {
      newChannelViewModelsWithSelectedState.push(channelViewModelWithSelectedState);
      channelViewModelWithSelectedState.selected = newCheckedState;
    });

    setChannelViewModelsWithSelectedState(newChannelViewModelsWithSelectedState);

    evaluateWhetherFormIsReadyForSearch();
  }

  // handles a change to the selected state of a channel -- whether to include it in the search
  function handleChannelSelectedStateChanged(channelId: string, newSelectedState: boolean): void {

    displayConsoleLogs && console.log(`DigitalMediaSearchCriteriaForm. Entered handleChannelSelectedStateChanged(). channelId: ${channelId}; newSelectedState: ${newSelectedState}`);

    // copy the array of IChannelViewModelWithSelectedState objects to a new array
    const newChannelViewModelsWithSelectedState: Array<IChannelViewModelWithSelectedState> = [];

    channelViewModelsWithSelectedState.map(channelViewModelWithSelectedState => {
      newChannelViewModelsWithSelectedState.push(channelViewModelWithSelectedState);

      // if the channel's Id matches the channelId parameter passed in, set the selectedState per the newSelectedState parameter passed in
      if (channelViewModelWithSelectedState.channelViewModel.channel.id === channelId) {
        channelViewModelWithSelectedState.selected = newSelectedState;
      }
    });

    setChannelViewModelsWithSelectedState(newChannelViewModelsWithSelectedState);

    // if the new state for the channel is "checked" (turned on)...
    if (newSelectedState === true) {
      // if all channel checkboxes are checked, we want to set the main "All Accessible Channels"
      if (allChannelsHaveSameCheckedState(newChannelViewModelsWithSelectedState, newSelectedState)) {

        displayConsoleLogs && console.log(`In DigitalMediaSearchCriteriaForm.handleChannelSelectedStateChanged(). Ready to call setSearchAllAccessibleChannels with: ${newSelectedState}`);

        setSearchAllAccessibleChannels(newSelectedState);
      }
    } else {
      // ensure that the main "All Accessible Channels" is turned off
      setSearchAllAccessibleChannels(newSelectedState);
    }

    evaluateWhetherFormIsReadyForSearch();
  }

  // returns whether all channels have the same checked state, per the given checked state
  function allChannelsHaveSameCheckedState(channelsAndSelectedState: Array<IChannelViewModelWithSelectedState>, checkedState: boolean): boolean {
    // default return value
    let allHaveSameCheckedState: boolean = true;

    // Use a 'for' loop to traverse the checked (selected) state of each channel checkbox. We use a 'for' loop because
    // we can break from the loop prematurely, if desired.
    for (let idx = 0; idx < channelsAndSelectedState.length; idx++) {
      // if the current channel's checkbox has a different checked state...
      if (channelsAndSelectedState[idx].selected !== checkedState) {
        // set the return value to indicate that not all have the same checked state
        allHaveSameCheckedState = false;
        // break prematurely from the 'for' loop, since we have already determined that not all checkboxes are in the same state
        break;
      }
    }

    displayConsoleLogs && console.log(`In DigitalMediaSearchCriteriaForm.allChannelsHaveSameCheckedState(). allHaveSameCheckedState: ${allHaveSameCheckedState}`);

    return allHaveSameCheckedState;
  }

  function isAnyChannelChecked(): boolean {
    let anyChannelChecked = false;

    // Use a 'for' loop to traverse the checked (selected) state of each channel checkbox. We use a 'for' loop because
    // we can break from the loop prematurely, if desired.
    for (let idx = 0; idx < channelViewModelsWithSelectedState.length; idx++) {
      // if the current channel's checkbox is in a checked state...
      if (channelViewModelsWithSelectedState[idx].selected === true) {
        // set the return value to indicate that at least one channel is in a checked state
        anyChannelChecked = true;
        // break prematurely from the 'for' loop, since we have already determined that at least one channel is in a checked state
        break;
      }
    }
    
    displayConsoleLogs && console.log(`In DigitalMediaSearchCriteriaForm.isAnyChannelChecked(). anyChannelChecked: ${anyChannelChecked}`);

    return anyChannelChecked;
  }

  // builds an array of channel Ids based on which channel Ids are currently selected
  function createArrayOfSelectedChannelIds(): Array<string> {
    const channelIds: Array<string> = [];

    for (let idx = 0; idx < channelViewModelsWithSelectedState.length; idx++) {
      // if the current channel's checkbox is in a checked state...
      if (channelViewModelsWithSelectedState[idx].selected) {
        // add its channelId to the array
        channelIds.push(channelViewModelsWithSelectedState[idx].channelViewModel.channel.id);
      }
    }

    return channelIds;
  }

  // handles a checked state change for the "All digital media types" checkbox
  function handleIncludeAllDigitalMediaTypesStateChanged(newCheckedState: boolean): void {
    setIncludeAllDigitalMediaTypes(newCheckedState);
    setCheckedStateForAllDigitalMediaTypes(newCheckedState);
  }

  // sets the checked state for all digital media types to the value provided
  function setCheckedStateForAllDigitalMediaTypes(newCheckedState: boolean): void {
    // copy the array of IDigitalMediaTypeWithSelectedState objects to a new array
    const newDigitalMediaTypesWithSelectedState: Array<IDigitalMediaTypeWithSelectedState> = [];

    digitalMediaTypesWithSelectedState.map(digitalMediaTypeWithSelectedState => {
      newDigitalMediaTypesWithSelectedState.push(digitalMediaTypeWithSelectedState);
      digitalMediaTypeWithSelectedState.selected = newCheckedState;
    });

    setDigitalMediaTypesWithSelectedState(newDigitalMediaTypesWithSelectedState);

    evaluateWhetherFormIsReadyForSearch();
  }

  // handles a change to the selected state of a digital media type -- whether to include it in the search
  function handleDigitalMediaTypeSelectedStateChanged(digitalMediaType: string, newSelectedState: boolean) {

    displayConsoleLogs && console.log(`DigitalMediaSearchCriteriaForm. Entered handleChannelSelectedStateChanged(). digitalMediaType: ${digitalMediaType}; newSelectedState: ${newSelectedState}`);

    // copy the array of IDigitalMediaTypeWithSelectedState objects to a new array
    const newDigitalMediaTypesWithSelectedState: Array<IDigitalMediaTypeWithSelectedState> = [];

    digitalMediaTypesWithSelectedState.map(digitalMediaTypeWithSelectedState => {
      newDigitalMediaTypesWithSelectedState.push(digitalMediaTypeWithSelectedState);

      // if the digital media type's name matches the digitalMediaType parameter passed in, set the selectedState per the newSelectedState parameter passed in
      if (digitalMediaTypeWithSelectedState.digitalMediaType === digitalMediaType) {
        digitalMediaTypeWithSelectedState.selected = newSelectedState;
      }
    });

    setDigitalMediaTypesWithSelectedState(newDigitalMediaTypesWithSelectedState);

    // if the new state for the digital media type is "checked" (turned on)...
    if (newSelectedState === true) {
      // if all channel checkboxes are checked, we want to set the main "All Accessible Channels"
      if (allDigitalMediaTypesHaveSameCheckedState(newDigitalMediaTypesWithSelectedState, newSelectedState)) {

        displayConsoleLogs && console.log(`In DigitalMediaSearchCriteriaForm.handleDigitalMediaTypeSelectedStateChanged(). Ready to call setIncludeAllDigitalMediaTypes with: ${newSelectedState}`);

        setIncludeAllDigitalMediaTypes(newSelectedState);
      }
    } else {
      // ensure that the main "All digital media types" is turned off
      setIncludeAllDigitalMediaTypes(newSelectedState);
    }

    evaluateWhetherFormIsReadyForSearch();
  }

  // returns whether all digital media types have the same checked state, per the given checked state
  function allDigitalMediaTypesHaveSameCheckedState(digitalMediaTypesAndSelectedState: Array<IDigitalMediaTypeWithSelectedState>, checkedState: boolean): boolean {
    // default return value
    let allHaveSameCheckedState: boolean = true;

    // Use a 'for' loop to traverse the checked (selected) state of each digital media type checkbox. We use a 'for' loop because
    // we can break from the loop prematurely, if desired.
    for (let idx = 0; idx < digitalMediaTypesAndSelectedState.length; idx++) {
      // if the current digital media type's checkbox has a different checked state...
      if (digitalMediaTypesAndSelectedState[idx].selected !== checkedState) {
        // set the return value to indicate that not all have the same checked state
        allHaveSameCheckedState = false;
        // break prematurely from the 'for' loop, since we have already determined that not all checkboxes are in the same state
        break;
      }
    }

    displayConsoleLogs && console.log(`In DigitalMediaSearchCriteriaForm.allDigitalMediaTypesHaveSameCheckedState(). allHaveSameCheckedState: ${allHaveSameCheckedState}`);

    return allHaveSameCheckedState;
  }

  function isAnyDigitalMediaTypeChecked(): boolean {
    let anyDigitalMediaTypeChecked = false;

    // Use a 'for' loop to traverse the checked (selected) state of each digital media type checkbox. We use a 'for' loop because
    // we can break from the loop prematurely, if desired.
    for (let idx = 0; idx < digitalMediaTypesWithSelectedState.length; idx++) {
      // if the current digital media type's checkbox is in a checked state...
      if (digitalMediaTypesWithSelectedState[idx].selected === true) {
        // set the return value to indicate that at least one digital media type is in a checked state
        anyDigitalMediaTypeChecked = true;
        // break prematurely from the 'for' loop, since we have already determined that at least one digital media type is in a checked state
        break;
      }
    }
    
    displayConsoleLogs && console.log(`In DigitalMediaSearchCriteriaForm.isAnyDigitalMediaTypeChecked(). anyDigitalMediaTypeChecked: ${anyDigitalMediaTypeChecked}`);

    return anyDigitalMediaTypeChecked;
  }

  // builds an array of digital media types based on which digital media types are currently selected
  function createArrayOfSelectedDigitalMediaTypes(): Array<string> {
    const digitalMediaTypes: Array<string> = [];

    for (let idx = 0; idx < digitalMediaTypesWithSelectedState.length; idx++) {
      // if the current digital media type's checkbox is in a checked state...
      if (digitalMediaTypesWithSelectedState[idx].selected) {
        // add its digital media type to the array
        digitalMediaTypes.push(digitalMediaTypesWithSelectedState[idx].digitalMediaType);
      }
    }

    return digitalMediaTypes;
  }

  function evaluateWhetherFormIsReadyForSearch() {
    let readyForSearch: boolean = false;

    // if (formIsValid && isAnyChannelChecked() && isAnyDigitalMediaTypeChecked()) {
    if ((currentSearchPhrase.length > 0) && isAnyChannelChecked() && isAnyDigitalMediaTypeChecked()) {
      readyForSearch = true;
    }
    
    displayConsoleLogs && console.log(`In DigitalMediaSearchCriteriaForm.evaluateWhetherFormIsReadyForSearch(). currentSearchPhrase: ${currentSearchPhrase}; readyForSearch: ${readyForSearch}`);

    if (formReadyForSearch !== readyForSearch) {
      setFormReadyForSearch(readyForSearch);
    }
  }

  // present the form
  return (
    <>
      <FormWithActionBar
        onSubmit={handleSubmit(handleSearchSubmit)}
        submitButtonLabel={ControlsStringAssets.searchButtonText}
        // actionInProgress={saveInProgress}
        // actionInProgressLabel={MessagesStringAssets.topicItem_SaveRequested}
        // formIsValid={formIsValid}
        formIsValid={formReadyForSearch}
      >
        <TextField
          key='searchPhrase'
          inputRef={searchPhraseReg}
          {...searchPhraseProps}
          autoFocus
          label={ControlsStringAssets.digitalMediaSearchCriteriaSearchPhraseLabel}
          margin='normal'
          fullWidth
          multiline={true}
          minRows={2}
          maxRows={5}
          error={!!errors.searchPhrase}
          helperText={errors?.searchPhrase?.message}
          InputLabelProps={{
            required: true  // this will cause an asterisk ('*') to appear at the end of the label text
          }}
          // we use a defined onChange handler to provide opportunity to evaluate whether the form is ready for search
          onChange={handleSearchPhraseChanged}
        />

        <StyledBoxForFieldSet>
          <StyledBoxForLegend>Channels to include in search</StyledBoxForLegend>

          {/* We use a FormControlLabel to bundle a Material-UI Checkbox with a label */}
          <FormControlLabel
            key="searchAllAccessibleChannelsFormControlLabel"
            name="searchAllAccessibleChannels"
            control={
              <Checkbox
                key="searchAllAccessibleChannelsCheckbox"
                name="searchAllAccessibleChannels"
                checked={searchAllAccessibleChannels}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  handleSelectAllChannelsStateChanged(event.currentTarget.checked);
                }}
              />
            }
            label={ControlsStringAssets.digitalMediaSearchCriteriaSearchAllAccessibleChannelsLabel}
          />

          <StyledBoxForChannelSelections>
            {/* {
              channelViewModels.map(channelViewModel =>
                <FormControlLabel
                  name={channelViewModel.channel.name}
                  key={channelViewModel.channel.id}
                  control={
                    <StyledCheckboxForCheckboxList
                      name={channelViewModel.channel.name}
                      key={channelViewModel.channel.id}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      }}
                    />
                  }
                  label={<StyledTypographyForCheckboxList>{`${channelViewModel.channel.name} (Owner: ${channelViewModel.ownerUser?.userSettings.firstName} ${channelViewModel.ownerUser?.userSettings.lastName})`}</StyledTypographyForCheckboxList>}
                />
              )} */}
            {
              channelViewModelsWithSelectedState.map(channelViewModelWithSelectedState =>
                <FormControlLabel
                  name={channelViewModelWithSelectedState.channelViewModel.channel.name}
                  key={channelViewModelWithSelectedState.channelViewModel.channel.id}
                  control={
                    <StyledCheckboxForCheckboxList
                      name={channelViewModelWithSelectedState.channelViewModel.channel.name}
                      key={channelViewModelWithSelectedState.channelViewModel.channel.id}
                      checked={channelViewModelWithSelectedState.selected}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        handleChannelSelectedStateChanged(channelViewModelWithSelectedState.channelViewModel.channel.id, event.currentTarget.checked);
                      }}
                    />
                  }
                  label={
                    <StyledTypographyForCheckboxList>
                      {`${channelViewModelWithSelectedState.channelViewModel.channel.name} (Owner: ${channelViewModelWithSelectedState.channelViewModel.ownerUser?.userSettings.firstName} ${channelViewModelWithSelectedState.channelViewModel.ownerUser?.userSettings.lastName})`}
                    </StyledTypographyForCheckboxList>
                  }
                />
              )}
          </StyledBoxForChannelSelections>

        </StyledBoxForFieldSet>

        {/* <br></br> */}

        <StyledBoxForFieldSet>
          <StyledBoxForLegend>Digital media types to include in search</StyledBoxForLegend>

          {/* We use a FormControlLabel to bundle a Material-UI Checkbox with a label */}
          <FormControlLabel
            key="includeAllDigitalMediaTypesFormControlLabel"
            name="includeAllDigitalMediaTypes"
            control={
              <Checkbox
                key="includeAllDigitalMediaTypesCheckbox"
                name="includeAllDigitalMediaTypes"
                checked={includeAllDigitalMediaTypes}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  handleIncludeAllDigitalMediaTypesStateChanged(event.currentTarget.checked);
                }}
              />
            }
            label={ControlsStringAssets.digitalMediaSearchCriteriaIncludeAllDigitalMediaTypesLabel}
          />

          <StyledBoxForDigitalMediaTypeSelections>
            {
              digitalMediaTypesWithSelectedState.map(digitalMediaTypeWithSelectedState =>
                <FormControlLabel
                  name={digitalMediaTypeWithSelectedState.digitalMediaTypeDisplayName}
                  key={digitalMediaTypeWithSelectedState.digitalMediaType}
                  control={
                    <StyledCheckboxForCheckboxList
                      name={digitalMediaTypeWithSelectedState.digitalMediaTypeDisplayName}
                      key={digitalMediaTypeWithSelectedState.digitalMediaType}
                      checked={digitalMediaTypeWithSelectedState.selected}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        handleDigitalMediaTypeSelectedStateChanged(digitalMediaTypeWithSelectedState.digitalMediaType, event.currentTarget.checked);
                      }}
                    />
                  }
                  label={
                    <StyledTypographyForCheckboxList>
                      {`${digitalMediaTypeWithSelectedState.digitalMediaTypeDisplayName}`}
                    </StyledTypographyForCheckboxList>
                  }
                />
              )}
          </StyledBoxForDigitalMediaTypeSelections>

        </StyledBoxForFieldSet>

      </FormWithActionBar>
    </>

  );
}

export default DigitalMediaSearchCriteriaForm;
