import React, { PropsWithChildren, useCallback, useEffect, useRef } from 'react';
import { PageAndViewTitleStringAssets } from '../../../assets/stringAssets';
import GenericPageContainer from '../GenericPageContainer/GenericPageContainer';
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import gsap from 'gsap';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/styles';
import { Box, BoxProps } from '@mui/material';
import MyDigiBrainLogo from '../../images/MyDigiBrainLogo/MyDigiBrainLogo';
import { logoutStatusChange } from '../../../uiMiddleware-redux/slices/logout/logoutSlice';
import { useAppDispatch } from '../../../uiMiddleware-redux/store/configureStore';
import { enumWorkflowState } from '../../../dataObjects/enums';

const useStyles: () => Record<string, string> = makeStyles((theme: Theme) => ({

  myDigiBrainLogo: {
    margin: theme.spacing(0, 0, 2, 0),
    // animation: `$tennisBallExit 3000ms ${theme.transitions.easing.easeInOut}`,

    [theme.breakpoints.up('xs')]: {
      height: '80px',
    },
    [theme.breakpoints.up('sm')]: {
      height: '90px',
    },
    [theme.breakpoints.up('md')]: {
      height: '100px',
    },
    [theme.breakpoints.up('lg')]: {
      height: '110px',
    },
    [theme.breakpoints.up('xl')]: {
      height: '120px',
    },

  },
}));


/*** Using the Material UI Emotion Styling library, declare 'styled' instances for each area/object. 
 *** NOTE: These must be declared outside of the React Functional Component to ensure that the styled 
 *** objects will be properly rendered within the DOM. 
 ***/

// a styled Box (equivalent to a <div>), providing an area to display the overall header
const StyledBoxForPaddingAtopPage = styled((props: BoxProps) => (
  <Box
    {...props}
  />
))(({ theme }) => ({
  marginTop: theme.spacing(2),
}));


export interface ILogoutPageProps extends PropsWithChildren<unknown> {

}

const LogoutPage: React.FC<ILogoutPageProps> = (props: ILogoutPageProps) => {

  LogoutPage.displayName = "Logout Page";

  const classes: Record<string, string> = useStyles();

  const logoElement: React.RefObject<SVGSVGElement> = useRef<SVGSVGElement>(null);

  const delaySecondsBeforeLogout: number = 2;

  const performLogoExit: () => void = useCallback((): void => {

    // The starting point for the animation will be to:
    //  - Make the tennis ball fully visible (opacity: 1.0) and at full size (scale 1.0);
    //  - Set its original orientation to 0 (rotate: 0), and 
    gsap.from(logoElement.current, {
      opacity: 1.0,
      scale: 1.0,
      rotate: 0,
    });

    // Ending point
    //  - Make the ball almost disappear (opacity: 0.1)
    //  - Make the ball 25% of its size (scale: 0.25)
    //  - Rotate the ball backwards twice, or 720 degrees (rotation: -720)
    //  - Perform this over a delay period by some seconds (delaySecondsBeforeLogout variable value)
    gsap.to(logoElement.current, {
      opacity: 0.1,
      scale: 0.25,
      // rotate: -720,
      duration: delaySecondsBeforeLogout,
    });

  }, []);

  // useEffect to perform animated rotation of the tennis ball, based on the rotateTennisBall callback function. (Shoud perform every time page is loaded)
  useEffect(() => {
    performLogoExit();
  }, [performLogoExit]);

  
  const dispatch = useAppDispatch();
  
  // the 'useNavigate' hook will be used to navigate the browser
  const navigate = useNavigate();

  // default useEffect for the page
  useEffect(() => {
    // set a timeout to trigger after some number of milliseconds delay (delaySecondsBeforeLogout * 1000 milliseconds)
    setTimeout(() => {
      // // dispatch a call to logout
      // dispatch(logoutRequested());
      // dispatch a call to logout
      // dispatch(logoutRequest());
      dispatch(logoutStatusChange(enumWorkflowState.Requested));

      // navigate to the login page 
      navigate('/login');
    }, delaySecondsBeforeLogout * 1000);
  }, [dispatch, navigate]);


  return (
    <>
      <StyledBoxForPaddingAtopPage />
      <GenericPageContainer
        maxWidth="sm"
        showBackButton={false}
        pageTitle={PageAndViewTitleStringAssets.pageTitle_Logout}
      >
        <>
          <div>
            <MyDigiBrainLogo ref={logoElement} className={classes.myDigiBrainLogo} />
          </div>
        </>
      </GenericPageContainer>
    </>
  );

} // const LogoutPage...

export default LogoutPage;
