import React, { PropsWithChildren } from 'react';
import { Divider, Grid, IconButton, Tooltip, Typography } from '@mui/material';
import {
  Delete as DeleteIcon,
  Edit as EditIcon,
} from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { listViewItemStyles } from '../../../styles';
import { IUserRedemptionCodeRequest } from '../../../../dataObjects/models/userRedemptionCodeRequest';
import { TooltipStringAssets } from '../../../../assets/stringAssets';
import { enumSharingPermission } from '../../../../dataObjects/enums';


export interface IUserRedemptionCodeRequestsListViewItemProps extends PropsWithChildren<unknown> {
  userRedemptionCodeRequest: IUserRedemptionCodeRequest;
  userPermission: enumSharingPermission;
  onDelete: (userRedemptionCodeRequest: IUserRedemptionCodeRequest) => void;
}

export const UserRedemptionCodeRequestsListViewItem: React.FC<IUserRedemptionCodeRequestsListViewItemProps> = (props: IUserRedemptionCodeRequestsListViewItemProps) => {
  UserRedemptionCodeRequestsListViewItem.displayName = 'UserRedemptionCodeRequests List View Item';

  // eslint-disable-next-line no-console
  // console.info(`Render UserRedemptionCodeRequestsListViewItem`);

  const classes: Record<string, string> = listViewItemStyles();

  const { userRedemptionCodeRequest, userPermission, onDelete } = props;
  const { id, redemptionCode, recipientName, recipientEmailAddress, authorizerEmailAddress, startDate, endDate } = userRedemptionCodeRequest;

  // we're going to apply the 'useNavigate' hook to navigate the browser
  const navigate = useNavigate();

  const userHasPermission: boolean = userPermission === enumSharingPermission.Admin;

  return (
    <>
      <div className={classes.tableRow} key={id}>
        {/* Data Row 1: Recipient Name & Action Buttons  */}
        <Grid item container xs={12} direction="row" >
          {/* Name */}
          <Grid item container xs={8} sm={9} >
            <Typography className={classes.title} variant="h6">
              {recipientName}
            </Typography>
          </Grid>
          {/* Action Buttons */}
          <Grid item container xs={4} sm={3} direction="row" justifyContent="center" alignContent="flex-start">
            <Grid item container className={classes.actionButtonContainer} xs={4} >
              <Tooltip
                // the tooltip for the edit button will either be "Edit" (if user has sufficient permission) or "Edit disallowed" (insufficient permission)
                title={!userHasPermission ? TooltipStringAssets.edit_Disallowed : TooltipStringAssets.edit}
                arrow
              >
                <span>
                  <IconButton
                    size="small"
                    className={`${classes.actionButton} ${classes.editIconButton}`}
                    disabled={!userHasPermission}
                    onClick={() => id && navigate(`/userRedemptionCodeRequest/${id}`)}
                  >
                    <EditIcon />
                  </IconButton>
                </span>
              </Tooltip>
            </Grid>
            {/* Delete button */}
            <Grid item container className={classes.actionButtonContainer} xs={4} >
              <Tooltip
                // the tooltip for the delete button will either be "Delete" (if user has sufficient permission) or "Delete disallowed" (insufficient permission)
                title={!userHasPermission ? TooltipStringAssets.edit_Disallowed : TooltipStringAssets.edit}
                arrow
              >
                <span>
                  <IconButton
                    size="small"
                    className={`${classes.actionButton} ${classes.deleteIconButton}`}
                    disabled={!userHasPermission}
                    onClick={() => userRedemptionCodeRequest && onDelete(userRedemptionCodeRequest)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </span>
              </Tooltip>
            </Grid>
          </Grid>
        </Grid>
        {/* Data Row 2: Redemption Code  */}
        <Grid item container xs={12} >
          <Typography className={classes.description} variant="body1">
            Redemption code: {redemptionCode}
          </Typography>
        </Grid>
        {/* Data Row 3: Recipient Email Address  */}
        <Grid item container xs={12} >
          <Typography className={classes.description} variant="body1">
            Recipient email: {recipientEmailAddress}
          </Typography>
        </Grid>
        {/* Data Row 4: Authorizer Email Address  */}
        <Grid item container xs={12} >
          <Typography className={classes.description} variant="body1">
            Authorizer email: {authorizerEmailAddress}
          </Typography>
        </Grid>
        {/* Data Row 5: Start Date  */}
        <Grid item container xs={12} >
          <Typography className={classes.description} variant="body1">
            Start date:&nbsp;
            {startDate &&
              moment(startDate).format('YYYY-MM-DD')}
          </Typography>
        </Grid>
        {/* Data Row 6: End Date  */}
        <Grid item container xs={12} >
          <Typography className={classes.description} variant="body1">
            End date:&nbsp;
            {startDate &&
              moment(startDate).format('YYYY-MM-DD')}
          </Typography>
        </Grid>

        {/* Add a dividing line after each item */}
        <Divider />
      </div>
    </>
  );

}

export default UserRedemptionCodeRequestsListViewItem;