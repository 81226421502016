import { RandomId } from '../../../utilities/RandomId';
import { typeUniqueId, typePersistableParentObjectType, typeUniqueIdWithUndefinedOption } from '../../../types';
import { enumObjectPersistenceState, enumPersistableObjectType, enumPersistableObjectClassName } from '../../../enums';
import { ITopicItem, ITopicItemAsJson } from '.';
import { ITopicItemMetadata, TopicItemMetadata } from '../TopicItemMetadata';
import { VersionAwarePersistable } from '../../persistence/VersionAwarePersistable';
import { JsonConverter } from '../../../utilities/JsonConverter';
import { IUserPersistenceData } from '../../persistence/UserPersistenceData';

/** 
 * @class TopicItem represents a general topic item (an item of a topic)
 */
export class TopicItem extends VersionAwarePersistable implements ITopicItem {

  /**
   * @method Constructor method
   * @param {typeUniqueId} ownerId The Id of the owner (user or channel) of the instance
   * @param {typeUniqueId} id Unique Id of the instance
   * @param {typePersistableParentObjectType} parentObjectType The Parent's object type
   * @param {typeUniqueIdWithUndefinedOption} parentId Id of the object's parent
   * @param {enumObjectPersistenceState} objectState The state of the object since it was last persisted.
   * @param {string} name The name of the topic item
   * @param {string} description A description for the topic item
   * @param {IUserPersistenceData} userPersistenceData User-related persistence data
   */
  constructor(
    ownerId: typeUniqueId,
    id: typeUniqueId = RandomId.newId(),
    parentObjectType: typePersistableParentObjectType,
    parentId: typeUniqueIdWithUndefinedOption,
    objectState: enumObjectPersistenceState,
    name: string,
    description: string = '',
    useTimestamp?: boolean,
    occurrenceTimestamp?: Date,
    userPersistenceData?: IUserPersistenceData
  ) {
    super(ownerId, enumPersistableObjectClassName.TopicItem, enumPersistableObjectType.TopicItem, id, parentObjectType, parentId, objectState, userPersistenceData);

    // assign name & description passed in
    this.name = name;
    this.description = description;

    if (useTimestamp) {
      this.useTimestamp = useTimestamp;
    }

    if (occurrenceTimestamp) {
      this.occurrenceTimestamp = occurrenceTimestamp;
    }

    // initlalize the TopicItemMetadata
    this._topicItemMetadata = new TopicItemMetadata(this.ownerId, RandomId.newId(), this.objectType, this.id, enumObjectPersistenceState.New);
  }

  /*-----------------------------------------------*/
  /**
   * @property {string} _name The name of the TopicItem
   */
  private _name: string = '';

  /**
   * @method name Getter method for _namee
   */
  get name(): string {
    return this._name;
  }

  /**
   * @method name Setter method for _name
   * @param {string} value The input value for setting _name
   */
  set name(value: string) {
    this._name = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @property string _description property is the description for the TopicItem
   */
  private _description: string = '';

  /**
   * @method description Getter method for _description
   */
  get description(): string {
    return this._description;
  }

  /**
   * @method description Setter method for _description
   * @param {string} value The input value for setting _description
   */
  set description(value: string) {
    this._description = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @property boolean _useTimestamp property indicates whether to use a timestamp for the TopicItem
   */
  private _useTimestamp: boolean = true;

  /**
   * @method useTimestamp Getter method for _useTimestamp
   */
  get useTimestamp(): boolean {
    return this._useTimestamp;
  }

  /**
   * @method useTimestamp Setter method for _useTimestamp
   * @param {boolean} value The input value for setting _useTimestamp
   */
  set useTimestamp(value: boolean) {
    this._useTimestamp = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @property Date _occurrenceTimestamp property represents when the TopicItem occurred
   */
  private _occurrenceTimestamp: Date = new Date();

  /**
   * @method occurrenceTimestamp is an optional getter method for _occurrenceTimestamp
   */
  get occurrenceTimestamp() {
    return this._occurrenceTimestamp;
  }

  /**
   * @method occurrenceTimestamp is an optional setter method for _occurrenceTimestamp
   * @param {Date} value is the input value for setting _occurrenceTimestamp
   */
  set occurrenceTimestamp(value) {
    this._occurrenceTimestamp = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @property {ITopicItemMetadata} _topicItemMetadata provides metadata for the TopicItem
   */
  private _topicItemMetadata: ITopicItemMetadata;

  /**
   * @method topicItemMetadata is an optional getter method for _topicItemMetadata
   */
  get topicItemMetadata() {
    return this._topicItemMetadata;
  }

  /**
   * @method topicItemMetadata is an optional setter method for _topicItemMetadata
   * @param {ITopicItemMetadata} value is the input value for setting _topicItemMetadata
   */
  set topicItemMetadata(value) {
    // ensure that the 'parentObject', 'parentObjectType' and 'parentId' are set to this instance's 'this', 'objectType' and 'id', respectively.
    value.parentObject = this;
    value.parentObjectType = this.objectType;
    value.parentId = this.id;
    this._topicItemMetadata = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method copy Performs a "deep copy" of the instance, which includes a copy of all contained objects.
   * @returns {ITopicItem} A "deep copy" of the object instance, including a "deep copy" of all contained objects.
   */
  copy(): ITopicItem {
    // use Object.create() to create a new instance, and then Object.assign() to assign all core properties
    let copyOfObject: ITopicItem = Object.create(TopicItem.prototype);
    Object.assign(copyOfObject, this);

    // copy the contained objects
    if (this.topicItemMetadata !== undefined) {
      copyOfObject.topicItemMetadata = Object.create(TopicItemMetadata.prototype);
      copyOfObject.topicItemMetadata = this.topicItemMetadata.copy();
    }

    return copyOfObject;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method toJSON Serializes an instance of this class to a JSON object, including contained
   * objects (if requested).
   * @param {boolean} includeContainedObjects A boolean flag indicating whether to include contained objects.
   * @returns A JSON object with serialized data from 'this' class instance.
   */
  toJSON(includeContainedObjects: boolean = true): ITopicItemAsJson {
    try {
      // prepare  JSON object for return, starting with a call to the direct parent base 
      // class to get its members added to the JSON object
      const jsonObject: ITopicItemAsJson = super.toJSON(includeContainedObjects);

      // copy any additional field values to the json object 
      jsonObject.name = this.name;
      jsonObject.description = this.description;
      jsonObject.useTimestamp = this.useTimestamp;
      jsonObject.occurrenceTimestamp = this.occurrenceTimestamp.toISOString();

      // if requested to include contained objects, serialize contained objects
      if (includeContainedObjects) {
        // jsonObject.digitalMedia = this.digitalMedia.toJSON(true);
        jsonObject.topicItemMetadata = this.topicItemMetadata.toJSON(true);
      }

      return jsonObject;

    } catch (error: any) {
      // TODO: log error
      // re-throw error
      throw error;
    }
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method fromJSON Derializes an instance of this class from a JSON object, along with any contained 
   * objects (if requested).
   * @param {ITopicItemAsJson} jsonObject A JSON version of a class instance.
   * @param {boolean} includeContainedObjects A boolean flag indicating whether to include contained objects.
   * @returns A ImageLink instance with values copied from the jsonObject
   */
  static fromJSON(jsonObject: ITopicItemAsJson, includeContainedObjects: boolean = true): ITopicItem {
    try {
      // create a new instance of this class
      let topicItemObject: TopicItem = Object.create(TopicItem.prototype);

      // call the 'fromJSONProtected()' method on the immediate base to get its property values loaded
      topicItemObject = super.fromJSONProtected(topicItemObject, jsonObject, includeContainedObjects);

      // copy any additional field values from the json object 
      if (jsonObject.name) {
        topicItemObject.name = jsonObject.name;
      }

      if (jsonObject.description) {
        topicItemObject.description = jsonObject.description;
      }

      if (jsonObject.useTimestamp) {
        topicItemObject.useTimestamp = jsonObject.useTimestamp;
      }

      if (jsonObject.occurrenceTimestamp) {
        topicItemObject.occurrenceTimestamp = new Date(jsonObject.occurrenceTimestamp);
      }

      // if request is to include contained objects, copy additional fields
      if (includeContainedObjects) {
        // if (jsonObject.digitalMedia) {
        //   topicItemObject.digitalMedia = JsonConverter.fromJSON(DigitalMedia, jsonObject.digitalMedia);
        // }

        if (jsonObject.topicItemMetadata) {
          topicItemObject.topicItemMetadata = JsonConverter.fromJSON(TopicItemMetadata, jsonObject.topicItemMetadata);
        }
      }

      return topicItemObject;

    } catch (error: any) {
      // TODO: log error
      // re-throw error
      throw error;
    }
  }
  /*-----------------------------------------------*/

}
