import React, { PropsWithChildren, useEffect, useState } from 'react';
import { Box, BoxProps } from '@mui/material';
import { styled } from '@mui/styles';
import { IAudioLink } from '../../../dataObjects/models/digitalMedia/AudioLink';
import { enumAudioLinkType, enumDigitalMediaDisplayEnvironment } from '../../../dataObjects/enums';
import { convertGoogleDriveFileUrlForFileLink, urlIsAGoogleDriveLink } from '../../utilities/digitalMediaUtilities/externalSourceUtilities/googleDriveUtilities';
import { MdbError } from '../../../errorObjects/MdbError';
import ReactPlayer from 'react-player';
import { DigitalMediaNotAvailableDisplay } from '../DigitalMediaNotAvailableDisplay/DigitalMediaNotAvailableDisplay';
import { MessagesStringAssets } from '../../../assets/stringAssets';
import { urlIsAYouTubeAddress } from '../../utilities/digitalMediaUtilities/externalSourceUtilities/youtubeUtilities';
import { urlIsAMixCloudAddress } from '../../utilities/digitalMediaUtilities/externalSourceUtilities/mixCloudUtilities';
import { urlIsASoundCloudAddress } from '../../utilities/digitalMediaUtilities/externalSourceUtilities/soundCloudUtilities';
import { urlIsAnAppleMusicAddress } from '../../utilities/digitalMediaUtilities/externalSourceUtilities/appleMusicUtilities';


/*** Using the Material UI Emotion Styling library, declare 'styled' instances for each area/object. 
 *** NOTE: These must be declared outside of the React Functional Component to ensure that the styled 
 *** objects will be properly rendered within the DOM. 
 ***/

// a styled Box (equivalent to a <div>) that will serve as a base container in a Card View
const StyledContainerForCardView = styled((props: BoxProps) => (
  <Box
    {...props}
  />
))(({ theme }) => ({
  margin: theme.spacing(0, 0, 2, 0),
  border: `2px solid ${theme.palette.primary.main}`,
  borderRadius: '10000px', // to give it completely rounded sides
  display: 'flex',
  justifyContent: 'center',
  flexGrow: 1,
  position: 'relative',
  height: '250px',
  width: 'auto'
}));

// a styled Box (equivalent to a <div>) that will serve as a base container in a Form View
const StyledContainerForFormView = styled((props: BoxProps) => (
  <Box
    {...props}
  />
))(({ theme }) => ({
  margin: theme.spacing(2, 0),
  border: `2px solid ${theme.palette.primary.main}`,
  borderRadius: '10000px', // to give it completely rounded sides
  display: 'flex',
  justifyContent: 'center',
  flexGrow: 1,
  position: 'relative',
  height: '250px',
  width: 'auto'
}));

// a styled Box (equivalent to a <div>) that will serve as a base container in a List View
const StyledContainerForListView = styled((props: BoxProps) => (
  <Box
    {...props}
  />
))(({ theme }) => ({
  margin: theme.spacing(1, 2, 1, 0),
  border: `2px solid ${theme.palette.primary.main}`,
  borderRadius: '10000px', // to give it completely rounded sides
  display: 'flex',
  justifyContent: 'center',
  flexGrow: 1,
  position: 'relative',
  height: '250px',
  width: 'auto',

  [theme.breakpoints.down('xs')]: {
    maxWidth: '300px',
  },
  [theme.breakpoints.up('sm')]: {
    maxWidth: '350px',
  },
  [theme.breakpoints.up('md')]: {
    maxWidth: '500px',
  },
}));

// a styled Box (equivalent to a <div>), providing a background for a not available message
const AudioNotAvailableBox = styled((props: BoxProps) => (
  <Box
    {...props}
  />
))(({ theme }) => ({
  background: `linear-gradient(135deg, ${theme.palette.primary.light}, #BBB, #EEE, ${theme.palette.primary.light}, #BBB, #EEE, ${theme.palette.primary.light})`,
  display: 'flex',
  justifyContent: 'center',
}));

/**
 * @interface IAudioMediaDisplayProps declares any input properties required for this component.
 */
export interface IAudioMediaDisplayProps extends PropsWithChildren<unknown> {
  audioLink: IAudioLink;
  displayEnvironment: enumDigitalMediaDisplayEnvironment;
}

/**
 * @function AudioMediaDisplay is a React functional component for displaying a simple audio (via an IAudioLink).
 * @param {} props are the properties passed into the component (currently no input properties).
 */
export const AudioMediaDisplay: React.FC<IAudioMediaDisplayProps> = (props: IAudioMediaDisplayProps) => {
  AudioMediaDisplay.displayName = 'Audio Media Display';

  // whether to display console logs (displayConsoleLogs && console.log statements)
  const displayConsoleLogs: boolean = false;

  displayConsoleLogs && console.log(`AudioMediaDisplay. Entered AudioMediaDisplay`);

  const { audioLink, displayEnvironment } = props;

  // whether the audio source is supported
  const [supportedAudioSource, setSupportedAudioSource] = useState<boolean>(false);

  // the height of the audio display component
  const [componentHeight, setComponentHeight] = useState<string>('3rem');

  // the border radius of the display
  const [componentBorderRadius, setComponentBorderRadius] = useState<string>('10000px');

  // the URL that will be set for the audio display object
  const [audioUrl, setAudioUrl] = useState<string>('');

  const audioNotAvailableWidth: string = `100%`;
  const audioNotAvailableHeight: string = `100%`;

  // useEffect to establish container and audio display component parameters based on the audioLink and display environment
  useEffect(() => {
    // The variables that can change are the:
    //   - Whether the Url provided is from a supported audio source
    //   - Url of the audio being displayed
    //   - Height of the container
    //   - Border radius of the container
    let localSupportedAudioSource: boolean = false;
    let localAudioUrl: string = audioLink.downloadUrl;
    let localComponentHeight: string = '3rem'; // default height
    let localBorderRadius: string = '1px'; // mostly squared sides

    switch (audioLink.audioLinkType) {
      // if the currently selected Audio Type is file upload (GoogleCloudStorage)...
      case enumAudioLinkType.GoogleCloudStorage:
        localSupportedAudioSource = true;
        localComponentHeight = '3rem';
        localBorderRadius = '10000px'; // to give it completely rounded sides
        break;

      // if the currently selected Audio Type is from an external source...
      case enumAudioLinkType.ExternalSource:
        // if the Url is a known supported URL Address where no Url conversion is required: 
        // Apple Music YouTube, MixCloud, SoundCloud...
        if (urlIsAnAppleMusicAddress(audioLink.downloadUrl) ||
          urlIsAYouTubeAddress(audioLink.downloadUrl) ||
          urlIsAMixCloudAddress(audioLink.downloadUrl) ||
          urlIsASoundCloudAddress(audioLink.downloadUrl)) {
          localSupportedAudioSource = true;

          // set component height
          localComponentHeight = '250px';
        } else
          // if the Url is a Google Drive address...
          if (urlIsAGoogleDriveLink(audioLink.downloadUrl)) {
            // not a supported source at this time, so no change to the supported source flag (should be 'false')

            // we'll convert the Url anyway, in case we support in the future
            localAudioUrl = convertGoogleDriveFileUrlForFileLink(audioLink.downloadUrl);

            localComponentHeight = '3rem';
          }

        break;

      default:
        throw new MdbError(`Unhandled audio type (${audioLink.audioLinkType}) in AudioMediaDisplay`);
    }

    displayConsoleLogs && console.log(`AudioMediaDisplay. useEffect for setting component specs. componentHeight to be set to: ${localComponentHeight}`);
    displayConsoleLogs && console.log(`AudioMediaDisplay. useEffect for setting component specs. componentBorderRadius to be set to: ${localBorderRadius}`);

    setSupportedAudioSource(localSupportedAudioSource);
    setAudioUrl(localAudioUrl);
    setComponentHeight(localComponentHeight);
    setComponentBorderRadius(localBorderRadius);

  }, [audioLink.audioLinkType, audioLink.downloadUrl]);


  /**
   * @function renderAudioElement Renders the JSX element of the audio object to be displayed on the UI.
   * @param {IAudioLink} audioLink The AudioLink object to be rendered.
   */
  function renderAudioElement(): JSX.Element {

    // default the audio element to displaying no audio
    let audioElement: JSX.Element =
      <>
        <AudioNotAvailableBox
          sx={{ width: audioNotAvailableWidth, height: audioNotAvailableHeight }}
        />
      </>;

    // if there's an established Url for the object, perform logic
    if (audioUrl) {

      // if (supportedSource) {
      if (supportedAudioSource) {
        audioElement =
          <>
            <ReactPlayer
              url={audioUrl}
              crossOrigin="anonymous"
              playsinline
              controls
              position='relative'
              width='100%'
              height={'100%'}
            />
          </>
      } else {
        audioElement =
          <>
            <DigitalMediaNotAvailableDisplay displayText={MessagesStringAssets.audioSourceOrFormatNotSupported} />
          </>
      }

    }

    return audioElement;
  }

  /**
   * @function renderAudioContainerAndAudioDisplay Renders the JSX element of the audio element container and audio element to be displayed on the UI.
   */
  function renderAudioContainerAndAudioDisplay(): JSX.Element {

    let audioContainerAndAudioDisplay: JSX.Element =
      <>
      </>

    displayConsoleLogs && console.log(`AudioMediaDisplay.renderAudioContainerAndAudioDisplay(). displayEnvironment: ${displayEnvironment}; componentHeight: ${componentHeight}; borderRadius: ${componentBorderRadius}`);

    switch (displayEnvironment) {
      case enumDigitalMediaDisplayEnvironment.CardView:
        audioContainerAndAudioDisplay =
          <StyledContainerForCardView sx={{ height: componentHeight, borderRadius: componentBorderRadius }} >
            {renderAudioElement()}
          </StyledContainerForCardView>
        break;

      case enumDigitalMediaDisplayEnvironment.FormView:
        audioContainerAndAudioDisplay =
          <StyledContainerForFormView sx={{ height: componentHeight, borderRadius: componentBorderRadius }} >
            {renderAudioElement()}
          </StyledContainerForFormView>
        break;

      case enumDigitalMediaDisplayEnvironment.ListView:
        audioContainerAndAudioDisplay =
          <StyledContainerForListView sx={{ height: componentHeight, borderRadius: componentBorderRadius }} >
            {renderAudioElement()}
          </StyledContainerForListView>
        break;

      default:
        throw new MdbError(`Unhandled display environoment (${displayEnvironment}) in AudioMediaDisplay`);
    }

    return audioContainerAndAudioDisplay;
  }


  return (
    <>
      {renderAudioContainerAndAudioDisplay()}
    </>
  )
}
