import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { enumWorkflowState } from '../../../dataObjects/enums';
import { IUserRedemptionCodeRequest } from '../../../dataObjects/models/userRedemptionCodeRequest';

const initialState: enumWorkflowState | null = null;

export const userRedemptionCodeRequestDeleteStatusSlice = createSlice({
  name: 'userRedemptionCodeRequestDeleteStatus',
  initialState,
  reducers: {
    // action has a payload with IUserRedemptionCodeRequests
    userRedemptionCodeRequestDeleteRequest: (state, action: PayloadAction<IUserRedemptionCodeRequest>) => {
      userRedemptionCodeRequestDeleteStatusChange(enumWorkflowState.Requested);
    },
    // action has a payload with the new enumWorkflowState value or null
    userRedemptionCodeRequestDeleteStatusChange(state, action) {
      // change status of process
      return action.payload;
    },
  },
})

// Action creators are generated for each function in reducer
export const { userRedemptionCodeRequestDeleteRequest, userRedemptionCodeRequestDeleteStatusChange } = userRedemptionCodeRequestDeleteStatusSlice.actions;

// export the core reducer for the slice
export const userRedemptionCodeRequestDeleteStatusReducer = userRedemptionCodeRequestDeleteStatusSlice.reducer;

export default userRedemptionCodeRequestDeleteStatusSlice.reducer;