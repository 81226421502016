import React, { PropsWithChildren } from 'react';
import { styled } from '@mui/styles';
import { Box, BoxProps, Typography, TypographyProps } from '@mui/material';


/*** Using the Material UI Emotion Styling library, declare 'styled' instances for each area/object. 
 *** NOTE: These must be declared outside of the React Functional Component to ensure that the styled 
 *** objects will be properly rendered within the DOM. 
 ***/

// a styled Box (equivalent to a <div>) for an Unselected Color Swatch
const StyledBoxForUnselectedColorSwatch = styled((props: BoxProps) => (
  <Box
    {...props}
  />
))(({ theme }) => ({
  border: theme.colorSwatch.border,
  borderRadius: theme.colorSwatch.borderRadius,

  padding: theme.colorSwatch.paddingAroundColorBar,
  background: theme.colorSwatch.backgroundColor,
  cursor: 'pointer',
  '&:hover': {
    padding: theme.colorSwatch.hoverPaddingAroundColorBar,
    border: theme.colorSwatch.hoverBorder,
    backgroundColor: theme.colorSwatch.hoverBackgroundColor,
  },
}));

// a styled Box (equivalent to a <div>) for a Selected Color Swatch
const StyledBoxForSelectedColorSwatch = styled((props: BoxProps) => (
  <Box
    {...props}
  />
))(({ theme }) => ({
  border: theme.colorSwatch.selectedSwatchBorder,
  borderRadius: theme.colorSwatch.borderRadius,

  padding: theme.colorSwatch.hoverPaddingAroundColorBar,
  background: theme.colorSwatch.backgroundColor,
  cursor: 'pointer',
  '&:hover': {
    padding: theme.colorSwatch.hoverPaddingAroundColorBar,
    border: theme.colorSwatch.hoverSelectedSwatchBorder,
    backgroundColor: theme.colorSwatch.hoverBackgroundColor,
  },
}));

// a styled Box (equivalent to a <div>) for the Color Bar within the Color Swatch
const StyledBoxForColorBarInSwatch = styled((props: BoxProps) => (
  <Box
    {...props}
  />
))(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignContent: 'center',
  height: '2rem',
  paddingTop: '2px',
  border: theme.colorBarInSwatch.border,
  borderRadius: theme.colorBarInSwatch.borderRadius,
}));

// a styled Typography for displaying text within the Color Swatch
const StyledTypographyForColorSwatchText = styled((props: TypographyProps) => (
  <Typography
    variant="body1"
    {...props}
  />
))(({ theme }) => ({
  // alignSelf: 'center',
  // justifySelf: 'center',
}));
/**
 * @interface IColorSwatchProps declares any input properties required for this component.
 */
export interface IColorSwatchProps extends PropsWithChildren<unknown> {
  background: string; // the current color of the swatch
  width?: string | undefined; // width of the control
  height?: string | undefined; // height of the control
  selected?: boolean; // whether the swatch is currently selected
  text?: string; // text to display in swatch
  textColor?: string; // color of text to display 
  onClick?: (event: React.MouseEvent<HTMLElement>) => void; // (optional) click handler
}

/**
 * @function ColorSwatch is a React functional component, representing a control for displaying a current color, and then 
 *           selecting to change the color.
 * @param {IColorSwatchProps} props are the properties passed into the component.
 */
export const ColorSwatch: React.FC<IColorSwatchProps> = (props: IColorSwatchProps) => {
  ColorSwatch.displayName = 'Color Swatch';

  const { background: color, width, height, selected, text, textColor, onClick } = props;

  function handleClick(event: React.MouseEvent<HTMLElement>) {
    onClick && onClick(event); // if an onClick handler (callback function) has been provided, call it
  }

  // prepare specs for swatch dimensions (width/height), based on parms provided
  // Note: If definition for either 'width' or 'height' is undefined, the spec will be ignored
  const swatchDimensionsSpec: {} = { width: `${width}`, height: `${height}` };

  // prepare the color bar to display in the swatch, dynamically setting the background color, per the 'color' parm provided
  const colorBarInSwatch: JSX.Element =
    <StyledBoxForColorBarInSwatch sx={{ backgroundColor: color, width: `${width}`, height: `${height}` }}>
      {text &&
        <StyledTypographyForColorSwatchText sx={{ color: textColor }}>
          {text}
        </StyledTypographyForColorSwatchText>
      }
    </StyledBoxForColorBarInSwatch>

  return (
    <>
      {
        // if the swatch is selected, use the styled box for a selected swatch
        selected ?
          // <StyledBoxForSelectedColorSwatch sx={swatchDimensionsSpec} onClick={handleClick} >
          <StyledBoxForSelectedColorSwatch onClick={handleClick} >
            {colorBarInSwatch}
          </StyledBoxForSelectedColorSwatch>
          :
          // since the swatch is NOT selected, use the styled box for an unselected swatch
          // <StyledBoxForUnselectedColorSwatch sx={swatchDimensionsSpec} onClick={handleClick} >
          <StyledBoxForUnselectedColorSwatch onClick={handleClick} >
            {colorBarInSwatch}
          </StyledBoxForUnselectedColorSwatch>
      }
    </>
  )
}