import { createTheme, Theme } from '@mui/material/styles';
import { convertHexColorStringToRgbaString } from '../../components/utilities/colorConversions';
import { PaletteMode } from '@mui/material';
import { enumThemeMode } from '../../dataObjects/enums/enumThemeMode';
import { IThemeColorSpectrum, IThemeColorSpectrumsPalette } from '../../dataObjects/models/themes';
import { IMyDigiBrainTheme } from './IMyDigiBrainTheme';

// get the default theme (will be used to set some default properties)
const defaultTheme = createTheme();

export const createThemeFromModeAndColorPalettes: (themeMode: PaletteMode, themeColorPalettes: IThemeColorSpectrumsPalette) => IMyDigiBrainTheme = (themeMode: PaletteMode, themeColorPalettes: IThemeColorSpectrumsPalette) => {
  const mode: PaletteMode = themeMode;
  const themeColorPrimary: IThemeColorSpectrum = themeColorPalettes.colorSpectrumPrimary;
  const themeColorSecondary: IThemeColorSpectrum = themeColorPalettes.colorSpectrumSecondary;
  const themeColorCancel: IThemeColorSpectrum = themeColorPalettes.colorSpectrumCancel;
  const themeColorError: IThemeColorSpectrum = themeColorPalettes.colorSpectrumError;
  const themeColorHighlight: IThemeColorSpectrum = themeColorPalettes.colorSpectrumHighlight;
  const themeColorInfo: IThemeColorSpectrum = themeColorPalettes.colorSpectrumInfo;
  const themeColorSuccess: IThemeColorSpectrum = themeColorPalettes.colorSpectrumSuccess;
  const themeColorWarning: IThemeColorSpectrum = themeColorPalettes.colorSpectrumWarning;

  const customTheme: IMyDigiBrainTheme = createTheme({
    palette: {
      mode: mode,
      ...(mode === enumThemeMode.Light
        ? {
          // palette values for light mode
          primary: themeColorPrimary[25],
          divider: themeColorPrimary[200],
          background: {
            default: themeColorPrimary[25], // 'white', //themeColorPrimary[25],
            paper: themeColorPrimary[25], //'white', // themeColorPrimary[25],
          },
          text: {
            primary: themeColorPrimary[900],
            secondary: themeColorPrimary[800],
          },
        }
        : {
          // palette values for dark mode
          primary: themeColorPrimary[900],
          divider: themeColorPrimary[800],
          background: {
            default: themeColorPrimary[900],
            paper: themeColorPrimary[900],
          },
          text: {
            primary: themeColorPrimary[25],
            secondary: themeColorPrimary[100],
          },
        }),
      primary: {
        light: themeColorPrimary[25],
        main: themeColorPrimary[500],
        dark: themeColorPrimary[900],
        contrastText: themeColorPrimary.contrastText,
      },

      secondary: {
        light: themeColorSecondary[25],
        main: themeColorSecondary[500],
        dark: themeColorSecondary[900],
        contrastText: themeColorSecondary.contrastText,
      },

      error: {
        light: themeColorError[25],
        main: themeColorError[500],
        dark: themeColorError[900],
        contrastText: themeColorError.contrastText,
      },

      info: {
        light: themeColorInfo[25],
        main: themeColorInfo[500],
        dark: themeColorInfo[900],
        contrastText: themeColorInfo.contrastText,
      },

      success: {
        light: themeColorSuccess[25],
        main: themeColorSuccess[500],
        dark: themeColorSuccess[900],
        contrastText: themeColorSuccess.contrastText,
      },

      warning: {
        light: themeColorWarning[25],
        main: themeColorWarning[500],
        dark: themeColorWarning[900],
        contrastText: themeColorWarning.contrastText
      },

    }, // end of palette


    aboutPageInfoGraphics: {
      boundingBoxBorder: mode === enumThemeMode.Light ? `2px solid ${themeColorPrimary[500]}` : `2px solid ${themeColorPrimary[500]}`,
      boundingBoxBackground: mode === enumThemeMode.Light ? `linear-gradient(to bottom, ${themeColorPrimary[50]}, 30%, ${themeColorPrimary[100]})` : `linear-gradient(to bottom, ${themeColorPrimary[900]}, 30%, ${themeColorPrimary[800]})`,
    },

    // text that will have an onClick action associated with it (eg, "Forgot Password" on Login page)
    actionText: {
      fontSize: '0.8rem',
      color: mode === enumThemeMode.Light ? themeColorPrimary[900] : themeColorPrimary[25],
      cursor: 'pointer',
      textDecorationLine: 'underline',
      textDecorationColor: mode === enumThemeMode.Light ? themeColorPrimary[900] : themeColorPrimary[25],
      hoverColor: mode === enumThemeMode.Light ? themeColorPrimary[700] : themeColorPrimary[100],
      hoverTextDecorationColor: mode === enumThemeMode.Light ? themeColorPrimary[700] : themeColorPrimary[100],
    }, // end of actionText

    alertBar: {
      fontSize: '1.4rem',
      border: 'none',
    }, // end of alertBar

    appFooter: {
      iconArea: {
        background: mode === enumThemeMode.Light ? themeColorPrimary[500] : themeColorPrimary[700],
        color: themeColorPrimary.contrastText ?? mode === enumThemeMode.Light ? themeColorPrimary[50] : themeColorPrimary[100],
        hover: {
          color: mode === enumThemeMode.Light ? themeColorPrimary[100] : themeColorPrimary[200],
        },
      },
      infoDisplayArea: {
        background: mode === enumThemeMode.Light ? themeColorSecondary[500] : themeColorSecondary[700],
        color: themeColorSecondary.contrastText ?? mode === enumThemeMode.Light ? themeColorSecondary.contrastTextForMainBackground : themeColorSecondary.contrastTextForDarkBackground,
      },
    },

    appHeader: {
      appBar: {
        background: mode === enumThemeMode.Light ? themeColorPrimary[500] : themeColorPrimary[700],
        color: themeColorPrimary.contrastText ?? mode === enumThemeMode.Light ? themeColorPrimary[50] : themeColorPrimary[100],
      },
      areaBackground: {
        background: mode === enumThemeMode.Light ? themeColorSecondary[100] : themeColorSecondary[700],
        border: mode === enumThemeMode.Light ? `2px solid ${themeColorSecondary[500]}` : `2px solid ${themeColorSecondary[500]}`,
        color: themeColorSecondary.contrastText ?? mode === enumThemeMode.Light ? themeColorSecondary.contrastTextForMainBackground : themeColorSecondary.contrastTextForDarkBackground,
      },
      // properties of the switch used to select the themeMode (light/dark)
      themeModeSwitch: {
        // base color for the switch thumb (the circle on the switch)
        thumb: {
          color: mode === enumThemeMode.Light ? themeColorPrimary.contrastTextForMainBackground : themeColorPrimary.contrastTextForDarkBackground,
          background: mode === enumThemeMode.Light ? themeColorPrimary[300] : themeColorPrimary[500],
          border: mode === enumThemeMode.Light ? `1px solid ${themeColorPrimary.contrastTextForMainBackground}` : `1px solid ${themeColorPrimary.contrastTextForDarkBackground}`,
        },
        // controls styling of the track (the elongated area to the side of the thumb)
        track: {
          opacity: 1.0,
          background: mode === enumThemeMode.Light ? themeColorPrimary[100] : themeColorPrimary[200],
          border: mode === enumThemeMode.Light ? 'none' : 'none',
        },
      },
    },

    beaconNotification: {
      beaconTitleFontSize: '1.2rem',
      beaconMessageFontSize: '1.0rem',
      beaconBoard: {
        border: mode === enumThemeMode.Light ? `2px solid ${themeColorPrimary[200]}` : `2px solid ${themeColorPrimary[800]}`,
        background: mode === enumThemeMode.Light ? `linear-gradient(to bottom, ${themeColorPrimary[25]}, 60%, ${themeColorPrimary[50]})` : `linear-gradient(to bottom, ${themeColorPrimary[900]}, 60%, ${themeColorPrimary[800]})`,
        closeButtonColor: mode === enumThemeMode.Light ? themeColorPrimary[900] : themeColorPrimary[200],
      },
      beacon: {
        borderRadius: '0.5rem',
        titleFontSize: '1.2rem',
        messageFontSize: '1.0rem',
        border: mode === enumThemeMode.Light ? `1px solid ${themeColorPrimary[900]}` : `1px solid ${themeColorPrimary[25]}`,
      },
    }, // end of beaconNotification

    boxShadow: mode === enumThemeMode.Light ? `${convertHexColorStringToRgbaString(themeColorPrimary[700], 0.6)} 6px 6px 8px` : `${convertHexColorStringToRgbaString(themeColorPrimary[300], 0.6)} 6px 6px 8px`,

    cardViewCard: {
      border: mode === enumThemeMode.Light ? `1px solid ${themeColorPrimary[300]}` : `1px solid ${themeColorPrimary[300]}`,
      // border: '3px solid red',
      background: mode === enumThemeMode.Light ? `linear-gradient(to bottom, #FFF, 30%, ${themeColorPrimary[50]})` : `linear-gradient(to bottom, ${themeColorPrimary[900]}, 30%, ${themeColorPrimary[700]})`,
      hoverBackground: mode === enumThemeMode.Light ? `linear-gradient(to bottom, ${themeColorPrimary[50]}, 30%, ${themeColorPrimary[100]})` : `linear-gradient(to bottom, ${themeColorPrimary[800]}, 30%, ${themeColorPrimary[700]})`,
      hoverTransform: 'scale(1.02) translate(-1%, -1%)',
      titleColor: mode === enumThemeMode.Light ? themeColorPrimary[900] : themeColorPrimary[25],
      occurrenceTimestampColor: mode === enumThemeMode.Light ? themeColorPrimary[700] : themeColorPrimary[200],
      descriptionColor: mode === enumThemeMode.Light ? themeColorPrimary[900] : themeColorPrimary[25],
      drillDownButton: {
        background: mode === enumThemeMode.Light ? themeColorSecondary[500] : themeColorSecondary[700],
        hoverBackground: mode === enumThemeMode.Light ? themeColorSecondary[700] : themeColorSecondary[500],
        textColor: themeColorSecondary.contrastText ?? mode === enumThemeMode.Light ? themeColorSecondary.contrastTextForMainBackground : themeColorSecondary.contrastTextForDarkBackground,
      },

      // "Drill-down" icon button appearance features
      drillDownIconButton: {
        border: mode === enumThemeMode.Light ? `1px solid ${themeColorSecondary[300]}` : `1px solid ${themeColorSecondary[700]}`,
        background: mode === enumThemeMode.Light ? themeColorSecondary[500] : themeColorSecondary[700],
        textColor: themeColorSecondary.contrastText ?? mode === enumThemeMode.Light ? themeColorSecondary.contrastTextForMainBackground : themeColorSecondary.contrastTextForDarkBackground,
        iconColor: themeColorSecondary.contrastText ?? mode === enumThemeMode.Light ? themeColorSecondary[50] : themeColorSecondary[100],
        hoverBorder: mode === enumThemeMode.Light ? `1px solid ${themeColorSecondary[900]}` : `1px solid ${themeColorSecondary[25]}`,
        hoverBackground: mode === enumThemeMode.Light ? themeColorSecondary[700] : themeColorSecondary[500],
        hoverTextColor: mode === enumThemeMode.Light ? themeColorSecondary.contrastTextForMainBackground : themeColorSecondary.contrastTextForDarkBackground,
        hoverIconColor: mode === enumThemeMode.Light ? themeColorSecondary.contrastTextForMainBackground : themeColorSecondary.contrastTextForDarkBackground,
      },
      // "Edit" icon button appearance features
      editIconButton: {
        border: mode === enumThemeMode.Light ? `1px solid ${themeColorPrimary[500]}` : `1px solid ${themeColorPrimary[25]}`,
        background: mode === enumThemeMode.Light ? `linear-gradient(to bottom, ${themeColorPrimary[50]}, 50%, ${themeColorPrimary[100]})` : `linear-gradient(to bottom, ${themeColorPrimary[900]}, 50%, ${themeColorPrimary[800]})`,
        textColor: mode === enumThemeMode.Light ? themeColorPrimary[500] : themeColorPrimary[25],
        iconColor: mode === enumThemeMode.Light ? themeColorPrimary[500] : themeColorPrimary[25],
        hoverBorder: mode === enumThemeMode.Light ? `1px solid ${themeColorPrimary[700]}` : `1px solid ${themeColorPrimary[200]}`,
        hoverBackground: mode === enumThemeMode.Light ? `linear-gradient(to bottom, ${themeColorPrimary[100]}, 50%, ${themeColorPrimary[200]})` : `linear-gradient(to bottom, ${themeColorPrimary[700]}, 50%, ${themeColorPrimary[600]})`,
        hoverTextColor: mode === enumThemeMode.Light ? themeColorPrimary[700] : themeColorPrimary[25],
        hoverIconColor: mode === enumThemeMode.Light ? themeColorPrimary[700] : themeColorPrimary[25],
      },
      // "Delete" icon button appearance features
      deleteIconButton: {
        border: mode === enumThemeMode.Light ? `1px solid ${themeColorCancel[500]}` : `1px solid ${themeColorCancel[25]}`,
        background: mode === enumThemeMode.Light ? themeColorCancel[50] : themeColorCancel[900],
        textColor: mode === enumThemeMode.Light ? themeColorCancel[500] : themeColorCancel[25],
        iconColor: mode === enumThemeMode.Light ? themeColorCancel[500] : themeColorCancel[25],
        hoverBorder: mode === enumThemeMode.Light ? `1px solid ${themeColorCancel[700]}` : `1px solid ${themeColorCancel[25]}`,
        hoverBackground: mode === enumThemeMode.Light ? `linear-gradient(to bottom, ${themeColorCancel[100]}, 50%, ${themeColorCancel[200]})` : `linear-gradient(to bottom, ${themeColorCancel[700]}, 50%, ${themeColorCancel[600]})`,
        hoverTextColor: mode === enumThemeMode.Light ? themeColorCancel[700] : themeColorCancel[25],
        hoverIconColor: mode === enumThemeMode.Light ? themeColorCancel[700] : themeColorCancel[25],
      },
      // "Navigation" icon button appearance features
      navigationIconButton: {
        border: mode === enumThemeMode.Light ? `none` : `none`,
        background: mode === enumThemeMode.Light ? `none` : `none`,
        textColor: mode === enumThemeMode.Light ? themeColorPrimary[500] : themeColorPrimary[25],
        iconColor: mode === enumThemeMode.Light ? themeColorPrimary[500] : themeColorPrimary[25],
        hoverBorder: mode === enumThemeMode.Light ? `none` : `none`,
        hoverBackground: mode === enumThemeMode.Light ? themeColorPrimary[100] : themeColorPrimary[700],
        hoverTextColor: mode === enumThemeMode.Light ? themeColorPrimary[700] : themeColorPrimary[25],
        hoverIconColor: mode === enumThemeMode.Light ? themeColorPrimary[700] : themeColorPrimary[25],
      },
      // "Share" icon button appearance features
      shareIconButton: {
        border: mode === enumThemeMode.Light ? `1px solid ${themeColorPrimary[500]}` : `1px solid ${themeColorPrimary[25]}`,
        background: mode === enumThemeMode.Light ? `linear-gradient(to bottom, ${themeColorPrimary[50]}, 50%, ${themeColorPrimary[100]})` : `linear-gradient(to bottom, ${themeColorPrimary[900]}, 50%, ${themeColorPrimary[800]})`,
        textColor: mode === enumThemeMode.Light ? themeColorPrimary[500] : themeColorPrimary[25],
        iconColor: mode === enumThemeMode.Light ? themeColorPrimary[500] : themeColorPrimary[25],
        hoverBorder: mode === enumThemeMode.Light ? `1px solid ${themeColorPrimary[700]}` : `1px solid ${themeColorPrimary[200]}`,
        hoverBackground: mode === enumThemeMode.Light ? `linear-gradient(to bottom, ${themeColorPrimary[100]}, 50%, ${themeColorPrimary[200]})` : `linear-gradient(to bottom, ${themeColorPrimary[700]}, 50%, ${themeColorPrimary[600]})`,
        hoverTextColor: mode === enumThemeMode.Light ? themeColorPrimary[700] : themeColorPrimary[25],
        hoverIconColor: mode === enumThemeMode.Light ? themeColorPrimary[700] : themeColorPrimary[25],
      },
    }, // end of cardViewCard

    colorBarInSwatch: {
      border: mode === enumThemeMode.Light ? `1px solid ${themeColorPrimary[900]}` : `1px solid ${themeColorPrimary[25]}`,
      borderRadius: `${defaultTheme.shape.borderRadius}`,
    },

    colorSwatch: {
      border: mode === enumThemeMode.Light ? `1px solid ${themeColorPrimary[300]}` : `1px solid ${themeColorPrimary[300]}`,
      borderRadius: `${defaultTheme.shape.borderRadius}`,
      backgroundColor: 'none',
      hoverBorder: mode === enumThemeMode.Light ? `3px solid ${themeColorPrimary[300]}` : `3px solid ${themeColorPrimary[700]}`,
      hoverBackgroundColor: 'none',
      paddingAroundColorBar: '5px',
      hoverPaddingAroundColorBar: '3px',
      selectedSwatchBorder: mode === enumThemeMode.Light ? `3px solid ${themeColorPrimary[500]}` : `3px solid ${themeColorPrimary[500]}`,
      hoverSelectedSwatchBorder: mode === enumThemeMode.Light ? `3px solid ${themeColorPrimary[500]}` : `3px solid ${themeColorPrimary[500]}`,
    },

    dialog: {
      background: mode === enumThemeMode.Light ? themeColorPrimary[25] : themeColorPrimary[900],
      border: mode === enumThemeMode.Light ? `2px solid ${themeColorPrimary[200]}` : `2px solid ${themeColorPrimary[700]}`,
      borderRadius: `${defaultTheme.shape.borderRadius}`,
      acceptanceButton: {
        border: mode === enumThemeMode.Light ? `2px solid ${themeColorSecondary[500]}` : `2px solid ${themeColorSecondary[300]}`,
        background: mode === enumThemeMode.Light ? themeColorSecondary[500] : themeColorSecondary[900],
        color: mode === enumThemeMode.Light ? themeColorSecondary.contrastTextForMainBackground : themeColorSecondary.contrastTextForDarkBackground,
        iconColor: mode === enumThemeMode.Light ? themeColorSecondary.contrastTextForMainBackground : themeColorSecondary.contrastTextForDarkBackground,
        hoverBorder: mode === enumThemeMode.Light ? `2px solid ${themeColorSecondary[900]}` : `2px solid ${themeColorSecondary[300]}`,
        hoverBackground: mode === enumThemeMode.Light ? themeColorSecondary[900] : themeColorSecondary[700],
        hoverColor: mode === enumThemeMode.Light ? themeColorSecondary[700] : themeColorSecondary[100],
        disabledBackground: mode === enumThemeMode.Light ? themeColorSecondary[200] : themeColorSecondary[900],
        disabledBorder: mode === enumThemeMode.Light ? `2px solid ${themeColorSecondary[200]}` : `2px solid ${themeColorSecondary[700]}`,
        disabledColor: mode === enumThemeMode.Light ? themeColorSecondary.contrastTextForMainBackground : themeColorSecondary.contrastTextForDarkBackground,
        disabledIconColor: mode === enumThemeMode.Light ? themeColorSecondary.contrastTextForMainBackground : themeColorSecondary.contrastTextForDarkBackground,
      },
      nonAcceptanceButton: {
        border: mode === enumThemeMode.Light ? `1px solid ${themeColorCancel[500]}` : `1px solid ${themeColorCancel[25]}`,
        background: mode === enumThemeMode.Light ? themeColorCancel[50] : themeColorCancel[900],
        color: mode === enumThemeMode.Light ? themeColorCancel[500] : themeColorCancel[25],
        iconColor: mode === enumThemeMode.Light ? themeColorCancel[500] : themeColorCancel[25],
        hoverBorder: mode === enumThemeMode.Light ? `1px solid ${themeColorCancel[700]}` : `1px solid ${themeColorCancel[25]}`,
        hoverBackground: mode === enumThemeMode.Light ? `linear-gradient(to bottom, ${themeColorCancel[100]}, 50%, ${themeColorCancel[200]})` : `linear-gradient(to bottom, ${themeColorCancel[700]}, 50%, ${themeColorCancel[600]})`,
        hoverColor: mode === enumThemeMode.Light ? themeColorCancel[700] : themeColorCancel[100],
        disabledBackground: mode === enumThemeMode.Light ? themeColorCancel[200] : themeColorCancel[900],
        disabledBorder: mode === enumThemeMode.Light ? `2px solid ${themeColorCancel[200]}` : `2px solid ${themeColorCancel[700]}`,
        disabledColor: mode === enumThemeMode.Light ? themeColorCancel[800] : themeColorCancel[300],
        disabledIconColor: mode === enumThemeMode.Light ? themeColorCancel[800] : themeColorCancel[300],
      },
    }, // end of dialog

    documentIcon: {
      border: 'none',
      textColor: mode === enumThemeMode.Light ? themeColorPrimary[900] : themeColorPrimary[100],
      hover: {
        background: mode === enumThemeMode.Light ? themeColorPrimary[100] : themeColorPrimary[700],
        textColor: mode === enumThemeMode.Light ? themeColorPrimary[900] : themeColorPrimary[100],
      }
    }, // end of documentIcon

    emphasisSection: {
      background: mode === enumThemeMode.Light ? themeColorPrimary[50] : themeColorPrimary[900],
    }, // end of emphasisSection

    fieldSet: {
      border: mode === enumThemeMode.Light ? `1px solid ${themeColorPrimary[200]}` : `1px solid ${themeColorPrimary[100]}`,
      borderRadius: `0.5rem`,
      legendFontColor: mode === enumThemeMode.Light ? themeColorPrimary.contrastTextForLightBackground : themeColorPrimary.contrastTextForDarkBackground,
      legendFontSize: '0.9rem',
    }, // end of fieldSet

    fileDragAnDropArea:
    {
      background: mode === enumThemeMode.Light ? `linear-gradient(to bottom, ${themeColorPrimary[25]}, 30%, ${themeColorPrimary[50]})` : `linear-gradient(to bottom, ${themeColorPrimary[900]}, 30%, ${themeColorPrimary[800]})`,
      border: mode === enumThemeMode.Light ? `2px dashed ${themeColorPrimary[100]}` : `2px dashed ${themeColorPrimary[500]}`,
      textColor: mode === enumThemeMode.Light ? themeColorPrimary[800] : themeColorPrimary[25],
      fontWeight: '500',
      iconColor: mode === enumThemeMode.Light ? themeColorPrimary[800] : themeColorPrimary[25],
      cursor: 'pointer',
      hoverBorder: mode === enumThemeMode.Light ? `2px dashed ${themeColorPrimary[200]}` : `2px dashed ${themeColorPrimary[300]}`,
      hoverBackground: mode === enumThemeMode.Light ? `linear-gradient(to bottom, ${themeColorPrimary[50]}, 30%, ${themeColorPrimary[100]})` : `linear-gradient(to bottom, ${themeColorPrimary[800]}, 30%, ${themeColorPrimary[700]})`,
      hoverTextColor: mode === enumThemeMode.Light ? themeColorPrimary[900] : themeColorPrimary[100],
      hoverFontWeight: '600',
      hoverIconColor: mode === enumThemeMode.Light ? themeColorPrimary[900] : themeColorPrimary[100],
      hoverCursor: 'pointer',
    }, // end of fileDragAnDropArea

    floatingActionButton:
    {
      hover: {
        background: mode === enumThemeMode.Light ? themeColorSecondary[700] : themeColorSecondary[500],
        border: mode === enumThemeMode.Light ? themeColorSecondary[500] : `2px solid ${themeColorSecondary[300]}`,
        color: themeColorSecondary.contrastText ?? mode === enumThemeMode.Light ? themeColorSecondary.contrastTextForMainBackground : themeColorSecondary.contrastTextForDarkBackground,

      },
    },

    form: {
      border: mode === enumThemeMode.Light ? `2px solid ${themeColorPrimary[200]}` : `2px solid ${themeColorPrimary[300]}`,
      borderRadius: '1rem',
      submitButton: {
        border: mode === enumThemeMode.Light ? `2px solid ${themeColorSecondary.contrastHighlightForLightBackground}` : `2px solid ${themeColorSecondary.contrastHighlightForDarkBackground}`,
        background: mode === enumThemeMode.Light ? themeColorSecondary[500] : themeColorSecondary[900],
        color: mode === enumThemeMode.Light ? themeColorSecondary.contrastTextForMainBackground : themeColorSecondary.contrastTextForDarkBackground,
        iconColor: mode === enumThemeMode.Light ? themeColorSecondary.contrastTextForMainBackground : themeColorSecondary.contrastTextForDarkBackground,
        hoverBorder: mode === enumThemeMode.Light ? `2px solid ${themeColorSecondary[900]}` : `2px solid ${themeColorSecondary[300]}`,
        hoverBackground: mode === enumThemeMode.Light ? themeColorSecondary[600] : themeColorSecondary[800],
        hoverColor: mode === enumThemeMode.Light ? themeColorSecondary.contrastTextForMainBackground : themeColorSecondary.contrastTextForDarkBackground,
        disabledBackground: mode === enumThemeMode.Light ? themeColorSecondary[200] : themeColorSecondary[900],
        disabledBorder: mode === enumThemeMode.Light ? `2px solid ${themeColorSecondary[700]}` : `2px solid ${themeColorSecondary[300]}`,
        disabledColor: mode === enumThemeMode.Light ? themeColorSecondary[700] : themeColorSecondary[300],
        disabledIconColor: mode === enumThemeMode.Light ? themeColorSecondary[700] : themeColorSecondary[500],
      },
  
      cancelButton: {
        border: mode === enumThemeMode.Light ? `1px solid ${themeColorCancel[500]}` : `1px solid ${themeColorCancel[25]}`,
        background: mode === enumThemeMode.Light ? themeColorCancel[50] : themeColorCancel[900],
        color: mode === enumThemeMode.Light ? themeColorCancel[500] : themeColorCancel[25],
        iconColor: mode === enumThemeMode.Light ? themeColorCancel[500] : themeColorCancel[25],
        hoverBorder: mode === enumThemeMode.Light ? `1px solid ${themeColorCancel[700]}` : `1px solid ${themeColorCancel[25]}`,
        hoverBackground: mode === enumThemeMode.Light ? `linear-gradient(to bottom, ${themeColorCancel[100]}, 50%, ${themeColorCancel[200]})` : `linear-gradient(to bottom, ${themeColorCancel[700]}, 50%, ${themeColorCancel[600]})`,
        hoverColor: mode === enumThemeMode.Light ? themeColorCancel[700] : themeColorCancel[100],
        disabledBackground: mode === enumThemeMode.Light ? themeColorCancel[200] : themeColorCancel[900],
        disabledBorder: mode === enumThemeMode.Light ? `2px solid ${themeColorCancel[200]}` : `2px solid ${themeColorCancel[700]}`,
        disabledColor: mode === enumThemeMode.Light ? themeColorCancel[400] : themeColorCancel[300],
        disabledIconColor: mode === enumThemeMode.Light ? themeColorCancel[200] : themeColorCancel[300],
      },
    }, // end of form

    highlightColor: themeColorHighlight[500],

    hoverBoxShadow: `${convertHexColorStringToRgbaString(themeColorPrimary[700], 0.6)} 8px 8px 10px`, // color is themeColorPrimary[700]

    hyperlinkIcon: {
      border: 'none',
      textColor: mode === enumThemeMode.Light ? themeColorPrimary[900] : themeColorPrimary[100],
      hover: {
        background: mode === enumThemeMode.Light ? themeColorPrimary[100] : themeColorPrimary[700],
        textColor: mode === enumThemeMode.Light ? themeColorPrimary[900] : themeColorPrimary[100],
      }
    },

    // text for information sections, such as on 'About' pages
    infoText: {
      color: mode === enumThemeMode.Light ? themeColorPrimary[900] : themeColorPrimary[25],
    },

    listViewTable: {
      header: {
        background: mode === enumThemeMode.Light ? `linear-gradient(to bottom, ${themeColorPrimary[400]}, 50%, ${themeColorPrimary[600]})` : `linear-gradient(to bottom, ${themeColorPrimary[600]}, 50%, ${themeColorPrimary[400]})`,
        border: 'none',
        textColor: mode === enumThemeMode.Light ? themeColorPrimary[25] : themeColorPrimary[900],
      },
      detailRow: {
        dominantTextColor: mode === enumThemeMode.Light ? themeColorPrimary[900] : themeColorPrimary[25],
        otherTextColor: mode === enumThemeMode.Light ? themeColorPrimary[900] : themeColorPrimary[25],
        titleColor: mode === enumThemeMode.Light ? themeColorPrimary[900] : themeColorPrimary[25],
        occurrenceTimestampColor: mode === enumThemeMode.Light ? themeColorPrimary[700] : themeColorPrimary[200],
        descriptionColor: mode === enumThemeMode.Light ? themeColorPrimary[900] : themeColorPrimary[25],

        evenRowBackground: mode === enumThemeMode.Light ? themeColorPrimary[25] : themeColorPrimary[900],
        evenRowHoverBackground: mode === enumThemeMode.Light ? themeColorPrimary[25] : themeColorPrimary[900],
        evenRowBorder: mode === enumThemeMode.Light ? `1px solid ${themeColorPrimary[25]}` : `1px solid ${themeColorPrimary[900]}`,
        evenRowHoverBorder: mode === enumThemeMode.Light ? `1px solid ${themeColorPrimary[500]}` : `1px solid ${themeColorPrimary[500]}`,

        oddRowBackground: mode === enumThemeMode.Light ? themeColorPrimary[100] : themeColorPrimary[700],
        oddRowHoverBackground: mode === enumThemeMode.Light ? themeColorPrimary[100] : themeColorPrimary[700],
        oddRowBorder: mode === enumThemeMode.Light ? `1px solid ${themeColorPrimary[100]}` : themeColorPrimary[700],
        oddRowHoverBorder: mode === enumThemeMode.Light ? `1px solid ${themeColorPrimary[500]}` : themeColorPrimary[500],

        rowDividerColor: mode === enumThemeMode.Light ? themeColorPrimary[50] : themeColorPrimary[800],

        // "Drill-down" icon button appearance features
        drillDownIconButton: {
          border: mode === enumThemeMode.Light ? `1px solid ${themeColorSecondary[300]}` : `1px solid ${themeColorSecondary[700]}`,
          background: mode === enumThemeMode.Light ? themeColorSecondary[500] : themeColorSecondary[700],
          textColor: themeColorSecondary.contrastText ?? mode === enumThemeMode.Light ? themeColorSecondary.contrastTextForMainBackground : themeColorSecondary.contrastTextForDarkBackground,
          iconColor: themeColorSecondary.contrastText ?? mode === enumThemeMode.Light ? themeColorSecondary.contrastTextForMainBackground : themeColorSecondary.contrastTextForDarkBackground,
          hoverBorder: mode === enumThemeMode.Light ? `1px solid ${themeColorSecondary[900]}` : `1px solid ${themeColorSecondary[25]}`,
          hoverBackground: mode === enumThemeMode.Light ? themeColorSecondary[700] : themeColorSecondary[500],
          hoverTextColor: mode === enumThemeMode.Light ? themeColorSecondary.contrastTextForMainBackground : themeColorSecondary.contrastTextForDarkBackground,
          hoverIconColor: mode === enumThemeMode.Light ? themeColorSecondary.contrastTextForMainBackground : themeColorSecondary.contrastTextForDarkBackground,
        },
        // "Edit" icon button appearance features
        editIconButton: {
          border: mode === enumThemeMode.Light ? `1px solid ${themeColorPrimary[500]}` : `1px solid ${themeColorPrimary[25]}`,
          background: mode === enumThemeMode.Light ? `linear-gradient(to bottom, ${themeColorPrimary[50]}, 50%, ${themeColorPrimary[100]})` : `linear-gradient(to bottom, ${themeColorPrimary[900]}, 50%, ${themeColorPrimary[800]})`,
          textColor: mode === enumThemeMode.Light ? themeColorPrimary[500] : themeColorPrimary[25],
          iconColor: mode === enumThemeMode.Light ? themeColorPrimary[500] : themeColorPrimary[25],
          hoverBorder: mode === enumThemeMode.Light ? `1px solid ${themeColorPrimary[700]}` : `1px solid ${themeColorPrimary[200]}`,
          hoverBackground: mode === enumThemeMode.Light ? `linear-gradient(to bottom, ${themeColorPrimary[100]}, 50%, ${themeColorPrimary[200]})` : `linear-gradient(to bottom, ${themeColorPrimary[700]}, 50%, ${themeColorPrimary[600]})`,
          hoverTextColor: mode === enumThemeMode.Light ? themeColorPrimary[700] : themeColorPrimary[25],
          hoverIconColor: mode === enumThemeMode.Light ? themeColorPrimary[700] : themeColorPrimary[25],
        },
        // "Delete" icon button appearance features
        deleteIconButton: {
          border: mode === enumThemeMode.Light ? `1px solid ${themeColorCancel[500]}` : `1px solid ${themeColorCancel[25]}`,
          background: mode === enumThemeMode.Light ? themeColorCancel[50] : themeColorCancel[900],
          textColor: mode === enumThemeMode.Light ? themeColorCancel[500] : themeColorCancel[25],
          iconColor: mode === enumThemeMode.Light ? themeColorCancel[500] : themeColorCancel[25],
          hoverBorder: mode === enumThemeMode.Light ? `1px solid ${themeColorCancel[700]}` : `1px solid ${themeColorCancel[25]}`,
          hoverBackground: mode === enumThemeMode.Light ? `linear-gradient(to bottom, ${themeColorCancel[100]}, 50%, ${themeColorCancel[200]})` : `linear-gradient(to bottom, ${themeColorCancel[700]}, 50%, ${themeColorCancel[600]})`,
          hoverTextColor: mode === enumThemeMode.Light ? themeColorCancel[700] : themeColorCancel[25],
          hoverIconColor: mode === enumThemeMode.Light ? themeColorCancel[700] : themeColorCancel[25],
        },
      },
    }, // end of listViewTable

    labelField: {
      fontColor: mode === enumThemeMode.Light ? themeColorPrimary[500] : themeColorPrimary[200],
      fontSize: '0.75rem',
    },

    mediaFileViewTable: {
      header: {
        background: mode === enumThemeMode.Light ? `linear-gradient(to bottom, ${themeColorPrimary[400]}, 50%, ${themeColorPrimary[600]})` : `linear-gradient(to bottom, ${themeColorPrimary[600]}, 50%, ${themeColorPrimary[400]})`,
        border: 'none',
        textColor: mode === enumThemeMode.Light ? themeColorPrimary[25] : themeColorPrimary[900],
      },
      detailRow: {
        dominantTextColor: mode === enumThemeMode.Light ? themeColorPrimary[900] : themeColorPrimary[25],
        otherTextColor: mode === enumThemeMode.Light ? themeColorPrimary[900] : themeColorPrimary[25],
        titleColor: mode === enumThemeMode.Light ? themeColorPrimary[900] : themeColorPrimary[25],
        occurrenceTimestampColor: mode === enumThemeMode.Light ? themeColorPrimary[700] : themeColorPrimary[200],
        descriptionColor: mode === enumThemeMode.Light ? themeColorPrimary[900] : themeColorPrimary[25],

        evenRowBackground: mode === enumThemeMode.Light ? themeColorPrimary[25] : themeColorPrimary[900],
        evenRowHoverBackground: mode === enumThemeMode.Light ? themeColorPrimary[25] : themeColorPrimary[900],
        evenRowBorder: mode === enumThemeMode.Light ? `1px solid ${themeColorPrimary[25]}` : `1px solid ${themeColorPrimary[900]}`,
        evenRowHoverBorder: mode === enumThemeMode.Light ? `1px solid ${themeColorPrimary[500]}` : `1px solid ${themeColorPrimary[500]}`,

        oddRowBackground: mode === enumThemeMode.Light ? themeColorPrimary[100] : themeColorPrimary[700],
        oddRowHoverBackground: mode === enumThemeMode.Light ? themeColorPrimary[100] : themeColorPrimary[700],
        oddRowBorder: mode === enumThemeMode.Light ? `1px solid ${themeColorPrimary[100]}` : themeColorPrimary[700],
        oddRowHoverBorder: mode === enumThemeMode.Light ? `1px solid ${themeColorPrimary[500]}` : themeColorPrimary[500],

        rowDividerColor: mode === enumThemeMode.Light ? themeColorPrimary[50] : themeColorPrimary[800],

        // "Delete" icon button appearance features
        deleteIconButton: {
          border: mode === enumThemeMode.Light ? `1px solid ${themeColorCancel[500]}` : `1px solid ${themeColorCancel[25]}`,
          background: mode === enumThemeMode.Light ? themeColorCancel[50] : themeColorCancel[900],
          textColor: mode === enumThemeMode.Light ? themeColorCancel[500] : themeColorCancel[25],
          iconColor: mode === enumThemeMode.Light ? themeColorCancel[500] : themeColorCancel[25],
          hoverBorder: mode === enumThemeMode.Light ? `1px solid ${themeColorCancel[700]}` : `1px solid ${themeColorCancel[25]}`,
          hoverBackground: mode === enumThemeMode.Light ? `linear-gradient(to bottom, ${themeColorCancel[100]}, 50%, ${themeColorCancel[200]})` : `linear-gradient(to bottom, ${themeColorCancel[700]}, 50%, ${themeColorCancel[600]})`,
          hoverTextColor: mode === enumThemeMode.Light ? themeColorCancel[700] : themeColorCancel[25],
          hoverIconColor: mode === enumThemeMode.Light ? themeColorCancel[700] : themeColorCancel[25],
        },
      },
    }, // end of mediaFileViewTable

    menuButton: {
      border: mode === enumThemeMode.Light ? `2px solid ${themeColorSecondary[500]}` : `2px solid ${themeColorSecondary[300]}`,
      background: mode === enumThemeMode.Light ? themeColorSecondary[500] : themeColorSecondary[700],
      hoverBorder: mode === enumThemeMode.Light ? `2px solid ${themeColorSecondary[900]}` : `2px solid ${themeColorSecondary[300]}`,
      hoverBackground: mode === enumThemeMode.Light ? themeColorSecondary[700] : themeColorSecondary[500],
      textColor: mode === enumThemeMode.Light ? themeColorSecondary.contrastTextForMainBackground : themeColorSecondary.contrastTextForDarkBackground,
      hoverTextColor: mode === enumThemeMode.Light ? themeColorSecondary.contrastTextForMainBackground : themeColorSecondary.contrastTextForDarkBackground,
    },

    menuIconButton: {
      backgroundColor: mode === enumThemeMode.Light ? themeColorSecondary[900] : themeColorSecondary[700],
      hoverBackgroundColor: mode === enumThemeMode.Light ? themeColorSecondary[700] : themeColorSecondary[500],
      textColor: mode === enumThemeMode.Light ? themeColorPrimary[50] : themeColorPrimary[100],
      hoverTextColor: mode === enumThemeMode.Light ? themeColorPrimary[100] : themeColorPrimary[200],
    },

    myDigiBrainLogo: {
      // fill & stroke for myDigiBrainLogo Logo Person Head
      personHeadFill: mode === enumThemeMode.Light ? themeColorPrimary[100] : themeColorPrimary[100],
      personHeadStroke: mode === enumThemeMode.Light ? themeColorSecondary[900] : themeColorSecondary[900],

      // fill & stroke for other objects
      otherObjectsFill: mode === enumThemeMode.Light ? themeColorSecondary[900] : themeColorSecondary[900],
      otherObjectsStroke: mode === enumThemeMode.Light ? themeColorSecondary[900] : themeColorSecondary[900],

    }, // end of myDigiBrainLogo

    myTennisBrainLogo: {
      // fill & stroke for MyTennisBrain Logo Person Head
      personHeadFill: themeColorPrimary[100], // mode === enumThemeMode.Light ? themeColorPrimary[100] : themeColorPrimary[900],
      personHeadStroke: '#4d4d4d', // mode === enumThemeMode.Light ? '#4d4d4d' : themeColorPrimary[500],

      // fill & stroke for racket head
      racketHeadFill: '#004400', // mode === enumThemeMode.Light ? '#004400' : themeColorPrimary[100],
      racketHeadStroke: '#004400', //  mode === enumThemeMode.Light ? '#004400' : themeColorPrimary[100],

      // fill & stroke for racket throat
      racketThroatFill: '#004400', // mode === enumThemeMode.Light ? '#004400' : themeColorPrimary[100],
      racketThroatStroke: '#004400', // mode === enumThemeMode.Light ? '#004400' : themeColorPrimary[100],

      // fill & stroke for racket throat base
      racketThroatBaseFill: '#004400', // mode === enumThemeMode.Light ? '#004400' : themeColorPrimary[100],
      racketThroatBaseStroke: '#004400', // mode === enumThemeMode.Light ? '#004400' : themeColorPrimary[100],

      // fill & stroke for strings -- main & cross
      stringMainFill: 'none',
      stringMainStroke: '#3f3f3f', // mode === enumThemeMode.Light ? '#3f3f3f' : themeColorPrimary[25],
      stringCrossFill: 'none',
      stringCrossStroke: '#3f3f3f', // mode === enumThemeMode.Light ? '#3f3f3f' : themeColorPrimary[25],
    }, // end of myTennisBrainLogo

    page: {
      titleFontColor: mode === enumThemeMode.Light ? themeColorPrimary[900] : themeColorPrimary[100],
    }, // end of page

    scrollableDiv: {
      border: mode === enumThemeMode.Light ? `1px solid ${themeColorPrimary[200]}` : `1px solid ${themeColorPrimary[100]}`,
      borderRadius: '0.25rem',
    }, // end of scrollableDiv

    secondaryButton: {
      border: mode === enumThemeMode.Light ? `2px solid ${themeColorSecondary.contrastHighlightForLightBackground}` : `2px solid ${themeColorSecondary.contrastHighlightForDarkBackground}`,
      background: mode === enumThemeMode.Light ? themeColorSecondary[500] : themeColorSecondary[900],
      textColor: mode === enumThemeMode.Light ? themeColorSecondary.contrastTextForMainBackground : themeColorSecondary.contrastTextForDarkBackground,
      iconColor: mode === enumThemeMode.Light ? themeColorSecondary.contrastTextForMainBackground : themeColorSecondary.contrastTextForDarkBackground,
      hoverBorder: mode === enumThemeMode.Light ? `2px solid ${themeColorSecondary[900]}` : `2px solid ${themeColorSecondary[300]}`,
      hoverBackground: mode === enumThemeMode.Light ? themeColorSecondary[600] : themeColorSecondary[800],
      hoverTextColor: mode === enumThemeMode.Light ? themeColorSecondary.contrastTextForMainBackground : themeColorSecondary.contrastTextForDarkBackground,
      hoverIconColor: mode === enumThemeMode.Light ? themeColorSecondary.contrastTextForMainBackground : themeColorSecondary.contrastTextForDarkBackground,
      disabledBackground: mode === enumThemeMode.Light ? themeColorSecondary[200] : themeColorSecondary[900],
      disabledBorder: mode === enumThemeMode.Light ? `2px solid ${themeColorSecondary[700]}` : `2px solid ${themeColorSecondary[300]}`,
      disabledTextColor: mode === enumThemeMode.Light ? themeColorSecondary[700] : themeColorSecondary[300],
      disabledIconColor: mode === enumThemeMode.Light ? themeColorSecondary[700] : themeColorSecondary[300],

    }, // end of secondaryButton

    stylizedSvgIconGraphics: {
      graphicsPrimaryColor: mode === enumThemeMode.Light ? themeColorPrimary[900] : themeColorPrimary[25],
      graphicsSecondaryColor: mode === enumThemeMode.Light ? themeColorPrimary[50] : themeColorPrimary[900],
    }, // end of stylizedSvgIconGraphics

    tennisCourt: {
      surfaceColor: mode === enumThemeMode.Light ? themeColorSecondary[500] : themeColorSecondary[500],
    },

    textField: {
      backgroundColor: mode === enumThemeMode.Light ? themeColorPrimary[25] : themeColorPrimary[900],
      hoverBackgroundColor: mode === enumThemeMode.Light ? themeColorPrimary[50] : themeColorPrimary[800],
    },

    // }, // end of palette


    components: {

      MuiCssBaseline: {
        styleOverrides: (themeParam) => ({
          body: {
            // overscroll-behavior: none;
            // background: mode === enumThemeMode.Light ? themeColorPrimary[25] : themeColorPrimary[800],
            background: mode === enumThemeMode.Light ? 'white' : themeColorPrimary[800],
          },

        }),
      },

      // Base class for Buttons
      MuiButtonBase: {
        styleOverrides: {
          root: {
            // when a button has focus, remove outline border 
            "&.Mui-focused": {
              border: 'none',
              outline: 'none',
            },
          }
        }
      },

      // Card objects
      MuiCard: {
        styleOverrides: {
          root: {
            background: mode === enumThemeMode.Light ? `linear-gradient(to bottom, ${themeColorPrimary[25]}, 30%, ${themeColorPrimary[50]})` : `linear-gradient(to bottom, ${themeColorPrimary[900]}, 30%, ${themeColorPrimary[800]})`,
            border: mode === enumThemeMode.Light ? `1px solid ${themeColorPrimary[300]}` : `1px solid ${themeColorPrimary[700]}`,
            boxShadow: mode === enumThemeMode.Light ? `${convertHexColorStringToRgbaString(themeColorPrimary[700], 0.6)} 6px 6px 8px` : `${convertHexColorStringToRgbaString(themeColorPrimary[100], 0.6)} 6px 6px 8px`,

            // when user hovering over a card, make adjustments
            '&.Mui-hover': {
              background: '#F00', // `linear-gradient(to bottom, ${themeColorPrimary[50]}, 30%, ${themeColorPrimary[100]})`,
              boxShadow: `${convertHexColorStringToRgbaString(themeColorPrimary[700], 0.6)} 8px 8px 10px`, // color is themeColorPrimary[700]
              transform: 'scale(1.02) translate(-1%, -1%)',
            }
          }
        }
      },

      // A Checkbox
      MuiCheckbox: {
        styleOverrides: {
          root: {
            color: mode === enumThemeMode.Light ? themeColorPrimary[900] : themeColorPrimary[25],
            "&.Mui-checked": {
              // Controls checked color for the checkbox
              color: mode === enumThemeMode.Light ? themeColorPrimary[900] : themeColorPrimary[25],
            },
          },
        },
        defaultProps: {
          // color: mode === enumThemeMode.Light ? 'primary' : 'error'
        }
      },

      // A Container
      MuiContainer: {
        styleOverrides: {
          root: {
            flexGrow: 1,
            paddingLeft: `0px`,
            paddingRight: `0px`,
          },
        },
        defaultProps: {
          disableGutters: true,
          maxWidth: false,
        }
      },

      // A Dialog
      MuiDialog: {
        styleOverrides: {
          root: {
            background: mode === enumThemeMode.Light ? `linear-gradient(to bottom, #FFF, 30%, ${themeColorPrimary[50]})` : `linear-gradient(to bottom, #FFF, 30%, ${themeColorPrimary[800]})`,
            border: mode === enumThemeMode.Light ? `1px solid ${themeColorPrimary[200]}` : `1px solid ${themeColorPrimary[700]}`,
            boxShadow: mode === enumThemeMode.Light ? `${convertHexColorStringToRgbaString(themeColorPrimary[700], 0.6)} 6px 6px 8px` : `${convertHexColorStringToRgbaString(themeColorPrimary[200], 0.6)} 6px 6px 8px`,
          },
        },
      },

      // The Title for a Dialog
      MuiDialogTitle: {
        styleOverrides: {
          root: {
            color: mode === enumThemeMode.Light ? themeColorPrimary[900] : themeColorPrimary[25]
          },
        },
      },
      // },

      // The Content Text (message body) for a Dialog
      MuiDialogContentText: {
        styleOverrides: {
          root: {
            color: mode === enumThemeMode.Light ? themeColorPrimary[900] : themeColorPrimary[25]
          }
        },
      },

      // Slideout drawer (generally for use as a collapsible menu)
      MuiDrawer: {
        styleOverrides: {
          // root: {
          //   backgroundColor: themeColorPrimary[500],
          //   color: themeColorPrimary[900]
          // },
          paper: {
            background: mode === enumThemeMode.Light ? themeColorPrimary[50] : themeColorPrimary[800],
            color: mode === enumThemeMode.Light ? themeColorPrimary[900] : themeColorPrimary[25],
            '&.Mui-hover': {
              background: mode === enumThemeMode.Light ? themeColorPrimary[200] : themeColorPrimary[700]
            }
          },
        },
      },

      // Floating Action Buttons
      MuiFab: {
        styleOverrides: {
          root: {
            background: mode === enumThemeMode.Light ? themeColorSecondary[500] : themeColorSecondary[700],
            border: mode === enumThemeMode.Light ? themeColorSecondary[500] : `2px solid ${themeColorSecondary[500]}`,
            color: themeColorSecondary.contrastText ?? mode === enumThemeMode.Light ? themeColorSecondary.contrastTextForMainBackground : themeColorSecondary.contrastTextForDarkBackground,

            boxShadow: mode === enumThemeMode.Light ?
              `${convertHexColorStringToRgbaString(themeColorPrimary[700], 0.6)} 3px 3px 4px` :
              `${convertHexColorStringToRgbaString(themeColorPrimary[300], 0.6)} 3px 3px 8px`,
            // when user is hovering, make adjustments
            // NOTE: Hover styleOverrides for the MuiFab doesn't seem to work, so there's a floatingActionButton property higher
            //       up in this file that provides for hover properties
            '&.Mui-hover': {
              background: mode === enumThemeMode.Light ? themeColorPrimary[700] : themeColorPrimary[300],
              color: themeColorSecondary.contrastText ?? mode === enumThemeMode.Light ? themeColorSecondary.contrastTextForMainBackground : themeColorSecondary.contrastTextForDarkBackground,
            }
          }
        },
      },

      // Any standard form control
      MuiFormControl: {
        styleOverrides: {
        },
        defaultProps: {
          variant: 'outlined',
        },
      },

      // A FormControlLabel (eg, a label next to a checkbox)
      MuiFormControlLabel: {
        styleOverrides: {
          root: {
            color: mode === enumThemeMode.Light ? themeColorPrimary[900] : themeColorPrimary[25],
          },
        },
        defaultProps: {
        }
      },

      // Helper Text for Form Fields, such as the instructional text for an input field, or an error message for an input field
      MuiFormHelperText: {
        styleOverrides: {
          root: {
            border: 'yellow',
            '&.Mui-error': {
              color: mode === enumThemeMode.Light ? themeColorError[900] : themeColorError[200], // text color for input field labels in error condition
            },
          }
        },
      },

      // Base class for Input fields
      MuiInputBase: {
        styleOverrides: {
          root: {
            // set the font color
            color: mode === enumThemeMode.Light ? themeColorPrimary[900] : themeColorPrimary[25],
            // when an input field has focus, shade the field's background color
            '&.Mui-focused': {
              background: mode === enumThemeMode.Light ? themeColorPrimary[50] : themeColorPrimary[800],
              border: 'yellow',
            },
            // when an input field is disabled, shade the field's background color and alter text color
            '&.Mui-disabled': {
              background: mode === enumThemeMode.Light ? themeColorPrimary[100] : themeColorPrimary[700],
              color: mode === enumThemeMode.Light ? themeColorPrimary[700] : themeColorPrimary[200],
            },
            // input field has focus and is under error condition
            '&.Mui-focused.Mui-error': {
              background: mode === enumThemeMode.Light ? themeColorPrimary[50] : themeColorPrimary[800],
            },
          }
        },
      },

      // Input fields
      MuiInput: {
        styleOverrides: {
          root: {
            // set the font color
            color: mode === enumThemeMode.Light ? themeColorPrimary[900] : themeColorPrimary[25],
          }
        },
      },

      // Label associated with input fields
      MuiInputLabel: {
        styleOverrides: {
          root: {
            color: mode === enumThemeMode.Light ? themeColorPrimary[700] : themeColorPrimary[200],
            '&.Mui-focused': {
              // controls the properties when the control has focus
              color: mode === enumThemeMode.Light ? themeColorPrimary[700] : themeColorPrimary[300],
            },
            '&.Mui-error': {
              color: mode === enumThemeMode.Light ? themeColorError[900] : themeColorError[200], // text color for input field labels in error condition
            }
          }
        },
      },

      // ListItem objects
      MuiListItem: {
        styleOverrides: {
          root: {
            color: mode === enumThemeMode.Light ? themeColorPrimary[900] : themeColorPrimary[25],
          }
        },
      },

      // Menu
      MuiMenu: {
        styleOverrides: {
          root: {
            // Note: The 'root backgroundColor' here represents the adornment of the web page under (behind) the popup menu
            backgroundColor: mode === enumThemeMode.Light ? `${convertHexColorStringToRgbaString(themeColorPrimary[25], 0.4)}` : `${convertHexColorStringToRgbaString(themeColorPrimary[700], 0.8)}`,
            // '&.MuiPaper-root': {
            //   // backgroundColor: themeColorPrimary[100],
            //   background: mode === enumThemeMode.Light ? themeColorPrimary[100] : 'yellow', // themeColorPrimary[900],
            //   color: themeColorPrimary[900],
            // },
          },
        }
      },

      // MenuItem objects (in a Manu or Select control)
      MuiMenuItem: {
        styleOverrides: {
          root: {
            // default properties for each item
            color: mode === enumThemeMode.Light ? themeColorPrimary[900] : themeColorPrimary[25],
            background: mode === enumThemeMode.Light ? themeColorPrimary[100] : themeColorPrimary[800],
            '&.Mui-selected': {
              // controls the properties of the item that is the currently selected Menu Item
              background: mode === enumThemeMode.Light ? themeColorPrimary[300] : themeColorPrimary[500],
              "&.Mui-focusVisible": {
                // controls the properties of the item that is currently displayed in the base Select control, but has been officially selected
                background: mode === enumThemeMode.Light ? themeColorPrimary[300] : themeColorPrimary[500],
              },
            },
            // '&.Mui-hover': {
            //   // DOESN'T SEEM TO WORK
            //   // controls the properties of the item that is being hovered in the collection of Menu Items
            //   background: mode === enumThemeMode.Light ? themeColorPrimary[400] : themeColorPrimary[500],
            // },
          }
        },
      },

      // Select dropdown control
      MuiSelect: {
        styleOverrides: {
          // Currently, there's apparently a bug in Mui 5.x where the MuiSelect overrides must use the 'select' element instead of the 'root' element
          // https://stackoverflow.com/questions/69577570/mui-v5-styleoverrides-not-working-with-themes 
          select: {
            // default colors for display of a Select field (the base Select field, not the Menu Items)

            // root: {
            // backgroundColor: themeColorPrimary[25],
            // // when user hovering, make adjustments
            // '&.Mui-hover': {
            //   backgroundColor: themeColorPrimary[50],
            // }

            border: mode === enumThemeMode.Light ? themeColorPrimary[700] : themeColorPrimary[25],
            background: mode === enumThemeMode.Light ? themeColorPrimary[25] : themeColorPrimary[900],
            color: mode === enumThemeMode.Light ? themeColorPrimary[900] : themeColorPrimary[25],
            // '&.Mui-selected': {
            //   // controls the properties of the item that is the currently selected Menu Item
            //   background: 'yellow', // mode === enumThemeMode.Light ? themeColorPrimary[400] : themeColorPrimary[500],
            // },
            // '&.Mui-hover': {
            //   // when user hovers over the Select field
            //   border: mode === enumThemeMode.Light ? themeColorPrimary[700] : themeColorPrimary[100],
            //   background: 'orange', // mode === enumThemeMode.Light ? themeColorPrimary[500] : themeColorPrimary[800],
            //   color: mode === enumThemeMode.Light ? themeColorPrimary[900] : themeColorPrimary[25],
            // },

            // root: {
            // backgroundColor: themeColorPrimary[25],
            //   // when user hovering, make adjustments
            //   '&.Mui-hover': {
            //     backgroundColor: themeColorPrimary[50],
            //   },
            // },
          },
        },
        defaultProps: {
          variant: 'outlined',
        },
      },

      // Switch component (on/off slider switch)
      MuiSwitch: {
        styleOverrides: {
          root: {
          },
          switchBase: {
            color: mode === enumThemeMode.Light ? themeColorPrimary[200] : themeColorPrimary[400], // controls default color for the thumb (the circle on the switch)
            "&.Mui-hover": {  // switch being hovered over while in non-checked state
              color: mode === enumThemeMode.Light ? themeColorPrimary[200] : themeColorPrimary[25],
            },
          },
          colorPrimary: {
            "&.Mui-checked": {
              // Controls checked color for the thumb
              color: mode === enumThemeMode.Light ? themeColorPrimary[600] : themeColorPrimary[100],
              "&.Mui-hover": {  // switch being hovered over while in checked state
                color: mode === enumThemeMode.Light ? themeColorPrimary[700] : themeColorPrimary[25],
              },
            },
          },
          // controls styling of the track (the elongated area to the side of the thumb)
          track: {
            // controls default (unchecked) properties for the track
            opacity: 1.0, // controls opacity of the track when switch is in the 'off' (unchecked) position
            background: mode === enumThemeMode.Light ? themeColorPrimary[300] : themeColorPrimary[200], // controls color of the track when switch is in 'off' (unchecked) position
            ".Mui-checked.Mui-checked + &": {
              // controls properties of the track when the switch is checked (turned on)
              opacity: 1.0, // controls opacity of the track when switch is in the 'on' (checked) position
              background: mode === enumThemeMode.Light ? themeColorPrimary[300] : themeColorPrimary[200], // controls color for the track when switch is in the 'on' (checked) position
            }
          },
        },
      },

      // Inidividual Tab within a Tabs control
      MuiTab: {
        styleOverrides: {
          root: {
            padding: 0,
            margin: defaultTheme.spacing(0, 0.25), // small margin on left & right to separate the tabs
            background: mode === enumThemeMode.Light ? `linear-gradient(to bottom, ${themeColorPrimary[300]}, 50%, ${themeColorPrimary[300]})` : `linear-gradient(to bottom, ${themeColorPrimary[600]}, 50%, ${themeColorPrimary[600]})`,
            borderTop: mode === enumThemeMode.Light ? `2px solid ${themeColorPrimary[500]}` : `2px solid ${themeColorPrimary[500]}`,
            borderLeft: mode === enumThemeMode.Light ? `2px solid ${themeColorPrimary[500]}` : `2px solid ${themeColorPrimary[500]}`,
            borderRight: mode === enumThemeMode.Light ? `2px solid ${themeColorPrimary[500]}` : `2px solid ${themeColorPrimary[500]}`,
            borderTopLeftRadius: defaultTheme.spacing(1.5),
            borderTopRightRadius: defaultTheme.spacing(1.5),
            "&.Mui-hover": {
              background: mode === enumThemeMode.Light ? themeColorPrimary[100] : themeColorPrimary[800],
              color: mode === enumThemeMode.Light ? themeColorPrimary[900] : themeColorPrimary[25],
            },
            "&.Mui-selected": {
              background: mode === enumThemeMode.Light ? `linear-gradient(to bottom, ${themeColorPrimary[50]}, 50%, ${themeColorPrimary[25]})` : `linear-gradient(to bottom, ${themeColorPrimary[600]}, 50%, ${themeColorPrimary[800]})`,
              borderTop: mode === enumThemeMode.Light ? `3px solid ${themeColorPrimary[700]}` : `3px solid ${themeColorPrimary[200]}`,
              borderLeft: mode === enumThemeMode.Light ? `3px solid ${themeColorPrimary[700]}` : `3px solid ${themeColorPrimary[200]}`,
              borderRight: mode === enumThemeMode.Light ? `3px solid ${themeColorPrimary[700]}` : `3px solid ${themeColorPrimary[200]}`,
              borderBottom: 'none',
              color: mode === enumThemeMode.Light ? themeColorPrimary[900] : themeColorPrimary[25],
              "&.Mui-hover": {
                backgroundColor: mode === enumThemeMode.Light ? themeColorPrimary[700] : themeColorPrimary[200],
                border: mode === enumThemeMode.Light ? `1px solid ${themeColorPrimary[700]}` : `1px solid ${themeColorPrimary[200]}`,
                color: mode === enumThemeMode.Light ? themeColorPrimary[50] : themeColorPrimary[800],
              }
            }
          },
        },
      },

      // Overall Tabs control
      MuiTabs: {
        styleOverrides: {
          root: {
            color: mode === enumThemeMode.Light ? themeColorPrimary[800] : themeColorPrimary[50],
            padding: 0,
            borderBottom: mode === enumThemeMode.Light ? `1px solid ${themeColorPrimary[50]}` : `1px solid ${themeColorPrimary[800]}`,
          },
          indicator: {
            backgroundColor: mode === enumThemeMode.Light ? themeColorPrimary[25] : themeColorPrimary[800],
          },
        },
      },

      MuiTextField: {
        styleOverrides: {
          root: {
            border: mode === enumThemeMode.Light ? themeColorPrimary[700] : themeColorPrimary[100],
            background: mode === enumThemeMode.Light ? themeColorPrimary[25] : themeColorPrimary[900],
            color: mode === enumThemeMode.Light ? themeColorPrimary[900] : themeColorPrimary[25],
            '&.Mui-hover': {
              border: mode === enumThemeMode.Light ? themeColorPrimary[700] : themeColorPrimary[100],
              background: mode === enumThemeMode.Light ? themeColorPrimary[50] : themeColorPrimary[800],
              color: mode === enumThemeMode.Light ? themeColorPrimary[900] : themeColorPrimary[25],
            },
          },
        },
        defaultProps: {
          variant: 'outlined',
        },
      },

      // Toggle Button (a button within a ToggleButtonGroup)
      MuiToggleButton: {
        styleOverrides: {
          root: {
            backgroundColor: mode === enumThemeMode.Light ? themeColorPrimary[100] : themeColorPrimary[600],
            color: mode === enumThemeMode.Light ? themeColorPrimary[600] : themeColorPrimary[200],
            '&.Mui-selected': {
              backgroundColor: mode === enumThemeMode.Light ? themeColorPrimary[700] : themeColorPrimary[100],
              color: mode === enumThemeMode.Light ? themeColorPrimary[25] : themeColorPrimary[900],
            },
          },
        },
        defaultProps: {
        },
      },

      // Toggle Button Group
      MuiToggleButtonGroup: {
        styleOverrides: {
          root: {
            background: themeColorPrimary[500], // background color used during a mouse hover
            color: themeColorPrimary[25], // NOTE: This doesn't seem to have any effect (doesn't override the ToggleButton styleOverrides above for 'color')
          },
        },
        defaultProps: {
        },
      },

      // Toolbar control
      MuiToolbar: {
        styleOverrides: {
          root: {
            gutters: {
              [defaultTheme.breakpoints.up('xs')]: {
                paddingLeft: '1rem',
                paddingRight: '1rem',
              },
            }
          },
        },
      },

      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            backgroundColor: mode === enumThemeMode.Light ? themeColorPrimary[700] : themeColorPrimary[200],
            color: mode === enumThemeMode.Light ? themeColorPrimary[50] : themeColorPrimary[900],
            fontSize: '0.75rem',
          },
          arrow: {
            color: mode === enumThemeMode.Light ? themeColorPrimary[700] : themeColorPrimary[200],
          },

          // root: {
          //   // set fundamental CSS overrides
          //   tooltip: {
          //     backgroundColor: themeColorPrimary[700],
          //     color: themeColorPrimary[50],
          //     fontSize: '0.75rem',
          //   },
          //   arrow: {
          //     color: themeColorPrimary[700],
          //   },
          // },
        },
        defaultProps: {
          arrow: true
        },
      },

    },

  }); // end of createTheme

  return customTheme;
}
