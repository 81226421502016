import React, { PropsWithChildren, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import {
  Box,
  BoxProps,
  Card,
  CardActions,
  CardContent,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  MenuItemProps,
  MenuProps,
  Tooltip,
  Typography,
  TypographyProps
} from '@mui/material';
import {
  Delete as DeleteIcon,
  Edit as EditIcon,
  NearMe as NavigationIcon,
} from '@mui/icons-material';
import { IAudioLink } from '../../../../dataObjects/models/digitalMedia/AudioLink';
import { cardGridViewItemStyles } from '../../../styles/views/cardGridViewItemStyles';
import { MenuItemStringAssets, TooltipStringAssets } from '../../../../assets/stringAssets';
import { IAudioLinkViewModel } from '../../../../dataObjects/viewModels/audioLinkViewModel';
import { Channel } from '../../../../dataObjects/models/channels/Channel';
import { AudioMediaDisplay } from '../../../controls/digitalMediaDisplays/AudioMediaDisplay';
import { enumDigitalMediaDisplayEnvironment } from '../../../../dataObjects/enums';


// a styled Popover Menu (used for popping up the 'User' menu)
const StyledPopoverUserMenu = styled((props: MenuProps) => (
  <Menu
    elevation={5}
    anchorOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  // transform: 'translate(0, 40px)',  // move the court left by 50% of its width
}));

// a styled menu item in the Popover Menu
const StyledPopoverMenuItem = styled((props: MenuItemProps) => (
  <MenuItem
    {...props}
  />
))(({ theme }) => ({
  '&:focus': {
    //       // '&.Mui-focus': {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.primary.light,
  },
  '&:hover': {
    //       // '&.Mui-hover': {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.light,
  },
}));

// styled text for a menu item in the Popover Menu
const StyledMenuItemText = styled((props: TypographyProps) => (
  <Typography
    {...props}
  />
))(({ theme }) => ({
  marginLeft: '1.0rem'
}));

// a styled Box (equivalent to a <div>), representing a background area for controls on the AppHeader
const StyledNavigateToButtonArea = styled((props: BoxProps) => (
  <Box
    {...props}
  />
))(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-end",
  justifyItems: 'flex-end',
  marginTop: theme.spacing(-1.50),
}));


export interface IAudioLinksCardGridViewItemProps extends PropsWithChildren<unknown> {
  audioLinkViewModel: IAudioLinkViewModel;
  onDelete: (audioLink: IAudioLink) => void;
}


export const AudioLinksCardGridViewItem: React.FC<IAudioLinksCardGridViewItemProps> = (props: IAudioLinksCardGridViewItemProps) => {

  const { audioLinkViewModel, onDelete } = props;
  const { audioLink, accessPermissionToAncestorChannel } = audioLinkViewModel;
  const { id, description } = audioLink;

  const classes = cardGridViewItemStyles();

  // we're going to apply the 'useNavigate' hook to navigate the browser
  const navigate = useNavigate();

  // memoize the rendering of the audio for existing AudioLink so that it won't have to be reset with every render
  const audioObjectForDisplay = useMemo(() => (
    <AudioMediaDisplay
      audioLink={audioLink}
      displayEnvironment={enumDigitalMediaDisplayEnvironment.CardView} />
  ), [audioLink]);

  // specifies the anchor position for the "Navigate To" popup menu
  const [navigateToButtonAnchorEl, setUserButtonAnchorEl] = React.useState(null);

  // controls whether the "Navigate To" popup menu is open (showing)
  const openNavigateToMenu = Boolean(navigateToButtonAnchorEl);


  function handleNavigateToButtonClicked(event: any) {
    setUserButtonAnchorEl(event.currentTarget);
  };

  const handleCloseNavigateToMenu = () => {
    setUserButtonAnchorEl(null);
  };

  const handlePageNavigationFromPopupMenu = (pageUrl: string) => {
    // navigate to page
    navigate(pageUrl);

    // close the user menu
    handleCloseNavigateToMenu();
  }

  return (
    <>
      {/* <Card classes={{ root: classes.card }} elevation={6}> */}
      <Card elevation={6} className={classes.card}>
        {/* <CardActionArea> */}
        {/* <CardContent>

            {audioObjectForDisplay}

            <Typography className={classes.cardDescription} variant="body1">
              {description}
            </Typography>
          </CardContent> */}
        <CardContent>
          <StyledNavigateToButtonArea>
            <IconButton
              className={`${classes.navigationIconButton}`}
              size="small"
              aria-label="navigate to"
              aria-controls="navigate-to"
              aria-haspopup="true"
              onClick={(evt: React.MouseEvent<HTMLButtonElement, MouseEvent>) => handleNavigateToButtonClicked(evt)}
            >
              <Tooltip title={'Navigate to...'} arrow >
                <NavigationIcon fontSize='small' />
              </Tooltip>
            </IconButton>

            {/* Render the Popover User Menu **ONLY** if the navigateToButtonAnchorEl object has been created */}
            {
              navigateToButtonAnchorEl &&
              <StyledPopoverUserMenu
                id="menu-appbar"
                anchorEl={navigateToButtonAnchorEl}
                anchorReference={navigateToButtonAnchorEl && 'anchorEl'}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                open={openNavigateToMenu}
                onClose={handleCloseNavigateToMenu}
              >
                <StyledPopoverMenuItem selected={false} onClick={() => handlePageNavigationFromPopupMenu(`/${Channel.coreChannelIdFromChannelId(audioLinkViewModel.ancestorChannelId)}/topics`)}>
                  <StyledMenuItemText>
                    {MenuItemStringAssets.menuItem_NavigateToAncestorChannel}
                  </StyledMenuItemText>
                </StyledPopoverMenuItem>
                <StyledPopoverMenuItem selected={false} onClick={() => handlePageNavigationFromPopupMenu(`/${audioLinkViewModel.ancestorTopicId}/topicItems`)}>
                  <StyledMenuItemText>
                    {MenuItemStringAssets.menuItem_NavigateToAncestorTopic}
                  </StyledMenuItemText>
                </StyledPopoverMenuItem>
                <StyledPopoverMenuItem selected={false} onClick={() => handlePageNavigationFromPopupMenu(`/${audioLinkViewModel.parentTopicItemId}/digitalMedia`)}>
                  <StyledMenuItemText>
                    {MenuItemStringAssets.menuItem_NavigateToParentTopicItem}
                  </StyledMenuItemText>
                </StyledPopoverMenuItem>
              </StyledPopoverUserMenu>
            } {/* end of 'navigateToButtonAnchorEl && ...' */}

          </StyledNavigateToButtonArea>

          <Grid container direction="column" alignItems='center' >
            {/* Audio */}
            <Grid item container direction="row" >
              {audioObjectForDisplay}
            </Grid>
            <Grid item xs={12} >
              <Typography className={classes.cardDescription} variant="body1">
                {description}
              </Typography>
            </Grid>
          </Grid>

        </CardContent>
        {/* </CardActionArea> */}

        <CardActions className={classes.cardActionsContainer}>
          <Tooltip
            // the tooltip for the edit button will either be "Edit" (if user has sufficient permission) or "Edit disallowed" (insufficient permission)
            title={!accessPermissionToAncestorChannel.hasEditPermission ? TooltipStringAssets.edit_Disallowed : TooltipStringAssets.edit}
            arrow
          >
            <span>
              <IconButton
                className={`${classes.actionIconButton} ${classes.editIconButton}`}
                size="small"
                aria-label="edit"
                disabled={!accessPermissionToAncestorChannel.hasEditPermission}
                onClick={() => navigate(`/audioLink/${id}`)}>
                <EditIcon />
              </IconButton>
            </span>
          </Tooltip>

          <Tooltip
            // the tooltip for the delete button will either be "Delete" (if user has sufficient permission) or "Delete disallowed" (insufficient permission)
            title={!accessPermissionToAncestorChannel.hasEditPermission ? TooltipStringAssets.delete_Disallowed : TooltipStringAssets.delete}
            arrow
          >
            <span>
              <IconButton
                className={`${classes.actionIconButton} ${classes.deleteIconButton}`}
                size="small"
                aria-label="delete"
                disabled={!accessPermissionToAncestorChannel.hasEditPermission}
                onClick={() => audioLink && onDelete(audioLink)} >
                <DeleteIcon />
              </IconButton>
            </span>
          </Tooltip>
        </CardActions>
      </Card>
    </>
  );

}