import { createSlice } from '@reduxjs/toolkit';
import { IMdbError } from '../../../errorObjects/MdbError';

const initialState: IMdbError | null = null;

export const userRedemptionCodeRequestDeleteFailureSlice = createSlice({
  name: 'userRedemptionCodeRequestDeleteFailure',
  initialState,
  reducers: {
    // action has a payload with an error
    userRedemptionCodeRequestDeleteFailureChange(state, action) {
      return action.payload;
    },
        
    // action has a payload with a null value
    userRedemptionCodeRequestDeleteFailureClear(state) {
      return null;
    },
  },
})

// Action creators are generated for each function in reducer)
export const { userRedemptionCodeRequestDeleteFailureChange, userRedemptionCodeRequestDeleteFailureClear } = userRedemptionCodeRequestDeleteFailureSlice.actions;

// export the core reducer for the slice
export const userRedemptionCodeRequestDeleteFailureReducer = userRedemptionCodeRequestDeleteFailureSlice.reducer;

export default userRedemptionCodeRequestDeleteFailureSlice.reducer;