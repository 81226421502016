import React, { PropsWithChildren, useCallback, useEffect, useState } from 'react';
import { Box, BoxProps, Container, ContainerProps } from '@mui/material';
import { ControlsStringAssets, MessagesStringAssets, PageAndViewTitleStringAssets } from '../../../../assets/stringAssets';
import { enumAlertType } from '../../../enums';
import { INote } from '../../../../dataObjects/models/digitalMedia/Note';
import { typeUniqueId } from '../../../../dataObjects/types';
import { IStoreState } from '../../../../uiMiddleware-redux/store/IStoreState';
import { enumBeaconType, enumCollectionViewType, enumWorkflowState } from '../../../../dataObjects/enums';
import TwoButtonAcceptanceDialog from '../../../dialogs/TwoButtonAcceptanceDialog/TwoButtonAcceptanceDialog';
import { Beacon } from '../../../../dataObjects/models/alerts/Beacon';
import { useReduxWorkflowState } from '../../../customHooks';
import ActivityIndicatorDialog from '../../../dialogs/ActivityIndicatorDialog/ActivityIndicatorDialog';
import { IActivityIndicatorData } from '../../../dialogs/ActivityIndicatorData';
import { AlertInfo, IAlertInfo } from '../../../../dataObjects/models/alerts/AlertInfo';
import { useGetCollectionViewType } from '../../../customHooks/useGetCollectionViewType';
import GenericToggleViewContainer from '../../GenericToggleViewContainer/GenericToggleViewContainer';
import { NotesCardGridView } from '../NotesCardGridView/NotesCardGridView';
import NotesListView from '../NotesListView/NotesListView';
import { styled } from '@mui/styles';
import { INoteViewModel } from '../../../../dataObjects/viewModels/noteViewModel';
import { useAppDispatch } from '../../../../uiMiddleware-redux/store/configureStore';
import { noteDeleteRequest, noteDeleteStatusChange } from '../../../../uiMiddleware-redux/slices/note/noteDeleteStatusSlice';
import { beaconChange } from '../../../../uiMiddleware-redux/slices/beacon/beaconSlice';
import { alertInfoChange } from '../../../../uiMiddleware-redux/slices/alertInfo/alertInfoSlice';


/*** Using the Material UI Emotion Styling library, declare 'styled' instances for each area/object. 
 *** NOTE: These must be declared outside of the React Functional Component to ensure that the styled 
 *** objects will be properly rendered within the DOM. 
 ***/

// a styled Box (equivalent to a <div>), providing an area padding at the top of the view
const StyledBoxForPaddingAtopView = styled((props: BoxProps) => (
  <Box
    {...props}
  />
))(({ theme }) => ({
  marginTop: theme.spacing(2),
}));

// a styled Container for the View Container
const StyledContainerForViewContainer = styled((props: ContainerProps) => (
  <Container
    {...props}
  />
))(({ theme }) => ({
  flexGrow: 1,
  paddingLeft: 0,
  paddingRight: 0,
}));

export interface INotesViewProps extends PropsWithChildren<unknown> {
  parentId?: typeUniqueId; // the parent DigitalMedia for the Notes
  notesToDisplay: Array<INoteViewModel>;
  allowAddingNewItems: boolean;
  notesDataLoading: boolean;
}

const NotesView: React.FC<INotesViewProps> = (props: INotesViewProps) => {

  NotesView.displayName = 'Notes View';

  // whether to display console logs (displayConsoleLogs && console.log statements)
  const displayConsoleLogs: boolean = false;

  const { parentId, notesToDisplay, allowAddingNewItems, notesDataLoading } = props;

  // call custom hook to get the current view type, so the value can be used to initialize the View Type state variable
  const collectionViewType: enumCollectionViewType = useGetCollectionViewType();

  // the NoteViewModels associcated with the current Digital Media
  // const [notes, setNotes] = useState<Array<INote>>(notesToDisplay);
  const [noteViewModels, setNoteViewModels] = useState<Array<INoteViewModel>>(notesToDisplay);
  // whether data is currently loading
  const [dataLoading, setDataLoading] = useState<boolean>(notesDataLoading);
  // if user is deleting a Note, the Note to delete
  const [noteToDelete, setNoteToDelete] = useState<INote | undefined>(undefined);
  // whether to show the Delete Confirmation dialog
  const [showDeleteConfirmationDlg, setShowDeleteConfirmationDlg] = useState<boolean>(false);

  // for dispatching redux actions
  const dispatch = useAppDispatch();

  /**
   * @function resetDeleteNoteStatus Resets the Delete status in Redux state
   */
  const resetDeleteNoteStatus: () => void = useCallback(() => {
    dispatch(noteDeleteStatusChange(null));
  }, [dispatch])

  // use a custom hook to evaluate the redux state for the deleteNote workflow and any deleteNote error
  const { workflowState: deleteNoteStatus, errorState: deleteNoteFailure } = useReduxWorkflowState((state: IStoreState) => state.deleteNoteStatus, (state: IStoreState) => state.deleteNoteFailure);

  useEffect(() => {
    if (noteViewModels !== notesToDisplay) {
      setNoteViewModels(notesToDisplay);
    }
  }, [notesToDisplay, noteViewModels]);

  useEffect(() => {
    if (dataLoading !== notesDataLoading) {
      setDataLoading(notesDataLoading);
    }
  }, [dataLoading, notesDataLoading]);

  /**
   * @method handleNoteDelete Handles requests to delete a Channel
   * @param note The Note to be deleted
   */
  async function handleNoteDelete(note: INote): Promise<void> {
    // set the note to be deleted into local state
    setNoteToDelete(note);

    // set flag to show the delete confirmation dialog
    setShowDeleteConfirmationDlg(true);
  }

  function handleNoteDeleteConfirmed(): void {
    // using the note object to be deleted from the local state, dispatch an action to delete the note object
    if (noteToDelete !== undefined) {
      dispatch(noteDeleteRequest(noteToDelete));
    }

    // call method to reset the note delete local state details
    noteDeleteReset();
  }

  function handleNoteDeleteCanceled(): void {
    // call method to reset the note delete local state details
    noteDeleteReset();
  }

  function noteDeleteReset() {
    // reset the note to be deleted to 'undefined' in local state, since we're done with the object
    setNoteToDelete(undefined);

    // set flag to hide the delete confirmation dialog
    setShowDeleteConfirmationDlg(false);
  }


  // data to indicate whether an activity indicator should be displayed and, if so, the message
  let activityIndicatorData: IActivityIndicatorData = {
    showActivityIndicator: false,
    activityIndicatorMessage: ''
  }

  // data to indicate whether an alert should be displayed
  let alertInfo: IAlertInfo | undefined = undefined;

  // indicates whether the object has been successfully deleted
  const [successfullyDeletedObject, setSuccessfullyDeletedObject] = useState<boolean>(false);

  // define an effect that will process whenever the successfullyCompletedPage state changes
  useEffect(() => {
    if (successfullyDeletedObject) {
      // dispatch an action to reset the deleteTopicStatus
      resetDeleteNoteStatus();

      // notify user via a Beacon notification that the audioLink has been deleted
      dispatch(beaconChange(new Beacon(undefined, enumBeaconType.Success, MessagesStringAssets.note_DeleteConfirmationHeader, MessagesStringAssets.note_DeleteSuccess)));

      // reset the successfullyDeletedObject flag
      setSuccessfullyDeletedObject(false);
    }
  }, [successfullyDeletedObject, dispatch, resetDeleteNoteStatus]);

  if (dataLoading) {
    // display an activity indicator
    activityIndicatorData.showActivityIndicator = true;
    activityIndicatorData.activityIndicatorMessage = MessagesStringAssets.notes_FetchingRequested;
  } else {
    // if the workflow is in the midst of performing deletion of a note, prepare an alert message fragment to display progress message(s)
    if (deleteNoteStatus !== undefined && deleteNoteStatus !== null) {
      if (deleteNoteStatus === enumWorkflowState.Requested || deleteNoteStatus === enumWorkflowState.InProgress) {
        // display an activity indicator
        activityIndicatorData.showActivityIndicator = true;
        activityIndicatorData.activityIndicatorMessage = MessagesStringAssets.note_DeleteRequested;
      } else if (deleteNoteStatus === enumWorkflowState.Success) {
        // if the successfullyDeletedObject is curently in a 'false' state, we'll want the flag to indicate that the deletion was a success
        if (!successfullyDeletedObject) {
          // set flag to indicate that the object has been successfully deleted, which will force a re-render to allow for cleanup and user notification
          setSuccessfullyDeletedObject(true);
        }
      } else if (deleteNoteStatus === enumWorkflowState.Failure) {
        // display an alert message
        // alertFragment = generateJsxAlertMessageFragment(MessagesStringAssets.note_DeleteFailure, enumJsxAlertMessageDisplayVariants.Failure, false, deleteNoteFailureMessage, MessagesStringAssets.substitutionKeyword);
        let failureMessage = 'Unknown error';
        if (deleteNoteFailure && deleteNoteFailure instanceof Error) {
          failureMessage = deleteNoteFailure.message;
        }
        alertInfo = new AlertInfo(true, enumAlertType.Error, failureMessage);
        dispatch(alertInfoChange(alertInfo));
      }
    }
  }


  // Establish the text to be displayed on the '+ New' button
  let addNewButtonText: string = '';
  if (allowAddingNewItems) {
    addNewButtonText = ControlsStringAssets.noteNew;
  }


  return (
    <>
      <StyledBoxForPaddingAtopView />
      <GenericToggleViewContainer
        viewTitle={PageAndViewTitleStringAssets.viewTitle_Notes}
        addNewButtonText={addNewButtonText}
        urlForAddNewButtonNavigation={`/${parentId}/note`}
      >

        {/* Container for embedded data view */}
        <StyledContainerForViewContainer>

          {/* If there are Notes to display and the current view selection is CardsGridView, display the NotesCardGridView */}
          {noteViewModels &&
            (collectionViewType === enumCollectionViewType.CardsGridView) &&
            <NotesCardGridView noteViewModels={noteViewModels} onDelete={handleNoteDelete} />
          }

          {/* If there are Notes to display and the current view selection is ListView, display the NotesListView */}
          {noteViewModels &&
            (collectionViewType === enumCollectionViewType.ListView) &&
            <NotesListView noteViewModels={noteViewModels} onDelete={handleNoteDelete} />
          }
        </StyledContainerForViewContainer>

        {/* Delete Note Confirmation Dialog */}
        <TwoButtonAcceptanceDialog
          showDialog={showDeleteConfirmationDlg}
          headerText={MessagesStringAssets.note_DeleteConfirmationHeader}
          bodyText={MessagesStringAssets.note_DeleteConfirmation}
          acceptanceButtonText={ControlsStringAssets.confirmButtonText}
          nonAcceptanceButtonText={ControlsStringAssets.cancelButtonText}
          onAcceptance={handleNoteDeleteConfirmed}
          onNonAcceptance={handleNoteDeleteCanceled}
        />

        {activityIndicatorData.showActivityIndicator &&
          <ActivityIndicatorDialog activityLabel={activityIndicatorData.activityIndicatorMessage} />
        }

      </GenericToggleViewContainer>
    </>
  );
}

export default NotesView;
