import React, { PropsWithChildren, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  Drawer as MuiDrawer,
  Divider,
  List,
  ListItemIcon,
  ListItemText,
  ListItemButton,
  ListItemButtonProps
} from '@mui/material';
import {
  Backup as BackupDataFilesIcon,
  Close as CloseIcon,
  // ArrowForwardIos as CloseDrawerIcon,
  ChevronRight as CloseDrawerIcon,
  ColorLens as ThemesIcon,
  ExitToApp as LogoutIcon,
  Home as HomeIcon,
  Info as AboutIcon,
  LocalActivity as UserRedemptionCodeRequestsIcon,
  LockOpen as LoginIcon,
  ManageSearch as PopulateSearchDataIcon,
  PersonRemove as ExpungeUserIcon,
  Search as SearchIcon,
  Settings as SettingsIcon,
  Storage as DatabaseIcon
} from '@mui/icons-material';
import { useTheme, responsiveFontSizes } from '@mui/material/styles';
import { Theme } from '@mui/material/styles';
import { styled } from '@mui/material/styles';
import { IFirebaseAuthAndUserAuthenticationStatus } from '../../customHooks/hookReturnObjects';
import { useFirebaseAuth } from '../../customHooks';
import { MenuItemStringAssets } from '../../../assets/stringAssets';
import { ICurrentUserContextData, useCurrentUserContext } from '../../providersAndContexts/currentUser';
import { pingSearchMetadataDbViaHttp } from '../../../firebaseServices/dataServices/dataServiceActions/cloudSqlActions';
import { Beacon } from '../../../dataObjects/models/alerts/Beacon';
import { enumBeaconType } from '../../../dataObjects/enums';
import { useAppDispatch } from '../../../uiMiddleware-redux/store/configureStore';
import { beaconChange } from '../../../uiMiddleware-redux/slices/beacon/beaconSlice';


/*** Using the Material UI Emotion Styling library, declare 'styled' instances for each area/object. 
 *** NOTE: These must be declared outside of the React Functional Component to ensure that the styled 
 *** objects will be properly rendered within the DOM. 
 ***/

// a styled ListItemButton
const StyledListItemButton = styled((props: ListItemButtonProps) => (
  <ListItemButton
    {...props}
  />
))(({ theme }) => ({
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.primary.light,
    // apply styling to the ListItemIcon and ListItemText items...
    '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
      color: theme.palette.primary.light,
    },
  },
}));


/**
 * @interface ICollapsibleMenuDrawerProps represents a Material-UI Drawer that is collapsible (actually, slides in and out of view).
 *   RouteComponentProps is the core properties value injected by 'withRouter' from react-router-dom
 */
export interface ICollapsibleMenuDrawerProps extends PropsWithChildren<unknown> {
  onCloseMenuDrawer: () => void,
  onLogout: () => void,
  open: boolean
}

// const CollapsibleMenuDrawer = (props: any) => {
const CollapsibleMenuDrawer: React.FC<ICollapsibleMenuDrawerProps> = (props: ICollapsibleMenuDrawerProps) => {

  // whether to display console logs (displayConsoleLogs && console.log statements)
  const displayConsoleLogs: boolean = false;

  const { onCloseMenuDrawer, onLogout, open } = props;

  // console.log(`CollapsibleMenuDrawer: 'open'=${open}`);

  let theme: Theme = useTheme();
  theme = responsiveFontSizes(theme);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  // whether to open (display) the drawer
  // const [openDrawer, setOpenDrawer] = useState<boolean>(open !== undefined ? open : false);
  const [openDrawer, setOpenDrawer] = useState<boolean>(open);
  // console.log(`CollapsibleMenuDrawer: 'openDrawer'=${openDrawer}`);

  // whether user is authenticated
  const [userAuthenticated, setUserAuthenticated] = useState(false);

  // get the Firebase auth state using a custom hook
  const { auth, userAuthenticated: userIsAuthenticated }: IFirebaseAuthAndUserAuthenticationStatus = useFirebaseAuth();

  // use a custom hook to get the Current User information from the CurrentUserProvider
  const { currentUser }: ICurrentUserContextData = useCurrentUserContext();
  displayConsoleLogs && console.log(`%c AppHeader. currentUser: \n${JSON.stringify(currentUser)}`, 'background: #600; color: #fff');

  const currentUserIsSuperAdmin: boolean | undefined = currentUser && currentUser.sa;
  displayConsoleLogs && console.log(`%c AppHeader. currentUserIsSuperAdmin: \n${currentUserIsSuperAdmin}`, 'background: #600; color: #fff');

  // anytime the auth object changes in state, we'll set whether the user is authenticated
  useEffect(() => {
    // set whether user is authenticated
    // setUserAuthenticated(auth.uid !== undefined);
    setUserAuthenticated(userIsAuthenticated);
    // }, [auth]);
  }, [userIsAuthenticated]);

  // anytime the 'open' parameter changes, set the status of the drawer to open or closed
  useEffect(() => {
    setOpenDrawer(open);
    // console.info(`CollapsibleMenuDrawer: 'openDrawer being set to' ${open}`);
  }, [open])


  const handlePingSearchMetadataDb = async () => {
    const success: boolean = await pingSearchMetadataDbViaHttp();

    if (success) {
      // notify user via a Beacon notification that the operation has been completed
      dispatch(beaconChange(new Beacon(undefined, enumBeaconType.Success, 'Ping Operation', 'Ping of SearchMetadata DB was successful')));
    }

    // call method to close this Menu Drawer
    onCloseMenuDrawer();
  }

  function handleMenuItemSelection(path: string) {
    // call the Router 'navigate' object's push() method with the path provided
    navigate(path);
    // call method to close this Menu Drawer
    onCloseMenuDrawer();
  }

  return (
    <>
      <MuiDrawer anchor='right' open={openDrawer}>
        {/* List for authenticated user */}
        {userAuthenticated &&
          <List>
            <StyledListItemButton key={'Close'} onClick={() => onCloseMenuDrawer()}>
              <ListItemIcon><CloseDrawerIcon /></ListItemIcon>
            </StyledListItemButton>
            <Divider />
            <StyledListItemButton key={'Home'} onClick={() => handleMenuItemSelection('/')}>
              <ListItemIcon><HomeIcon /></ListItemIcon>
              <ListItemText primary={MenuItemStringAssets.menuItem_Home} />
            </StyledListItemButton>
            <StyledListItemButton key={'Search'} onClick={() => handleMenuItemSelection('/digitalMediaSearchCriteria')}>
              <ListItemIcon><SearchIcon /></ListItemIcon>
              <ListItemText primary={MenuItemStringAssets.menuItem_Search} />
            </StyledListItemButton>
            <StyledListItemButton key={'About'} onClick={() => handleMenuItemSelection('/about')}>
              <ListItemIcon><AboutIcon /></ListItemIcon>
              <ListItemText primary={MenuItemStringAssets.menuItem_About} />
            </StyledListItemButton>
            <Divider />
            <StyledListItemButton key={'Settings'} onClick={() => handleMenuItemSelection('/settings')}>
              <ListItemIcon><SettingsIcon /></ListItemIcon>
              <ListItemText primary={MenuItemStringAssets.menuItem_Settings} />
            </StyledListItemButton>
            <StyledListItemButton key={'Set Theme'} onClick={() => handleMenuItemSelection('/setTheme')}>
              <ListItemIcon><ThemesIcon /></ListItemIcon>
              <ListItemText primary={MenuItemStringAssets.menuItem_SetTheme} />
            </StyledListItemButton>
            {currentUserIsSuperAdmin && // if user has SuperAdmin privilege, present a Divider to separate Admin menu items
              <Divider />
            }
            {currentUserIsSuperAdmin && // if user has SuperAdmin privilege, present the "User Redemption Code Requests" menu item
              <StyledListItemButton key={'userRedemptionCodeRequests'} onClick={() => handleMenuItemSelection('/userRedemptionCodeRequests')}>
                <ListItemIcon><UserRedemptionCodeRequestsIcon /></ListItemIcon>
                <ListItemText primary={MenuItemStringAssets.menuItem_UserRedemptionCodeRequests} />
              </StyledListItemButton>
            }
            {currentUserIsSuperAdmin && // if user has SuperAdmin privilege, present the "Ping Search Metadata Db" menu item
              <StyledListItemButton key={'pingSearchMetadataDb'} onClick={() => handlePingSearchMetadataDb()}>
                <ListItemIcon><DatabaseIcon /></ListItemIcon>
                <ListItemText primary={MenuItemStringAssets.menuItem_PingSearchMetadataDb} />
              </StyledListItemButton>
            }
            {currentUserIsSuperAdmin && // if user has SuperAdmin privilege, present the "Populate Search Data" menu item
              <StyledListItemButton key={'populateSearchData'} onClick={() => handleMenuItemSelection('/populateSearchData')}>
                <ListItemIcon><PopulateSearchDataIcon /></ListItemIcon>
                <ListItemText primary={MenuItemStringAssets.menuItem_PopulateSearchMetadataDb} />
              </StyledListItemButton>
            }
            {currentUserIsSuperAdmin && // if user has SuperAdmin privilege, present the "Backup Data Files" menu item
              <StyledListItemButton key={'backupDataFiles'} onClick={() => handleMenuItemSelection('/backupDataFiles')}>
                <ListItemIcon><BackupDataFilesIcon /></ListItemIcon>
                <ListItemText primary={MenuItemStringAssets.menuItem_BackupDataFiles} />
              </StyledListItemButton>
            }
            {currentUserIsSuperAdmin && // if user has SuperAdmin privilege, present the "Expunge User Account" menu item
              <StyledListItemButton key={'ExpungeUser'} onClick={() => handleMenuItemSelection('/expungeUser')}>
                <ListItemIcon><ExpungeUserIcon /></ListItemIcon>
                <ListItemText primary={MenuItemStringAssets.menuItem_ExpungeUser} />
              </StyledListItemButton>
            }
            <Divider />
            <StyledListItemButton key={'Logout'} onClick={() => onLogout()}>
              <ListItemIcon><LogoutIcon /></ListItemIcon>
              <ListItemText primary={MenuItemStringAssets.menuItem_Logout} />
            </StyledListItemButton>
          </List>
        }
        {/* List for unauthenticated user */}
        {!userAuthenticated &&
          <List>
            <StyledListItemButton key={'Close'} onClick={() => onCloseMenuDrawer()}>
              <ListItemIcon><CloseDrawerIcon /></ListItemIcon>
            </StyledListItemButton>
            <Divider />
            <StyledListItemButton key={'Login'} onClick={() => handleMenuItemSelection('/login')}>
              <ListItemIcon><LoginIcon /></ListItemIcon>
              <ListItemText primary={MenuItemStringAssets.menuItem_Login} />
            </StyledListItemButton>
            <StyledListItemButton key={'About'} onClick={() => handleMenuItemSelection('/about')}>
              <ListItemIcon><AboutIcon /></ListItemIcon>
              <ListItemText primary={MenuItemStringAssets.menuItem_About} />
            </StyledListItemButton>

          </List>
        }
      </MuiDrawer>
    </>
  );

}

export default CollapsibleMenuDrawer;