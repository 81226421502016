import React, { PropsWithChildren } from 'react';
import { useNavigate } from 'react-router-dom';
import { IThemeSpecsViewModel } from '../../../../dataObjects/viewModels/themeSpecsViewModel';
import { cardGridViewItemStyles } from '../../../styles/views/cardGridViewItemStyles';
import {
  Box,
  BoxProps,
  Card,
  CardActions,
  CardContent,
  IconButton,
  Tooltip,
  Typography
} from '@mui/material';
import {
  Delete as DeleteIcon,
  Edit as EditIcon,
} from '@mui/icons-material';
import { TooltipStringAssets } from '../../../../assets/stringAssets';
import { styled } from '@mui/styles';


/*** Using the Material UI Emotion Styling library, declare 'styled' instances for each area/object. 
 *** NOTE: These must be declared outside of the React Functional Component to ensure that the styled 
 *** objects will be properly rendered within the DOM. 
 ***/

// a styled Box (equivalent to a <div>), providing an area for displaying the collection of ThemeSpecs colors
const StyledBoxForThemeSpecsColorsArea = styled((props: BoxProps) => (
  <Box
    {...props}
  />
))(({ theme }) => ({
  display: "flex",
  flexWrap: 'wrap',
  justifyContent: 'start',
  margin: '4px 0',
  padding: '2px'
}));

// a styled Box (equivalent to a <div>), providing an area for each ThemeSpecs color
const StyledBoxForThemeSpecsColor = styled((props: BoxProps) => (
  <Box
    {...props}
  />
))(({ theme }) => ({
  margin: '0 4px 4px 0', 
  flex: '1 1 0px', 
  minWidth: '2rem', 
  maxWidth: '2rem', 
  height: '2rem', 
  border: theme.colorBarInSwatch.border,
}));


export interface IThemeSpecsCardGridViewItemProps extends PropsWithChildren<unknown> {
  themeSpecs: IThemeSpecsViewModel;
  onDelete: (themeSpecs: IThemeSpecsViewModel) => void;
}


export const ThemeSpecsCardGridViewItem: React.FC<IThemeSpecsCardGridViewItemProps> = (props: IThemeSpecsCardGridViewItemProps) => {

  const { onDelete, themeSpecs } = props;

  const classes = cardGridViewItemStyles();

  // the 'useNavigate' hook will be used to navigate the browser
  const navigate = useNavigate();

  return (
    <>
      {/* <Card classes={{ root: classes.card }} elevation={6}> */}
      <Card elevation={6} className={classes.card}>
        {/* <div style={{ margin: '5px', paddingLeft: '5px', paddingRight: '5px', backgroundColor: 'yellow', border: '1px solid black', color: 'black' }}>
          <Typography variant='caption' align='left'>
            {'Uncategorized'}
          </Typography>
        </div> */}

        {/* Note: Couldn't get proper behavior from <CardHeader>, so will just display a title via <Typography> */}
        <Typography className={classes.cardTitle} variant='h6' align='center'>
          {themeSpecs.name}
        </Typography>

        <CardContent>
          {/* <Typography variant="body1" color="textPrimary" component="p"> */}
          <Typography className={classes.cardDescription} variant="body1">
            {themeSpecs.description}
          </Typography>

          <StyledBoxForThemeSpecsColorsArea>
            <StyledBoxForThemeSpecsColor sx={{ background: themeSpecs.themeBaseColors.baseColorForPrimary }}/>
            <StyledBoxForThemeSpecsColor sx={{ background: themeSpecs.themeBaseColors.baseColorForSecondary }}/>
            <StyledBoxForThemeSpecsColor sx={{ background: themeSpecs.themeBaseColors.baseColorForCancel }}/>
            <StyledBoxForThemeSpecsColor sx={{ background: themeSpecs.themeBaseColors.baseColorForError }}/>
            <StyledBoxForThemeSpecsColor sx={{ background: themeSpecs.themeBaseColors.baseColorForHighlight }}/>
            <StyledBoxForThemeSpecsColor sx={{ background: themeSpecs.themeBaseColors.baseColorForInfo }}/>
            <StyledBoxForThemeSpecsColor sx={{ background: themeSpecs.themeBaseColors.baseColorForSuccess }}/>
            <StyledBoxForThemeSpecsColor sx={{ background: themeSpecs.themeBaseColors.baseColorForWarning }}/>
          </StyledBoxForThemeSpecsColorsArea>

        </CardContent>

        <CardActions className={classes.cardActionsContainer}>
          {/* // the tooltip for the Edit button */}
          <Tooltip title={TooltipStringAssets.edit} arrow>
            <span>
              <IconButton
                className={`${classes.actionIconButton} ${classes.editIconButton}`}
                size="small"
                aria-label="edit"
                onClick={() => navigate(`/customTheme/${themeSpecs.id}`)}>
                <EditIcon />
              </IconButton>
            </span>
          </Tooltip>
          {/* // the tooltip for the Delete button */}
          <Tooltip
            title={"Delete"}
            arrow
          >
            <span>
              <IconButton
                className={`${classes.actionIconButton} ${classes.deleteIconButton}`}
                size="small"
                aria-label="delete"
                // this button will be disabled if the ThemeSpecs is the user's Uncategorized themeSpecs
                onClick={() => themeSpecs && onDelete(themeSpecs)} >
                <DeleteIcon />
              </IconButton>
            </span>
          </Tooltip>
        </CardActions>
      </Card>
    </>
  );

}