import React, { PropsWithChildren, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Box, LinearProgress, MenuItem, TextField, TextFieldProps, Typography } from '@mui/material';
import {
  Description as DocumentIcon,
} from '@mui/icons-material';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import FormWithActionBar from '../FormWithActionBar/FormWithActionBar';
import { ControlsStringAssets, KeyValuePairsStringAssets, MessagesStringAssets } from '../../../assets/stringAssets';
import { IDocumentLink } from '../../../dataObjects/models/digitalMedia/DocumentLink';
import { IFileUploadProgress } from '../../../dataObjects/models/fileUpload/FileUploadProgress';
import { typeUniqueId } from '../../../dataObjects/types';
import { enumFileUploadType, enumDocumentLinkType, enumDocumentLinkTypeConvert } from '../../../dataObjects/enums';
import { ISaveDocumentLinkRequest } from '../../../dataObjects/models/serviceRequests/documentLink/SaveDocumentLinkRequest';
import { IFileUploadRequest } from '../../../dataObjects/models/fileUpload/FileUploadRequest';
import FileInput from '../../controls/FileInput/FileInput';
import { Accept, DropEvent, FileRejection } from 'react-dropzone';
import { FileForUpload, IFileForUpload } from '../../../dataObjects/models/fileUpload/FileForUpload';
import MediaFileUploadList from '../../listsAndListItems/mediaFiles/MediaFileUploadList';
import { enumAlertType } from '../../enums';
import { AlertInfo, IAlertInfo } from '../../../dataObjects/models/alerts/AlertInfo';
import { constructDropZoneFileRejectionsErrorMessage } from '../../utilities/constructDropZoneFileRejectionsErrorMessage';
import { styled } from '@mui/styles';
import { convertGoogleDriveFileUrlForDownload, urlIsAGoogleDriveLink } from '../../utilities/digitalMediaUtilities/externalSourceUtilities/googleDriveUtilities';
import { useAppDispatch } from '../../../uiMiddleware-redux/store/configureStore';
import { alertInfoChange } from '../../../uiMiddleware-redux/slices/alertInfo/alertInfoSlice';


/*** Using the Material UI Emotion Styling library, declare 'styled' instances for each area/object. 
 *** NOTE: These must be declared outside of the React Functional Component to ensure that the styled 
 *** objects will be properly rendered within the DOM. 
 ***/

// a styled TextField that is serving as a Select control
const StyledTextFieldForSelectControl = styled((props: TextFieldProps) => (
  <TextField
    select
    fullWidth
    {...props}
  />
))(({ theme }) => ({
  width: '100%',
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(1),
}));

interface IDocumentLinkFormValues {
  name: string;
  documentLinkType: string;
  documentUrl: string;
  description: string;
}

// using 'yup', set up a schema for the form field values
const schema = yup.object().shape({
  name: yup
    .string()
    .required(ControlsStringAssets.documentNameRequired),

  documentLinkType: yup
    .string()
    .required(),

  /**
   * @property {string} documentUrl A URL for the DocumentLink IFF the type is NOT Document Upload
   */
  documentUrl: yup
    .string(), // needs to be required if a Document site is selected from the DocumentLinkType dropdown
    // .required(),

  /**
   * @property {string} description A description for the DocumentLink.
   */
  description: yup
    .string()
    .required(ControlsStringAssets.documentLinkDescriptionRequired),
});

export interface IDocumentLinkFormProps extends PropsWithChildren<unknown> {
  /**
   * @property {typeUniqueId} userId The id of the current user
   */
  userId: typeUniqueId,

  /**
   * @property {IDocumentLink} documentLink The DocumentLink details for the form (will have blank properties values if we're creating a new record)
   */
  documentLink: IDocumentLink,

  /**
   * @property {boolean} saveRequestInProgress (optional) Whether a save request is in progress (default: false).
   */
  saveRequestInProgress?: boolean,

  /**
   * @property {string} progressMessage (optional) If a save is in progress, display this message to provide progress updates to the user.
   */
  progressMessage?: string,

  /**
   * @property {Array<IFileUploadProgress>} fileUploadProgressCollection (optional) If files are in the process of being uploaded, this array
   *                                        provides the details for the upload progress of each file being uploaded.
   */
  fileUploadProgressCollection?: Array<IFileUploadProgress>,

  /**
   * @property {(saveDocumentLinkRequest: ISaveDocumentLinkRequest) => void} onSubmit Method to call for submitting the form for a save operation
   */
  onSubmit: (saveDocumentLinkRequest: ISaveDocumentLinkRequest) => void,
}

const DocumentLinkForm: React.FC<IDocumentLinkFormProps> = (props: IDocumentLinkFormProps) => {
  DocumentLinkForm.displayName = 'DocumentLink Form';

  const dispatch = useAppDispatch();

  // get required arguments from props
  const { userId, documentLink, progressMessage, onSubmit } = props;

  // set up details for ReactHookForm
  const { control, register, formState, formState: { errors }, handleSubmit, setValue, watch } = useForm<IDocumentLinkFormValues>({
    defaultValues: {
      name: documentLink.name,
      documentLinkType: documentLink.documentLinkType,
      documentUrl: documentLink.downloadUrl,
      description: documentLink.description,
    },
    mode: "all",
    // resolver: yupResolver(schema)
  });

  const { ref: nameReg, ...nameProps } = register("name", { required: true });
  const { ref: documentLinkTypeReg } = register("documentLinkType", { required: true });
  const { ref: documentUrlReg, ...documentUrlProps } = register("documentUrl", { required: true });
  const { ref: descriptionReg, ...descriptionProps } = register("description", { required: true });

  // for convenience, use a state variable to indicate whether we are working with an existing DocumentLink
  const [isExistingDocumentLink, setIsExistingDocumentLink] = useState<boolean>(false);

  // files selected by the user
  const [filesSelectedForUpload, setFilesSelectedForUpload] = useState<Array<IFileForUpload> | null>(null);

  const [documentLinkTypeKey, setDocumentLinkTypeKey] = useState<string>("");

  const [documentUrlCurrentValue, setDocumentUrlCurrentValue] = useState<string>(documentLink.downloadUrl);

  // for testing whether the form is in a valid state (cast 'isValid' to 'formIsValid')
  const { isValid } = formState;

  const filesAreSelectedForUpload: boolean = (filesSelectedForUpload !== null) ? filesSelectedForUpload.length > 0 : false;

  // determine whether the form is valid for submission, requiring yup validation -AND- either it's an existing DocumentLink for 
  // GoogleCloudStorage -OR- files have been selected
  // -OR-
  // it's an external source and a Document URL has been provided
  const formIsValid: boolean = isValid && (((documentLinkTypeKey === enumDocumentLinkType.GoogleCloudStorage) && (isExistingDocumentLink || filesAreSelectedForUpload))
    || ((documentLinkTypeKey === enumDocumentLinkType.ExternalSource) && (documentUrlCurrentValue.trim().length > 0)));

  const [documentLinkTypesForDropdown, setDocumentLinkTypesForDropdown] = useState<Array<JSX.Element>>([]);

  console.log(`In DocumentLinkForm. documentLink.documentLinkType ${documentLink.documentLinkType}; documentLinkTypeKey: ${documentLinkTypeKey}`);

  // useEffect to be executed upon mounting of this component
  useEffect(() => {
    // prepare an array of DocumentLinkType values from the enumDocumentLinkType enumerator that can be used to populate MenuItem components for the DocumentLinkType <Select> component
    let documentLinkTypeMenuItems: Array<JSX.Element> = [];
    KeyValuePairsStringAssets.documentLinkTypeValuePairs.forEach((keyValuePair: { key: string, value: string }) => {
      documentLinkTypeMenuItems.push(<MenuItem key={keyValuePair.key} value={keyValuePair.key}>{keyValuePair.value}</MenuItem>);
    });

    setDocumentLinkTypesForDropdown(documentLinkTypeMenuItems);
  }, []);

  // execute whenever documentLink.documentLinkType changes
  useEffect(() => {
    // set the documentLinkTypeKey from the documentLink.documentLinkType value
    const defaultDocumentLinkTypeKey: string = (documentLink.documentLinkType === undefined) ? enumDocumentLinkType.GoogleCloudStorage : documentLink.documentLinkType;
    setDocumentLinkTypeKey(defaultDocumentLinkTypeKey);

  }, [documentLink.documentLinkType]);


  // console.log(`formIsValid: ${formIsValid}; filesSelectedForUpload is ${filesSelectedForUpload === null ? 'null' : 'non-null'}`);
  if (filesSelectedForUpload !== null) {
    // console.log(`filesSelectedForUpload.length: ${filesSelectedForUpload.length}`);
  }

  // if uploading a file, the current progress
  const [fileUploadProgress, setFileUploadProgress] = useState<IFileUploadProgress | undefined>(undefined);

  // capture whether a save is currently being submitted
  const saveRequestInProgress: boolean = props.saveRequestInProgress ?? false;

  // state value indicating whether a save is in progress
  const [saveInProgress, setSaveInProgress] = useState<boolean>(saveRequestInProgress);

  // useEffect hook for setting the 'saveInProgress' local state based on whether a save is currently in progress
  useEffect(() => {
    setSaveInProgress(saveRequestInProgress);
  }, [saveRequestInProgress]);


  // if this is an existing documentLink (documentLink.downloadUrl is non-blank), execute logic based on the documentLink.baseStoragePath
  useEffect(() => {
    if (documentLink.downloadUrl && documentLink.baseStoragePath) {
      // set convenience state variable indicating that we're working with an existing DocumentLink
      setIsExistingDocumentLink(true);
    } else {
      // set convenience state variable indicating that we're NOT working with an existing DocumentLink
      setIsExistingDocumentLink(false);
    }
  }, [documentLink]);


  function handleDocumentUrlCurrentValueChanged(event: React.ChangeEvent<HTMLInputElement>) {
    setDocumentUrlCurrentValue(event.target.value);
  }

  function handleFilesSelectedForUpload<T extends File>(acceptedFiles: T[], fileRejections: FileRejection[], event: DropEvent): void {
    // // console.log(acceptedFiles);

    // // console.log(`documentLinkForm.handleFileDrop. Ready to setFilesSelectedForUpload to ${acceptedFiles}`);

    let filesForUpload: Array<IFileForUpload> = new Array<IFileForUpload>();

    // capture any files accepted by the file input
    acceptedFiles.forEach(file => {
      filesForUpload.push(new FileForUpload(file, enumFileUploadType.DocumentFiles));

      // clear any alerts
      dispatch(alertInfoChange(null));
    });

    // if just one file has been indicated for file upload, set the value of the name field to be the filename
    if (acceptedFiles.length === 1) {
      setValue("name", acceptedFiles[0].name);
    }

    setFilesSelectedForUpload(filesForUpload);

    // if any files were rejected, notify the user with an alert
    if (fileRejections.length > 0) {
      const errorMessage = constructDropZoneFileRejectionsErrorMessage(fileRejections);

      // create an AlertInfo object and dispatch a request to set the AlertInfo into Redux state
      const alertInfo: IAlertInfo = new AlertInfo(true, enumAlertType.Error, errorMessage);
      dispatch(alertInfoChange(alertInfo));
    }
  }

  function onFileUploadProgressUpdate(latestFileUploadProgress: IFileUploadProgress): void {
    // alert('Updating the file upload progress');
    setFileUploadProgress(latestFileUploadProgress);
  }

  // handles a save/submit request from the form
  const handleSaveSubmit = async (data: any) => {

    setSaveInProgress(true);

    // fill in data from the DocumentLink object passed in
    documentLink.name = data.name;
    documentLink.description = data.description;
    documentLink.documentLinkType = enumDocumentLinkTypeConvert.fromString(documentLinkTypeKey);

    // if the document link is for an external source...
    if (documentLinkTypeKey === enumDocumentLinkType.ExternalSource) {
      // set the downloadUrl
      // default to the url value provided in the file input
      let downloadUrl: string = data.documentUrl;

      // if the url is of a type that requires conversion, perform the conversion
      if (urlIsAGoogleDriveLink(downloadUrl)) {
        downloadUrl = convertGoogleDriveFileUrlForDownload(downloadUrl);
      } else {
        // ... any other checks and conversions can take place here
      }

      documentLink.downloadUrl = downloadUrl;
    }

    // prepare a request for saving, starting with just the documentLink
    let saveDocumentLinkRequest: ISaveDocumentLinkRequest = {
      documentLink: documentLink
    };

    // if this is a new object being saved, along with a file upload...
    if (!documentLink.downloadUrl) {

      // if there are files to be uploaded, proceed
      if (filesSelectedForUpload !== null && filesSelectedForUpload.length > 0) {
        // // set flag indicating that a file upload is in progress
        // setFileUploading(true);

        // prepare the details of the FileUploadRequest
        // for now, only upload the first file in the list
        const fileUploadRequest: IFileUploadRequest = {
          userId: userId,
          fileToUpload: filesSelectedForUpload[0].file,
          fileClass: filesSelectedForUpload[0].fileClass,
          fileUniqueId: filesSelectedForUpload[0].id,
          uploadProgressCallback: onFileUploadProgressUpdate
        };

        // add the FileUploadRequest to the save request object
        saveDocumentLinkRequest.fileUploadRequest = fileUploadRequest;

      }
    }

    // call the onSubmit handler passed in, supplying the save request
    await onSubmit(saveDocumentLinkRequest);
  }

  const handleDeleteFileForUpload: (fileForUpload: IFileForUpload) => void = (fileForUpload: IFileForUpload) => {
    if (filesSelectedForUpload && filesSelectedForUpload.length > 0) {
      // ...copy the filesSelectedForUpload array to a local array variable, and then remove the specified item from the new array
      let revisedFilesSelectedForUpload: Array<IFileForUpload> = [...filesSelectedForUpload];
      revisedFilesSelectedForUpload.splice(revisedFilesSelectedForUpload.findIndex(fileForUploadElement => fileForUploadElement.id === fileForUpload.id), 1);

      // now, set the revised array into state
      setFilesSelectedForUpload(revisedFilesSelectedForUpload);
    }
  }

  const fileUploadCompletePercent: string = (fileUploadProgress && fileUploadProgress.percentComplete) ?
    fileUploadProgress.percentComplete.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) :
    '0';


  const [urlForFileSelectedForUpload, setUrlForFileSelectedForUpload] = useState<string | undefined>(undefined);
  // const [urlWithTimeOffsetForFileSelectedForUpload, setUrlWithTimeOffsetForFileSelectedForUpload] = useState<string | undefined>(undefined);

  // Anytime there's a change in values that pertain to whether there's a file selected for upload, establish the URL (or undefined) for a file upload
  useEffect(() => {
    let newUrlForFileSelectedForUpload: string | undefined = undefined;

    // if this is a new DocumentLink (isn't an existing DocumentLink) -AND- there's a file selected for upload, obtain a URL for the selected file to be uploaded
    // (Note: If it's either an existing Document Link or there's no file selected for upload, the Url string will be set as undefined.)
    if (!isExistingDocumentLink && filesSelectedForUpload && filesSelectedForUpload.length > 0) {
      newUrlForFileSelectedForUpload = URL.createObjectURL(filesSelectedForUpload[0].file);

      setUrlForFileSelectedForUpload(newUrlForFileSelectedForUpload);
    } else {
      setUrlForFileSelectedForUpload(undefined);
    }

  }, [isExistingDocumentLink, filesSelectedForUpload, setUrlForFileSelectedForUpload])


  // prepare specs for accepted filetypes that will be passed on to the FileInput component
  // const acceptedFileTypes: Accept = { 'document/*': ['document/pdf', 'document/docx', 'document/xlsx'] };
  const acceptedFileTypes: Accept = { 'document/*': [".doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"] };

  return (
    <>
      <FormWithActionBar
        onSubmit={handleSubmit(handleSaveSubmit)}
        actionInProgress={saveInProgress}
        actionInProgressLabel={progressMessage}
        finiteActionInProgressPercentComplete={fileUploadProgress && fileUploadProgress.percentComplete}
        finiteActionInProgressLabel={fileUploadProgress && MessagesStringAssets.file_Uploading}
        formIsValid={formIsValid}
      >

        {/* We use a TextField with 'select' attribute as a pseudo <Select> (or dropdown) control */}
        {/* Only display the field if the dropdown options have been created */}
        {(documentLinkTypesForDropdown.length > 0) &&
          <StyledTextFieldForSelectControl
            inputRef={documentLinkTypeReg}
            label="Document Type"
            margin='normal'
            value={documentLinkTypeKey}
            onChange={e => setDocumentLinkTypeKey(e.target.value)}
          >
            {documentLinkTypesForDropdown}
          </StyledTextFieldForSelectControl>
        }

        {/* if the selected Document Link Type is "Upload" (GoogleCloudStorage) -AND-
            if we're not editing an existing documentLink and no files have been selected for upload,  */}
        {(documentLinkTypeKey === enumDocumentLinkType.GoogleCloudStorage) && !isExistingDocumentLink && !filesAreSelectedForUpload &&
          <FileInput
            control={control}
            name="fileInput"
            // acceptedFileTypes="document/*"
            // The React Player supports a set of document types, so specify the types that it's believed the player accepts
            // acceptedFileTypes="document/mp4, document/ogg, document/quicktime, document/x-mpegURL, document/MP2T, document/x-msdocument, document/3gpp, document/webm"
            // acceptedFileTypes={acceptedFileTypes}
            allowMultipleFiles={false}
            onFilesSelectedForUpload={handleFilesSelectedForUpload}
            maxFileSize={262144000} // Max document file size is 250MB
          >
          </FileInput>
        }

        {/* if the currently selected Document Type is file upload (GoogleCloudStorage), we'll have one type of rendering for the 
            document display and any potential file upload activity */}
        {
          documentLinkTypeKey === enumDocumentLinkType.GoogleCloudStorage &&

          /* if we're not editing an existing documentLink and files have been selected for upload */
          (!isExistingDocumentLink && filesSelectedForUpload && filesSelectedForUpload.length > 0) &&
          <>

            <MediaFileUploadList
              filesForUpload={filesSelectedForUpload}
              mediaIcon={<DocumentIcon />}
              onDelete={handleDeleteFileForUpload}
            />

            {
              fileUploadProgress &&
              <>
                <Box display='flex' flexDirection='column' >
                  <Box display="flex" alignItems="center">
                    <Box
                      width="100%"
                      mr={1}>
                      <LinearProgress
                        variant="determinate"
                        value={fileUploadProgress.percentComplete}
                      />
                    </Box>
                    <Box minWidth={35}>
                      <Typography
                        variant="body2"
                        color="primary">
                        {/* {`${Math.round(fileUploadProgress.percentComplete)}%`} */}
                        {`${fileUploadCompletePercent}%`}
                      </Typography>
                    </Box>
                  </Box>
                  <Box display='flex' justifyContent='center'>
                    <Typography
                      variant="caption"
                      color="primary">
                      {`Uploading document...`}
                    </Typography>
                  </Box>
                </Box>
              </>
            }

          </>
        }

        {/* if the currently selected Document Type is file upload (GoogleCloudStorage) -AND- we're editing 
            an existing DocumentLink, display the document object for the existing document... */}

        {/* if the selected Document Link Type is NOT "Upload" (GoogleCloudStorage), show the Document URL text box */}
        {(documentLinkTypeKey !== enumDocumentLinkType.GoogleCloudStorage) &&
          <>
            <TextField
              inputRef={documentUrlReg}
              {...documentUrlProps}
              label="Document URL *"
              margin='normal'
              fullWidth
              onChange={handleDocumentUrlCurrentValueChanged}
              error={!!errors.documentUrl}
              helperText={errors?.documentUrl?.message}
            />
          </>
        }

        <TextField
          inputRef={nameReg}
          {...nameProps}
          autoFocus
          type="text"
          label={ControlsStringAssets.documentNameLabel}
          margin='normal'
          fullWidth
          error={!!errors.name}
          helperText={errors?.name?.message}
          InputLabelProps={{
            required: true,  // this will cause an asterisk ('*') to appear at the end of the label text
            shrink: !!watch('name') // this will watch whether the value of 'name' changes and if 'name' has a value, will shrink the label
          }}
        // InputLabelProps={{ shrink: !!watch('name') }}
        />

        <TextField
          inputRef={descriptionReg}
          {...descriptionProps}
          autoFocus
          label={ControlsStringAssets.topicDescriptionLabel}
          margin='normal'
          fullWidth
          multiline={true}
          minRows={3}
          maxRows={5}
          error={!!errors.description}
          helperText={errors?.description?.message}
          InputLabelProps={{
            required: true  // this will cause an asterisk ('*') to appear at the end of the label text
          }}
        />

      </FormWithActionBar>
    </>

  );
}

export default DocumentLinkForm;