import React, { PropsWithChildren } from 'react';
import Grid from '@mui/material/Grid';
import { IUserRedemptionCodeRequest } from '../../../../dataObjects/models/userRedemptionCodeRequest';
import { UserRedemptionCodeRequestsCardGridViewItem } from './UserRedemptionCodeRequestsCardGridViewItem';
import { IUser } from '../../../../dataObjects/models/users/User';
import { IChannel } from '../../../../dataObjects/models/channels/Channel';
import { IUserAccessPermissionsForObject, UserAccessPermissionsForObject } from '../../../../dataObjects/models/collaboration/ObjectUserPermissions';
import { enumSharingPermission } from '../../../../dataObjects/enums';


export interface IUserRedemptionCodeRequestsCardGridViewProps extends PropsWithChildren<unknown> {
  userRedemptionCodeRequests: Array<IUserRedemptionCodeRequest>;
  currentUser: IUser;
  onDelete: (userRedemptionCodeRequest: IUserRedemptionCodeRequest) => void;
}


export const UserRedemptionCodeRequestsCardGridView: React.FC<IUserRedemptionCodeRequestsCardGridViewProps> = (props: IUserRedemptionCodeRequestsCardGridViewProps) => {
  UserRedemptionCodeRequestsCardGridView.displayName = 'UserRedemptionCodeRequests Card Grid View';

  // eslint-disable-next-line no-console
  // console.info(`Render UserRedemptionCodeRequestsList`);

  const { userRedemptionCodeRequests, currentUser, onDelete } = props;

  const userPermission : enumSharingPermission = currentUser.sa ? enumSharingPermission.Admin : enumSharingPermission.None;

  return (
    <>
      <Grid container spacing={2} >
        {
          // only display userRedemptionCodeRequests if there is a currentUser
          currentUser &&  
          userRedemptionCodeRequests.map(userRedemptionCodeRequest =>
            <Grid item key={userRedemptionCodeRequest.id} xs={12} sm={6} md={4} lg={2} xl={2}>
              <UserRedemptionCodeRequestsCardGridViewItem userRedemptionCodeRequest={userRedemptionCodeRequest} userPermission={userPermission} onDelete={onDelete} />
            </Grid>
          )
        }
      </Grid>
    </>
  );

}