import React, { PropsWithChildren } from 'react';
import { ControlsStringAssets } from '../../../assets/stringAssets';
import { Box, BoxProps, Button, ButtonProps } from '@mui/material';
import Form from '../Form/Form';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/styles';

/*** Using the Material UI Emotion Styling library, declare 'styled' instances for each area/object. 
 *** NOTE: These must be declared outside of the React Functional Component to ensure that the styled 
 *** objects will be properly rendered within the DOM. 
 ***/

// a styled Box (equivalent to a <div>), providing an area to display the Action Buttons Area
const StyledBoxForActionButtonsArea = styled((props: BoxProps) => (
  <Box
    {...props}
  />
))(({ theme }) => ({
  marginTop: theme.spacing(2),
  flexDirection: 'column',
}));

// a styled Button for the 'Proceed to Sign-in' Button
const StyledButtonForProceedToSigninButton = styled((props: ButtonProps) => (
  <Button
    fullWidth
    {...props}
  />
))(({ theme }) => ({
  padding: theme.spacing(1, 2),
  color: theme.secondaryButton.textColor,
  border: theme.secondaryButton.border,
  background: theme.secondaryButton.background,
  '&:hover': {
    border: theme.secondaryButton.hoverBorder,
    background: theme.secondaryButton.hoverBackground,
    color: theme.secondaryButton.hoverTextColor,
  },
  '&:disabled': {
    border: theme.secondaryButton.disabledBorder,
    background: theme.secondaryButton.disabledBackground,
    color: theme.secondaryButton.disabledTextColor,
  },
}));

export interface IEmailVerificationResultsFormProps extends PropsWithChildren<unknown> {
  /**
   * @property {boolean} emailVerificationSuccessful Whether the email verification process was successful
   */
  emailVerificationSuccessful: boolean,
}

const EmailVerificationResultsForm: React.FC<IEmailVerificationResultsFormProps> = (props: IEmailVerificationResultsFormProps) => {
  EmailVerificationResultsForm.displayName = 'Email Verification Results Form';

  const { emailVerificationSuccessful } = props;

  const navigate = useNavigate();

  // handles a login (submit) request from the form
  const handleSubmitStub = () => {

    // do nothing in this method
  }

  return (
    <>
      <Form
        onSubmit={handleSubmitStub}
      >
        {emailVerificationSuccessful &&
          <p>MyDigiBrain has successfully verified your email address!
            You may now proceed to the sign-in page by pressing the button below.
          </p>
        }

        {!emailVerificationSuccessful &&
          <>
            <p>The attempt to verify your email address was unsuccessful. This is likely due to an invalid or expired verification code.
              To submit another request to receive an email with a verification code, press the button below to
              proceed to the sign-in page where you can enter your email address and request another verification email.
            </p>
            <p>
              It is also possible that your email address has already been verified. You can test this by attempting to sign in.
              If you are able to sign in, that indicates that your email address has been verified and no further verification steps are required.
            </p>
          </>
        }

        <StyledBoxForActionButtonsArea>
          <StyledButtonForProceedToSigninButton
            onClick={() => navigate('/login')}
          >
            {ControlsStringAssets.proceedToSigninButtonText}
          </StyledButtonForProceedToSigninButton>
        </StyledBoxForActionButtonsArea>
      </Form>
    </>

  );
}

export default EmailVerificationResultsForm;