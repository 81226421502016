import React from 'react';
import { Button, ButtonProps } from '@mui/material';
import {
  CheckCircle as AcceptanceIcon,
} from '@mui/icons-material';
import { styled } from '@mui/styles';


/*** Using the Material UI Emotion Styling library, declare 'styled' instances for each area/object. 
 *** NOTE: These must be declared outside of the React Functional Component to ensure that the styled 
 *** objects will be properly rendered within the DOM. 
 ***/

// a styled Button
const StyledButton = styled((props: ButtonProps) => (
  <Button
    variant="outlined"
    {...props}
  />
))(({ theme }) => ({
  padding: theme.spacing(1, 2),
  color: theme.dialog.acceptanceButton.color,
  border: theme.dialog.acceptanceButton.border,
  background: theme.dialog.acceptanceButton.background,
  '&:hover': {
    border: theme.dialog.acceptanceButton.hoverBorder,
    background: theme.dialog.acceptanceButton.hoverBackground,
    color: theme.dialog.acceptanceButton.hoverColor,
  },
  '&:disabled': {
    border: theme.dialog.acceptanceButton.disabledBorder,
    background: theme.dialog.acceptanceButton.disabledBackground,
    color: theme.dialog.acceptanceButton.disabledColor,
  },
}));

export interface IAcceptanceButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  onAcceptance: () => void;
}

const AcceptanceButton: React.FC<IAcceptanceButtonProps> = (props) => {

  // const { children, onAcceptance, ...otherProps } = props;
  const { children, onAcceptance } = props;

  return (
    <StyledButton
      startIcon={<AcceptanceIcon />}
      // {...otherProps}
      // if an onAcceptance handler was provided, call it; otherwise, call the default method for acceptance
      onClick={() => onAcceptance()}
    >
      {/* if there are any children to pass on (ie, children !== undefined), pass them on; otherwise, pass an empty React fragment */}
      <>
        {children && children}
      </>
    </StyledButton>
  );
}

export default AcceptanceButton;