import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { enumWorkflowState } from '../../../dataObjects/enums';

// export interface IFetchUserRedemptionCodeRequestsStatusState {
//   value: enumWorkflowState | null
// }

const initialState: enumWorkflowState | null = null;

export const fetchUserRedemptionCodeRequestsStatusSlice = createSlice({
  name: 'fetchUserRedemptionCodeRequestsStatus',
  initialState,
  reducers: {
    setFetchUserRedemptionCodeRequestsStatus: (state: enumWorkflowState | null, action: PayloadAction<enumWorkflowState>) => {
      state = action.payload;
    },
    clearFetchUserRedemptionCodeRequestsStatus: (state: enumWorkflowState | null) => {
      state = null;
    },
  },
})

// Action creators are generated for each function in reducer)
export const { setFetchUserRedemptionCodeRequestsStatus, clearFetchUserRedemptionCodeRequestsStatus } = fetchUserRedemptionCodeRequestsStatusSlice.actions;

// export the core reducer for the slice
export const fetchUserRedemptionCodeRequestsStatusReducer = fetchUserRedemptionCodeRequestsStatusSlice.reducer;

export default fetchUserRedemptionCodeRequestsStatusSlice.reducer;