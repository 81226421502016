import React, { PropsWithChildren } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, BoxProps, Divider, Grid, IconButton, Tooltip, Typography } from '@mui/material';
import {
  Delete as DeleteIcon,
  Edit as EditIcon,
} from '@mui/icons-material';
import { listViewItemStyles } from '../../../styles';
import { IThemeSpecsViewModel } from '../../../../dataObjects/viewModels/themeSpecsViewModel';
import { TooltipStringAssets } from '../../../../assets/stringAssets';
import { styled } from '@mui/styles';


/*** Using the Material UI Emotion Styling library, declare 'styled' instances for each area/object. 
 *** NOTE: These must be declared outside of the React Functional Component to ensure that the styled 
 *** objects will be properly rendered within the DOM. 
 ***/

// a styled Box (equivalent to a <div>), providing an area for displaying the collection of ThemeSpecs colors
const StyledBoxForThemeSpecsColorsArea = styled((props: BoxProps) => (
  <Box
    {...props}
  />
))(({ theme }) => ({
  display: "flex",
  flexWrap: 'wrap',
  justifyContent: 'start',
  margin: '4px 0',
  padding: '2px'
}));

// a styled Box (equivalent to a <div>), providing an area for each ThemeSpecs color
const StyledBoxForThemeSpecsColor = styled((props: BoxProps) => (
  <Box
    {...props}
  />
))(({ theme }) => ({
  margin: '0 4px 4px 0',
  flex: '1 1 0px',
  minWidth: '2rem',
  maxWidth: '2rem',
  height: '2rem',
  border: theme.colorBarInSwatch.border,
}));


export interface ThemeSpecsListViewItemProps extends PropsWithChildren<unknown> {
  themeSpecs: IThemeSpecsViewModel;
  onDelete: (themeSpecs: IThemeSpecsViewModel) => void;
}

export const ThemeSpecsListViewItem: React.FC<ThemeSpecsListViewItemProps> = (props: ThemeSpecsListViewItemProps) => {
  ThemeSpecsListViewItem.displayName = 'ThemeSpecs List View Item';

  // eslint-disable-next-line no-console
  // console.info(`Render ThemeSpecsListViewItem`);

  const classes: Record<string, string> = listViewItemStyles();

  const { themeSpecs, onDelete } = props;
  const { id, name, description } = themeSpecs;

  // the 'useNavigate' hook will be used to navigate the browser
  const navigate = useNavigate();

  return (
    <>
      {/* <span className={classes.tableRow} key={id}> */}
      <div className={classes.tableRow} key={id}>
        {/* Data Row 1: Name & Action Buttons  */}
        <Grid item container xs={12} direction="row" >
          {/* <Grid className={classes.tableRow} item container xs={12} direction="row" > */}
          {/* Name */}
          <Grid item container xs={8} sm={10} >
            <Typography className={classes.title} variant="h6">
              {/* Display the name with a bounding box that reflect the colors (background, border, font) */}
              {name}
            </Typography>

          </Grid>
          {/* Action Buttons */}
          <Grid item container xs={4} sm={2} direction="row" justifyContent="center" alignContent="flex-start">
            {/* Edit button */}
            <Grid item container className={classes.actionButtonContainer} xs={6} >
              {/* // the tooltip for the Edit button */}
              <Tooltip title={TooltipStringAssets.edit} arrow>
                <span>
                  <IconButton
                    size="small"
                    className={`${classes.actionButton} ${classes.editIconButton}`}
                    onClick={() => id && navigate(`/customTheme/${id}`)}
                  >
                    <EditIcon />
                  </IconButton>
                </span>
              </Tooltip>
            </Grid>
            {/* Delete button */}
            <Grid item container className={classes.actionButtonContainer} xs={6} >
              {/* // the tooltip for the Delete button */}
              <Tooltip
                title={"Delete"}
                arrow
              >
                <span>
                  <IconButton
                    size="small"
                    className={`${classes.actionButton} ${classes.deleteIconButton}`}
                    onClick={() => themeSpecs && onDelete(themeSpecs)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </span>
              </Tooltip>
            </Grid>
          </Grid>
        </Grid>
        {/* Data Row 2: Theme Colors  */}
        <Grid item container xs={12} >
          <StyledBoxForThemeSpecsColorsArea>
            <StyledBoxForThemeSpecsColor sx={{ background: themeSpecs.themeBaseColors.baseColorForPrimary }} />
            <StyledBoxForThemeSpecsColor sx={{ background: themeSpecs.themeBaseColors.baseColorForSecondary }} />
            <StyledBoxForThemeSpecsColor sx={{ background: themeSpecs.themeBaseColors.baseColorForCancel }} />
            <StyledBoxForThemeSpecsColor sx={{ background: themeSpecs.themeBaseColors.baseColorForError }} />
            <StyledBoxForThemeSpecsColor sx={{ background: themeSpecs.themeBaseColors.baseColorForHighlight }} />
            <StyledBoxForThemeSpecsColor sx={{ background: themeSpecs.themeBaseColors.baseColorForInfo }} />
            <StyledBoxForThemeSpecsColor sx={{ background: themeSpecs.themeBaseColors.baseColorForSuccess }} />
            <StyledBoxForThemeSpecsColor sx={{ background: themeSpecs.themeBaseColors.baseColorForWarning }} />
          </StyledBoxForThemeSpecsColorsArea>
        </Grid>
        {/* Data Row 3: Description  */}
        <Grid item container xs={12} >
          <Typography className={classes.description} variant="body1">
            {description}
          </Typography>
        </Grid>

        {/* Add a dividing line after each item */}
        <Divider />
      </div>
      {/* </span> */}
    </>
  );

}

export default ThemeSpecsListViewItem;