import React, { PropsWithChildren, useCallback, useEffect } from 'react';
import { Box, BoxProps, Grid, IconButton, SxProps, Typography, TypographyProps } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { IAlertInfo } from '../../../dataObjects/models/alerts/AlertInfo';
import { typeAlertType } from '../../types/typeAlertType';
import { enumAlertType } from '../../enums';
import { styled } from '@mui/styles';
import { Theme } from 'styled-components';
import { useTheme } from '@mui/material/styles';

interface IAlertBarSxProperty {
  visibility: string;
  border: string;
  backgroundColor: string;
  color: string;
  fontSize: string;
}

interface ICloseButtonSxProperty {
  visibility: string;
  color: string;
}


/*** Using the Material UI Emotion Styling library, declare 'styled' instances for each area/object. 
 *** NOTE: These must be declared outside of the React Functional Component to ensure that the styled 
 *** objects will be properly rendered within the DOM. 
 ***/

// a styled Box (equivalent to a <div>), providing an area to display the overall Progress Bar Area
const StyledBoxForAlertBarContainer = styled((props: BoxProps) => (
  <Box
    {...props}
  />
))(({ theme }) => ({
  padding: theme.spacing(1),
  width: "100%",
}));

// a styled Typography for the Message to be displayed
const StyledTypographyForMessage = styled((props: TypographyProps) => (
  <Typography
    {...props}
  />
))(({ theme }) => ({
  marginLeft: theme.spacing(1),
}));

// a styled CloseIcon to enable use of sx property
const StyledCloseIcon = styled((props) => (
  <CloseIcon
    {...props}
  />
))(({ theme }) => ({
}));

export interface IAlertBarProps extends PropsWithChildren<unknown> {
  // alertType: string | null;
  // alertMessage: string;
  // onClose: () => void;
  /**
   * @property {IAlertInfo} alertInfo (optional) Alert information to be displayed in the AlertBar
   */
  alertInfo?: IAlertInfo;
  /**
   * @property {() => void} onCloseAlert (optional) Callback function to call when an Alert gets closed
   */
  onCloseAlert?: () => void;
}

const AlertBar: React.FC<IAlertBarProps> = (props: IAlertBarProps) => {

  // const { alertType, alertMessage, onClose } = props;
  const { alertInfo, onCloseAlert } = props;

  const theme: Theme = useTheme();


  // function createAlertBarSxPropertyPerAlertType(alertType: typeAlertType): IAlertBarSxProperty {
  //   // default to 'info'
  //   let alertBarSxProperty: IAlertBarSxProperty = {
  //     visibility: 'visible',
  //     border: theme.alertBar.border,
  //     backgroundColor: "white",
  //     color: "black",
  //     fontSize: "1.0rem",
  //   };

  //   switch (alertType) {
  //     case enumAlertType.Error:
  //       alertBarSxProperty = {
  //         visibility: 'visible',
  //         border: theme.alertBar.border,
  //         backgroundColor: theme.palette.error.main,
  //         color: theme.palette.error.contrastText,
  //         fontSize: theme.alertBar.fontSize,
  //       };
  //       break;

  //     case enumAlertType.Info:
  //       alertBarSxProperty = {
  //         visibility: 'visible',
  //         border: theme.alertBar.border,
  //         backgroundColor: theme.palette.info.main,
  //         color: theme.palette.info.contrastText,
  //         fontSize: theme.alertBar.fontSize,
  //       };
  //       break;

  //     case enumAlertType.Success:
  //       alertBarSxProperty = {
  //         visibility: 'visible',
  //         border: theme.alertBar.border,
  //         backgroundColor: theme.palette.success.main,
  //         color: theme.palette.success.contrastText,
  //         fontSize: theme.alertBar.fontSize,
  //       };
  //       break;

  //     case enumAlertType.Warning:
  //       alertBarSxProperty = {
  //         visibility: 'visible',
  //         border: theme.alertBar.border,
  //         backgroundColor: theme.palette.warning.main,
  //         color: theme.palette.warning.contrastText,
  //         fontSize: theme.alertBar.fontSize,
  //       };
  //       break;

  //     default:
  //       alertBarSxProperty = {
  //         visibility: 'visible',
  //         border: theme.alertBar.border,
  //         backgroundColor: theme.palette.success.main,
  //         color: theme.palette.success.contrastText,
  //         fontSize: theme.alertBar.fontSize,
  //       };
  //   }

  //   return alertBarSxProperty;
  // }

  function createAlertBarSxPropertyPerAlertType(alertType: typeAlertType): SxProps<Theme> | undefined {

    // default to 'info'
    let alertBarSxProperty: SxProps<Theme> | undefined = {
      visibility: 'visible',
      border: theme.alertBar.border,
      backgroundColor: "white",
      color: "black",
      fontSize: "1.0rem",
    };

    switch (alertType) {
      case enumAlertType.Error:
        alertBarSxProperty = {
          visibility: 'visible',
          border: theme.alertBar.border,
          backgroundColor: theme.palette.error.main,
          color: theme.palette.error.contrastText,
          fontSize: theme.alertBar.fontSize,
        };
        break;

      case enumAlertType.Info:
        alertBarSxProperty = {
          visibility: 'visible',
          border: theme.alertBar.border,
          backgroundColor: theme.palette.info.main,
          color: theme.palette.info.contrastText,
          fontSize: theme.alertBar.fontSize,
        };
        break;

      case enumAlertType.Success:
        alertBarSxProperty = {
          visibility: 'visible',
          border: theme.alertBar.border,
          backgroundColor: theme.palette.success.main,
          color: theme.palette.success.contrastText,
          fontSize: theme.alertBar.fontSize,
        };
        break;

      case enumAlertType.Warning:
        alertBarSxProperty = {
          visibility: 'visible',
          border: theme.alertBar.border,
          backgroundColor: theme.palette.warning.main,
          color: theme.palette.warning.contrastText,
          fontSize: theme.alertBar.fontSize,
        };
        break;

      default:
        alertBarSxProperty = {
          visibility: 'visible',
          border: theme.alertBar.border,
          backgroundColor: theme.palette.success.main,
          color: theme.palette.success.contrastText,
          fontSize: theme.alertBar.fontSize,
        };
    }

    return alertBarSxProperty;
  }

  function createCloseButtonSxPropertyPerAlertType(alertType: typeAlertType): ICloseButtonSxProperty {

    let closeButtonSxProperty: ICloseButtonSxProperty = {
      visibility: 'hidden',
      color: 'black'
    };

    switch (alertType) {
      case enumAlertType.Error:
        closeButtonSxProperty = {
          visibility: 'visible',
          color: theme.palette.error.contrastText,
        };
        break;

      case enumAlertType.Info:
        closeButtonSxProperty = {
          visibility: 'visible',
          color: theme.palette.info.contrastText,
        };
        break;

      case enumAlertType.Success:
        closeButtonSxProperty = {
          visibility: 'visible',
          color: theme.palette.success.contrastText,
        };
        break;

      case enumAlertType.Warning:
        closeButtonSxProperty = {
          visibility: 'visible',
          color: theme.palette.warning.contrastText,
        };
        break;

      default:
        closeButtonSxProperty = {
          visibility: 'hidden',
          color: 'black',
        };
      }

    return closeButtonSxProperty;
  }

  // const handleClose: () => void = () => {
  //   if (alertInfo && alertInfo.onClearAlert) {
  //     alertInfo.onClearAlert();
  //   }
  // }

  /**
   * @function handleClose Handler method to be called when the AlertBar is being closed
   */
  const handleClose: () => void = useCallback(
    () => {
      // if the alertInfo object is non-null and an 'onClearAlert' method has been defined for the alertInfo object, 
      // call the onClearAlert method
      // if (alertInfo && alertInfo.onClearAlert) {
      //   alertInfo.onClearAlert();
      // }

      // if an 'onCloseAlert' handler was provided, call the handler function
      if (onCloseAlert) {
        onCloseAlert();
      }
    },
    [onCloseAlert],
  );

  // // console.log(`AlertHeader. classNameFromAlertType(alertType): ${classNameFromAlertType(alertType)}`);
  // // console.log(`AlertHeader. classNameFromAlertType(alertType) for Close Button: ${classNameFromAlertType(alertType)}CloseButton`);

  // the key purpose of this 'useEffect' hook is to ensure that the 'handleClose' method is called to clear any alert information
  // when this component is unmounted
  useEffect(() => {
    return () => {
      handleClose();
    }
  }, [handleClose]);


  return (
    <StyledBoxForAlertBarContainer sx={alertInfo ? createAlertBarSxPropertyPerAlertType(alertInfo.alertType) : {}}>
      <Grid container>
        <Grid item container xs={11} justifyContent='flex-start' alignItems='center'>
          <StyledTypographyForMessage>
            {alertInfo && alertInfo.message}
          </StyledTypographyForMessage>
        </Grid>
        <Grid item container xs={1} justifyContent='flex-end' alignItems='center' >
          <IconButton size='small' onClick={handleClose} >
            <StyledCloseIcon sx={alertInfo ? createCloseButtonSxPropertyPerAlertType(alertInfo.alertType) : {}} />
          </IconButton>
        </Grid>
      </Grid>
    </StyledBoxForAlertBarContainer>
  );

}

export default AlertBar;