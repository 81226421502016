import * as slices from '../../slices';

// import appVersionReducer from '../../slices/appVersion/appVersionSlice'; // NOTE: App Version no longer read from IStoreState


export const combinedReducers = {

  // appVersion: slices.appVersion,
  // appVersion: appVersionReducer,

  collectionViewType: slices.collectionViewTypeReducer,

  displayCategorized: slices.displayCategorizedReducer,

  selectedDigitalMediaType: slices.selectedDigitalMediaTypeReducer,

  // currentUser: slices.currentUserReducer,

  // AlertInfo
  alertInfo: slices.alertInfoReducer,

  // Beacons
  beacon: slices.beaconReducer,
  liveBeacons: slices.liveBeaconsReducer,

  // User Registration
  userRegistrationStatus: slices.userRegistrationStatusReducer,
  userRegistrationData: slices.userRegistrationDataReducer,
  userRegistrationFailure: slices.userRegistrationFailureReducer,

  // Change/Update User Email Address
  updateUserEmailAddressStatus: slices.userUpdateEmailAddressStatusReducer,
  updateUserEmailAddressData: slices.userUpdateEmailAddressDataReducer,
  updateUserEmailAddressFailure: slices.userUpdateEmailAddressFailureReducer,

  // Expunge User Account
  expungeUserAccountStatus: slices.userAccountExpungeStatusReducer,
  expungeUserAccountData: slices.userAccountExpungeDataReducer,
  expungeUserAccountFailure: slices.userAccountExpungeFailureReducer,

  // Login
  loginStatus: slices.loginStatusReducer,
  loginData: slices.loginDataReducer,
  loginFailure: slices.loginFailureReducer,

  // Login
  logoutStatus: slices.logoutReducer,

  // Populate Search Metadata
  populateSearchMetadataStatus: slices.searchMetadataPopulateStatusReducer,
  populateSearchMetadataData: slices.searchMetadataPopulateDataReducer,
  populateSearchMetadataFailure: slices.searchMetadataPopulateFailureReducer,

  // Users
  saveUserStatus: slices.userSaveStatusReducer,
  saveUserFailure: slices.userSaveFailureReducer,

  // Categories
  fetchCategoriesStatus: slices.categoriesFetchStatusReducer,
  fetchCategoriesFailure: slices.categoriesFetchFailureReducer,
  saveCategoryStatus: slices.categorySaveStatusReducer,
  saveCategoryFailure: slices.categorySaveFailureReducer,
  deleteCategoryStatus: slices.categoryDeleteStatusReducer,
  deleteCategoryFailure: slices.categoryDeleteFailureReducer,

  // Channels
  fetchChannelsStatus: slices.channelsFetchStatusReducer,
  fetchChannelsFailure: slices.channelsFetchFailureReducer,
  saveChannelStatus: slices.channelSaveStatusReducer,
  saveChannelFailure: slices.channelSaveFailureReducer,
  deleteChannelStatus: slices.channelDeleteStatusReducer,
  deleteChannelFailure: slices.channelDeleteFailureReducer,

  // currentChannel: slices.currentChannelSetReducer,
  // setCurrentChannelFailure: slices.currentChannelSetFailureReducer,

  // Collaboration
  acceptSharingRequestStatus: slices.sharingRequestAcceptanceStatusReducer,
  acceptSharingRequestFailure: slices.sharingRequestAcceptanceFailureReducer,
  declineSharingRequestStatus: slices.sharingRequestDeclineStatusReducer,
  declineSharingRequestFailure: slices.sharingRequestDeclineFailureReducer,

  fetchObjectSharingRequestTrackersStatus: slices.fetchObjectSharingRequestTrackersStatusReducer,
  fetchObjectSharingRequestTrackersFailure: slices.fetchObjectSharingRequestTrackersFailureReducer,
  saveObjectSharingRequestTrackerStatus: slices.objectSharingRequestTrackerSaveStatusReducer,
  saveObjectSharingRequestTrackerFailure: slices.objectSharingRequestTrackerSaveFailureReducer,

  // Digital Media Search
  digitalMediaSearchCriteria: slices.digitalMediaSearchCriteriaReducer,
  digitalMediaSearchResults: slices.digitalMediaSearchResultsReducer,

  // AudioLinks
  deleteAudioLinkFailure: slices.audioLinkDeleteFailureReducer,
  deleteAudioLinkStatus: slices.audioLinkDeleteStatusReducer,
  fetchAudioLinksFailure: slices.fetchAudioLinksFailureReducer,
  fetchAudioLinksStatus: slices.fetchAudioLinksStatusReducer,
  saveAudioLinkStatus: slices.audioLinkSaveStatusReducer,

  // DocumentLinks
  fetchDocumentLinksStatus: slices.fetchDocumentLinksStatusReducer,
  fetchDocumentLinksFailure: slices.fetchDocumentLinksFailureReducer,
  deleteDocumentLinkStatus: slices.documentLinkDeleteStatusReducer,
  deleteDocumentLinkFailure: slices.documentLinkDeleteFailureReducer,
  saveDocumentLinkStatus: slices.documentLinkSaveStatusReducer,

  // HyperLinks
  fetchHyperLinksStatus: slices.fetchHyperLinksStatusReducer,
  fetchHyperLinksFailure: slices.fetchHyperLinksFailureReducer,
  deleteHyperLinkStatus: slices.hyperLinkDeleteStatusReducer,
  deleteHyperLinkFailure: slices.hyperLinkDeleteFailureReducer,
  saveHyperLinkStatus: slices.hyperLinkSaveStatusReducer,

  // ImageLinks
  deleteImageLinkFailure: slices.imageLinkDeleteFailureReducer,
  deleteImageLinkStatus: slices.imageLinkDeleteStatusReducer,
  fetchImageLinksFailure: slices.fetchImageLinksFailureReducer,
  fetchImageLinksStatus: slices.fetchImageLinksStatusReducer,
  saveImageLinkStatus: slices.imageLinkSaveStatusReducer,

  // Notes
  fetchNotesStatus: slices.fetchNotesStatusReducer,
  fetchNotesFailure: slices.fetchNotesFailureReducer,
  deleteNoteStatus: slices.noteDeleteStatusReducer,
  deleteNoteFailure: slices.noteDeleteFailureReducer,
  saveNoteStatus: slices.noteSaveStatusReducer,

  // Social Media Posts
  fetchSocialMediaPostsStatus: slices.fetchSocialMediaPostsStatusReducer,
  fetchSocialMediaPostsFailure: slices.fetchSocialMediaPostsFailureReducer,
  deleteSocialMediaPostStatus: slices.socialMediaPostDeleteStatusReducer,
  deleteSocialMediaPostFailure: slices.socialMediaPostDeleteFailureReducer,
  saveSocialMediaPostStatus: slices.socialMediaPostSaveStatusReducer,

  // ThemeSpecs
  fetchThemeSpecsStatus: slices.fetchThemeSpecsStatusReducer,
  fetchThemeSpecsFailure: slices.fetchThemeSpecsFailureReducer,
  saveThemeSpecsStatus: slices.themeSpecsSaveStatusReducer,
  saveThemeSpecsFailure: slices.themeSpecsSaveFailureReducer,
  deleteThemeSpecsStatus: slices.themeSpecsDeleteStatusReducer,
  deleteThemeSpecsFailure: slices.themeSpecsDeleteFailureReducer,

  // Topics
  fetchTopicsStatus: slices.fetchTopicsStatusReducer,
  fetchTopicsFailure: slices.fetchTopicsFailureReducer,
  saveTopicStatus: slices.topicSaveStatusReducer,
  saveTopicFailure: slices.topicSaveFailureReducer,
  deleteTopicStatus: slices.topicDeleteStatusReducer,
  deleteTopicFailure: slices.topicDeleteFailureReducer,

  currentTopic: slices.currentTopicReducer,

  // TopicItems
  fetchTopicItemsStatus: slices.fetchTopicItemsStatusReducer,
  fetchTopicItemsFailure: slices.fetchTopicItemsFailureReducer,
  saveTopicItemStatus: slices.topicItemSaveStatusReducer,
  saveTopicItemFailure: slices.topicItemSaveFailureReducer,
  deleteTopicItemStatus: slices.topicItemDeleteStatusReducer,
  deleteTopicItemFailure: slices.topicItemDeleteFailureReducer,

  // UserRedemptionCodeRequests
  fetchUserRedemptionCodeRequestsStatus: slices.fetchUserRedemptionCodeRequestsStatusReducer,
  fetchUserRedemptionCodeRequestsFailure: slices.fetchUserRedemptionCodeRequestsFailureReducer,
  saveUserRedemptionCodeRequestStatus: slices.userRedemptionCodeRequestSaveStatusReducer,
  saveUserRedemptionCodeRequestFailure: slices.userRedemptionCodeRequestSaveFailureReducer,
  deleteUserRedemptionCodeRequestStatus: slices.userRedemptionCodeRequestDeleteStatusReducer,
  deleteUserRedemptionCodeRequestFailure: slices.userRedemptionCodeRequestDeleteFailureReducer,

  // VideoLinks
  fetchVideoLinksStatus: slices.fetchVideoLinksStatusReducer,
  fetchVideoLinksFailure: slices.fetchVideoLinksFailureReducer,
  deleteVideoLinkStatus: slices.videoLinkDeleteStatusReducer,
  deleteVideoLinkFailure: slices.videoLinkDeleteFailureReducer,
  saveVideoLinkStatus: slices.videoLinkSaveStatusReducer,
};

// // export the combined set of slices (to be called "rootReducer")
// export const rootReducer = combineReducers(partialAllReducers);
                                                                                                                                                                                                                                                                                          
// // export the data type of the rootReducer
// export type RootState = ReturnType<typeof rootReducer>;
