import React, { PropsWithChildren, useCallback, useEffect, useState } from 'react';
import { DocumentData, QuerySnapshot } from 'firebase/firestore';
import _ from 'lodash';
import { useNavigate, useParams } from 'react-router-dom';
import { Accordion, AccordionDetails, AccordionSummary, Box, BoxProps, Container, ContainerProps, Grid, GridProps, Typography, TypographyProps } from '@mui/material';
import { useTheme } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ControlsStringAssets, MessagesStringAssets, PageAndViewTitleStringAssets } from '../../../../assets/stringAssets';
import GenericPageContainer from '../../GenericPageContainer/GenericPageContainer';
import { enumAlertType } from '../../../enums';
import { IChannel, IChannelAsJson, Channel, IChannelAndParentCategoryId } from '../../../../dataObjects/models/channels/Channel';
import { IUser, IUserAsJson, User } from '../../../../dataObjects/models/users/User';
import { typeUniqueId } from '../../../../dataObjects/types';
import { IStoreState } from '../../../../uiMiddleware-redux/store/IStoreState';
import { enumBeaconType, enumCollectionViewType, enumPersistableObjectType, enumSharingPermission, enumSharingRequestStatus, enumWorkflowState } from '../../../../dataObjects/enums';
import { JsonConverter } from '../../../../dataObjects/utilities/JsonConverter';
import TwoButtonAcceptanceDialog from '../../../dialogs/TwoButtonAcceptanceDialog/TwoButtonAcceptanceDialog';
import { Beacon } from '../../../../dataObjects/models/alerts/Beacon';
import ActivityIndicatorDialog from '../../../dialogs/ActivityIndicatorDialog/ActivityIndicatorDialog';
import { IActivityIndicatorData } from '../../../dialogs/ActivityIndicatorData';
import { AlertInfo, IAlertInfo } from '../../../../dataObjects/models/alerts/AlertInfo';
import { useReduxWorkflowState } from '../../../customHooks';
import DataViewControls from '../../../controls/DataViewControls/DataViewControls';
import { useGetCollectionViewType } from '../../../customHooks/useGetCollectionViewType';
import { getMultipleObjectsByIds_onSnapshot, getSingleObjectById_onSnapshot } from '../../../../firebaseServices/dataServices/dataServiceActions/genericActions';
import { IObjectSharingRequestTracker, IObjectSharingRequestTrackerAsJson, ObjectSharingRequestTracker } from '../../../../dataObjects/models/collaboration/ObjectSharingTracker';
import { IUserSharingPermissions } from '../../../../dataObjects/models/collaboration/UserSharingPermissions';
import { ChannelSharingUsersCardGridView } from '../../../views/channelSharing/ChannelSharingUsersCardGridView/ChannelSharingUsersCardGridView';
import { ChannelSharingUser, IChannelSharingUser } from '../../../../dataObjects/viewModels/channelSharing/ChannelSharingUser';
import ChannelSharingUsersListView from '../../../views/channelSharing/ChannelSharingUsersListView/ChannelSharingUsersListView';
import { getRequestsForSharingObject_onSnapshot } from '../../../../firebaseServices/dataServices/dataServiceActions/collaborationActions/getRequestsForSharingObject_onSnapshot';
import { ChannelSharingRequestsCardGridView } from '../../../views/channelSharing/ChannelSharingRequestsCardGridView/ChannelSharingRequestsCardGridView';
import ChannelSharingRequestsListView from '../../../views/channelSharing/ChannelSharingRequestsListView/ChannelSharingRequestsListView';
import { CurrentUserChannelSharingViewModel, ICurrentUserChannelSharingViewModel } from '../../../../dataObjects/viewModels/channelSharing/CurrentUserChannelSharingViewModel';
import { styled } from '@mui/styles';
import { ICurrentUserContextData, useCurrentUserContext } from '../../../providersAndContexts/currentUser';
import { enumMdbErrorType } from '../../../../errorObjects/enums';
import { MdbError } from '../../../../errorObjects/MdbError';
import { useAppDispatch } from '../../../../uiMiddleware-redux/store/configureStore';
import { channelSaveRequest, channelSaveStatusChange } from '../../../../uiMiddleware-redux/slices/channel/channelSaveStatusSlice';
import { objectSharingRequestTrackerSaveRequest, objectSharingRequestTrackerSaveStatusChange } from '../../../../uiMiddleware-redux/slices/collaboration/objectSharingRequestTrackerSaveStatusSlice';
import { beaconChange } from '../../../../uiMiddleware-redux/slices/beacon/beaconSlice';
import { alertInfoChange } from '../../../../uiMiddleware-redux/slices/alertInfo/alertInfoSlice';


/*** Using the Material UI Emotion Styling library, declare 'styled' instances for each area/object. 
 *** NOTE: These must be declared outside of the React Functional Component to ensure that the styled 
 *** objects will be properly rendered within the DOM. 
 ***/

// a styled Box (equivalent to a <div>), providing an area to display the overall header
const StyledBoxForPaddingAtopPage = styled((props: BoxProps) => (
  <Box
    {...props}
  />
))(({ theme }) => ({
  marginTop: theme.spacing(3),
}));

// a styled Box (equivalent to a <div>), providing an area of padding above the Data View controls
const StyledBoxForPaddingAboveDataViewControls = styled((props: BoxProps) => (
  <Box
    {...props}
  />
))(({ theme }) => ({
  marginTop: theme.spacing(1),
}));

// a styled Box for a Horizontal Divider (a CSS Horizontal Rule <hr/>)
const StyledBoxForHorizontalDivider = styled((props: BoxProps) => (
  <Box
    component='hr'
    {...props}
  />
))(({ theme }) => ({
  width: "100%",
  borderTop: `1px solid ${theme.palette.primary.light}`,
}));

// a styled Container for Data View
const StyledContainerForDataView = styled((props: ContainerProps) => (
  <Container
    disableGutters
    maxWidth={false}
    {...props}
  />
))(({ theme }) => ({
  flexGrow: 1,
  paddingLeft: 0,
  paddingRight: 0,
}));

// a styled Accordion for Data View
const StyledAccordion = styled((props: any) => (
  <Accordion
    {...props}
  />
))(({ theme }) => ({
  width: '100%'
}));

// a styled AccordionSummary for Pending Users
const StyledAccordionSummaryForPendingUsers = styled((props: any) => (
  <AccordionSummary
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: theme.palette.primary.light,
  color: theme.palette.primary.dark,
}));

// a styled AccordionSummary for Current Users
const StyledAccordionSummaryForCurrentUsers = styled((props: any) => (
  <AccordionSummary
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: theme.palette.primary.dark,
  color: theme.palette.primary.light,
}));

// a styled Grid for Accordian Summary Content
const StyledGridForAccordionSummaryContent = styled((props: GridProps) => (
  <Grid
    item
    container
    xs={12}
    direction="row"
    {...props}
  />
))(({ theme }) => ({
}));

// a styled Grid for an Accordian Summary Header
const StyledGridForAccordionSummaryHeader = styled((props: GridProps) => (
  <Grid
    item
    container
    xs={12}
    {...props}
  />
))(({ theme }) => ({
}));

// a styled Typography for an Accordian Summary Header
const StyledTypographyForAccordionSummaryHeader = styled((props: TypographyProps) => (
  <Typography
    variant='h6'
    {...props}
  />
))(({ theme }) => ({
}));

// a styled Grid for an Accordian Summary Description
const StyledGridForAccordionSummaryDescription = styled((props: GridProps) => (
  <Grid
    item
    container
    xs={12}
    {...props}
  />
))(({ theme }) => ({
}));

// a styled Typography for an Accordian Summary Description
const StyledTypographyForAccordionSummaryDescription = styled((props: TypographyProps) => (
  <Typography
    variant='body1'
    {...props}
  />
))(({ theme }) => ({
}));

// a styled AccordionDetails for Data View
const StyledAccordionDetails = styled((props: any) => (
  <AccordionDetails
    {...props}
  />
))(({ theme }) => ({
  flexDirection: "column"
}));


export interface IChannelSharingPageProps extends PropsWithChildren<unknown> {
}

const ChannelSharingPage: React.FC<IChannelSharingPageProps> = (props: IChannelSharingPageProps) => {

  interface IUserChannelSharingRevokeInfo {
    channelId: typeUniqueId;
    userId: typeUniqueId;
    userName: string;
  }

  ChannelSharingPage.displayName = 'Channel Sharing Page';

  // whether to display console logs (displayConsoleLogs && console.log statements)
  const displayConsoleLogs: boolean = false;

  const params = useParams();

  // params.id is the Id of the Channel. If that hasn't been provided, throw an error.
  if (params.id === undefined) {
    throw new MdbError('A channelId must be provided in order to manage channel sharing', enumMdbErrorType.RequiredInputIsMissing);
  }

  const theme: Theme = useTheme();

  // will be set to the 'id' provided in the URL parms
  const [channelId, setChannelId] = useState<typeUniqueId | undefined>(params.id);

  // Will be set to the Channel object associated with the channelId provided in the URL parms. The Channel object will be retrieved
  // during the mounting of this component in a useEffect().
  const [channel, setChannel] = useState<IChannel | undefined>(undefined);

  // Will be set to the collection of ObjectSharingRequestTracker records associated with the given Channel
  const [channelSharingRequestTrackers, setChannelSharingRequestTrackers] = useState<Array<IObjectSharingRequestTracker>>([]);

  // call custom hook to get the current view type, so the value can be used to initialize the View Type state variable
  const collectionViewType: enumCollectionViewType = useGetCollectionViewType();

  // Will be set to an array of Users that are configured to share the Channel. After the Channel information has been retrieved,
  // objects will be retrieved that represent Users configured to share the Channel.
  // This includes Users that have accepted sharing of the Channel and those that have been invited/requested, but haven't yet accepted sharing.)
  const [usersSharingChannel, setUsersSharingChannel] = useState<Array<IUser>>([]);

  // // Will be set to an array of IObjectSharingRequestTracker objects that will be by UI components to display channel sharing request info
  // const [channelSharingRequests, setChannelSharingRequests] = useState<Array<IObjectSharingRequestTracker>>([]);

  // Will be set to an array of IChannelSharingUser view model objects that will be by UI components to display channel sharing user info
  const [channelSharingUserViewModels, setChannelSharingUserViewModels] = useState<Array<IChannelSharingUser>>([]);

  // // the Users that have an active invitation/request to share the Channel, but have not yet accepted
  // const [usersPendingSharingChannel, setUsersPendingSharingChannel] = useState<Array<IUser>>([]);

  // whether data is currently loading
  const [dataLoading, setDataLoading] = useState<boolean>(true);

  // if user is revoking a User from sharing a Channel, this will hold the User and Channel information
  const [userChannelSharingRevokeInfo, setUserChannelSharingRevokeInfo] = useState<IUserChannelSharingRevokeInfo | undefined>(undefined);

  // a ChannelSharingRequest that is to be revoked
  const [channelSharingRequestToBeRevoked, setChannelSharingRequestToBeRevoked] = useState<IObjectSharingRequestTracker | undefined>(undefined);

  // whether to show the Revoke User Sharing Confirmation dialog
  const [showRevokeUserSharingConfirmationDlg, setShowRevokeUserConfirmationDlg] = useState<boolean>(false);

  // whether to show the Revoke Channel Sharing Request Confirmation dialog
  const [showRevokeChannelSharingRequestConfirmationDlg, setShowRevokeChannelSharingRequestConfirmationDlg] = useState<boolean>(false);

  // for dispatching redux actions
  const dispatch = useAppDispatch();
  // for using browser router navigate
  const navigate = useNavigate();

  // data to indicate whether an alert should be displayed
  let alertInfo: IAlertInfo | null = null;

  // define an effect that will set the channelId anytime the params.id changes
  useEffect(() => {
    displayConsoleLogs && console.log(`ChannelSharingPage. Entered useEffect() for [channelId, params.id]. params.id value: ${params.id}`);
    if (channelId !== params.id) {
      displayConsoleLogs && console.log(`ChannelSharingPage. In useEffect() for [channelId, params.id]. params.id value: ${params.id}. Ready to call setChannelId()`);
      setChannelId(params.id);
    }

    return () => {
      // process for 'unmounting'
      displayConsoleLogs && console.log(`ChannelSharingPage. In 'unmounting' area of useEffect() for [channelId, params.id]`);
    }

  }, [channelId, params.id]);

  // define an effect based on a change to solely the channelId. 
  useEffect(() => {
    displayConsoleLogs && console.log(`ChannelSharingPage. Entered useEffect() for [channelId]. channelId value: ${channelId}`);

    // clear array of users currently sharing the Channel
    setUsersSharingChannel([]);

    // Declare an 'unsubscribe' variable that will hold the unsubscribe callback from a firestore onSnapshot() Channel request.
    // We initialize it to a function that does nothing, so if an onSnapshot() is never requested, we can still call unsubscribe() during cleanup. 
    // After an onShapshot() request, the 'unsubscribe' variable will be set to a callback function issued by firestore.
    let unsubscribeCallbackForChannelData: () => void = () => { };

    // Declare an 'unsubscribe' variable that will hold the unsubscribe callback from a firestore onSnapshot() ObjectSharingRequestTracker request.
    // We initialize it to a function that does nothing, so if an onSnapshot() is never requested, we can still call unsubscribe() during cleanup. 
    // After an onShapshot() request, the 'unsubscribe' variable will be set to a callback function issued by firestore.
    let unsubscribeCallbackForObjectSharingRequestTrackerData: () => void = () => { };

    // if an Id was provided, we will be editing an existing channel; therefore, if we haven't already fetched the channel...
    if (channelId !== undefined) {
      displayConsoleLogs && console.log(`ChannelSharingPage. In useEffect() for [channelId] (${channelId}). Ready to call getSingleObjectById_onSnapshot().`);

      // subscribe to onShapshot updates for Channel, providing realtime updates to the data, and capture the 'unsubscribe' callback method provided by firestore
      getSingleObjectById_onSnapshot(channelId, enumPersistableObjectType.Channel, onChannelSnapshotCallback).then((unsubscribe: () => void) => {
        unsubscribeCallbackForObjectSharingRequestTrackerData = unsubscribe;
      });

      // We will be fetching a collection of Channel Sharing Requests (ObjectSharingRequestTracker objects) for the Channel (channelId) 
      // that qualify with one of the allowable status codes.
      // First, set up an array of allowable status codes
      const channelSharingRequestStatusCodes: Array<enumSharingRequestStatus> = [enumSharingRequestStatus.Requested];

      // subscribe to onSnapshot updates for Channel Sharing Requests (ObjectSharingRequestTracker objects) for the Channel (channelId)
      getRequestsForSharingObject_onSnapshot(channelId, onObjectSharingRequestTrackerSnapshotCallback, channelSharingRequestStatusCodes).then((unsubscribe: () => void) => {
        unsubscribeCallbackForChannelData = unsubscribe;
      });

    }

    // perform cleanup when the component unmounts
    return () => {
      // Call the unsubscribe() callback to unsubscribe from the Channels onSnapshot. 
      displayConsoleLogs && console.info(`ChannelSharingPage. Cleanup before unmounting. Ready to call unsubscribeCallbackChannel().`)
      unsubscribeCallbackForChannelData();
      displayConsoleLogs && console.info(`ChannelSharingPage. Cleanup before unmounting. After call to unsubscribeCallbackChannel().`)

      // Call the unsubscribe() callback to unsubscribe from the ObjectSharingRequestTrackers onSnapshot. 
      displayConsoleLogs && console.info(`ChannelSharingPage. Cleanup before unmounting. Ready to call unsubscribeCallbackForObjectSharingRequestTrackerData().`)
      unsubscribeCallbackForObjectSharingRequestTrackerData();
      displayConsoleLogs && console.info(`ChannelSharingPage. Cleanup before unmounting. After call to unsubscribeCallbackForObjectSharingRequestTrackerData().`)
    }

  }, [channelId]);


  /**
   * @function onChannelSnapshotCallback A callback method to receive firestore Channel data snapshots for dynamic data updates
   * @param {QuerySnapshot<DocumentData>} snapshot A snapshot of data from firestore
                        */
  function onChannelSnapshotCallback(snapshot: QuerySnapshot<DocumentData>): void {
    // set state variable indicating that dataLoading is no longer taking place
    setDataLoading(false);

    // fetch the data from the document, which should be a JSON version of the correct type of object
    const channelAsJson: IChannelAsJson = snapshot.docs[0].data() as IChannelAsJson;

    // convert the JSON object to a Typescript object
    const channelData: IChannel = JsonConverter.fromJSON(Channel, channelAsJson, true);

    displayConsoleLogs && console.log(`ChannelSharingPage.onChannelSnapshotCallback(). New Channel object retrieved from database. # shared users: ${channelData.userSharingPermissions.length}`)

    // since we have a new Channel object, we'll set the info for Users Sharing the Channel (derived from the Channel data) to an empty array
    // when the new Channel object is set into state, we'll resolve the latest set of Users Sharing the Channel
    setUsersSharingChannel([]);

    // set new Channel data into state
    setChannel(channelData);
  }

  /**
   * @function onObjectSharingRequestTrackerSnapshotCallback A callback method to receive firestore Channel data snapshots for dynamic data updates
   * @param {QuerySnapshot<DocumentData>} snapshot A snapshot of data from firestore
                          */
  function onObjectSharingRequestTrackerSnapshotCallback(snapshot: QuerySnapshot<DocumentData>): void {
    // set state variable indicating that dataLoading is no longer taking place
    setDataLoading(false);

    const objectSharingRequestTrackersDataAsJson: Array<IObjectSharingRequestTrackerAsJson> = [];
    snapshot.forEach(doc => objectSharingRequestTrackersDataAsJson.push({ ...doc.data(), id: doc.id } as IObjectSharingRequestTrackerAsJson));

    // create an array of ObjectSharingRequestTracker objects from the JSON data
    let objectSharingRequestTrackersData = JsonConverter.arrayFromJSONArray(ObjectSharingRequestTracker, objectSharingRequestTrackersDataAsJson);

    // use lodash to sort the array by 'name' in ascending order
    objectSharingRequestTrackersData = _.orderBy(objectSharingRequestTrackersData, [objectSharingRequestTracker => objectSharingRequestTracker.recipientName.toUpperCase()], ['asc']);

    setChannelSharingRequestTrackers(objectSharingRequestTrackersData);
  }

  // // An array of unsubscribe callback functions for snapshots of User records, one for each currently sharing user for the channal.
  // // When the channel object (local state) changes, this array of unsubscribe methods will need to be called to unsubscribed from the snapshot queries.
  // const [unsubscribeCallbacksForUserRecords, setUnsubscribeCallbacksForUserRecords] = useState<Array<() => void>>([]);


  /**
   * @function onUserSnapshotCallback A callback method to receive firestore User data snapshots for dynamic data updates
   * @param {QuerySnapshot<DocumentData>} snapshot A snapshot of data from firestore
                                */
  const onUserSnapshotCallback: (snapshot: QuerySnapshot<DocumentData>) => void
    = useCallback((snapshot: QuerySnapshot<DocumentData>) => {

      // set state variable indicating that dataLoading is no longer taking place
      setDataLoading(false);

      // fetch the data from the document, which should be a JSON version of the correct type of object
      const userAsJson: IUserAsJson = snapshot.docs[0].data() as IUserAsJson;

      // convert the JSON object to a Typescript object
      const userData: IUser = JsonConverter.fromJSON(User, userAsJson, true);

      displayConsoleLogs && console.log(`ChannelSharingPage.onUserSnapshotCallback(). New User object retrieved from database. userId: ${userData.id}`)

      // We're going to set the state of the Users (array) associated with users currently sharing the channel into local state, 
      // and we need to add the latest userData object to the array. However, we need to ensure that we don't have a duplicate of this particular user's data (identified by
      // the userData.id field). So, in calling the setUsersForUniqueOwnerIds() method, we will need to access the existing (previous) state
      // of the array, filtering out any array elements where the user's Id matches the userData.id, and than append the userData object to
      // the array
      setUsersSharingChannel((prevStateOfUsersSharingChannelArray: Array<IUser>) =>
        [...prevStateOfUsersSharingChannelArray.filter(user => { return (user.id !== userData.id) }),
          userData]
      );

      if (channel !== undefined) {
        // fetch the SharingPermission value within the channel that is associated with this user from the userIdAndChannelPermissionMap

        let userSharingPermission: enumSharingPermission | undefined = undefined;

        const fetchedUserSharingPermissions: IUserSharingPermissions | undefined = channel.userSharingPermissions.find((userSharingPermissions: IUserSharingPermissions) =>
          userSharingPermissions.userId === userData.id);

        if (fetchedUserSharingPermissions !== undefined) {
          userSharingPermission = fetchedUserSharingPermissions.sharingPermission;
        } else {
          userSharingPermission = enumSharingPermission.ViewContent;
        }

        // create a ChannelSharingUser object that is ultimately going to be added to another collection of the ChannelSharingUser objects.
        const channelSharingUser: IChannelSharingUser =
          new ChannelSharingUser(userData.id, userData.userSettings.email, userData.userSettings.firstName, userData.userSettings.lastName,
            channel.id, channel.name, userSharingPermission);

        // setChannelSharingUserViewModels((prevStateOfUsersSharingChannelArray: Array<IChannelSharingUser>) =>
        //   [...prevStateOfUsersSharingChannelArray, channelSharingUser]
        // );

        // We're going to set the state of the User View Models (array) for the current Channel being displayed for sharing into local state, 
        // and we need to add the latest channelSharingUser object to the array, appending it to the existing array (previous state). 
        // Additionally, we will sort on the fly using the lodash orderBy() method.
        setChannelSharingUserViewModels((prevStateOfUsersSharingChannelArray: Array<IChannelSharingUser>) =>
          _.orderBy([...prevStateOfUsersSharingChannelArray, channelSharingUser], channelSharingUserViewModel => channelSharingUserViewModel.formattedName, ['asc'])
        );

      }
    }, [channel]);

  // define an effect based on a change to the channel. 
  useEffect(() => {
    displayConsoleLogs && console.log(`ChannelSharingPage. Entered useEffect() for [channel]. channel.id value: ${channel !== undefined ? channel.id : 'undefined'}`);

    // since the Channel has changed, initialize the collection of Channel Sharing User View Models to an empty array, as the array will be
    // rebuilt through retrieving the latest collection of channel sharing users
    setChannelSharingUserViewModels(new Array<IChannelSharingUser>());

    // initialize a new empty array of unsubscribe callbacks
    let unsubscribeCallbacksForUsers: Array<() => void> = [];

    // if the Channel is a defined object, call the snapshot function for each User record and capture the unsubscribe callback functions
    if (channel !== undefined) {
      // Create an array of User Ids, representing all users having permission to share the channel. These Ids will be used
      // below to fetch User records from the database.
      let usersSharingChannelUserIds: Array<typeUniqueId> = new Array<typeUniqueId>();

      channel.userSharingPermissions.forEach((userSharingPermissions: IUserSharingPermissions, index: number) => {
        usersSharingChannelUserIds.push(userSharingPermissions.userId);
      });

      if (usersSharingChannelUserIds && usersSharingChannelUserIds.length > 0) {
        // use an IIFE (Immediately Invoked Function Expression) to perform inline operation using async/await
        (async () => {
          try {
            // request to get all User objects/records from database that correspond to Channel objects, in one fell swoop
            unsubscribeCallbacksForUsers = await getMultipleObjectsByIds_onSnapshot(usersSharingChannelUserIds, enumPersistableObjectType.User, onUserSnapshotCallback);
          } catch (error: any) {
            displayConsoleLogs && console.error(`HomePage. Error getting multiple User/Owner records (call to getMultipleObjectsByIds_onSnapshot): ${error.toString()}`);
          }
        })();
      }
    }

    // perform cleanup when the component unmounts
    return () => {
      // Call the unsubscribe() callback to unsubscribe from the Users onSnapshots. 
      displayConsoleLogs && console.info(`ChannelSharingPage. In useEffect() for [channel]. Cleanup before unmounting. Ready to call array of unsubscribeCallbacksForUserRecords.`)
      if (unsubscribeCallbacksForUsers && unsubscribeCallbacksForUsers.length > 0) {
        unsubscribeCallbacksForUsers.forEach((unsubscribeCallback: () => void, index: number) => {
          unsubscribeCallback();
        });
      }
      displayConsoleLogs && console.info(`ChannelSharingPage. In useEffect() for [channel, onUserSnapshotCallback]. Cleanup before unmounting. After call to array of unsubscribeCallbacksForUserRecords.`)
    }
    // }, [channel, onUserSnapshotCallback]);
  }, [channel, onUserSnapshotCallback]);


  // use a custom hook to get the Current User information from a CurrentUserContext/Provider higher up in the component tree
  const { currentUser }: ICurrentUserContextData = useCurrentUserContext();
  displayConsoleLogs && console.log(`%c ChannelSharingPge. currentUser: \n${JSON.stringify(currentUser)}`, 'background: #600; color: #fff');

  // // will hold setting indicating whether the currentUser has Admin privilege to the channel
  // const [currentUserHasAdminAccessToChannel, setCurrentUserHasAdminAccessToChannel] = useState<boolean>(false);

  // will hold an ICurrentUserChannelSharingViewModel object to be passed into embedded views
  const [currentUserChannelSharingViewModel, setCurrentUserChannelSharingViewModel] = useState<ICurrentUserChannelSharingViewModel | undefined>(undefined);

  // define an effect that will set whether the currentUser has Admin privilege to the channel
  useEffect(() => {
    if (currentUser && channel) {
      const userChannelSharingViewModel: ICurrentUserChannelSharingViewModel =
        new CurrentUserChannelSharingViewModel(currentUser, channel);

      setCurrentUserChannelSharingViewModel(userChannelSharingViewModel);
    }
  }, [currentUser, channel]);


  // use a custom hook to evaluate the redux state for the revokeUserSharing workflow and any revokeUserSharing error
  const { workflowState: revokeUserSharingStatus, errorState: revokeUserSharingFailure } = useReduxWorkflowState((state: IStoreState) => state.saveChannelStatus, (state: IStoreState) => state.saveChannelFailure);

  // use a custom hook to evaluate the redux state for the revokeChannelSharingRequest workflow and any revokeChannelSharingRequest error
  const { workflowState: revokeChannelSharingRequestStatus, errorState: revokeChannelSharingRequestFailure } = useReduxWorkflowState((state: IStoreState) => state.saveObjectSharingRequestTrackerStatus, (state: IStoreState) => state.saveObjectSharingRequestTrackerFailure);

  /**
   * @method handleUserRevokeFromChannelSharing Handles requests to revoke a user from sharing the current Channel being viewed
   * @param channelSharingUser The User information to be used in preparing to revoke from sharing the Channel
   */
  async function handleRevokeUserFromChannelSharing(channelSharingUser: IChannelSharingUser): Promise<void> {
    // const userChannelSharingInfoForRevoke = {channelSharingUser.};
    // // set the information needed to revoke a User from sharing a Channel
    // setUserChannelSharingRevokeInfo({channelSharingUser.channelId, channelSharingUser.userId, channelSharingUser.user});

    // // set flag to show the revoke confirmation dialog
    // setShowRevokeUserConfirmationDlg(true);
    return new Promise(async (resolve, reject) => {
      try {
        // set the information needed to revoke a User from sharing a Channel
        setUserChannelSharingRevokeInfo({
          channelId: channelSharingUser.channelId,
          userId: channelSharingUser.userId,
          userName: `${channelSharingUser.userFirstName} ${channelSharingUser.userLastName}`
        });

        // set flag to show the revoke confirmation dialog
        setShowRevokeUserConfirmationDlg(true);

        resolve();
      } catch (error: any) {
        reject(error);
      }
    });

  }


  function handleRevokeUserChannelSharingConfirmed(): void {
    // update the sharingPermission for the user within the channel
    if (channel !== undefined && userChannelSharingRevokeInfo !== undefined) {
      const userRemovedFromSharingChannel: boolean = channel.removeUserAsChannelMember(userChannelSharingRevokeInfo.userId);

      // if the user has been removed from sharing the channel...
      if (userRemovedFromSharingChannel) {
        // dispatch an action to save (update) the Channel 
        const channelAndParentCategoryId: IChannelAndParentCategoryId = { channel: channel, parentCategoryId: '' };
        dispatch(channelSaveRequest(channelAndParentCategoryId));
      }
    }

    // call method to reset the User Channel Sharing Revoke Info local state details
    userChannelSharingRevokeReset();
  }

  function handleRevokeUserChannelSharingCanceled(): void {
    // call method to reset the User Channel Sharing Revoke Info local state details
    userChannelSharingRevokeReset();
  }

  function userChannelSharingRevokeReset() {
    // reset the User Channel Sharing Revoke Info to 'undefined' in local state, since we're done with the object
    setUserChannelSharingRevokeInfo(undefined);

    // set flag to hide the User Channel Sharing Revoke confirmation dialog
    setShowRevokeUserConfirmationDlg(false);
  }


  /**
   * @method handleRevokeChannelSharingRequest Handles requests to revoke a Channel Sharing Request
   * @param channelSharingRequest The User to be revoked from sharing the Channel
   */
  async function handleRevokeChannelSharingRequest(channelSharingRequest: IObjectSharingRequestTracker): Promise<void> {
    // for the channelSharingRequest passed in, set its value into local state
    setChannelSharingRequestToBeRevoked(channelSharingRequest);

    // set flag to show the revoke Channel Sharing Request confirmation dialog
    setShowRevokeChannelSharingRequestConfirmationDlg(true);
  }

  function handleRevokeChannelSharingRequestConfirmed(): void {
    // if we have a request to be revoked and a valid currentUser...
    if (channelSharingRequestToBeRevoked !== undefined && currentUser) {
      // make a copy of the request
      let channelSharingRequestCopy: IObjectSharingRequestTracker = channelSharingRequestToBeRevoked.copy();

      displayConsoleLogs && console.log(`ChannelSharingPage.handleRevokeChannelSharingRequestConfirmed(). Revoking channel sharing for. channelSharingRequestCopy: ${JSON.stringify(channelSharingRequestCopy)}`)

      // update the channelSharingRequest to indicate that the current user has revoked the request
      channelSharingRequestCopy.revokeSharing(currentUser.id, currentUser.userSettings.email);

      displayConsoleLogs && console.log(`ChannelSharingPage.handleRevokeChannelSharingRequestConfirmed(). Revoking channel sharing for. Updated channelSharingRequestCopy: ${JSON.stringify(channelSharingRequestCopy)}`)

      // dispatch an action to save (update) the Channel 
      dispatch(objectSharingRequestTrackerSaveRequest(channelSharingRequestCopy));
    }

    // call method to reset the Channel Sharing Request Revoke Info local state details
    channelSharingRequestRevokeReset();
  }

  function handleRevokeChannelSharingRequestCanceled(): void {
    // call method to reset the request to be revoked local state details
    channelSharingRequestRevokeReset();
  }

  function channelSharingRequestRevokeReset() {
    // reset the Channel Sharing Request To Be Revoked to 'undefined' in local state, since we're done with the object
    setChannelSharingRequestToBeRevoked(undefined);

    // set flag to hide the revoke Channel Sharing Request confirmation dialog
    setShowRevokeChannelSharingRequestConfirmationDlg(false);
  }


  // data to indicate whether an activity indicator should be displayed and, if so, the message
  let activityIndicatorData: IActivityIndicatorData = {
    showActivityIndicator: false,
    activityIndicatorMessage: ''
  }


  // indicates whether the User has been successfully revoked from sharing the Channel
  const [successfullyRevokedUser, setSuccessfullyRevokedUser] = useState<boolean>(false);

  // define an effect that will process whenever the successfullyRevokedUser state changes
  useEffect(() => {
    if (successfullyRevokedUser) {
      // dispatch an action to reset the Channel Save Status (set to null)
      dispatch(channelSaveStatusChange(null));

      // notify user via a Beacon notification that the Sharing User has been successfully revoked from sharing the channel
      dispatch(beaconChange(new Beacon(undefined, enumBeaconType.Success, MessagesStringAssets.channelSharing_RevokeUserConfirmationHeader, MessagesStringAssets.channelSharing_RevokeUserSuccess)));

      // reset the successfullyRevokedUser flag
      setSuccessfullyRevokedUser(false);
    }
  }, [successfullyRevokedUser, dispatch]);

  // indicates whether the Channel Sharing Request has been successfully revoked
  const [successfullyRevokedChannelSharingRequest, setSuccessfullyRevokedChannelSharingRequest] = useState<boolean>(false);

  // define an effect that will process whenever the successfullyRevokedChannelSharingRequest state changes
  useEffect(() => {
    if (successfullyRevokedChannelSharingRequest) {
      // dispatch an action to reset the objectSharingRequestTracker Save Status
      dispatch(objectSharingRequestTrackerSaveStatusChange(null));

      // notify user via a Beacon notification that the Channel Sharing Request has been successfully revoked
      dispatch(beaconChange(new Beacon(undefined, enumBeaconType.Success, MessagesStringAssets.channelSharing_RevokeChannelSharingRequestConfirmationHeader, MessagesStringAssets.channelSharing_RevokeChannelSharingRequestSuccess)));

      // reset the setSuccessfullyRevokedChannelSharingRequest flag
      setSuccessfullyRevokedChannelSharingRequest(false);
    }
  }, [successfullyRevokedChannelSharingRequest, dispatch]);

  if (dataLoading) {
    // display an activity indicator
    activityIndicatorData.showActivityIndicator = true;
    activityIndicatorData.activityIndicatorMessage = MessagesStringAssets.channelSharing_Loading;
  } else {
    // if the workflow is in the midst of performing the revocation of a user from sharing the channel, prepare an alert message
    // fragment to display progress message(s)
    if (revokeUserSharingStatus !== undefined && revokeUserSharingStatus !== null) {
      if (revokeUserSharingStatus === enumWorkflowState.Requested || revokeUserSharingStatus === enumWorkflowState.InProgress) {
        // display an activity indicator
        activityIndicatorData.showActivityIndicator = true;
        activityIndicatorData.activityIndicatorMessage = MessagesStringAssets.channelSharing_RevokeUserRequested;
      } else if (revokeUserSharingStatus === enumWorkflowState.Success) {
        // if the successfullyRevokedUser is currently in a 'false' state, we'll want the flag to indicate that the revocation was a success
        if (!successfullyRevokedUser) {
          // set flag to indicate that the user sharing has been successfully revoked, which will force a re-render to allow for cleanup and user notification
          setSuccessfullyRevokedUser(true);
        }
      } else if (revokeUserSharingStatus === enumWorkflowState.Failure) {
        // display an alert message
        let failureMessage = 'Unknown error';
        if (revokeUserSharingFailure && revokeUserSharingFailure instanceof Error) {
          failureMessage = revokeUserSharingFailure.message;
        }
        // create an AlertInfo object and dispatch a request to set the AlertInfo into Redux state
        alertInfo = new AlertInfo(true, enumAlertType.Error, failureMessage);
        dispatch(alertInfoChange(alertInfo));
      }
    }
    // if the workflow is in the midst of performing the revocation of a Channel Sharing Request, prepare an alert message 
    // fragment to display progress message(s)
    else if (revokeChannelSharingRequestStatus !== undefined && revokeChannelSharingRequestStatus !== null) {
      if (revokeChannelSharingRequestStatus === enumWorkflowState.Requested || revokeChannelSharingRequestStatus === enumWorkflowState.InProgress) {
        // display an activity indicator
        activityIndicatorData.showActivityIndicator = true;
        activityIndicatorData.activityIndicatorMessage = MessagesStringAssets.channelSharing_RevokeChannelSharingRequestRequested;
      } else if (revokeChannelSharingRequestStatus === enumWorkflowState.Success) {
        // if the successfullyRevokedChannelSharingRequest is currently in a 'false' state, we'll want the flag to indicate that the revocation was a success
        if (!successfullyRevokedChannelSharingRequest) {
          // set flag to indicate that the Channel Sharing Request has been successfully revoked, which will force a re-render to allow for cleanup and user notification
          setSuccessfullyRevokedChannelSharingRequest(true);
        }
      } else if (revokeChannelSharingRequestStatus === enumWorkflowState.Failure) {
        // display an alert message
        let failureMessage = 'Unknown error';
        if (revokeChannelSharingRequestFailure && revokeChannelSharingRequestFailure instanceof Error) {
          failureMessage = revokeChannelSharingRequestFailure.message;
        }
        // create an AlertInfo object and dispatch a request to set the AlertInfo into Redux state
        alertInfo = new AlertInfo(true, enumAlertType.Error, failureMessage);
        dispatch(alertInfoChange(alertInfo));
      }
    }

  }

  return (
    <>
      <StyledBoxForPaddingAtopPage />
      <GenericPageContainer
        maxWidth="xl"
        showBackButton={true}
        pageTitle={PageAndViewTitleStringAssets.pageTitle_ChannelSharing}
        pageSubtitle={channel !== undefined ? `(${channel.name})` : undefined}
        showCategoriesOption={true}
      // onClickEditCategories={handleClickEditChannelCategories}
      // onCloseAlert={resetDeleteChannelStatus}
      // fixedAlertInfo={alertInfo}
      >

        <StyledBoxForPaddingAboveDataViewControls />

        {/* Present the Data View Controls ("Add new" button & "View Type" toggle button) */}
        <DataViewControls
          addNewItemButtonText={ControlsStringAssets.channelSharingNew}
          onClickAddNewItemButton={() => navigate(`/channelSharingRequest/new/${channelId}`)}
        >
        </DataViewControls>

        <StyledBoxForHorizontalDivider />

        {/* <Button onClick={() => setShowActivityIndicator(true)} >
          Show Activity Indicator
        </Button> */}


        {/* Container for embedded data view */}
        <StyledContainerForDataView>

          <StyledAccordion key={'Pending'}>
            <StyledAccordionSummaryForPendingUsers expandIcon={<ExpandMoreIcon sx={{ color: theme.palette.primary.dark }} />} >
              <StyledGridForAccordionSummaryContent>
                <StyledGridForAccordionSummaryHeader>
                  <StyledTypographyForAccordionSummaryHeader>
                    {`Pending Shared Users (${channelSharingRequestTrackers.length})`}
                  </StyledTypographyForAccordionSummaryHeader>
                </StyledGridForAccordionSummaryHeader>
                <StyledGridForAccordionSummaryDescription>
                  <StyledTypographyForAccordionSummaryDescription>
                    {'List of users who have been invited to share the channel, but who have not yet accepted'}
                  </StyledTypographyForAccordionSummaryDescription>
                </StyledGridForAccordionSummaryDescription>
              </StyledGridForAccordionSummaryContent>
            </StyledAccordionSummaryForPendingUsers>
            <StyledAccordionDetails>
              {
                currentUserChannelSharingViewModel &&
                (
                  //  If there are no sharing requests for this channel, display a message 
                  (channelSharingRequestTrackers.length === 0)
                    ?
                    `${MessagesStringAssets.channelSharing_NoPendingUsersForSharingChannel}`
                    :
                    (
                      // If the current view is CardGridView, display CardGridView
                      (collectionViewType === enumCollectionViewType.CardsGridView)
                        ?
                        // <ChannelSharingRequestsCardGridView channelSharingRequests={channelSharingRequestTrackers} channel={channel} onRevoke={handleRevokeChannelSharingRequest} />
                        <ChannelSharingRequestsCardGridView channelSharingRequests={channelSharingRequestTrackers} currentUserChannelSharingViewModel={currentUserChannelSharingViewModel} onRevoke={handleRevokeChannelSharingRequest} />
                        :
                        // Otherwise, display ListView
                        <ChannelSharingRequestsListView channelSharingRequests={channelSharingRequestTrackers} currentUserChannelSharingViewModel={currentUserChannelSharingViewModel} onRevoke={handleRevokeChannelSharingRequest} />
                    )
                )
              }
            </StyledAccordionDetails>
          </StyledAccordion>

          <StyledAccordion key={'Current'}>
            <StyledAccordionSummaryForCurrentUsers expandIcon={<ExpandMoreIcon sx={{ color: theme.palette.primary.light }} />} >
              <StyledGridForAccordionSummaryContent>
                <StyledGridForAccordionSummaryHeader>
                  <StyledTypographyForAccordionSummaryHeader>
                    {`Current Shared Users (${usersSharingChannel.length})`}
                  </StyledTypographyForAccordionSummaryHeader>
                </StyledGridForAccordionSummaryHeader>
                <StyledGridForAccordionSummaryDescription>
                  <StyledTypographyForAccordionSummaryDescription>
                    {'List of users with whom the channel is currently shared'}
                  </StyledTypographyForAccordionSummaryDescription>
                </StyledGridForAccordionSummaryDescription>
              </StyledGridForAccordionSummaryContent>
            </StyledAccordionSummaryForCurrentUsers>
            <StyledAccordionDetails>
              {
                currentUserChannelSharingViewModel &&
                (
                  //  If there are no users sharing this channel, display a message 
                  (usersSharingChannel.length === 0)
                    ?
                    `${MessagesStringAssets.channelSharing_NoCurrentUsersharingChannel}`
                    :
                    // if we've completed the work in preparing the Channel Sharing User View Models (the array has the same # of
                    // entries as the userIdAndChannelPermissionMap), then we can display the sharing users in either card or list views
                    // (channelSharingUserViewModels.length === userIdAndChannelPermissionMap.size) &&
                    (channelSharingUserViewModels.length === currentUserChannelSharingViewModel.channel.userSharingPermissions.length) &&
                    (
                      // If the current view is CardGridView, display CardGridView
                      (collectionViewType === enumCollectionViewType.CardsGridView)
                        ?
                        <ChannelSharingUsersCardGridView users={channelSharingUserViewModels} currentUserChannelSharingViewModel={currentUserChannelSharingViewModel} onRevoke={handleRevokeUserFromChannelSharing} />
                        :
                        // Otherwise, display ListView
                        <ChannelSharingUsersListView users={channelSharingUserViewModels} currentUserChannelSharingViewModel={currentUserChannelSharingViewModel} onRevoke={handleRevokeUserFromChannelSharing} />
                    )
                )
              }
            </StyledAccordionDetails>
          </StyledAccordion>
        </StyledContainerForDataView>

        {/* Revoke User from Sharing Channel Confirmation Dialog */}
        <TwoButtonAcceptanceDialog
          showDialog={showRevokeUserSharingConfirmationDlg}
          headerText={MessagesStringAssets.channelSharing_RevokeUserConfirmationHeader}
          bodyText={MessagesStringAssets.channelSharing_RevokeUserConfirmation}
          acceptanceButtonText={ControlsStringAssets.confirmButtonText}
          nonAcceptanceButtonText={ControlsStringAssets.cancelButtonText}
          onAcceptance={handleRevokeUserChannelSharingConfirmed}
          onNonAcceptance={handleRevokeUserChannelSharingCanceled}
        />

        {/* Revoke Channel Sharing Request Confirmation Dialog */}
        <TwoButtonAcceptanceDialog
          showDialog={showRevokeChannelSharingRequestConfirmationDlg}
          headerText={MessagesStringAssets.channelSharing_RevokeChannelSharingRequestConfirmationHeader}
          bodyText={MessagesStringAssets.channelSharing_RevokeChannelSharingRequestConfirmation}
          acceptanceButtonText={ControlsStringAssets.confirmButtonText}
          nonAcceptanceButtonText={ControlsStringAssets.cancelButtonText}
          onAcceptance={handleRevokeChannelSharingRequestConfirmed}
          onNonAcceptance={handleRevokeChannelSharingRequestCanceled}
        />

        {activityIndicatorData.showActivityIndicator &&
          <ActivityIndicatorDialog activityLabel={activityIndicatorData.activityIndicatorMessage} />
        }

      </GenericPageContainer>
    </>
  );
}

export default ChannelSharingPage;
