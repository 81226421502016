import React, { PropsWithChildren } from 'react';
import { Box, BoxProps, Container, ContainerProps } from '@mui/material';
import { ViewHeader } from '../../headerAndFooter/ViewHeader/ViewHeader';
import DataViewControls from '../../controls/DataViewControls/DataViewControls';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/styles';


/*** Using the Material UI Emotion Styling library, declare 'styled' instances for each area/object. 
 *** NOTE: These must be declared outside of the React Functional Component to ensure that the styled 
 *** objects will be properly rendered within the DOM. 
 ***/

// a styled Box (equivalent to a <div>), providing an area for displaying the view title
const StyledBoxForViewTitleContainer = styled((props: BoxProps) => (
  <Box
    {...props}
  />
))(({ theme }) => ({
  marginBottom: theme.spacing(1)
}));

// a styled Box for a Horizontal Divider (a CSS Horizontal Rule <hr/>)
const StyledBoxForHorizontalDivider = styled((props: BoxProps) => (
  <Box
    component='hr'
    {...props}
  />
))(({ theme }) => ({
  width: "100%",
  borderTop: `1px solid ${theme.palette.primary.light}`,
}));

// a styled Container for displaying the View Data
const StyledContainerForViewData = styled((props: ContainerProps) => (
  <Container
    {...props}
  />
))(({ theme }) => ({
  marginTop: theme.spacing(0),
  padding: theme.spacing(0),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}));

export interface IGenericToggleViewContainerProps extends PropsWithChildren<unknown> {
  /**
   * @property {false | "sm" | "xs" | "md" | "lg" | "xl" | undefined} maxWidth (optional) "Maximum Width" specification for the view. If not provided, defaults to "am"
   */
  maxWidth?: false | "sm" | "xs" | "md" | "lg" | "xl" | undefined;
  /**
   * @property {string} viewTitle Title to be displayed for the view
   */
  viewTitle: string;
  /**
   * @property {string} addNewButtonText Text to be displayed in the "Add New" button
   */
  addNewButtonText?: string;
  /**
   * @property {string} urlForAddNewButtonNavigation The navigation URL to specify when user clicks on the "Add New" button
   */
  urlForAddNewButtonNavigation?: string;
}

// const GenericToggleViewContainer: React.FC<IGenericToggleViewContainerProps> = ({ children, maxWidth, viewTitle, addNewButtonText, urlForAddNewButtonNavigation, ...otherProps }) => {
const GenericToggleViewContainer: React.FC<IGenericToggleViewContainerProps> = (props) => {

  const { children, maxWidth, viewTitle, addNewButtonText, urlForAddNewButtonNavigation, ...otherProps } = props;

  const navigate = useNavigate();


  return (
    <>
      {/* display the View Header */}
      <StyledBoxForViewTitleContainer>
        <ViewHeader viewTitle={viewTitle} />
      </StyledBoxForViewTitleContainer>

      {/* Present the Data View Controls ("Add new" button & "View Type" toggle button) */}
      {/* Within the DataViewControls, if 'addNewButtonText' AND 'urlForAddNewButtonNavigation' have been provided, supply in
          their appropriate data parameters */}
      <DataViewControls
        addNewItemButtonText={addNewButtonText && addNewButtonText}
        onClickAddNewItemButton={() => urlForAddNewButtonNavigation ? navigate(urlForAddNewButtonNavigation) : {}}
      >
      </DataViewControls>

      <StyledBoxForHorizontalDivider />

      {/* pass 'props' to Container */}
      <StyledContainerForViewData maxWidth={props.maxWidth === undefined ? undefined : props.maxWidth ?? "sm"} {...otherProps}>
        {/* if there are any children to pass on (ie, children !== undefined), pass them on; otherwise, pass an empty React fragment */}
        <>
          {children && children}
        </>
      </StyledContainerForViewData>
    </>
  )
}

export default GenericToggleViewContainer;