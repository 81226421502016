import { take, put } from 'redux-saga/effects';
import { deleteUserRedemptionCodeRequest } from '../../../firebaseServices/dataServices/dataServiceActions/userRedemptionCodeRequestActions';
import { userRedemptionCodeRequestDeleteRequest, userRedemptionCodeRequestDeleteStatusChange } from '../../slices/userRedemptionCodeRequest/userRedemptionCodeRequestDeleteStatusSlice';
import { userRedemptionCodeRequestDeleteFailureChange } from '../../slices/userRedemptionCodeRequest/userRedemptionCodeRequestDeleteFailureSlice';
import { enumWorkflowState } from '../../../dataObjects/enums';

/**
 * @function deleteUserRedemptionCodeRequestRequestedSaga A saga workflow pertaining to a user having requested to delete a userRedemptionCodeRequest.
 */
export function* deleteUserRedemptionCodeRequestRequestedSaga() {
  // console.info('(deleteUserRedemptionCodeRequestRequestedSaga) entered deleteUserRedemptionCodeRequestRequestedSaga');

  // loop to keep the saga running throughout the life of the application
  while (true) {
    try {
      // wait on the userRedemptionCodeRequestDeleteRequest action to be dispatched
      const userRedemptionCodeRequestDeleteRequestData = yield take(userRedemptionCodeRequestDeleteRequest);
      const { payload: userRedemptionCodeRequest } = userRedemptionCodeRequestDeleteRequestData;

      // make a request to delete the userRedemptionCodeRequest
      yield deleteUserRedemptionCodeRequest(userRedemptionCodeRequest)

      // dispatch an action to indicate that the delete operation was successful
      yield put(userRedemptionCodeRequestDeleteStatusChange(enumWorkflowState.Success));
    } catch (error: any) {
      // dispatch an action to indicate that the delete operation failed
      yield put(userRedemptionCodeRequestDeleteStatusChange(enumWorkflowState.Failure));
      yield put(userRedemptionCodeRequestDeleteFailureChange(error));
    }
  }
}
