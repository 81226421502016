import React, { PropsWithChildren } from 'react';
import { Fab as FloatingActionButton, FabProps } from '@mui/material';
import { Edit as EditIcon } from '@mui/icons-material';
import { styled } from '@mui/styles';


/*** Using the Material UI Emotion Styling library, declare 'styled' instances for each area/object. 
 *** NOTE: These must be declared outside of the React Functional Component to ensure that the styled 
 *** objects will be properly rendered within the DOM. 
 ***/

// a styled FloatingActionButton object to represent the button
const StyledFloatingActionButton = styled((props: FabProps) => (
  <FloatingActionButton
    {...props}
  />
))(({ theme }) => ({
  // MuiFab theming isn't responding properly, so directly theme the backgroundColor upon hover
  '&:hover': {
    background: theme.floatingActionButton.hover.background,
    border: theme.floatingActionButton.hover.border,
    color: theme.floatingActionButton.hover.color,
  },
  [theme.breakpoints.up('xs')]: {
    transform: 'scale(.70) translate(-10%)',
  },
  [theme.breakpoints.up('sm')]: {
    transform: 'scale(.70) translate(-10%)',
  },
  [theme.breakpoints.up('md')]: {
    transform: 'scale(0.75) translate(-5%)',
  },
  [theme.breakpoints.up('lg')]: {
    transform: 'scale(.80)',
  },
  [theme.breakpoints.up('xl')]: {
    transform: 'scale(0.80)',
  },
}));

/**
 * @interface IEditCategoriesButtonProps declares any input properties required for this component.
 */
export interface IEditCategoriesButtonProps extends PropsWithChildren<unknown> {
  // there are no input properties for this component at this time. Properties will be added to his interface 
  // if input properties for this component are added.
  onClick: () => void,
}

/**
 * @function EditCategoriesButton is a React functional component representing an "edit categories" button that will appear on a page and its only 
 *           responsibility is to call a function that has been supplied as an input property, if pressed.
 *           (NOTE: Appearance styling for the Fab (FloatingActionButton), but not sizing, is addressed in the global theme.)
 * @param {} props are the properties passed into the component (currently no input properties).
 */
export const EditCategoriesButton: React.FC<IEditCategoriesButtonProps> = (props: IEditCategoriesButtonProps) => {
  EditCategoriesButton.displayName = 'Edit Categories Button';

  const { onClick: onClickHandler } = props;

  return (
    <>
      <StyledFloatingActionButton onClick={onClickHandler}>
        <EditIcon />
      </StyledFloatingActionButton>
    </>
  )
}
