import React, { PropsWithChildren } from 'react';
import { Box, BoxProps } from '@mui/material';
import { ControlsStringAssets } from '../../../../assets/stringAssets';
import AcceptanceButton from '../../buttons/AcceptanceButton/AcceptanceButton';
import { styled } from '@mui/styles';

/*** Using the Material UI Emotion Styling library, declare 'styled' instances for each area/object. 
 *** NOTE: These must be declared outside of the React Functional Component to ensure that the styled 
 *** objects will be properly rendered within the DOM. 
 ***/

// a styled Box (equivalent to a <div>), for the Buttons Bar Area
const StyledBoxForButtonsBarArea = styled((props: BoxProps) => (
  <Box
    {...props}
  />
))(({ theme }) => ({
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
}));

export interface IOneButtonDialogActionBarProps extends PropsWithChildren<unknown> {
  // if provided, the label to display for the button
  buttonLabel?: string;
  // the function to be called upon clicking the button
  onButtonClick: () => void;
}

/**
 * @function OneButtonDialogActionBar A React component that provides an "Action Button" bar with a single action button, 
 *           typically an "Ok" button.
 * @param {IOneButtonDialogActionBarProps} props Input properties
 */
const OneButtonDialogActionBar: React.FC<IOneButtonDialogActionBarProps> = (props: IOneButtonDialogActionBarProps) => {

  const { buttonLabel, onButtonClick } = props;

  return (
    <>
      {/* display the action buttons (Acceptance & NonAcceptance) */}
      <StyledBoxForButtonsBarArea>
        <AcceptanceButton onAcceptance={onButtonClick}>
          {buttonLabel ?? ControlsStringAssets.confirmButtonText}
        </AcceptanceButton>
      </StyledBoxForButtonsBarArea>
    </>
  );
}

export default OneButtonDialogActionBar;