import React, { PropsWithChildren, useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Box, BoxProps, Checkbox, FormControlLabel, Grid, LinearProgress, LinearProgressProps, Typography, TypographyProps } from '@mui/material';
import { ControlsStringAssets, MessagesStringAssets } from '../../../assets/stringAssets';
import FormWithActionBar from '../FormWithActionBar/FormWithActionBar';
import { ICurrentUserContextData, useCurrentUserContext } from '../../providersAndContexts/currentUser';
import { styled } from '@mui/styles';

/*** Using the Material UI Emotion Styling library, declare 'styled' instances for each area/object. 
 *** NOTE: These must be declared outside of the React Functional Component to ensure that the styled 
 *** objects will be properly rendered within the DOM. 
 ***/

// a styled Box (equivalent to a <div>), providing an area to display the overall Progress Bar Area
const StyledBoxForProgressBarArea = styled((props: BoxProps) => (
  <Box
    {...props}
  />
))(({ theme }) => ({
  marginTop: theme.spacing(2),
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  width: "100%",
}));

// a styled LinearProgress for the Progress Bar
const StyledLinearProgressForProgressBar = styled((props: LinearProgressProps) => (
  <LinearProgress
    {...props}
  />
))(({ theme }) => ({
  width: "100%",
}));

// a styled Box (equivalent to a <div>), providing an area to display the Progress Label Area
const StyledBoxForProgressLabelArea = styled((props: BoxProps) => (
  <Box
    {...props}
  />
))(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  width: "100%",
}));

// a styled Typography for the Progress Label
const StyledTypographyForProgressLabel = styled((props: TypographyProps) => (
  <Typography
    variant="caption"
    {...props}
  />
))(({ theme }) => ({
}));

/**
 * @interface IBackupDataFilesFormProps Properties for the ExpungUserForm component
 */
export interface IBackupDataFilesFormProps extends PropsWithChildren<unknown> {
  /**
   * @property {() => Promise<void>} onSubmit Method to call for submitting the form 
   */
  onSubmit: () => Promise<void>,
}

const BackupDataFilesForm: React.FC<IBackupDataFilesFormProps> = (props: IBackupDataFilesFormProps) => {
  BackupDataFilesForm.displayName = 'Expunge User Form';

  // whether to display console logs (displayConsoleLogs && console.log statements)
  const displayConsoleLogs: boolean = false;

  // get required arguments from props
  const { onSubmit } = props;

  // set up details for ReactHookForm
  const { control, formState, formState: { errors }, getValues, handleSubmit, register, trigger } = useForm({
    defaultValues: {
    },
    // mode: "onBlur",
    mode: "all",
  });

  // whether user has selected to backup Transactional data in Firestore
  const [backupTransactionalData, setBackupTransactionalData] = useState<boolean>(true);

  // whether user has selected to backup Digital Media data in Cloud Storage
  const [backupDigitalMediaData, setBackupDigitalMediaData] = useState<boolean>(true);

  // whether user has selected to backup Search Metadata in the SQL Database
  const [backupSearchMetaData, setBackupSearchMetadata] = useState<boolean>(true);

  // use a custom hook to get the Current User information from a CurrentUserContext/Provider higher up in the component tree
  const { currentUser }: ICurrentUserContextData = useCurrentUserContext();
  displayConsoleLogs && console.log(`%c BackupDataFilesForm. currentUser: \n${JSON.stringify(currentUser)}`, 'background: #600; color: #fff');

  // for testing whether the form is in a valid state
  const { isValid } = formState;

  const [formIsValid, setFormIsValid] = useState<boolean>(false);

  useEffect(() => {
    setFormIsValid(isValid);
    // console.info(`UserSettings.useEffect: formIsValid being set to ${isValid}`);
  }, [isValid]);

  // whether a request is currently being processed
  const [requestBeingProcessed, setRequestBeingProcessed] = useState<boolean>(false);

  // label for a request currently being processed
  const [requestBeingProcessedLabel, setRequestBeingProcessedLabel] = useState<string>('');

  useEffect(() => {
    // if at least one of the checkboxes has been checked, indicate that the form is valid
    setFormIsValid(backupTransactionalData || backupDigitalMediaData || backupSearchMetaData);
  }, [backupTransactionalData, backupDigitalMediaData, backupSearchMetaData]);

  // handles a save/submit request from the form
  const handleSaveSubmit = async () => {

    // set flag and label to display a progress indicator
    setRequestBeingProcessed(true);
    setRequestBeingProcessedLabel(MessagesStringAssets.backup_RequestBeingProcessed);

    // call the onSubmit handler passed in
    await onSubmit();

    // reset the 'Request Being Processed' flag and label
    resetRequestBeingProcessed();
  }

  function resetRequestBeingProcessed() {
    setRequestBeingProcessed(false);
    setRequestBeingProcessedLabel('');
  }

  // present the form
  return (
    <>
      <FormWithActionBar
        onSubmit={handleSubmit(handleSaveSubmit)}
        formIsValid={formIsValid}
        submitButtonLabel='Perform Backup'
      >
        {/* if there's an action in progress, display the progress bar area */}
        {requestBeingProcessed &&
          <StyledBoxForProgressBarArea>
            <StyledLinearProgressForProgressBar />
            <StyledBoxForProgressLabelArea>
              {/* if an action progress label has been provided, display it */}
              {requestBeingProcessedLabel &&
                <StyledTypographyForProgressLabel>{requestBeingProcessedLabel}</StyledTypographyForProgressLabel>
              }
            </StyledBoxForProgressLabelArea>
          </StyledBoxForProgressBarArea>
        }

        <Grid container >
          <Grid item key='backupTransactionalData' xs={12} >
            {/* We use a FormControlLabel to bundle a Material-UI Checkbox with a label */}
            <FormControlLabel
              name="backupTransactionalData"
              control={
                <Checkbox
                  name="backupTransactionalData"
                  color='primary'
                  value={backupTransactionalData}
                  checked={backupTransactionalData}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setBackupTransactionalData(event.currentTarget.checked);
                  }}
                />
              }
              label={ControlsStringAssets.backupTransactionalDataLabel}
            />
          </Grid>
          <Grid item key='backupDigitalMediaData' xs={12} >
            {/* We use a FormControlLabel to bundle a Material-UI Checkbox with a label */}
            <FormControlLabel
              name="backupDigitalMediaData"
              control={
                <Checkbox
                  name="backupDigitalMediaData"
                  color='primary'
                  value={backupDigitalMediaData}
                  checked={backupDigitalMediaData}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setBackupDigitalMediaData(event.currentTarget.checked);
                  }}
                />
              }
              label={ControlsStringAssets.backupDigitalMediaDataLabel}
            />
          </Grid>
          <Grid item key='backupSearchMetadata' xs={12} >
            {/* We use a FormControlLabel to bundle a Material-UI Checkbox with a label */}
            <FormControlLabel
              name="backupSearchMetadata"
              control={
                <Checkbox
                  name="backupSearchMetadata"
                  color='primary'
                  value={backupSearchMetaData}
                  checked={backupSearchMetaData}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setBackupSearchMetadata(event.currentTarget.checked);
                  }}
                />
              }
              label={ControlsStringAssets.backupSearchMetadataLabel}
            />
          </Grid>
        </Grid>

      </FormWithActionBar>
    </>

  );
}

export default BackupDataFilesForm;