import React, { PropsWithChildren, useCallback, useEffect, useState } from 'react';
import { DocumentData, QuerySnapshot } from 'firebase/firestore';
import _ from 'lodash';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, BoxProps, Container, ContainerProps } from '@mui/material';
import { ControlsStringAssets, MessagesStringAssets, PageAndViewTitleStringAssets } from '../../../../assets/stringAssets';
import GenericPageContainer from '../../GenericPageContainer/GenericPageContainer';
import { enumAlertType } from '../../../enums';
import { ITopic, ITopicAsJson, Topic } from '../../../../dataObjects/models/topics/Topic';
import { typeUniqueId } from '../../../../dataObjects/types';
import { getTopicsForParentId_onSnapshot } from '../../../../firebaseServices/dataServices/dataServiceActions/topicActions/getTopicsForParentId_onSnapshot';
import { IStoreState } from '../../../../uiMiddleware-redux/store/IStoreState';
import { enumBeaconType, enumCollectionViewType, enumPersistableObjectType, enumWorkflowState } from '../../../../dataObjects/enums';
import { JsonConverter } from '../../../../dataObjects/utilities/JsonConverter';
import TopicsListView from '../../../views/topics/TopicsListView/TopicsListView';
import { TopicsCardGridView } from '../../../views/topics/TopicsCardGridView/TopicsCardGridView';
import TwoButtonAcceptanceDialog from '../../../dialogs/TwoButtonAcceptanceDialog/TwoButtonAcceptanceDialog';
import { Beacon } from '../../../../dataObjects/models/alerts/Beacon';
import { useReduxWorkflowState } from '../../../customHooks';
import ActivityIndicatorDialog from '../../../dialogs/ActivityIndicatorDialog/ActivityIndicatorDialog';
import { IActivityIndicatorData } from '../../../dialogs/ActivityIndicatorData';
import { AlertInfo, IAlertInfo } from '../../../../dataObjects/models/alerts/AlertInfo';
import DataViewControls from '../../../controls/DataViewControls/DataViewControls';
import { useGetCollectionViewType } from '../../../customHooks/useGetCollectionViewType';
import { Channel, IChannel } from '../../../../dataObjects/models/channels/Channel';
import { styled } from '@mui/styles';
import { ICurrentUserContextData, useCurrentUserContext } from '../../../providersAndContexts/currentUser';
import { IUserCategoriesAndChannelsContextData, useUserCategoriesAndChannelsContext } from '../../../providersAndContexts/userCategoriesAndChannels';
import { IUser } from '../../../../dataObjects/models/users/User';
import { IUserAccessPermissionsForObject, UserAccessPermissionsForObject } from '../../../../dataObjects/models/collaboration/ObjectUserPermissions';
import { enumCategoriesAndChannelsDataPreparationStatus } from '../../../enums';
import { useAppDispatch } from '../../../../uiMiddleware-redux/store/configureStore';
import { clearCurrentTopic } from "../../../../uiMiddleware-redux/slices/topic/currentTopicSlice";
import { beaconChange } from '../../../../uiMiddleware-redux/slices/beacon/beaconSlice';
import { alertInfoChange } from '../../../../uiMiddleware-redux/slices/alertInfo/alertInfoSlice';
import { topicDeleteRequest, topicDeleteStatusChange } from '../../../../uiMiddleware-redux/slices/topic/topicDeleteStatusSlice';


/*** Using the Material UI Emotion Styling library, declare 'styled' instances for each area/object. 
 *** NOTE: These must be declared outside of the React Functional Component to ensure that the styled 
 *** objects will be properly rendered within the DOM. 
 ***/

// a styled Box (equivalent to a <div>), providing an area of padding at the top of the page
const StyledBoxForPaddingAtopPage = styled((props: BoxProps) => (
  <Box
    {...props}
  />
))(({ theme }) => ({
  marginTop: theme.spacing(1),
}));

// a styled Box for a Horizontal Divider (a CSS Horizontal Rule <hr/>)
const StyledBoxForHorizontalDivider = styled((props: BoxProps) => (
  <Box
    component='hr'
    {...props}
  />
))(({ theme }) => ({
  width: "100%",
  borderTop: `1px solid ${theme.palette.primary.light}`,
}));

// a styled Container for Data View
const StyledContainerForDataView = styled((props: ContainerProps) => (
  <Container
    disableGutters
    maxWidth={false}
    {...props}
  />
))(({ theme }) => ({
  flexGrow: 1,
  paddingLeft: 0,
  paddingRight: 0,
}));

export interface ITopicsPageProps extends PropsWithChildren<unknown> {
  parentId?: typeUniqueId
}

const TopicsPage: React.FC<ITopicsPageProps> = (props: ITopicsPageProps) => {

  TopicsPage.displayName = 'Topics Page';

  // whether to display console logs (displayConsoleLogs && console.log statements)
  const displayConsoleLogs: boolean = false;

  const navigate = useNavigate();
  const params = useParams();

  const parentId = params.parentId; // from the path '/:parentId/topics

  // call custom hook to get the current view type, so the value can be used to initialize the View Type state variable
  const collectionViewType: enumCollectionViewType = useGetCollectionViewType();

  // the Topics associcated with the current user account
  const [topics, setTopics] = useState<Array<ITopic>>([]);
  // whether data is currently loading
  const [dataLoading, setDataLoading] = useState<boolean>(true);
  // if user is deleting a Topic, the Topic to delete
  const [topicToDelete, setTopicToDelete] = useState<ITopic | undefined>(undefined);
  // whether to show the Delete Confirmation dialog
  const [showDeleteConfirmationDlg, setShowDeleteConfirmationDlg] = useState<boolean>(false);

  // to assess the status of data preparation for user categories and channels
  const [userCategoriesAndChannelsDataPrepStatus, SetUserCategoriesAndChannelsDataPrepStatus] = useState<enumCategoriesAndChannelsDataPreparationStatus>(enumCategoriesAndChannelsDataPreparationStatus.None);

  // for dispatching redux actions
  const dispatch = useAppDispatch();

  // use a custom hook to get the Current User information from a CurrentUserContext/Provider higher up in the component tree
  const currentUserContextData: ICurrentUserContextData = useCurrentUserContext();
  displayConsoleLogs && console.log(`%c TopicsPage. currentUser: \n${JSON.stringify(currentUserContextData)}`, 'background: #006; color: #fff');
  const currentUser: IUser | undefined = currentUserContextData.currentUser;
  const userId: typeUniqueId | undefined = currentUser?.id;

  // get UserCategoriesAndChannelsContextData from the UserCategoriesAndChannelsProvider using a custom hook (useUserCategoriesAndChannelsContext)
  const userCategoriesAndChannelsContext: IUserCategoriesAndChannelsContextData = useUserCategoriesAndChannelsContext();
  const currentChannel: IChannel | undefined = userCategoriesAndChannelsContext.state.currentChannel;
  displayConsoleLogs && console.log(`%c TopicsPage. currentChannel: \n${JSON.stringify(currentChannel)}`, 'background: #006; color: #fff');
  if (userCategoriesAndChannelsDataPrepStatus !== userCategoriesAndChannelsContext.state.status) {
    SetUserCategoriesAndChannelsDataPrepStatus(userCategoriesAndChannelsContext.state.status);
  }

  /**
   * @function resetDeleteTopicStatus Resets the Delete status in Redux state
   */
  const resetDeleteTopicStatus: () => void = useCallback(() => {
    dispatch(topicDeleteStatusChange(null));
  }, [dispatch])


  // a useEffect hook for to evaluate changes to the userCategoriesAndChannelsDataPrepStatus state variable
  useEffect(() => {
    // If there is no currentChannel specified in the Categories & Channels context, inform the context
    // to resolve the current channel from the Id of the Channel that has been passed to this component as the parentId.
    // (The currentChannel in the context could be undefined if the browser was refreshed after landing on this page.)
    if (userCategoriesAndChannelsDataPrepStatus === enumCategoriesAndChannelsDataPreparationStatus.DataPreparationComplete) {
      const localCurrentChannel: IChannel | undefined = userCategoriesAndChannelsContext.state.currentChannel;
      if (((localCurrentChannel === undefined) || Channel.coreChannelIdFromChannelId(localCurrentChannel.id) !== parentId) && 
        (userCategoriesAndChannelsContext.actions.resolveCurrentChannel !== undefined) && (parentId !== undefined)) {
        userCategoriesAndChannelsContext.actions.resolveCurrentChannel(parentId, enumPersistableObjectType.Channel);
      }
    }
  }, [parentId, userCategoriesAndChannelsDataPrepStatus]);

  // set up a useEffect() hook to get the topics for the given parentId and get data updates via onSnapshot
  useEffect(() => {
    // Declare an 'unsubscribe' variable that will hold the unsubscribe callback from a firestore onSnapshot() request.
    // We initialize it to a function that does nothing, so if an onSnapshot() is never requested, we can still call unsubscribe() during cleanup. 
    // After an onShapshot() request, the 'unsubscribe' variable will be set to a callback function issued by firestore.
    let unsubscribeCallback: () => void = () => { };

    // clear out the current topic in Redux state
    // dispatch(setCurrentTopic(undefined));
    dispatch(clearCurrentTopic());

    if (parentId) {
      // subscribe to onShapshot updates, providing realtime updates to the data, and capture the 'unsubscribe' callback method provided by firestore
      // displayConsoleLogs && console.log(`TopicsPage: before initiating snapshot, unsubscribeCallback: ${unsubscribeCallback}`);
      getTopicsForParentId_onSnapshot(parentId, onSnapshotCallback).then((unsubscribe: () => void) => {
        unsubscribeCallback = unsubscribe;
        // displayConsoleLogs && console.log(`TopicsPage: after initiating snapshot, unsubscribeCallback: ${unsubscribeCallback}`);
      });

      // set indicator that data is being loaded
      setDataLoading(true);
    }

    // perform cleanup when the component unmounts
    return () => {
      // if the user is still logged in...
      // displayConsoleLogs && console.log(`TopicsPage: unmounting (useEffect return). userId: ${userId}`);
      // displayConsoleLogs && console.log(`TopicsPage: unmounting (useEffect return). unsubscribeCallback: ${unsubscribeCallback}`);
      if (userId) {
        // Call the unsubscribe() callback to unsubscribe from the onSnapshot . If it was never 
        // displayConsoleLogs && console.log(`TopicsPage. Ready to call 'unsubscribeCallback'`);
        unsubscribeCallback();
        // displayConsoleLogs && console.log(`TopicsPage. Completed call to 'unsubscribeCallback'`);

        // reset the DeleteTopicStatus in Redux state to return to a clean slate
        resetDeleteTopicStatus();
      }
    }
  }, [dispatch, parentId, userId, resetDeleteTopicStatus])

  // use a custom hook to evaluate the redux state for the deleteTopic workflow and any deleteTopic error
  const { workflowState: deleteTopicStatus, errorState: deleteTopicFailure } = useReduxWorkflowState((state: IStoreState) => state.deleteTopicStatus, (state: IStoreState) => state.deleteTopicFailure);

  /**
   * @function onSnapshotCallback A callback method to receive firestore data snapshots for dynamic data updates
   * @param {QuerySnapshot<DocumentData>} snapshot A snapshot of data from firestore
   */
  function onSnapshotCallback(snapshot: QuerySnapshot<DocumentData>): void {
    // displayConsoleLogs && console.log(`TopicsPage:onSnapshotCallback(). Entered callback function.`);
    // set state variable indicating that dataLoading is no longer taking place
    setDataLoading(false);
    const topicDataAsJson: Array<ITopicAsJson> = [];
    // displayConsoleLogs && console.log(`TopicsPage:onSnapshotCallback(). Ready to get doc data.`);
    snapshot.forEach(doc => topicDataAsJson.push({ ...doc.data(), id: doc.id } as ITopicAsJson));
    // displayConsoleLogs && console.log(`TopicsPage:onSnapshotCallback(). Completed getting doc data.`);

    // create an array of Topic objects from the JSON data
    let topicsData = JsonConverter.arrayFromJSONArray(Topic, topicDataAsJson);

    // use lodash to sort the array by 'name' in ascending order
    // topicsData = _.orderBy(topicsData, ['name'], ['asc']);
    topicsData = _.orderBy(topicsData, [topic => topic.name.toUpperCase()], ['asc']);

    setTopics(topicsData);
  }

  /**
   * @method handleTopicDelete Handles requests to delete a Channel
   * @param topic The Topic to be deleted
   */
  async function handleTopicDelete(topic: ITopic): Promise<void> {
    // set the topic to be deleted into local state
    setTopicToDelete(topic);

    // set flag to show the delete confirmation dialog
    setShowDeleteConfirmationDlg(true);
  }

  function handleTopicDeleteConfirmed(): void {
    // using the topic object to be deleted from the local state, dispatch an action to delete the topic object
    if (topicToDelete !== undefined) {
      dispatch(topicDeleteRequest(topicToDelete));
    }

    // call method to reset the topic delete local state details
    topicDeleteReset();
  }

  function handleTopicDeleteCanceled(): void {
    // call method to reset the topic delete local state details
    topicDeleteReset();
  }

  function topicDeleteReset() {
    // reset the topic to be deleted to 'undefined' in local state, since we're done with the object
    setTopicToDelete(undefined);

    // set flag to hide the delete confirmation dialog
    setShowDeleteConfirmationDlg(false);
  }


  // data to indicate whether an activity indicator should be displayed and, if so, the message
  let activityIndicatorData: IActivityIndicatorData = {
    showActivityIndicator: false,
    activityIndicatorMessage: ''
  }

  // data to indicate whether an alert should be displayed
  let alertInfo: IAlertInfo | undefined = undefined;

  // indicates whether the object has been successfully deleted
  const [successfullyDeletedObject, setSuccessfullyDeletedObject] = useState<boolean>(false);

  // define an effect that will process whenever the successfullyDeletedObject state changes
  useEffect(() => {
    if (successfullyDeletedObject) {
      // dispatch an action to reset the deleteTopicStatus
      resetDeleteTopicStatus();

      // notify user via a Beacon notification that the topic has been deleted
      const beacon: Beacon = new Beacon(undefined, enumBeaconType.Success, MessagesStringAssets.topic_DeleteConfirmationHeader, MessagesStringAssets.topic_DeleteSuccess);
      dispatch(beaconChange(beacon));

      // reset the successfullyDeletedObject flag
      setSuccessfullyDeletedObject(false);
    }
  }, [successfullyDeletedObject, dispatch, resetDeleteTopicStatus]);

  if (dataLoading) {
    // display an activity indicator
    activityIndicatorData.showActivityIndicator = true;
    activityIndicatorData.activityIndicatorMessage = MessagesStringAssets.topic_Loading;
  } else {
    // if the workflow is in the midst of performing deletion of a topic, prepare an alert message fragment to display progress message(s)
    if (deleteTopicStatus !== undefined && deleteTopicStatus !== null) {
      if (deleteTopicStatus === enumWorkflowState.Requested || deleteTopicStatus === enumWorkflowState.InProgress) {
        // display an activity indicator
        activityIndicatorData.showActivityIndicator = true;
        activityIndicatorData.activityIndicatorMessage = MessagesStringAssets.topic_DeleteRequested;
      } else if (deleteTopicStatus === enumWorkflowState.Success) {
        // if the successfullyDeletedObject is curently in a 'false' state, we'll want the flag to indicate that the deletion was a success
        if (!successfullyDeletedObject) {
          // set flag to indicate that the object has been successfully deleted, which will force a re-render to allow for cleanup and user notification
          setSuccessfullyDeletedObject(true);
        }
      } else if (deleteTopicStatus === enumWorkflowState.Failure) {
        // display an alert message
        // alertFragment = generateJsxAlertMessageFragment(MessagesStringAssets.topic_DeleteFailure, enumJsxAlertMessageDisplayVariants.Failure, false, deleteTopicFailureMessage, MessagesStringAssets.substitutionKeyword);
        let failureMessage = 'Unknown error';
        if (deleteTopicFailure && deleteTopicFailure instanceof Error) {
          failureMessage = deleteTopicFailure.message;
        }
        alertInfo = new AlertInfo(true, enumAlertType.Error, failureMessage);
        dispatch(alertInfoChange(alertInfo));
      }
    }
  }

  // Establish the page title to be displayed. Once we have retrieved the parent Channel, apply the topicNameAliasPlural; otherwise, show nothing
  const pageTitle: string = currentChannel ? currentChannel.topicNameAliasPlural : PageAndViewTitleStringAssets.pageTitle_Topics;

  // Determine the user's permissions relative to the current channel
  const userPermissions: IUserAccessPermissionsForObject | undefined = currentUser && currentChannel ? new UserAccessPermissionsForObject(currentUser.id, currentChannel) : undefined;

  // Establish the text to be displayed on the '+ New' button
  const userHasEditPermission: boolean = (userPermissions !== undefined) && userPermissions.hasEditPermission;
  // const addNewButtonText: string = currentChannel ? `New ${currentChannel.topicNameAliasSingular}` : ControlsStringAssets.topicNew;
  let addNewButtonText: string = '';
  if (userHasEditPermission && currentChannel) {
    addNewButtonText = currentChannel ? `New ${currentChannel.topicNameAliasSingular}` : ControlsStringAssets.topicNew;
  }

  return (
    <>
      {/* only display the page after we have retrieved the Channel and we have a current User */}
      {currentChannel && currentUser &&
        <>
          <StyledBoxForPaddingAtopPage />
          <GenericPageContainer
            // maxWidth="xl"
            showBackButton={true}
            objectSpecificPageHeaderCategory={PageAndViewTitleStringAssets.objectSpecificCategory_Channel}
            objectSpecificPageHeaderText={currentChannel.name}
            pageTitle={pageTitle}
            onCloseAlert={resetDeleteTopicStatus}
          >

            {/* Present the Data View Controls ("Add new" button & "View Type" toggle button) */}
            <DataViewControls
              addNewItemButtonText={addNewButtonText}
              onClickAddNewItemButton={() => navigate(`/${parentId}/topic`)}
            >
            </DataViewControls>

            <StyledBoxForHorizontalDivider />

            {/* Container for embedded data view */}
            <StyledContainerForDataView>
              {/* If there are Topics to display and the current view selection is CardsGridView, display the TopicsCardGridView */}
              {topics &&
                (collectionViewType === enumCollectionViewType.CardsGridView) &&
                <TopicsCardGridView topics={topics} currentUser={currentUser} channel={currentChannel} onDelete={handleTopicDelete} />
              }

              {/* If there are Topics to display and the current view selection is ListView, display the TopicsListView */}
              {topics &&
                (collectionViewType === enumCollectionViewType.ListView) &&
                <TopicsListView topics={topics} currentUser={currentUser} channel={currentChannel} onDelete={handleTopicDelete} />
              }
            </StyledContainerForDataView>

            {/* Delete Topic Confirmation Dialog */}
            <TwoButtonAcceptanceDialog
              showDialog={showDeleteConfirmationDlg}
              headerText={MessagesStringAssets.topic_DeleteConfirmationHeader}
              bodyText={MessagesStringAssets.topic_DeleteConfirmation}
              acceptanceButtonText={ControlsStringAssets.confirmButtonText}
              nonAcceptanceButtonText={ControlsStringAssets.cancelButtonText}
              onAcceptance={handleTopicDeleteConfirmed}
              onNonAcceptance={handleTopicDeleteCanceled}
            />

            {activityIndicatorData.showActivityIndicator &&
              <ActivityIndicatorDialog activityLabel={activityIndicatorData.activityIndicatorMessage} />
            }

          </GenericPageContainer>
        </>
      }
    </>
  );
}

export default TopicsPage;
