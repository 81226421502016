import { RegularExpressions } from "../../../../../assets/regularExpressionAssets";

/**
 * @function transformUrlAsNeededForInstagram (Potentially) Transforms the address for an Instagram Url, stripping away all but the core specs of the Url
 * @param originalUrl
 */
export function transformUrlAsNeededForInstagram(originalUrl: string): string {

  const urlRegEx = RegularExpressions.InstagramCoreUrl;
  const result: RegExpExecArray | null = urlRegEx.exec(originalUrl);
  const transformedUrl: string = (result === null) ? '' : result[0];

  return transformedUrl;
}
