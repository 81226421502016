import { NumericConstants } from "../numericAssets";

/**
 * @class MessagesStringAssets A collection of string assets that pertain to messages to be displayed throughout the application
 */
export class MessagesStringAssets {
  static substitutionKeyword = 'SUBSTITUTE';

  // REGISTRATION
  static registration_Requested: string = `New account being created...`;
  static registration_Success: string = `You have been successfully registered to use MyDigiBrain! An email has been sent to ${MessagesStringAssets.substitutionKeyword} 
    to verify your account. Please check your inbox, and if you don't find the message there, check your Junk email folder. 
    Before you can sign in to MyDigiBrain, you must respond to the verification link.
    Once you have verified your account, please proceed to sign in.`;
  static registration_Failure: string = `Registration failed with the following error: "${MessagesStringAssets.substitutionKeyword}". You may try to register again. 
    If you need assistance, please click the 'Support' button below.`;

  // STORE STATE CONFIGURATION
  static storeConfigurator_NotInitialized: string = `Store configurator has not been initialized`;

  // Deployment Environment
  static deploymentPlatformEnvironment_Unknown: string = `Unknown deployment environment: "${MessagesStringAssets.substitutionKeyword}"`;

  // LOGIN
  static login_Requested: string = `Sign in processing...`;
  static login_Success: string = `You are now signed in`;
  static login_Failure: string = `Sign in failed with the following error: "${MessagesStringAssets.substitutionKeyword}". If you need assistance, please request support.`;
  static login_AccountNotVerified: string = 'Signing in to this user account is not allowed until the account has been verified via the link sent to the email address for this account.';
  static login_CannotFindEmailAddress: string = `The email address, ${MessagesStringAssets.substitutionKeyword}, cannot be found.`;
  static login_ResendVerificationEmailConfirmationHeader: string = `Resend Verification Email`;
  static login_ResendVerificationEmailConfirmation: string = `An account verification email will be sent to ${MessagesStringAssets.substitutionKeyword}.`;
  static login_VerificationEmailSentConfirmationHeader: string = `Verification Email Sent`;
  static login_VerificationEmailSentConfirmation: string = `An account verification email has been sent to ${MessagesStringAssets.substitutionKeyword}.`;
  
  static login_SendPasswordResetEmailConfirmationHeader: string = `Send Password Reset Email`;
  static login_SendPasswordResetEmailConfirmation: string = `A password reset email will be sent to ${MessagesStringAssets.substitutionKeyword}.`;
  static login_PasswordResetEmailSentConfirmationHeader: string = `Password Reset Email Sent`;
  static login_PasswordResetEmailSentConfirmation: string = `A password reset email has been sent to ${MessagesStringAssets.substitutionKeyword}.`;
  static login_PasswordResetFailed: string = `Sorry! The attempt to reset your password was unsuccessful. This is likely due to an invalid or expired password reset code. 
  To submit another request to receive an email with a password reset code, press the button below to
  proceed to the sign-in page where you can enter your email address and click on the 'Forgot Password?' link.`;
  static login_PasswordResetInstructions: string = 'Enter and confirm your new password. If the password change is successful, you will be automatically signed in.';
  static login_PasswordResetResultsHeader: string = `Password Reset`;
  static login_PasswordResetResultsSuccess: string = `Your password has been successfully changed and you have been signed in with your new password`;

  static login_SendSigninWithEmailLinkEmailConfirmationHeader: string = `Send Sign-in Link Email`;
  static login_SendSigninWithEmailLinkEmailConfirmation: string = `A sign-in link email will be sent to ${MessagesStringAssets.substitutionKeyword}.`;
  static login_SigninWithEmailLinkEmailSentConfirmationHeader: string = `Sign-in Link Email Sent`;
  static login_SigninWithEmailLinkEmailSentConfirmation: string = `A sign-in link email has been sent to ${MessagesStringAssets.substitutionKeyword}.`;
  static login_SigninWithEmailLinkFailed: string = `Sorry! The attempt to sign you in with the email link was unsuccessful. This is likely due to an invalid or expired sign-in code, or you
  entered an incorrect email address. To submit another request to receive an email with a sign-in code, press the button below to
  proceed to the sign-in page where you can enter your email address and request another sign-in with email link.`;
  static login_SigninWithEmailLinkEmailInstructions: string = 'For security purposes, please enter the email address for which the sign-in with email link was requested.';
  static login_SigninWithEmailLinkResultsHeader: string = `Sign-in with Email Link`;
  static login_SigninWithEmailLinkResultsSuccess: string = `You have been successfully signed in with the email link`;
  static login_RequestBeingProcessed: string = 'Request being processed. Please wait...';

  // GENERIC "DATA"
  static generic_FetchingRequested: string = `Fetching data...`;
  static generic_FetchingSuccess: string = `Fetching data completed successfully...`;
  static generic_FetchingFailure: string = `Fetching data failed with the following error: "${MessagesStringAssets.substitutionKeyword}". If you need assistance, please request support. `;

  static generic_SaveRequested: string = `Saving data...`;
  static generic_SaveSuccess: string = `Data saved`;
  static generic_SaveFailure: string = `Saving data failed with the following error: "${MessagesStringAssets.substitutionKeyword}". If you need assistance, please request support. `;

  static generic_DeleteRequested: string = `Deleting data...`;
  static generic_DeleteSuccess: string = `Deleting data completed successfully...`;
  static generic_DeleteFailure: string = `Deleting data failed with the following error: "${MessagesStringAssets.substitutionKeyword}". If you need assistance, please request support. `;

  // BACKUP
  static backup_RequestBeingProcessed: string = 'Request being processed. Please wait...';

  // CATEGORIES
  static categories_FetchingRequested: string = `Fetching categories...`;
  static categories_FetchingSuccess: string = `Fetching categories completed successfully...`;
  static categories_FetchingFailure: string = `Fetching categories failed with the following error: "${MessagesStringAssets.substitutionKeyword}". If you need assistance, please request support. `;

  static category_Loading: string = `Loading categories...`;

  static category_SaveRequested: string = `Saving category...`;
  static category_SaveSuccess: string = `Category saved`;
  static category_SaveFailure: string = `Saving category failed with the following error: "${MessagesStringAssets.substitutionKeyword}". If you need assistance, please request support. `;

  static category_UpdateRequested: string = `Updating category...`;
  static category_UpdateSuccess: string = `Updating category completed successfully...`;
  static category_UpdateFailure: string = `Updating category failed with the following error: "${MessagesStringAssets.substitutionKeyword}". If you need assistance, please request support. `;

  static category_DeleteConfirmationHeader: string = `Delete Category`;
  static category_DeleteConfirmation: string = `Press 'Confirm' to confirm deletion of the category.`;
  static category_DeleteRequested: string = `Deleting category...`;
  static category_DeleteSuccess: string = `Category deleted`;
  static category_DeleteFailure: string = `Deleting category failed with the following error: "${MessagesStringAssets.substitutionKeyword}". If you need assistance, please request support. `;

  // CHANNELS
  static channels_FetchingRequested: string = `Fetching channels...`;
  static channels_FetchingSuccess: string = `Fetching channels completed successfully...`;
  static channels_FetchingFailure: string = `Fetching channels failed with the following error: "${MessagesStringAssets.substitutionKeyword}". If you need assistance, please request support. `;

  static channel_Loading: string = `Loading channels...`;

  static channel_NoChannelsForCategory: string = `There are no channels associated with this category`;

  static channel_SaveRequested: string = `Saving channel...`;
  static channel_SaveSuccess: string = `Channel saved`;
  static channel_SaveFailure: string = `Saving channel failed with the following error: "${MessagesStringAssets.substitutionKeyword}". If you need assistance, please request support. `;

  static channel_UpdateRequested: string = `Updating channel...`;
  static channel_UpdateSuccess: string = `Updating channel completed successfully...`;
  static channel_UpdateFailure: string = `Updating channel failed with the following error: "${MessagesStringAssets.substitutionKeyword}". If you need assistance, please request support. `;

  static channel_DeleteConfirmationHeader: string = `Delete Channel`;
  static channel_DeleteConfirmation: string = `Press 'Confirm' to confirm deletion of the channel.`;
  static channel_DeleteRequested: string = `Deleting channel...`;
  static channel_DeleteSuccess: string = `Channel deleted`;
  static channel_DeleteFailure: string = `Deleting channel failed with the following error: "${MessagesStringAssets.substitutionKeyword}". If you need assistance, please request support. `;

  // CHANNEL SHARING
  static channelSharing_Loading: string = `Loading channel sharing information...`;

  static channelSharing_RevokeUserConfirmationHeader: string = `Revoke User from Sharing Channel`;
  static channelSharing_RevokeUserConfirmation: string = `Press 'Confirm' to confirm revoking user from sharing channel.`;
  static channelSharing_RevokeUserRequested: string = `Revoking user from sharing channel...`;
  static channelSharing_RevokeUserSuccess: string = `Channel sharing for user revoked`;
  static channelSharing_RevokeUserFailure: string = `Revoking user from sharing channel failed with the following error: "${MessagesStringAssets.substitutionKeyword}". If you need assistance, please request support. `;

  static channelSharing_RevokeChannelSharingRequestConfirmationHeader: string = `Revoke Channel Sharing Request`;
  static channelSharing_RevokeChannelSharingRequestConfirmation: string = `Press 'Confirm' to confirm revoking channel sharing request.`;
  static channelSharing_RevokeChannelSharingRequestRequested: string = `Revoking channel sharing request...`;
  static channelSharing_RevokeChannelSharingRequestSuccess: string = `Channel sharing request revoked`;
  static channelSharing_RevokeChannelSharingRequestFailure: string = `Revoking channel sharing request failed with the following error: "${MessagesStringAssets.substitutionKeyword}". If you need assistance, please request support. `;

  static channelSharing_NoPendingUsersForSharingChannel: string = `There are no pending users for sharing this channel`;
  static channelSharing_NoCurrentUsersharingChannel: string = `There are no users currently sharing this channel`;

  static channelSharing_SaveRequested: string = `Saving channel sharing request...`;
  static channelSharing_SaveSuccess: string = `Channel sharing request saved`;
  static channelSharing_SaveFailure: string = `Saving channel sharing request failed with the following error: "${MessagesStringAssets.substitutionKeyword}". If you need assistance, please request support. `;

  static channelSharingRequest_UpdateRequested: string = `Updating channel sharing request...`;
  static channelSharingRequest_UpdateSuccess: string = `Updating channel sharing request completed successfully...`;
  static channelSharingRequest_UpdateFailure: string = `Updating channel sharing request failed with the following error: "${MessagesStringAssets.substitutionKeyword}". If you need assistance, please request support. `;

  static channelSharingUser_UpdateRequested: string = `Updating channel sharing user...`;
  static channelSharingUser_UpdateSuccess: string = `Updating channel sharing user completed successfully...`;
  static channelSharingUser_UpdateFailure: string = `Updating channel sharing user failed with the following error: "${MessagesStringAssets.substitutionKeyword}". If you need assistance, please request support. `;
  static channelSharingUser_UpdateUserViolation: string = 'A user is not allowed to administer his/her own sharing permission';

  static channelSharing_DeleteConfirmationHeader: string = `Delete Channel Sharing Request`;
  static channelSharing_DeleteConfirmation: string = `Press 'Confirm' to confirm deletion of the channel sharing request.`;
  static channelSharing_DeleteRequested: string = `Deleting channel sharing request...`;
  static channelSharing_DeleteSuccess: string = `Channel sharing request deleted`;
  static channelSharing_DeleteFailure: string = `Deleting channel sharing request failed with the following error: "${MessagesStringAssets.substitutionKeyword}". If you need assistance, please request support. `;

  // CURRENT CHANNEL
  static currentChannel_Error_NotCurrentlySet: string = `Internal Error: There is no current Channel. We would appreciate it if you would please report this to Support.`;

  // DIGITAL MEDIA
  static digitalMedia_Loading: string = `Loading digital media...`;
  static audioSourceOrFormatNotSupported = 'Audio source or format is not supported';
  static imageSourceOrFormatNotSupported = 'Image source or format is not supported';
  static socialMediaPostSourceOrFormatNotSupported = 'Social Media source is not supported';
  static videoSourceOrFormatNotSupported = 'Video source or format is not supported';

  // FILE UPLOAD
  static file_Uploading = 'Uploading file...';

  // AUDIO LINKS
  static audioLinks_FetchingRequested: string = `Fetching audios...`;
  static audioLinks_FetchingSuccess: string = `Fetching audios completed successfully...`;
  static audioLinks_FetchingFailure: string = `Fetching audios failed with the following error: "${MessagesStringAssets.substitutionKeyword}". If you need assistance, please request support. `;

  static audioLink_Loading: string = `Loading audio...`;

  static audioLink_SaveRequested: string = `Saving audio...`;
  static audioLink_SaveSuccess: string = `Audio saved`;
  static audioLink_SaveFailure: string = `Saving audio failed with the following error: "${MessagesStringAssets.substitutionKeyword}". If you need assistance, please request support. `;

  static audioLink_ManageTopicItemError: string = `Unable to create a new audio: "${MessagesStringAssets.substitutionKeyword}". If you need assistance, please request support. `;

  static audioLink_DeleteConfirmationHeader: string = `Delete Audio`;
  static audioLink_DeleteConfirmation: string = `Press 'Confirm' to confirm deletion of the audio.`;
  static audioLink_DeleteRequested: string = `Deleting audio...`;
  static audioLink_DeleteSuccess: string = `Audio deleted`;
  static audioLink_DeleteFailure: string = `Deleting audio failed with the following error: "${MessagesStringAssets.substitutionKeyword}". If you need assistance, please request support. `;

  static audioLink_UploadingAudio: string = `Uploading audio...`;
  static audioLink_FileUploadVerifying: string = `Verifying uploaded audio file...`;

  // DOCUMENT LINKS
  static documentLinks_FetchingRequested: string = `Fetching documents...`;
  static documentLinks_FetchingSuccess: string = `Fetching documents completed successfully...`;
  static documentLinks_FetchingFailure: string = `Fetching documents failed with the following error: "${MessagesStringAssets.substitutionKeyword}". If you need assistance, please request support. `;

  static documentLink_Loading: string = `Loading document...`;

  static documentLink_SaveRequested: string = `Saving document...`;
  static documentLink_SaveSuccess: string = `Document saved`;
  static documentLink_SaveFailure: string = `Saving document failed with the following error: "${MessagesStringAssets.substitutionKeyword}". If you need assistance, please request support. `;

  static documentLink_ManageTopicItemError: string = `Unable to create a new document: "${MessagesStringAssets.substitutionKeyword}". If you need assistance, please request support. `;

  static documentLink_DeleteConfirmationHeader: string = `Delete Document`;
  static documentLink_DeleteConfirmation: string = `Press 'Confirm' to confirm deletion of the document.`;
  static documentLink_DeleteRequested: string = `Deleting document...`;
  static documentLink_DeleteSuccess: string = `Document deleted`;
  static documentLink_DeleteFailure: string = `Deleting document failed with the following error: "${MessagesStringAssets.substitutionKeyword}". If you need assistance, please request support. `;

  static documentLink_UploadingDocument: string = `Uploading document...`;
  static documentLink_FileUploadVerifying: string = `Verifying uploaded document file...`;

  // HYPERLINKS
  static hyperLinks_FetchingRequested: string = `Fetching hyperLinks...`;
  static hyperLinks_FetchingSuccess: string = `Fetching hyperLinks completed successfully...`;
  static hyperLinks_FetchingFailure: string = `Fetching hyperLinks failed with the following error: "${MessagesStringAssets.substitutionKeyword}". If you need assistance, please request support. `;

  static hyperLink_Loading: string = `Loading hyperLink...`;

  static hyperLink_SaveRequested: string = `Saving hyperLink...`;
  static hyperLink_SaveSuccess: string = `Hyperlink saved`;
  static hyperLink_SaveFailure: string = `Saving hyperLink failed with the following error: "${MessagesStringAssets.substitutionKeyword}". If you need assistance, please request support. `;

  static hyperLink_ManageTopicItemError: string = `Unable to create a new hyperLink: "${MessagesStringAssets.substitutionKeyword}". If you need assistance, please request support. `;

  static hyperLink_DeleteConfirmationHeader: string = `Delete hyperLink`;
  static hyperLink_DeleteConfirmation: string = `Press 'Confirm' to confirm deletion of the hyperLink.`;
  static hyperLink_DeleteRequested: string = `Deleting hyperLink...`;
  static hyperLink_DeleteSuccess: string = `HyperLink deleted`;
  static hyperLink_DeleteFailure: string = `Deleting hyperLink failed with the following error: "${MessagesStringAssets.substitutionKeyword}". If you need assistance, please request support. `;

  // IMAGE LINKS
  static imageLinks_FetchingRequested: string = `Fetching images...`;
  static imageLinks_FetchingSuccess: string = `Fetching images completed successfully...`;
  static imageLinks_FetchingFailure: string = `Fetching images failed with the following error: "${MessagesStringAssets.substitutionKeyword}". If you need assistance, please request support. `;

  static imageLink_Loading: string = `Loading image...`;

  static imageLink_SaveRequested: string = `Saving image...`;
  static imageLink_SaveSuccess: string = `Image successfully saved`;
  static imageLink_SaveFailure: string = `Saving image failed with the following error: "${MessagesStringAssets.substitutionKeyword}". If you need assistance, please request support. `;

  static imageLink_ManageTopicItemError: string = `Unable to create a new image: "${MessagesStringAssets.substitutionKeyword}". If you need assistance, please request support. `;

  static imageLink_DeleteConfirmationHeader: string = `Delete Image`;
  static imageLink_DeleteConfirmation: string = `Press 'Confirm' to confirm deletion of the image.`;
  static imageLink_DeleteRequested: string = `Deleting image...`;
  static imageLink_DeleteSuccess: string = `Image deleted`;
  static imageLink_DeleteFailure: string = `Deleting image failed with the following error: "${MessagesStringAssets.substitutionKeyword}". If you need assistance, please request support. `;

  static imageLink_UploadingImage: string = `Uploading image...`;
  static imageLink_FileUploadVerifying: string = `Verifying uploaded image and generated image of different sizes (thumbnails, etc.)...`;

  // NOTES
  static notes_FetchingRequested: string = `Fetching notes...`;
  static notes_FetchingSuccess: string = `Fetching notes completed successfully...`;
  static notes_FetchingFailure: string = `Fetching notes failed with the following error: "${MessagesStringAssets.substitutionKeyword}". If you need assistance, please request support. `;

  static note_Loading: string = `Loading note...`;

  static note_SaveRequested: string = `Saving note...`;
  static note_SaveSuccess: string = `Note saved`;
  static note_SaveFailure: string = `Saving note failed with the following error: "${MessagesStringAssets.substitutionKeyword}". If you need assistance, please request support. `;

  static note_ManageTopicItemError: string = `Unable to create a new note: "${MessagesStringAssets.substitutionKeyword}". If you need assistance, please request support. `;

  static note_DeleteConfirmationHeader: string = `Delete note`;
  static note_DeleteConfirmation: string = `Press 'Confirm' to confirm deletion of the note.`;
  static note_DeleteRequested: string = `Deleting note...`;
  static note_DeleteSuccess: string = `Note deleted`;
  static note_DeleteFailure: string = `Deleting note failed with the following error: "${MessagesStringAssets.substitutionKeyword}". If you need assistance, please request support. `;

  // POPULATE SEARCH DATA
  static searchData_PopulateSearchMetadataRequested: string = `Populating search metadata...`;
  static searchData_PopulateSearchMetadataSuccess: string = `Population of search metadata is underway`;
  static searchData_PopulateSearchMetadataFailure: string = `Populating search metadata failed with the following error: "${MessagesStringAssets.substitutionKeyword}". If you need assistance, please request support. `;
  static searchData_PopulateSearchMetadataConfirmationHeader: string = `Populating search metadata`;
  static searchData_PopulateSearchMetadataConfirmation: string = `Populate search metadata?`;

  // SEARCH RESULTS
  static searchResults_NoDigitalMediaFoundForSearchCriteriaHeader: string = `No Search Results`;
  static searchResults_NoDigitalMediaFoundForSearchCriteria: string = `No digital media was found for specified search criteria. Consider adjusting search criteria.`;
  static searchResults_TooManySearchResultsFoundHeader: string = `Maximum Search Results Exceeded`;
  static searchResults_TooManySearchResultsFound: string = `Too many search results were found ${MessagesStringAssets.substitutionKeyword}for search criteria provided. The results will be truncated to ${NumericConstants.maxDigitalMediaSearchResults} search results items.`;

  // SHARING REQUESTS
  static sharingRequests_Loading: string = `Loading sharing requests...`;

  static sharingRequest_AcceptConfirmationHeader: string = `Accept Sharing Request`;
  static sharingRequest_AcceptConfirmation: string = `Press 'Confirm' to confirm accepting sharing request.`;
  static sharingRequest_AcceptRequested: string = `Accepting sharing request...`;
  static sharingRequest_AcceptSuccess: string = `Sharing request accepted`;
  static sharingRequest_AcceptFailure: string = `Accepting sharing request failed with the following error: "${MessagesStringAssets.substitutionKeyword}". If you need assistance, please request support. `;

  static sharingRequest_DeclineConfirmationHeader: string = `Decline Sharing Request`;
  static sharingRequest_DeclineConfirmation: string = `Press 'Confirm' to confirm declining sharing request.`;
  static sharingRequest_DeclineRequested: string = `Declining sharing request...`;
  static sharingRequest_DeclineSuccess: string = `Sharing request declined`;
  static sharingRequest_DeclineFailure: string = `Declining sharing request failed with the following error: "${MessagesStringAssets.substitutionKeyword}". If you need assistance, please request support. `;

  // SOCIAL MEDIA POSTS
  static socialMediaPosts_FetchingRequested: string = `Fetching social media posts...`;
  static socialMediaPosts_FetchingSuccess: string = `Fetching social media posts completed successfully...`;
  static socialMediaPosts_FetchingFailure: string = `Fetching social media posts failed with the following error: "${MessagesStringAssets.substitutionKeyword}". If you need assistance, please request support. `;

  static socialMediaPost_Loading: string = `Loading social media post...`;

  static socialMediaPost_SaveRequested: string = `Saving social media post...`;
  static socialMediaPost_SaveSuccess: string = `Social media post saved`;
  static socialMediaPost_SaveFailure: string = `Saving social media post failed with the following error: "${MessagesStringAssets.substitutionKeyword}". If you need assistance, please request support. `;

  static socialMediaPost_ManageTopicItemError: string = `Unable to create a new social media post: "${MessagesStringAssets.substitutionKeyword}". If you need assistance, please request support. `;

  static socialMediaPost_DeleteConfirmationHeader: string = `Delete social media post`;
  static socialMediaPost_DeleteConfirmation: string = `Press 'Confirm' to confirm deletion of the social media post.`;
  static socialMediaPost_DeleteRequested: string = `Deleting social media post...`;
  static socialMediaPost_DeleteSuccess: string = `Social media post deleted`;
  static socialMediaPost_DeleteFailure: string = `Deleting social media post failed with the following error: "${MessagesStringAssets.substitutionKeyword}". If you need assistance, please request support. `;

  // THEME SPECIFICATIONS
  static themeSpecs_FetchingRequested: string = `Fetching themes...`;
  static themeSpecs_FetchingSuccess: string = `Fetching themes completed successfully...`;
  static themeSpecs_FetchingFailure: string = `Fetching themes failed with the following error: "${MessagesStringAssets.substitutionKeyword}". If you need assistance, please request support. `;

  static themeSpecs_Loading: string = `Loading themes...`;

  static themeSpecs_SaveRequested: string = `Saving theme...`;
  static themeSpecs_SaveSuccess: string = `Category saved`;
  static themeSpecs_SaveFailure: string = `Saving theme failed with the following error: "${MessagesStringAssets.substitutionKeyword}". If you need assistance, please request support. `;

  static themeSpecs_UpdateRequested: string = `Updating theme...`;
  static themeSpecs_UpdateSuccess: string = `Updating theme completed successfully...`;
  static themeSpecs_UpdateFailure: string = `Updating theme failed with the following error: "${MessagesStringAssets.substitutionKeyword}". If you need assistance, please request support. `;

  static themeSpecs_DeleteConfirmationHeader: string = `Delete Theme`;
  static themeSpecs_DeleteConfirmation: string = `Press 'Confirm' to confirm deletion of the theme.`;
  static themeSpecs_DeleteRequested: string = `Deleting theme...`;
  static themeSpecs_DeleteSuccess: string = `Theme deleted`;
  static themeSpecs_DeleteFailure: string = `Deleting theme failed with the following error: "${MessagesStringAssets.substitutionKeyword}". If you need assistance, please request support. `;

  // TOPICS
  static topics_FetchingRequested: string = `Fetching topics...`;
  static topics_FetchingSuccess: string = `Fetching topics completed successfully...`;
  static topics_FetchingFailure: string = `Fetching topics failed with the following error: "${MessagesStringAssets.substitutionKeyword}". If you need assistance, please request support. `;

  static topic_Loading: string = `Loading topic...`;

  static topic_SaveRequested: string = `Saving topic...`;
  static topic_SaveSuccess: string = `Topic saved`;
  static topic_SaveFailure: string = `Saving topic failed with the following error: "${MessagesStringAssets.substitutionKeyword}". If you need assistance, please request support. `;

  static topic_DeleteConfirmationHeader: string = `Delete Topic`;
  static topic_DeleteConfirmation: string = `Press 'Confirm' to confirm deletion of the topic.`;
  static topic_DeleteRequested: string = `Deleting topic...`;
  static topic_DeleteSuccess: string = `Topic deleted`;
  static topic_DeleteFailure: string = `Deleting topic failed with the following error: "${MessagesStringAssets.substitutionKeyword}". If you need assistance, please request support. `;

  // TOPIC ITEMS
  static topicItems_FetchingRequested: string = `Fetching topic items...`;
  static topicItems_FetchingSuccess: string = `Fetching topic items completed successfully...`;
  static topicItems_FetchingFailure: string = `Fetching topic items failed with the following error: "${MessagesStringAssets.substitutionKeyword}". If you need assistance, please request support. `;

  static topicItem_Loading: string = `Loading topic item...`;

  static topicItem_SaveRequested: string = `Saving topic item...`;
  static topicItem_SaveSuccess: string = `Topic Item saved`;
  static topicItem_SaveFailure: string = `Saving topic item failed with the following error: "${MessagesStringAssets.substitutionKeyword}". If you need assistance, please request support. `;

  static topicItem_ManageTopicItemError: string = `Unable to create a new topic item: "${MessagesStringAssets.substitutionKeyword}". If you need assistance, please request support. `;

  static topicItem_DeleteConfirmationHeader: string = `Delete Topic Item`;
  static topicItem_DeleteConfirmation: string = `Press 'Confirm' to confirm deletion of the topic item.`;
  static topicItem_DeleteRequested: string = `Deleting topic item...`;
  static topicItem_DeleteSuccess: string = `Topic Item deleted`;
  static topicItem_DeleteFailure: string = `Deleting topic item failed with the following error: "${MessagesStringAssets.substitutionKeyword}". If you need assistance, please request support. `;

  // CURRENT USER
  static currentUser_Error_NotCurrentlySet: string = `Internal Error: There is no current User. We would appreciate it if you would please report this to Support.`;

  // CURRENT USER SETTINGS
  static currentUserSettings_Error_NotCurrentlySet: string = `Internal Error: There is no current UserSettings. We would appreciate it if you would please report this to Support.`;

  // USERS
  static user_SaveRequested: string = `Saving user information...`;
  static user_SaveSuccess: string = `User information saved`;
  static user_SaveFailure: string = `Saving user information failed with the following error: "${MessagesStringAssets.substitutionKeyword}". If you need assistance, please request support. `;

  static user_UpdateUserEmailAddressRequested: string = `Updating email address...`;
  static user_UpdateUserEmailAddressSuccess: string = `An email message has been sent to ${MessagesStringAssets.substitutionKeyword} to verify the email address change.`;
  static user_UpdateUserEmailAddressFailure: string = `Updating email address failed with the following error: "${MessagesStringAssets.substitutionKeyword}". If you need assistance, please request support. `;
  static user_UpdateUserEmailAddressConfirmationHeader: string = `Update Email Address`;
  static user_UpdateUserEmailAddressConfirmation: string = `Please confirm that you would like to update your email address to ${MessagesStringAssets.substitutionKeyword}?`;
  static user_UpdateUserEmailAddressRequiresDifferentEmail: string = `The new Email Address must be different from the old Email Address`;

  static user_ExpungeUserAccountRequested: string = `Expunging user account...`;
  static user_ExpungeUserAccountSuccess: string = `User account expunged`;
  static user_ExpungeUserAccountFailure: string = `Expunging user account failed with the following error: "${MessagesStringAssets.substitutionKeyword}". If you need assistance, please request support. `;
  static user_ExpungeUserAccountConfirmationHeader: string = `Expunge User Account`;
  static user_ExpungeUserAccountConfirmation: string = `Expunge user account with the email address of ${MessagesStringAssets.substitutionKeyword}?`;

  // USER REDEMPTION CODE REQUESTS
  static userRedemptionCodeRequest_FetchingRequested: string = `Fetching redemption codes...`;
  static userRedemptionCodeRequest_FetchingSuccess: string = `Fetching redemption codes completed successfully...`;
  static userRedemptionCodeRequest_FetchingFailure: string = `Fetching redemption codes failed with the following error: "${MessagesStringAssets.substitutionKeyword}". If you need assistance, please request support. `;

  static userRedemptionCodeRequest_Loading: string = `Loading redemption code...`;

  static userRedemptionCodeRequest_SaveRequested: string = `Saving redemption code...`;
  static userRedemptionCodeRequest_SaveSuccess: string = `Redemption code saved`;
  static userRedemptionCodeRequest_SaveFailure: string = `Saving redemption code failed with the following error: "${MessagesStringAssets.substitutionKeyword}". If you need assistance, please request support. `;

  static userRedemptionCodeRequest_ManageTopicItemError: string = `Unable to create a new redemption code: "${MessagesStringAssets.substitutionKeyword}". If you need assistance, please request support. `;

  static userRedemptionCodeRequest_DeleteConfirmationHeader: string = `Delete Redemption Code`;
  static userRedemptionCodeRequest_DeleteConfirmation: string = `Press 'Confirm' to confirm deletion of the redemption code.`;
  static userRedemptionCodeRequest_DeleteRequested: string = `Deleting redemption code...`;
  static userRedemptionCodeRequest_DeleteSuccess: string = `Redemption Code deleted`;
  static userRedemptionCodeRequest_DeleteFailure: string = `Deleting redemption code failed with the following error: "${MessagesStringAssets.substitutionKeyword}". If you need assistance, please request support. `;

  // USER SETTINGS
  static userSettings_SaveRequested: string = `Saving user settings...`;

  // VIDEO LINKS
  static videoLinks_FetchingRequested: string = `Fetching videos...`;
  static videoLinks_FetchingSuccess: string = `Fetching videos completed successfully...`;
  static videoLinks_FetchingFailure: string = `Fetching videos failed with the following error: "${MessagesStringAssets.substitutionKeyword}". If you need assistance, please request support. `;

  static videoLink_Loading: string = `Loading video...`;

  static videoLink_SaveRequested: string = `Saving video...`;
  static videoLink_SaveSuccess: string = `Video saved`;
  static videoLink_SaveFailure: string = `Saving video failed with the following error: "${MessagesStringAssets.substitutionKeyword}". If you need assistance, please request support. `;

  static videoLink_ManageTopicItemError: string = `Unable to create a new video: "${MessagesStringAssets.substitutionKeyword}". If you need assistance, please request support. `;

  static videoLink_DeleteConfirmationHeader: string = `Delete Video`;
  static videoLink_DeleteConfirmation: string = `Press 'Confirm' to confirm deletion of the video.`;
  static videoLink_DeleteRequested: string = `Deleting video...`;
  static videoLink_DeleteSuccess: string = `Video deleted`;
  static videoLink_DeleteFailure: string = `Deleting video failed with the following error: "${MessagesStringAssets.substitutionKeyword}". If you need assistance, please request support. `;

  static videoLink_UploadingVideo: string = `Uploading video...`;
  static videoLink_FileUploadVerifying: string = `Verifying uploaded video...`;

}