import React, { PropsWithChildren } from 'react';
import { Grid, GridProps } from '@mui/material';
import { ViewTitle } from '../../controls/ViewTitle/ViewTitle';
import { styled } from '@mui/styles';


/*** Using the Material UI Emotion Styling library, declare 'styled' instances for each area/object. 
 *** NOTE: These must be declared outside of the React Functional Component to ensure that the styled 
 *** objects will be properly rendered within the DOM. 
 ***/

// a styled Grid for the container of the View Title Container
const StyledGridForViewTitleContainer = styled((props: GridProps) => (
  <Grid
    item
    container
    {...props}
  />
))(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignContent: 'center',
}));

/**
 * @interface IViewHeaderProps declares any input properties required for this component.
 */
export interface IViewHeaderProps extends PropsWithChildren<unknown> {
  viewTitle: string;
}

/**
 * @function ViewHeader is a React functional component representing a generic header for a view.
 * @param {IViewHeaderProps} props are the properties passed into the component.
 */
export const ViewHeader: React.FC<IViewHeaderProps> = (props: IViewHeaderProps) => {
  ViewHeader.displayName = 'View Header';

  const { viewTitle } = props;

  return (
    <>
      <Grid container >
        {/* The View Title area */}
        <StyledGridForViewTitleContainer key={'viewTitle'} xs={12}  >
          <ViewTitle
            title={viewTitle}
          />
        </StyledGridForViewTitleContainer>
      </Grid>
    </>
  )
}