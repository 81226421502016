import React, { PropsWithChildren } from 'react';
import { Grid, Box, Typography } from '@mui/material';
import { listViewStyles } from '../../../styles';
import { IUserRedemptionCodeRequest } from '../../../../dataObjects/models/userRedemptionCodeRequest';
import UserRedemptionCodeRequestsListViewItem from './UserRedemptionCodeRequestsListViewItem';
import { IUser } from '../../../../dataObjects/models/users/User';
import { enumSharingPermission } from '../../../../dataObjects/enums';


export interface IUserRedemptionCodeRequestsListViewProps extends PropsWithChildren<unknown> {
  userRedemptionCodeRequests: Array<IUserRedemptionCodeRequest>;
  currentUser: IUser;
  onDelete: (userRedemptionCodeRequest: IUserRedemptionCodeRequest) => void;
}

export const UserRedemptionCodeRequestsListView: React.FC<IUserRedemptionCodeRequestsListViewProps> = (props: IUserRedemptionCodeRequestsListViewProps) => {
  UserRedemptionCodeRequestsListView.displayName = 'UserRedemptionCodeRequests List View';

  // eslint-disable-next-line no-console
  // console.info(`Render UserRedemptionCodeRequestsListView`);

  const classes: Record<string, string> = listViewStyles();

  const { userRedemptionCodeRequests, currentUser, onDelete } = props;

  const userPermission : enumSharingPermission = currentUser.sa ? enumSharingPermission.Admin : enumSharingPermission.None;

  return (
    <>
      {/* overall grid for list. There will be a header row and two data rows: 
          - Header Row
          - Data Row 1: Name & Action Buttons
          - Data Row 2: Description
      */}
      <Grid container direction="column" >
        {/* Header Row */}
        <Grid key='tableHeader' item container className={classes.tableHeader} xs={12} direction="row">
          <Grid key='nameAndDescriptionLabel' item xs={12} md={9} >
            <Typography variant='h6'>
              Name / Description
            </Typography>
          </Grid>
          <Grid item container md={3} direction="row" justifyContent="center" alignContent="flex-start">
            <Grid key='userRedemptionCodeRequestItemsLabel' item container className={classes.actionButtonLabelContainer} md={4} justifyContent='center' >
              {/* Only display the next label if the screen size is Medium or larger */}
              <Box sx={{ display: { xs: 'none', md: 'block' } }} >
                <Typography variant='h6'>
                  Digital Media
                </Typography>
              </Box>
            </Grid>
            <Grid key='editLabel' item container className={classes.actionButtonLabelContainer} md={4} justifyContent='center' >
              {/* Only display the next label if the screen size is Medium or larger */}
              <Box sx={{ display: { xs: 'none', md: 'block' } }} >
                <Typography variant='h6'>
                  Edit
                </Typography>
              </Box>
            </Grid>
            <Grid key='deleteLabel' item container className={classes.actionButtonLabelContainer} md={4} justifyContent='center' >
              {/* Only display the next label if the screen size is Medium or larger */}
              <Box sx={{ display: { xs: 'none', md: 'block' } }} >
                <Typography variant='h6'>
                  Delete
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Grid>

        {
          currentUser && 
          userRedemptionCodeRequests.map((userRedemptionCodeRequest, idx) => (
            <UserRedemptionCodeRequestsListViewItem
              key={userRedemptionCodeRequest.id}
              userRedemptionCodeRequest={userRedemptionCodeRequest}
              userPermission={userPermission}
              onDelete={onDelete}
            />
          ))
        }
      </Grid>
    </>
  );
};

export default UserRedemptionCodeRequestsListView;
