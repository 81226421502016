import React from 'react';
import { Button, ButtonProps } from '@mui/material';
import {
  CheckCircle as SaveIcon,
} from '@mui/icons-material';
import { styled, useTheme } from '@mui/styles';
import { IMyDigiBrainTheme } from '../../../../assets/themes';


/*** Using the Material UI Emotion Styling library, declare 'styled' instances for each area/object. 
 *** NOTE: These must be declared outside of the React Functional Component to ensure that the styled 
 *** objects will be properly rendered within the DOM. 
 ***/

// a styled Button
const StyledButton = styled((props: ButtonProps) => (
  <Button
    variant="outlined"
    {...props}
  />
))(({ theme }) => ({
  padding: theme.spacing(1, 2),
  color: theme.form.submitButton.color,
  border: theme.form.submitButton.border,
  background: theme.form.submitButton.background,
  '&:hover': {
    border: theme.form.submitButton.hoverBorder,
    background: theme.form.submitButton.hoverBackground,
    color: theme.form.submitButton.hoverColor,
  },
  '&:disabled': {
    border: theme.form.submitButton.disabledBorder,
    background: theme.form.submitButton.disabledBackground,
    color: theme.form.submitButton.disabledColor,
  },
}));

export interface ISubmitButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  fullWidth?: boolean;
  disabled?: boolean;
}

// const SubmitButton: React.FC = ({ children, ...props }) => {
const SubmitButton: React.FC<ISubmitButtonProps> = (props: ISubmitButtonProps) => {

  // const { children, fullWidth, disabled, ...otherProps } = props;
  const { children, fullWidth, disabled } = props;
  // console.info(`SubmitButton. disabled=${disabled}`);

  const disableButton: boolean = (disabled !== undefined) ? disabled : false;
  // console.info(`SubmitButton. disableButton=${disableButton}`);

  // whether to display console logs (displayConsoleLogs && console.log statements)
  const displayConsoleLogs: boolean = false;

  const theme: IMyDigiBrainTheme = useTheme() as IMyDigiBrainTheme;

  displayConsoleLogs && console.log(`In SubmitButton. Specs for Enabled button -- border: ${theme.form.submitButton.border}; background: ${theme.form.submitButton.background}; color: ${theme.form.submitButton.color}`);
  displayConsoleLogs && console.log(`In SubmitButton. Specs for Hover button -- border: ${theme.form.submitButton.hoverBorder}; background: ${theme.form.submitButton.hoverBackground}; color: ${theme.form.submitButton.hoverColor}`);
  displayConsoleLogs && console.log(`In SubmitButton. Specs for Disabled button -- border: ${theme.form.submitButton.disabledBorder}; background: ${theme.form.submitButton.disabledBackground}; color: ${theme.form.submitButton.disabledColor}`);

  displayConsoleLogs && console.log(`In SubmitButton. disableButton: ${disableButton}`);

  return (
    <StyledButton
      type="submit"
      disabled={disableButton}
      fullWidth={fullWidth ?? false}
      // {...otherProps}
      // variant="contained"
      startIcon={<SaveIcon />}
    >
      {/* if there are any children to pass on (ie, children !== undefined), pass them on; otherwise, pass an empty React fragment */}
      <>
        {children && children}
      </>
    </StyledButton>
  );
}

export default SubmitButton;