import { take, put } from 'redux-saga/effects';
import { saveUserRedemptionCodeRequest } from '../../../firebaseServices/dataServices/dataServiceActions/userRedemptionCodeRequestActions';
import { userRedemptionCodeRequestSaveRequest, userRedemptionCodeRequestSaveStatusChange } from '../../slices/userRedemptionCodeRequest/userRedemptionCodeRequestSaveStatusSlice';
import { userRedemptionCodeRequestSaveFailureChange } from '../../slices/userRedemptionCodeRequest/userRedemptionCodeRequestSaveFailureSlice';
import { enumWorkflowState } from '../../../dataObjects/enums';
// import { saveUserRedemptionCodeRequest } from '@tkent_dna/mytennisbrain-firebaseservices';

/**
 * @function saveUserRedemptionCodeRequestRequestedSaga A saga workflow pertaining to a user having requested to save a userRedemptionCodeRequest.
 */
export function* saveUserRedemptionCodeRequestRequestedSaga() {
  // console.info('(saveUserRedemptionCodeRequestRequestedSaga) entered saveUserRedemptionCodeRequestRequestedSaga');

  // loop to keep the saga running throughout the life of the application
  while (true) {
    try {
      // wait on the userRedemptionCodeRequestSaveRequest action to be dispatched
      const userRedemptionCodeRequestSaveRequestData = yield take(userRedemptionCodeRequestSaveRequest);
      const { payload: userRedemptionCodeRequest } = userRedemptionCodeRequestSaveRequestData;

      // make a request to save the userRedemptionCodeRequest
      yield saveUserRedemptionCodeRequest(userRedemptionCodeRequest)

      // dispatch an action to indicate that the save operation was successful
      yield put(userRedemptionCodeRequestSaveStatusChange(enumWorkflowState.Success));
    } catch (error: any) {
      // dispatch an action to indicate that the save operation failed
      yield put(userRedemptionCodeRequestSaveStatusChange(enumWorkflowState.Failure));
      yield put(userRedemptionCodeRequestSaveFailureChange(error));
    }
  }
}
