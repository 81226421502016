import React, { PropsWithChildren, ReactElement, useCallback, useEffect, useState } from 'react';
import _ from 'lodash';
import { Accordion, AccordionDetails, AccordionSummary, Box, BoxProps, Container, ContainerProps, Fab as FloatingActionButton, FabProps, Grid, GridProps, Typography, TypographyProps } from '@mui/material';
import { ControlsStringAssets, MessagesStringAssets, PageAndViewTitleStringAssets } from '../../../../assets/stringAssets';
import GenericPageContainer from '../../GenericPageContainer/GenericPageContainer';
import { enumAlertType, enumCategoriesAndChannelsDataPreparationStatus } from '../../../enums';
import { typeUniqueId } from '../../../../dataObjects/types';
import { IStoreState } from '../../../../uiMiddleware-redux/store/IStoreState';
import { enumBeaconType, enumCollectionViewType, enumWorkflowState } from '../../../../dataObjects/enums';
import TwoButtonAcceptanceDialog from '../../../dialogs/TwoButtonAcceptanceDialog/TwoButtonAcceptanceDialog';
import { Beacon } from '../../../../dataObjects/models/alerts/Beacon';
import ActivityIndicatorDialog from '../../../dialogs/ActivityIndicatorDialog/ActivityIndicatorDialog';
import { IActivityIndicatorData } from '../../../dialogs/ActivityIndicatorData';
import { AlertInfo, IAlertInfo } from '../../../../dataObjects/models/alerts/AlertInfo';
import { useReduxWorkflowState } from '../../../customHooks';
import DataViewControls from '../../../controls/DataViewControls/DataViewControls';
import { useGetCollectionViewType } from '../../../customHooks/useGetCollectionViewType';
import { styled } from '@mui/styles';
import { ICurrentUserContextData, useCurrentUserContext } from '../../../providersAndContexts/currentUser';
import { IUserCategoriesAndChannelsContextData, useUserCategoriesAndChannelsContext } from '../../../providersAndContexts/userCategoriesAndChannels';
import { ISharingRequestViewModel } from '../../../../dataObjects/viewModels/sharingRequestViewModel';
import { SharingRequestsCardGridView } from '../../../views/sharingRequests/SharingRequestsCardGridView/SharingRequestsCardGridView';
import SharingRequestsListView from '../../../views/sharingRequests/SharingRequestsListView/SharingRequestsListView';
import { beaconChange } from '../../../../uiMiddleware-redux/slices/beacon/beaconSlice';
import { alertInfoChange } from '../../../../uiMiddleware-redux/slices/alertInfo/alertInfoSlice';
import { useAppDispatch } from '../../../../uiMiddleware-redux/store/configureStore';
import { sharingRequestDeclineRequest, sharingRequestDeclineStatusChange } from '../../../../uiMiddleware-redux/slices/collaboration/sharingRequestDeclineStatusSlice';
import { sharingRequestAcceptanceRequest } from '../../../../uiMiddleware-redux/slices/collaboration/sharingRequestAcceptanceStatusSlice';


/*** Using the Material UI Emotion Styling library, declare 'styled' instances for each area/object. 
 *** NOTE: These must be declared outside of the React Functional Component to ensure that the styled 
 *** objects will be properly rendered within the DOM. 
 ***/

// a styled Box (equivalent to a <div>), providing an area of padding at the top of the page
const StyledBoxForPaddingAtopPage = styled((props: BoxProps) => (
  <Box
    {...props}
  />
))(({ theme }) => ({
  marginTop: theme.spacing(3),
}));

// a styled Box (equivalent to a <div>), providing an area of padding above the Data View controls
const StyledBoxForPaddingAboveDataViewControls = styled((props: BoxProps) => (
  <Box
    {...props}
  />
))(({ theme }) => ({
  marginTop: theme.spacing(1),
}));

// a styled Grid for the overall container of the Sharing Requests Floating Action Button Area
const StyledGridForSharingRequestsButtonContainer = styled((props: GridProps) => (
  <Grid
    container
    {...props}
  />
))(({ theme }) => ({
  flexGrow: 1,
  width: "100%",
  marginTop: theme.spacing(-4), // we want to eliminate some top marging if the button is being displayed
}));

// a styled Grid for the Sharing Requests Floating Action Button Area
const StyledGridForSharingRequestsFloatingActionButtonArea = styled((props: GridProps) => (
  <Grid
    item
    container
    // xs={8} sm={4}
    {...props}
  />
))(({ theme }) => ({
  alignItems: 'center',
  justifyContent: 'center'
}));

// a styled Floating Action Button
const StyledFloatingActionButton = styled((props: FabProps) => (
  <FloatingActionButton
    variant="extended"
    size="medium"
    color="primary"
    {...props}
  />
))(({ theme }) => ({
}));

// a styled Box for a Horizontal Divider (a CSS Horizontal Rule <hr/>)
const StyledBoxForHorizontalDivider = styled((props: BoxProps) => (
  <Box
    component='hr'
    {...props}
  />
))(({ theme }) => ({
  width: "100%",
  borderTop: `1px solid ${theme.palette.primary.light}`,
}));

// a styled Container for Data View
const StyledContainerForDataView = styled((props: ContainerProps) => (
  <Container
    disableGutters
    maxWidth={false}
    {...props}
  />
))(({ theme }) => ({
  flexGrow: 1,
  paddingLeft: 0,
  paddingRight: 0,
}));

// a styled Accordion for Data View
const StyledAccordion = styled((props: any) => (
  <Accordion
    {...props}
  />
))(({ theme }) => ({
  width: '100%'
}));

// a styled AccordionSummary for Pending Users
const StyledAccordionSummary = styled((props: any) => (
  <AccordionSummary
    {...props}
  />
))(({ theme }) => ({
}));

// a styled Grid for Accordian Summary Content
const StyledGridForAccordionSummaryContent = styled((props: GridProps) => (
  <Grid
    item
    container
    xs={12}
    direction="row"
    {...props}
  />
))(({ theme }) => ({
}));

// a styled Grid for an Accordian Summary Header
const StyledGridForAccordionSummaryHeader = styled((props: GridProps) => (
  <Grid
    item
    container
    xs={12}
    {...props}
  />
))(({ theme }) => ({
}));

// a styled Typography for an Accordian Summary Header
const StyledTypographyForAccordionSummaryHeader = styled((props: TypographyProps) => (
  <Typography
    variant='h6'
    {...props}
  />
))(({ theme }) => ({
}));

// a styled Grid for an Accordian Summary Description
const StyledGridForAccordionSummaryDescription = styled((props: GridProps) => (
  <Grid
    item
    container
    xs={12}
    {...props}
  />
))(({ theme }) => ({
}));

// a styled Typography for an Accordian Summary Description
const StyledTypographyForAccordionSummaryDescription = styled((props: TypographyProps) => (
  <Typography
    variant='body1'
    {...props}
  />
))(({ theme }) => ({
}));

// a styled AccordionDetails for Data View
const StyledAccordionDetails = styled((props: any) => (
  <AccordionDetails
    {...props}
  />
))(({ theme }) => ({
  flexDirection: "column"
}));


export interface ISharingRequestsPageProps extends PropsWithChildren<unknown> {

}

const SharingRequestsPage: React.FC<ISharingRequestsPageProps> = (props: ISharingRequestsPageProps) => {

  SharingRequestsPage.displayName = 'Sharing Requests Page';

  // whether to display console logs (displayConsoleLogs && console.log statements)
  const displayConsoleLogs: boolean = false;

  // call custom hook to get the current view type, so the value can be used to initialize the View Type state variable
  const collectionViewType: enumCollectionViewType = useGetCollectionViewType();

  // get UserCategoriesAndChannelsContextData from the UserCategoriesAndChannelsProvider using a custom hook (useUserCategoriesAndChannelsContext)
  const userCategoriesAndChannelsContext: IUserCategoriesAndChannelsContextData = useUserCategoriesAndChannelsContext();

  // // capture the status of 
  // const [userCategoriesAndChannelsDataStatus, setUserCategoriesAndChannelsDataStatus] =
  //   useState<enumCategoriesAndChannelsDataPreparationStatus>(userCategoriesAndChannelsContext.state.status);

  // // Channel View Models to be passed on to views within the page
  // const [channelViewModels, setChannelViewModels] = useState<Array<IChannelViewModel>>([]);

  // // the Categories associcated with the current user account
  // const [categories, setCategories] = useState<Array<ICategory>>([]);

  // // all ChannelViewModels for the current user account, organized by Category
  // // const [allChannelViewModelsByCategory, setAllChannelViewModelsByCategory] = useState<{ categoryId: string, channelViewModels: IChannelViewModel[], channelViewModelCount: number }[]>([]);
  // const [allChannelViewModelsByCategory, setAllChannelViewModelsByCategory] = useState<IChannelViewModelsByCategory[]>([]);

  // whether data is currently loading
  const [dataLoading, setDataLoading] = useState<boolean>(true);

  // if user is accepting a Shared Request, the Shared Request to accept
  const [sharedRequestToAccept, setSharedRequestToAccept] = useState<ISharingRequestViewModel | undefined>(undefined);
  // whether to show the Accept Confirmation dialog
  const [showAcceptConfirmationDlg, setShowAcceptConfirmationDlg] = useState<boolean>(false);

  // if user is declining a Shared Request, the Shared Request to decline
  const [sharedRequestToDecline, setSharedRequestToDecline] = useState<ISharingRequestViewModel | undefined>(undefined);
  // whether to show the Decline Confirmation dialog
  const [showDeclineConfirmationDlg, setShowDeclineConfirmationDlg] = useState<boolean>(false);

  // for dispatching redux actions
  const dispatch = useAppDispatch();

  // use a custom hook to get the Current User information from a CurrentUserContext/Provider higher up in the component tree
  const currentUserContextData: ICurrentUserContextData = useCurrentUserContext();
  displayConsoleLogs && console.log(`%c SharingRequestsPage. currentUser: \n${JSON.stringify(currentUserContextData)}`, 'background: #600; color: #fff');
  const userId: typeUniqueId | undefined = currentUserContextData.currentUser?.id;

  /**
   * @function resetDeclineSharingRequestStatus Resets the Decline Sharing Request status in Redux state
   */
  const resetDeclineSharingRequestStatus: () => void = useCallback(() => {
    dispatch(sharingRequestDeclineStatusChange(null));
  }, [dispatch])

  /**
   * @function resetDeclineSharingRequestStatus Resets the pertinent status values in Redux state
   */
  const resetSharingRequestStatusValues: () => void = () => {
    resetDeclineSharingRequestStatus();
    // resetAcceptSharingRequestStatus();
  }

  // a useEffect hook to respond to changes in the status of the Categories and Channels data
  useEffect(() => {
    // when the data preparation is complete, set all data variables
    if (userCategoriesAndChannelsContext.state.status === enumCategoriesAndChannelsDataPreparationStatus.DataPreparationComplete) {
      // setCategories(userCategoriesAndChannelsContext.state.categories ? userCategoriesAndChannelsContext.state.categories : []);
      // setChannelViewModels(userCategoriesAndChannelsContext.state.channelViewModels ? userCategoriesAndChannelsContext.state.channelViewModels : []);
      // setAllChannelViewModelsByCategory(userCategoriesAndChannelsContext.state.channelsByCategories ? userCategoriesAndChannelsContext.state.channelsByCategories : []);

      // indicate that we are no longer loading data
      if (dataLoading) {
        setDataLoading(false);
      }
    } else {
      // if the data has changed and the data preparation is not complete, indicate that we're loading data
      if (!dataLoading) {
        setDataLoading(true);
      }
    }
  // }, [userCategoriesAndChannelsContext, userCategoriesAndChannelsContext.state.status, dataLoading]);
  }, [userCategoriesAndChannelsContext.state.status, dataLoading]);


  // use a custom hook to evaluate the redux state for the decline Sharing Request workflow and any declineSharingRequest error
  const { workflowState: declineSharingRequestStatus, errorState: declineSharingRequestFailure } = useReduxWorkflowState((state: IStoreState) => state.declineSharingRequestStatus, (state: IStoreState) => state.declineSharingRequestFailure);

  /**
   * @method handleSharedRequestDecline Handles requests to decline a Shared Request
   * @param sharedRequestViewModel The Shared Request View Model having the info for declining
   */
  async function handleSharedRequestAccept(sharedRequestViewModel: ISharingRequestViewModel): Promise<void> {
    // set the Shared Request to be accepted into local state
    setSharedRequestToAccept(sharedRequestViewModel);

    // set flag to show the decline confirmation dialog
    setShowAcceptConfirmationDlg(true);
  }

  function handleSharedRequestAcceptConfirmed(): void {
    // using the Shared Resource object to be accepted from the local state, dispatch an action to accept the Shared Resource object
    if (sharedRequestToAccept !== undefined) {
      dispatch(sharingRequestAcceptanceRequest(sharedRequestToAccept.sharingRequest));
    }

    // call method to reset the Shared Request Accept local state details
    sharedRequestAcceptReset();
  }

  function handleSharedRequestAcceptCanceled(): void {
    // call method to reset the Shared Request Accept local state details
    sharedRequestAcceptReset();
  }

  function sharedRequestAcceptReset() {
    // reset the Shared Request to be accepted to 'undefined' in local state, since we're done with the object
    setSharedRequestToAccept(undefined);

    // set flag to hide the Accept confirmation dialog
    setShowAcceptConfirmationDlg(false);
  }

  /**
   * @method handleSharedRequestDecline Handles requests to decline a Shared Request
   * @param sharedRequestViewModel The Shared Request View Model having the info for declining
   */
  async function handleSharedRequestDecline(sharedRequestViewModel: ISharingRequestViewModel): Promise<void> {
    // set the Shared Request to be declined into local state
    setSharedRequestToDecline(sharedRequestViewModel);

    // set flag to show the decline confirmation dialog
    setShowDeclineConfirmationDlg(true);
  }

  function handleSharedRequestDeclineConfirmed(): void {
    // using the Shared Request object to be declined from the local state, dispatch an action to decline the Shared Request object
    if (sharedRequestToDecline !== undefined) {
      // dispatch(declineSharingRequestRequested(sharedRequestToDecline.sharingRequest));
      dispatch(sharingRequestDeclineRequest(sharedRequestToDecline.sharingRequest));
    }

    // call method to reset the Shared Request Decline local state details
    sharedRequestDeclineReset();
  }

  function handleSharedRequestDeclineCanceled(): void {
    // call method to reset the Shared Request Decline local state details
    sharedRequestDeclineReset();
  }

  function sharedRequestDeclineReset() {
    // reset the Shared Request to be Declined to 'undefined' in local state, since we're done with the object
    setSharedRequestToDecline(undefined);

    // set flag to hide the Shared Request Decline confirmation dialog
    setShowDeclineConfirmationDlg(false);
  }

  // data to indicate whether an activity indicator should be displayed and, if so, the message
  let activityIndicatorData: IActivityIndicatorData = {
    showActivityIndicator: false,
    activityIndicatorMessage: ''
  }

  // data to indicate whether an alert should be displayed
  let alertInfo: IAlertInfo | null = null;


  // indicates whether the Sharing Request has been successfully declined
  const [successfullyDeclinedSharingRequest, setSuccessfullyDeclinedSharingRequest] = useState<boolean>(false);

  // define an effect that will process whenever the successfullyDeclinedSharingRequest state changes
  useEffect(() => {
    if (successfullyDeclinedSharingRequest) {
      // dispatch an action to reset the declineSharingRequestStatus
      dispatch(sharingRequestDeclineStatusChange(null));

      // notify user via a Beacon notification that the Sharing Request has been declined
      dispatch(beaconChange(new Beacon(undefined, enumBeaconType.Success, MessagesStringAssets.sharingRequest_DeclineConfirmationHeader, MessagesStringAssets.sharingRequest_DeclineSuccess)));

      // reset the successfullyDeclinedSharingRequest flag
      setSuccessfullyDeclinedSharingRequest(false);
    }
  }, [successfullyDeclinedSharingRequest, dispatch]);

  if (dataLoading) {
    // display an activity indicator
    activityIndicatorData.showActivityIndicator = true;
    activityIndicatorData.activityIndicatorMessage = MessagesStringAssets.sharingRequests_Loading;
  } else {
    // if the workflow is in the midst of performing the declining of a Sharing Request, prepare an alert message fragment to 
    // display progress message(s)
    if (declineSharingRequestStatus !== undefined && declineSharingRequestStatus !== null) {
      if (declineSharingRequestStatus === enumWorkflowState.Requested || declineSharingRequestStatus === enumWorkflowState.InProgress) {
        // display an activity indicator
        activityIndicatorData.showActivityIndicator = true;
        activityIndicatorData.activityIndicatorMessage = MessagesStringAssets.sharingRequest_DeclineRequested;
      } else if (declineSharingRequestStatus === enumWorkflowState.Success) {
        // if the successfullyDeclinedSharingRequest is curently in a 'false' state, we'll want the flag to indicate that the declining was a success
        if (!successfullyDeclinedSharingRequest) {
          // set flag to indicate that the Sharing Request has been successfully declined, which will force a re-render to allow for cleanup and user notification
          setSuccessfullyDeclinedSharingRequest(true);
        }
      } else if (declineSharingRequestStatus === enumWorkflowState.Failure) {
        // display an alert message
        let failureMessage = 'Unknown error';
        if (declineSharingRequestFailure && declineSharingRequestFailure instanceof Error) {
          failureMessage = declineSharingRequestFailure.message;
        }
        // create an AlertInfo object and dispatch a request to set the AlertInfo into Redux state
        alertInfo = new AlertInfo(true, enumAlertType.Error, failureMessage);
        dispatch(alertInfoChange(alertInfo));
      }
    }
  }

  // set details for whether to display the Sharing Requests button (whether there are any invitations) and, if so, 
  // specify the text to display on the button
  let countOfSharingRequests: number = 0;
  let labelForSharingRequestsBtn: string = 'foo';
  if (
    (userCategoriesAndChannelsContext !== undefined) &&
    (userCategoriesAndChannelsContext.state.openSharingRequestViewModels !== undefined)) {
    countOfSharingRequests = userCategoriesAndChannelsContext.state.openSharingRequestViewModels.length;
    if (countOfSharingRequests === 1) {
      labelForSharingRequestsBtn = ControlsStringAssets.channelSharingRequest_Singular;
    } else {
      labelForSharingRequestsBtn = ControlsStringAssets.channelSharingRequests_Plural;
    }
  }
  const displaySharingRequestsBtn: boolean = countOfSharingRequests > 0;


  return (
    <>
      <StyledBoxForPaddingAtopPage />
      <GenericPageContainer
        // maxWidth="xl"
        showBackButton={true}
        pageTitle={PageAndViewTitleStringAssets.pageTitle_SharingRequests}
        // showCategoriesOption={true}
        // onClickEditCategories={handleClickEditChannelCategories}
        onCloseAlert={resetSharingRequestStatusValues}
      // fixedAlertInfo={alertInfo}
      >

        <StyledBoxForPaddingAboveDataViewControls />

        {/* Present the Data View Controls ("Add new" button & "View Type" toggle button) */}
        <DataViewControls
        // addNewItemButtonText={ControlsStringAssets.channelNew}
        // onClickAddNewItemButton={() => navigate('/channel')}
        >
        </DataViewControls>

        <StyledBoxForHorizontalDivider />

        {/* Container for embedded data view */}
        <StyledContainerForDataView>
          {
            // us an Immediately Invoked Function Expressions (IIFE) to streamline the logic and eliminate redundancies
            (() => {
              // default fragment to display is nothing, an empty React fragment
              let reactFragment: ReactElement = <></>;

              // if there are any Sharing Request View Models to display...
              if (userCategoriesAndChannelsContext.state.openSharingRequestViewModels && userCategoriesAndChannelsContext.state.openSharingRequestViewModels.length > 0) {
                const sharingRequestViewModels: Array<ISharingRequestViewModel> = userCategoriesAndChannelsContext.state.openSharingRequestViewModels;
                switch (collectionViewType) {
                  case enumCollectionViewType.CardsGridView:
                    reactFragment = <SharingRequestsCardGridView sharingRequestViewModels={sharingRequestViewModels} onAccept={handleSharedRequestAccept} onDecline={handleSharedRequestDecline} />;
                    break;

                  case enumCollectionViewType.ListView:
                    reactFragment = <SharingRequestsListView sharingRequestViewModels={sharingRequestViewModels} onAccept={handleSharedRequestAccept} onDecline={handleSharedRequestDecline} />;
                    break;
                }
              }

              return reactFragment;
            })()
          }

        </StyledContainerForDataView>

        {/* Accept Shared Request Confirmation Dialog */}
        <TwoButtonAcceptanceDialog
          showDialog={showAcceptConfirmationDlg}
          headerText={MessagesStringAssets.sharingRequest_AcceptConfirmationHeader}
          bodyText={MessagesStringAssets.sharingRequest_AcceptConfirmation}
          acceptanceButtonText={ControlsStringAssets.confirmButtonText}
          nonAcceptanceButtonText={ControlsStringAssets.cancelButtonText}
          onAcceptance={handleSharedRequestAcceptConfirmed}
          onNonAcceptance={handleSharedRequestAcceptCanceled}
        />

        {/* Decline Shared Request Confirmation Dialog */}
        <TwoButtonAcceptanceDialog
          showDialog={showDeclineConfirmationDlg}
          headerText={MessagesStringAssets.sharingRequest_DeclineConfirmationHeader}
          bodyText={MessagesStringAssets.sharingRequest_DeclineConfirmation}
          acceptanceButtonText={ControlsStringAssets.confirmButtonText}
          nonAcceptanceButtonText={ControlsStringAssets.cancelButtonText}
          onAcceptance={handleSharedRequestDeclineConfirmed}
          onNonAcceptance={handleSharedRequestDeclineCanceled}
        />

        {activityIndicatorData.showActivityIndicator &&
          <ActivityIndicatorDialog activityLabel={activityIndicatorData.activityIndicatorMessage} />
        }

      </GenericPageContainer>
    </>
  );
}

export default SharingRequestsPage;
