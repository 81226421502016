import React, { PropsWithChildren, useCallback, useEffect, useState } from 'react';
import _ from 'lodash';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, BoxProps, Container, ContainerProps } from '@mui/material';
import { ControlsStringAssets, MessagesStringAssets, PageAndViewTitleStringAssets } from '../../../../assets/stringAssets';
import GenericPageContainer from '../../GenericPageContainer/GenericPageContainer';
import { enumAlertType } from '../../../enums';
import { typeUniqueId } from '../../../../dataObjects/types';
import { IStoreState } from '../../../../uiMiddleware-redux/store/IStoreState';
import { enumBeaconType, enumCollectionViewType, enumWorkflowState } from '../../../../dataObjects/enums';
import ThemeSpecsListView from '../../../views/themeSpecs/ThemeSpecsListView/ThemeSpecsListView';
import { ThemeSpecsCardGridView } from '../../../views/themeSpecs/ThemeSpecsCardGridView/ThemeSpecsCardGridView';
import TwoButtonAcceptanceDialog from '../../../dialogs/TwoButtonAcceptanceDialog/TwoButtonAcceptanceDialog';
import { Beacon } from '../../../../dataObjects/models/alerts/Beacon';
import { useReduxWorkflowState } from '../../../customHooks';
import ActivityIndicatorDialog from '../../../dialogs/ActivityIndicatorDialog/ActivityIndicatorDialog';
import { IActivityIndicatorData } from '../../../dialogs/ActivityIndicatorData';
import { AlertInfo, IAlertInfo } from '../../../../dataObjects/models/alerts/AlertInfo';
import DataViewControls from '../../../controls/DataViewControls/DataViewControls';
import { useGetCollectionViewType } from '../../../customHooks/useGetCollectionViewType';
import { styled } from '@mui/styles';
import { ICurrentUserContextData, useCurrentUserContext } from '../../../providersAndContexts/currentUser';
import { IThemeSpecsViewModel } from '../../../../dataObjects/viewModels/themeSpecsViewModel';
import { getUserDefinedThemeSpecsForUser_onSnapshot, getUserDefinedThemeSpecsUsingId } from '../../../../firebaseServices/dataServices/dataServiceActions/themeSpecsActions';
import { IThemeSpecs, IThemeSpecsAsJson, ThemeSpecs } from '../../../../dataObjects/models/themes/ThemeSpecs';
import { DocumentData, QuerySnapshot } from 'firebase/firestore';
import { JsonConverter } from '../../../../dataObjects/utilities/JsonConverter';
import { useAppDispatch } from '../../../../uiMiddleware-redux/store/configureStore';
import { themeSpecsDeleteRequest, themeSpecsDeleteStatusChange } from '../../../../uiMiddleware-redux/slices/themeSpecs/themeSpecsDeleteStatusSlice';
import { beaconChange } from '../../../../uiMiddleware-redux/slices/beacon/beaconSlice';
import { alertInfoChange } from '../../../../uiMiddleware-redux/slices/alertInfo/alertInfoSlice';


/*** Using the Material UI Emotion Styling library, declare 'styled' instances for each area/object. 
 *** NOTE: These must be declared outside of the React Functional Component to ensure that the styled 
 *** objects will be properly rendered within the DOM. 
 ***/

// a styled Box (equivalent to a <div>), providing an area to display the overall header
const StyledBoxForPaddingAtopPage = styled((props: BoxProps) => (
  <Box
    {...props}
  />
))(({ theme }) => ({
  marginTop: theme.spacing(1),
}));

// a styled Box for a Horizontal Divider (a CSS Horizontal Rule <hr/>)
const StyledBoxForHorizontalDivider = styled((props: BoxProps) => (
  <Box
    component='hr'
    {...props}
  />
))(({ theme }) => ({
  width: "100%",
  borderTop: `1px solid ${theme.palette.primary.light}`,
}));

// a styled Container for Data View
const StyledContainerForDataView = styled((props: ContainerProps) => (
  <Container
    disableGutters
    maxWidth={false}
    {...props}
  />
))(({ theme }) => ({
  flexGrow: 1,
  paddingLeft: 0,
  paddingRight: 0,
}));


export interface ICustomThemesPageProps extends PropsWithChildren<unknown> {
}

const CustomThemesPage: React.FC<ICustomThemesPageProps> = (props: ICustomThemesPageProps) => {

  CustomThemesPage.displayName = 'CustomThemes Page';

  // whether to display console logs (displayConsoleLogs && console.log statements)
  const displayConsoleLogs: boolean = false;

  const params = useParams();

  displayConsoleLogs && console.log(`Entered CustomThemesPage.`);

  // call custom hook to get the current view type, so the value can be used to initialize the View Type state variable
  const collectionViewType: enumCollectionViewType = useGetCollectionViewType();

  // the ThemeSpecs associcated with the current user account
  const [themeSpecs, setThemeSpecs] = useState<Array<IThemeSpecsViewModel>>([]);
  // whether data is currently loading
  const [dataLoading, setDataLoading] = useState<boolean>(true);
  // if user is deleting a ThemeSpecs, the ThemeSpecs to delete
  const [themeSpecsToDelete, setThemeSpecsToDelete] = useState<IThemeSpecsViewModel | undefined>(undefined);
  // whether to show the Delete Confirmation dialog
  const [showDeleteConfirmationDlg, setShowDeleteConfirmationDlg] = useState<boolean>(false);

  // for dispatching redux actions
  const dispatch = useAppDispatch();
  // for using browser router navigate
  const navigate = useNavigate();

  // use a custom hook to get the Current User information from a CurrentUserContext/Provider higher up in the component tree
  const currentUserContextData: ICurrentUserContextData = useCurrentUserContext();
  displayConsoleLogs && console.log(`%c CustomThemesPage. currentUser: \n${JSON.stringify(currentUserContextData)}`, 'background: #600; color: #fff');
  const userId: typeUniqueId | undefined = currentUserContextData.currentUser?.id;

  /**
   * @function resetDeleteThemeSpecsStatus Resets the Delete status in Redux state
   */
  const resetDeleteThemeSpecsStatus: () => void = useCallback(() => {
    dispatch(themeSpecsDeleteStatusChange(null));
  }, [dispatch])


  // a useEffect hook to respond to changes in the userId
  useEffect(() => {
    // Declare an 'unsubscribe' variable that will hold the unsubscribe callback from a firestore onSnapshot() request.
    // We initialize it to a function that does nothing, so if an onSnapshot() is never requested, we can still call unsubscribe() during cleanup. 
    // After an onShapshot() request, the 'unsubscribe' variable will be set to a callback function issued by firestore.
    let unsubscribeCallback: () => void = () => { };

    if (userId) {
      // subscribe to onShapshot updates, providing realtime updates to the data, and capture the 'unsubscribe' callback method provided by firestore
      getUserDefinedThemeSpecsForUser_onSnapshot(userId, onSnapshotCallback).then((unsubscribe: () => void) => {
        unsubscribeCallback = unsubscribe;
        displayConsoleLogs && console.log(`%c CustomThemesPage: after initiating snapshot, unsubscribeCallback: ${unsubscribeCallback}`, 'background: #600; color: #fff');
      });
    }

    // perform cleanup when the component unmounts
    return () => {
      // if the user is still logged in...
      displayConsoleLogs && console.log(`CustomThemesPage: unmounting (useEffect return). userId: ${userId}`);
      displayConsoleLogs && console.log(`CustomThemesPage: unmounting (useEffect return). unsubscribeCallback: ${unsubscribeCallback}`);
      if (userId) {
        // Call the unsubscribe() callback to unsubscribe from the onSnapshot . If it was never 
        displayConsoleLogs && console.log(`CustomThemesPage. Ready to call 'unsubscribeCallback'`);
        unsubscribeCallback();
        displayConsoleLogs && console.log(`CustomThemesPage. Completed call to 'unsubscribeCallback'`);

        // reset the DeleteThemeSpecsStatus in Redux state to return to a clean slate
        resetDeleteThemeSpecsStatus();
      }
    }
  }, [userId]);

  // a useEffect hook to respond to changes in the status of the ThemeSpecs and Channels data
  useEffect(() => {
    //   // indicate that we are no longer loading data
    //   if (dataLoading) {
    //     setDataLoading(false);
    //   }
    // } else {
    //   // if the data has changed and the data preparation is not complete, indicate that we're loading data
    //   if (!dataLoading) {
    //     setDataLoading(true);
    //   }
  }, [dataLoading]);

  // use a custom hook to evaluate the redux state for the deleteThemeSpecs workflow and any deleteThemeSpecs error
  const { workflowState: deleteThemeSpecsStatus, errorState: deleteThemeSpecsFailure } = useReduxWorkflowState((state: IStoreState) => state.deleteThemeSpecsStatus, (state: IStoreState) => state.deleteThemeSpecsFailure);

  /**
   * @function onSnapshotCallback A callback method to receive firestore data snapshots for dynamic data updates
   * @param {QuerySnapshot<DocumentData>} snapshot A snapshot of data from firestore
   */
  function onSnapshotCallback(snapshot: QuerySnapshot<DocumentData>): void {
    // displayConsoleLogs && console.log(`ThemeSpecsPage:onSnapshotCallback(). Entered callback function.`);
    // set state variable indicating that dataLoading is no longer taking place
    setDataLoading(false);
    const themeSpecsDataAsJson: Array<IThemeSpecsAsJson> = [];
    // displayConsoleLogs && console.log(`ThemeSpecsPage:onSnapshotCallback(). Ready to get doc data.`);
    snapshot.forEach(doc => themeSpecsDataAsJson.push({ ...doc.data(), id: doc.id } as IThemeSpecsAsJson));
    // displayConsoleLogs && console.log(`ThemeSpecsPage:onSnapshotCallback(). Completed getting doc data.`);

    // create an array of ThemeSpecs objects from the JSON data
    let themeSpecsData = JsonConverter.arrayFromJSONArray(ThemeSpecs, themeSpecsDataAsJson);

    // use lodash to sort the array by 'name' in ascending order, if there are any elements in the array
    // themeSpecsData = _.orderBy(themeSpecsData, ['name'], ['asc']);
    if (themeSpecsData.length > 0) {
      themeSpecsData = _.orderBy(themeSpecsData, [themeSpecs => themeSpecs.name.toUpperCase()], ['asc']);
    }

    setThemeSpecs(themeSpecsData);
  }

  /**
   * @method handleThemeSpecsDelete Handles requests to delete a ThemeSpecs
   * @param themeSpecsViewModel The ThemeSpecs to be deleted
   */
  async function handleThemeSpecsDelete(themeSpecsViewModel: IThemeSpecsViewModel): Promise<void> {
    // set the themeSpecs to be deleted into local state
    setThemeSpecsToDelete(themeSpecsViewModel);

    // set flag to show the delete confirmation dialog
    setShowDeleteConfirmationDlg(true);
  }

  function handleThemeSpecsDeleteConfirmed(): void {
    if (themeSpecsToDelete !== undefined) {
      // fetch the ThemeSpecs object from the database, per the Id of the ThemeSpecsViewModel (themeSpecsToDelete)
      getUserDefinedThemeSpecsUsingId(themeSpecsToDelete.id).then((themeSpecs: IThemeSpecs | undefined) => {
        // using the ThemeSpecs object to be deleted from the local state, dispatch an action to delete the ThemeSpecs object
        if (themeSpecs !== undefined) {
          dispatch(themeSpecsDeleteRequest(themeSpecs));

          // call method to reset the themeSpecs delete local state details
          themeSpecsDeleteReset();
        }
      }).catch((error) => {
        throw error;
      });
    }
  }

  function handleThemeSpecsDeleteCanceled(): void {
    // call method to reset the themeSpecs delete local state details
    themeSpecsDeleteReset();
  }

  function themeSpecsDeleteReset() {
    // reset the themeSpecs to be deleted to 'undefined' in local state, since we're done with the object
    setThemeSpecsToDelete(undefined);

    // set flag to hide the delete confirmation dialog
    setShowDeleteConfirmationDlg(false);
  }


  // data to indicate whether an activity indicator should be displayed and, if so, the message
  let activityIndicatorData: IActivityIndicatorData = {
    showActivityIndicator: false,
    activityIndicatorMessage: ''
  }

  // data to indicate whether an alert should be displayed
  let alertInfo: IAlertInfo | undefined = undefined;

  // indicates whether the object has been successfully deleted
  const [successfullyDeletedObject, setSuccessfullyDeletedObject] = useState<boolean>(false);

  // define an effect that will process whenever the successfullyDeletedObject state changes
  useEffect(() => {
    if (successfullyDeletedObject) {
      // dispatch an action to reset the deleteThemeSpecsStatus
      resetDeleteThemeSpecsStatus();

      // notify user via a Beacon notification that the ThemeSpecs has been deleted
      dispatch(beaconChange(new Beacon(undefined, enumBeaconType.Success, MessagesStringAssets.themeSpecs_DeleteConfirmationHeader, MessagesStringAssets.themeSpecs_DeleteSuccess)));

      // reset the successfullyDeletedObject flag
      setSuccessfullyDeletedObject(false);
    }
  }, [successfullyDeletedObject, dispatch, resetDeleteThemeSpecsStatus]);

  if (dataLoading) {
    // display an activity indicator
    activityIndicatorData.showActivityIndicator = true;
    activityIndicatorData.activityIndicatorMessage = MessagesStringAssets.themeSpecs_Loading;
  } else {
    // if the workflow is in the midst of performing deletion of a themeSpecs, prepare an alert message fragment to display progress message(s)
    if (deleteThemeSpecsStatus !== undefined && deleteThemeSpecsStatus !== null) {
      if (deleteThemeSpecsStatus === enumWorkflowState.Requested || deleteThemeSpecsStatus === enumWorkflowState.InProgress) {
        // display an activity indicator
        activityIndicatorData.showActivityIndicator = true;
        activityIndicatorData.activityIndicatorMessage = MessagesStringAssets.themeSpecs_DeleteRequested;
      } else if (deleteThemeSpecsStatus === enumWorkflowState.Success) {
        // if the successfullyDeletedObject is curently in a 'false' state, we'll want the flag to indicate that the deletion was a success
        if (!successfullyDeletedObject) {
          // set flag to indicate that the object has been successfully deleted, which will force a re-render to allow for cleanup and user notification
          setSuccessfullyDeletedObject(true);
        }
      } else if (deleteThemeSpecsStatus === enumWorkflowState.Failure) {
        // display an alert message
        // alertFragment = generateJsxAlertMessageFragment(MessagesStringAssets.themeSpecs_DeleteFailure, enumJsxAlertMessageDisplayVariants.Failure, false, deleteThemeSpecsFailureMessage, MessagesStringAssets.substitutionKeyword);
        let failureMessage = 'Unknown error';
        if (deleteThemeSpecsFailure && deleteThemeSpecsFailure instanceof Error) {
          failureMessage = deleteThemeSpecsFailure.message;
        }
        alertInfo = new AlertInfo(true, enumAlertType.Error, failureMessage);
        dispatch(alertInfoChange(alertInfo));
      }
    }
  }

  // Establish the page title to be displayed. Once we have retrieved the parent ThemeSpecs, apply the themeSpecsNameAliasPlural; otherwise, show nothing
  const pageTitle: string = PageAndViewTitleStringAssets.pageTitle_CustomTheme;

  // Establish the text to be displayed on the '+ New' button
  const addNewButtonText: string = ControlsStringAssets.themeSpecsNew;

  return (
    <>
      <StyledBoxForPaddingAtopPage />
      <GenericPageContainer
        maxWidth="xl"
        showBackButton={true}
        pageTitle={pageTitle}
        onCloseAlert={resetDeleteThemeSpecsStatus}
      >

        {/* Present the Data View Controls ("Add new" button & "View Type" toggle button) */}
        <DataViewControls
          addNewItemButtonText={addNewButtonText}
          onClickAddNewItemButton={() => navigate(`/${userId}/customTheme`)}
        >
        </DataViewControls>

        <StyledBoxForHorizontalDivider />

        {/* Container for embedded data view */}
        <StyledContainerForDataView>

          {/* If there are ThemeSpecs to display and the current view selection is CardsGridView, display the ThemeSpecsCardGridView */}
          {themeSpecs &&
            (collectionViewType === enumCollectionViewType.CardsGridView) &&
            <ThemeSpecsCardGridView themeSpecsViewModels={themeSpecs} onDelete={handleThemeSpecsDelete} />
          }

          {/* If there are ThemeSpecs to display and the current view selection is ListView, display the ThemeSpecsListView */}
          {themeSpecs &&
            (collectionViewType === enumCollectionViewType.ListView) &&
            <ThemeSpecsListView themeSpecsViewModels={themeSpecs} onDelete={handleThemeSpecsDelete} />
          }
        </StyledContainerForDataView>

        {/* Delete ThemeSpecs Confirmation Dialog */}
        <TwoButtonAcceptanceDialog
          showDialog={showDeleteConfirmationDlg}
          headerText={MessagesStringAssets.themeSpecs_DeleteConfirmationHeader}
          bodyText={MessagesStringAssets.themeSpecs_DeleteConfirmation}
          acceptanceButtonText={ControlsStringAssets.confirmButtonText}
          nonAcceptanceButtonText={ControlsStringAssets.cancelButtonText}
          onAcceptance={handleThemeSpecsDeleteConfirmed}
          onNonAcceptance={handleThemeSpecsDeleteCanceled}
        />

        {activityIndicatorData.showActivityIndicator &&
          <ActivityIndicatorDialog activityLabel={activityIndicatorData.activityIndicatorMessage} />
        }

      </GenericPageContainer>
    </>
  );
}

export default CustomThemesPage;
