import { createSlice } from '@reduxjs/toolkit';
import { IMdbError } from '../../../errorObjects/MdbError';

const initialState: IMdbError | null = null;

export const userRedemptionCodeRequestSaveFailureSlice = createSlice({
  name: 'userRedemptionCodeRequestSaveFailure',
  initialState,
  reducers: {
    // action has a payload with an error
    userRedemptionCodeRequestSaveFailureChange(state, action) {
      return action.payload;
    },
        
    // action has a payload with a null value
    userRedemptionCodeRequestSaveFailureClear(state) {
      return null; // action.payload;
    },
  },
})

// Action creators are generated for each function in reducer)
export const { userRedemptionCodeRequestSaveFailureChange, userRedemptionCodeRequestSaveFailureClear } = userRedemptionCodeRequestSaveFailureSlice.actions;

// export the core reducer for the slice
export const userRedemptionCodeRequestSaveFailureReducer = userRedemptionCodeRequestSaveFailureSlice.reducer;

export default userRedemptionCodeRequestSaveFailureSlice.reducer;