import React, { useState, useEffect, ChangeEvent, PropsWithChildren } from 'react';
import { DocumentData, QuerySnapshot } from 'firebase/firestore';
import { useParams } from "react-router-dom";
import { typeUniqueId } from '../../../../dataObjects/types';
import { useTheme } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { Box, BoxProps, Tab, Tabs, TabsProps, useMediaQuery } from '@mui/material';
import { ControlsStringAssets, MessagesStringAssets, PageAndViewTitleStringAssets } from '../../../../assets/stringAssets';
import GenericPageContainer from '../../GenericPageContainer/GenericPageContainer';
import {
  Description as DocumentIcon,
  Image as ImageIcon,
  Link as HyperLinkIcon,
  Note as NoteIcon,
  ConnectWithoutContact as SocialMediaIcon,
  VideoLibrary as VideoLibraryIcon,
  VolumeUp as AudioClipIcon,
} from '@mui/icons-material';
import { IDigitalMedia } from '../../../../dataObjects/models/digitalMedia/DigitalMedia';
import { JsonConverter } from '../../../../dataObjects/utilities/JsonConverter';
import AudioLinksView from '../../../views/audioLinks/AudioLinksView/AudioLinksView';
import HyperLinksView from '../../../views/hyperLinks/HyperLinksView/HyperLinksView';
import NotesView from '../../../views/notes/NotesView/NotesView';
import ImageLinksView from '../../../views/imageLinks/ImageLinksView/ImageLinksView';
import VideoLinksView from '../../../views/videoLinks/VideoLinksView/VideoLinksView';
import { enumDigitalMediaType, enumPersistableObjectType } from '../../../../dataObjects/enums';
import { IStoreState } from '../../../../uiMiddleware-redux/store/IStoreState';
import { styled } from '@mui/styles';
import { IImageLink, IImageLinkAsJson, ImageLink } from '../../../../dataObjects/models/digitalMedia/ImageLink';
import _ from 'lodash';
import { IVideoLink, IVideoLinkAsJson, VideoLink } from '../../../../dataObjects/models/digitalMedia/VideoLink';
import { getVideoLinksForIds_onSnapshot, getVideoLinksForParentId_onSnapshot } from '../../../../firebaseServices/dataServices/dataServiceActions/videoLinkActions';
import { AudioLink, IAudioLink, IAudioLinkAsJson } from '../../../../dataObjects/models/digitalMedia/AudioLink';
import { getAudioLinksForIds_onSnapshot, getAudioLinksForParentId_onSnapshot } from '../../../../firebaseServices/dataServices/dataServiceActions/audioLinkActions';
import { INote, INoteAsJson, Note } from '../../../../dataObjects/models/digitalMedia/Note';
import { getNotesForIds_onSnapshot, getNotesForParentId_onSnapshot } from '../../../../firebaseServices/dataServices/dataServiceActions/noteActions';
import { HyperLink, IHyperLink, IHyperLinkAsJson } from '../../../../dataObjects/models/digitalMedia/HyperLink';
import { getHyperLinksForIds_onSnapshot, getHyperLinksForParentId_onSnapshot } from '../../../../firebaseServices/dataServices/dataServiceActions/hyperLinkActions';
import { AccessPermissions, IAccessPermissions, IUserAccessPermissionsForObject, UserAccessPermissionsForObject } from '../../../../dataObjects/models/collaboration/ObjectUserPermissions';
import { ICurrentUserContextData, useCurrentUserContext } from '../../../providersAndContexts/currentUser';
import { IUser } from '../../../../dataObjects/models/users/User';
import { Channel, IChannel, IChannelAsJson } from '../../../../dataObjects/models/channels/Channel';
import { DocumentLink, IDocumentLink, IDocumentLinkAsJson } from '../../../../dataObjects/models/digitalMedia/DocumentLink';
import { getDocumentLinksForIds_onSnapshot } from '../../../../firebaseServices/dataServices/dataServiceActions/documentLinkActions';
import { DigitalMediaSearchResults, IDigitalMediaSearchResultItem, IDigitalMediaSearchResults } from '../../../../dataObjects/models/digitalMediaSearch/digitalMediaSearchResults';
import { MdbError } from '../../../../errorObjects/MdbError';
import { getImageLinksForIds_onSnapshot } from '../../../../firebaseServices/dataServices/dataServiceActions/imageLinkActions';
import { AudioLinkViewModel, IAudioLinkViewModel } from '../../../../dataObjects/viewModels/audioLinkViewModel';
import { DocumentLinkViewModel, IDocumentLinkViewModel } from '../../../../dataObjects/viewModels/documentLinkViewModel';
import { HyperLinkViewModel, IHyperLinkViewModel } from '../../../../dataObjects/viewModels/hyperLinkViewModel';
import { IImageLinkViewModel, ImageLinkViewModel } from '../../../../dataObjects/viewModels/imageLinkViewModel';
import { INoteViewModel, NoteViewModel } from '../../../../dataObjects/viewModels/noteViewModel';
import { IVideoLinkViewModel, VideoLinkViewModel } from '../../../../dataObjects/viewModels/videoLinkViewModel';
import OneButtonDialog from '../../../dialogs/OneButtonDialog/OneButtonDialog';
import { NumericConstants } from '../../../../assets/numericAssets';
import { composeMessageUsingStringAsset } from '../../../messages';
import { IDigitalMediaSearchCriteria } from '../../../../dataObjects/models/digitalMediaSearch/digitalMediaSearchCriteria';
import DocumentLinksView from '../../../views/documentLinks/DocumentLinksView/DocumentLinksView';
import { getChannelsForIds_onSnapshot } from '../../../../firebaseServices/dataServices/dataServiceActions/channelActions';
import { mapReplacer } from '../../../utilities/mapUtilities';
import { ISocialMediaPost, ISocialMediaPostAsJson, SocialMediaPost } from '../../../../dataObjects/models/digitalMedia/SocialMediaPost';
import { ISocialMediaPostViewModel, SocialMediaPostViewModel } from '../../../../dataObjects/viewModels/socialMediaPostViewModel';
import { getSocialMediaPostsForIds_onSnapshot } from '../../../../firebaseServices/dataServices/dataServiceActions/socialMediaPostActions';
import SocialMediaPostsView from '../../../views/socialMediaPosts/SocialMediaPostsView/SocialMediaPostsView';
import { useAppDispatch, useAppSelector } from '../../../../uiMiddleware-redux/store/configureStore';
import { setSelectedDigitalMediaType } from '../../../../uiMiddleware-redux/slices/digitalMedia/selectedDigitalMediaTypeSlice';


/*** Using the Material UI Emotion Styling library, declare 'styled' instances for each area/object. 
 *** NOTE: These must be declared outside of the React Functional Component to ensure that the styled 
 *** objects will be properly rendered within the DOM. 
 ***/

// a styled Box (equivalent to a <div>), providing an area of padding at the top of the page
const StyledBoxForPaddingAtopPage = styled((props: BoxProps) => (
  <Box
    {...props}
  />
))(({ theme }) => ({
  marginTop: theme.spacing(1),
}));

// a styled Tabs object for displaying different Digital Media options
const StyledTabsForDigitalMediaOptions = styled((props: TabsProps) => (
  <Tabs
    {...props}
  />
))(({ theme }) => ({
  flexGrow: 1,
  width: "100%",
}));

// An interface for coalescing a group of digital media objects returned from a data snapshot request based on a collection
// of object ids. Firestore allows a maximum of 30 ids to be requested in a single snapshot request; therefore, we need to 
// be able to chunk both the requests and resulting data delivered.
interface IDigitalMediaObjectsGroup {
  unsubscribeCallback: () => void, // callback to unsubscribe from a snapshot subscription
  searchResultsDigitalMediaObjects: Array<object> // object is the digital media object
};

// The IAncestorIdsForDigitalMediaObject interface provides a collection of ancestor Ids for a digital media object.
interface IAncestorIdsForDigitalMediaObject {
  ancestorTopicItemId: string,
  ancestorTopicId: string,
  ancestorChannelId: string,
}

// Structure to organize objectIds and unsubscribe callback functions. Will be used for:
//  - each digital media object type;
//  - the channels across the entire range of digital media objects;
interface IQueryIdsAndUnsubcribeCallbacks {
  objectIds: Array<typeUniqueId>;
  unsubscribeCallbacks: Array<() => void>;
}

// Extracts the objectIds for digital media objects from search results and prepares a Map object that holds
// the details for all of the different types of digital media objects (audio, document, hyperlink, image, note, video)
function prepSearchResultsIdsForDigitalMediaObjectsSnapshots(digitalMediaSearchResults: IDigitalMediaSearchResults | null | undefined): Map<enumDigitalMediaType, IQueryIdsAndUnsubcribeCallbacks> {
  // whether to display console logs (displayConsoleLogs && console.log statements)
  const displayConsoleLogs: boolean = false;

  displayConsoleLogs && console.log(`%cDigitalMediaSearchResultsPage.prepSearchResultsIdsForDigitalMediaObjectsSnapshots. digitalMediaSearchResults: ${JSON.stringify(digitalMediaSearchResults)}`, 'background: #666; color: #fff');

  // initialize a map of digital media objects that will be loaded with applicable information and returned
  const localMapOfDigitalMediaObjects: Map<enumDigitalMediaType, IQueryIdsAndUnsubcribeCallbacks> = new Map<enumDigitalMediaType, IQueryIdsAndUnsubcribeCallbacks>([]);

  // copy the incoming search results to a local object, or if the search results parm hasn't been created, create a new empty one
  const localDigitalMediaSearchResults: IDigitalMediaSearchResults = digitalMediaSearchResults ? _.cloneDeep(digitalMediaSearchResults) : new DigitalMediaSearchResults([]);

  localDigitalMediaSearchResults.searchResults.forEach((searchResultsItem: IDigitalMediaSearchResultItem) => {
    displayConsoleLogs && console.log(`%cDigitalMediaSearchResultsPage.prepSearchResultsIdsForDigitalMediaObjectsSnapshots. forEach loop - searchResultsItem: ${JSON.stringify(searchResultsItem)}`, 'background: #666; color: #fff');
    const objectType: enumDigitalMediaType = searchResultsItem.objectType;

    displayConsoleLogs && console.log(`%cDigitalMediaSearchResultsPage.prepSearchResultsIdsForDigitalMediaObjectsSnapshots. localMapOfDigitalMediaObjects: ${JSON.stringify(localMapOfDigitalMediaObjects, mapReplacer)}`, 'background: #666; color: #fff');

    // if the current object type hasn't yet been seen, create an empty array for it within the map
    displayConsoleLogs && console.log(`%cDigitalMediaSearchResultsPage.prepSearchResultsIdsForDigitalMediaObjectsSnapshots. localMapOfDigitalMediaObjects.has(${objectType}): ${JSON.stringify(localMapOfDigitalMediaObjects.has(objectType))}`, 'background: #666; color: #fff');
    // if (localMapOfDigitalMediaObjects[objectType] === undefined) {
    if (!localMapOfDigitalMediaObjects.has(objectType)) {
      displayConsoleLogs && console.log(`%cDigitalMediaSearchResultsPage.prepSearchResultsIdsForDigitalMediaObjectsSnapshots. First instance of (${objectType}) object type`, 'background: #666; color: #fff');

      // localMapOfDigitalMediaObjects[objectType] = { objectIds: new Array<typeUniqueId>(searchResultsItem.id), unsubscribeCallbacks: new Array<() => void>() };
      localMapOfDigitalMediaObjects.set(objectType, { objectIds: new Array<typeUniqueId>(searchResultsItem.id), unsubscribeCallbacks: new Array<() => void>() });

      displayConsoleLogs && console.log(`%cDigitalMediaSearchResultsPage.prepSearchResultsIdsForDigitalMediaObjectsSnapshots. localMapOfDigitalMediaObjects.get(${objectType}) (${JSON.stringify(localMapOfDigitalMediaObjects.get(objectType))}) object type`, 'background: #666; color: #fff');
    } else {
      displayConsoleLogs && console.log(`%cDigitalMediaSearchResultsPage.prepSearchResultsIdsForDigitalMediaObjectsSnapshots. Additional instance of (${objectType}) object type`, 'background: #666; color: #fff');
      // add an id to the array of objectIds for that object type
      const digitalMediaObjectsForType: IQueryIdsAndUnsubcribeCallbacks | undefined = localMapOfDigitalMediaObjects.get(objectType);

      displayConsoleLogs && console.log(`%cDigitalMediaSearchResultsPage.prepSearchResultsIdsForDigitalMediaObjectsSnapshots. digitalMediaObjectsForType (${objectType}): (${JSON.stringify(digitalMediaObjectsForType)}) object type`, 'background: #666; color: #fff');

      if (digitalMediaObjectsForType) {
        digitalMediaObjectsForType.objectIds.push(searchResultsItem.id);
      }
    }
  });

  displayConsoleLogs && console.log(`%cDigitalMediaSearchResultsPage.prepSearchResultsIdsForSnapshots. localMapOfDigitalMediaObjects.size = ${localMapOfDigitalMediaObjects.size}; JSON.stringify(localMapOfDigitalMediaObjects, mapReplacer): ${JSON.stringify(localMapOfDigitalMediaObjects, mapReplacer)}`, 'background: #666; color: #fff');

  return localMapOfDigitalMediaObjects;
}

// From the parameter given, prepares a collection of Channel Ids an empty array of callback functions in preparation for 
// querying to get a snapshot of Channel objects related to the digital media objects that resulted from a search operaton. 
function prepSearchResultsIdsForChannelsSnapshots(digitalMediaSearchResults: IDigitalMediaSearchResults | null | undefined): IQueryIdsAndUnsubcribeCallbacks {
  // whether to display console logs (displayConsoleLogs && console.log statements)
  const displayConsoleLogs: boolean = false;

  displayConsoleLogs && console.log(`%cDigitalMediaSearchResultsPage.prepSearchResultsIdsForChannelsSnapshots. digitalMediaSearchResults: ${JSON.stringify(digitalMediaSearchResults)}`, 'background: #666; color: #fff');

  // create an array of unique Channel Ids in the search results
  const uniqueChannelIds: Array<typeUniqueId> = [];
  if (digitalMediaSearchResults) {
    digitalMediaSearchResults.searchResults.forEach(searchResultItem => {
      // if the channelId in the searchResultItem is NOT already in the array, add it to the array
      if (uniqueChannelIds.find(channelId => channelId === searchResultItem.channelId) === undefined) {
        uniqueChannelIds.push(searchResultItem.channelId);
      }
    });
  }

  displayConsoleLogs && console.log(`%cDigitalMediaSearchResultsPage.prepSearchResultsIdsForChannelsSnapshots. uniqueChannelIds: ${JSON.stringify(uniqueChannelIds)}`, 'background: #666; color: #fff');

  const queryIdsAndUnsubscribeCallbacks: IQueryIdsAndUnsubcribeCallbacks = {
    objectIds: [...uniqueChannelIds],
    unsubscribeCallbacks: []
  };

  displayConsoleLogs && console.log(`%cDigitalMediaSearchResultsPage.prepSearchResultsIdsForChannelsSnapshots. queryIdsAndUnsubscribeCallbacks: ${JSON.stringify(queryIdsAndUnsubscribeCallbacks)}`, 'background: #666; color: #fff');

  return queryIdsAndUnsubscribeCallbacks;
}

/**
 * @interface IDigitalMediaSearchResultsPageProps Input properties for the DigitalMediaSearchResultsPage
 */
export interface IDigitalMediaSearchResultsPageProps extends PropsWithChildren<unknown> {
}

const DigitalMediaSearchResultsPage: React.FC<IDigitalMediaSearchResultsPageProps> = (props: IDigitalMediaSearchResultsPageProps) => {

  DigitalMediaSearchResultsPage.displayName = 'Digital Media Search Results Page';

  // whether to display console logs (displayConsoleLogs && console.log statements)
  const displayConsoleLogs: boolean = false;

  const dispatch = useAppDispatch();

  const params = useParams();

  // whether to show a dialog indicating that the maximum number of search results has been exceeded
  const [showTooManySearchResultsDialog, setShowTooManySearchResultsDialog] = useState<boolean>(false);

  // determine if the screen width is currently small ('sm') or smaller, and then prepare display information accordingly
  const theme: Theme = useTheme();
  const onlySmallScreen: boolean = useMediaQuery(theme.breakpoints.down('sm'));
  const tabsDisplayFormat: 'scrollable' | 'fullWidth' | 'standard' | undefined = onlySmallScreen ? 'scrollable' : 'fullWidth'; // if small screen, make Tabs scrollable; otherwise, full width for Tabs
  // if small screen, don't show a label for the Tabs; if larger, include a label for Tabs
  const tabLabelForImages: string = onlySmallScreen ? '' : ControlsStringAssets.digitalMediaTab_Images;
  const tabLabelForVideos: string = onlySmallScreen ? '' : ControlsStringAssets.digitalMediaTab_Videos;
  const tabLabelForSocialMediaPosts: string = onlySmallScreen ? '' : ControlsStringAssets.digitalMediaTab_SocialMedia;
  const tabLabelForAudioClips: string = onlySmallScreen ? '' : ControlsStringAssets.digitalMediaTab_AudioClips;
  const tabLabelForDocuments: string = onlySmallScreen ? '' : ControlsStringAssets.digitalMediaTab_Documents;
  const tabLabelForNotes: string = onlySmallScreen ? '' : ControlsStringAssets.digitalMediaTab_Notes;
  const tabLabelForHyperlinks: string = onlySmallScreen ? '' : ControlsStringAssets.digitalMediaTab_Hyperlinks;

  const [digitalMediaBaseObj, setDigitalMediaBaseObj] = useState<IDigitalMedia | undefined>(undefined);

  // get which Digital Media type is currently set in state, so that we'll know which tab to select in the UI
  const selectedDigitalMediaTab: enumDigitalMediaType = useAppSelector((state: IStoreState) => state.selectedDigitalMediaType);

  // fetch from redux state whatever digital media search results that has been saved
  const digitalMediaSearchCriteria: IDigitalMediaSearchCriteria | null | undefined = useAppSelector((state: IStoreState) => state.digitalMediaSearchCriteria);

  // fetch from redux state whatever digital media search results that has been saved
  const digitalMediaSearchResults: IDigitalMediaSearchResults | null | undefined = useAppSelector((state: IStoreState) => state.digitalMediaSearchResults);
  displayConsoleLogs && console.log(`%cDigitalMediaSearchResultsPage. digitalMediaSearchResults: ${JSON.stringify(digitalMediaSearchResults)}`, 'background: #666; color: #fff');

  // const [mapOfDigitalMediaObjects, setMapOfDigitalMediaObjects] = useState<Map<enumDigitalMediaType, IDigitalMediaQueryIdsAndUnsubcribeCallbacks>>(prepSearchResultsIdsForSnapshots(digitalMediaSearchResults));
  const [mapOfDigitalMediaObjects, setMapOfDigitalMediaObjects] = useState<Map<enumDigitalMediaType, IQueryIdsAndUnsubcribeCallbacks>>(new Map<enumDigitalMediaType, IQueryIdsAndUnsubcribeCallbacks>());
  displayConsoleLogs && console.log(`%c DigitalMediaSearchResultsPage. mapOfDigitalMediaObjects: \n${JSON.stringify(mapOfDigitalMediaObjects, mapReplacer)}`, 'background: #006; color: #fff');

  // the AudioLinks associated with the current Digital Media
  const [audioLinks, setAudioLinks] = useState<Array<IAudioLink>>([]);
  // the AudioLinkViewModels associated with the current Digital Media
  const [audioLinkViewModels, setAudioLinkViewModels] = useState<Array<IAudioLinkViewModel>>([]);
  // whether AudioLinks data is currently loading
  const [audioLinksDataLoading, setAudioLinksDataLoading] = useState<boolean>(false);

  // the DocumentLinks associated with the current Digital Media
  const [documentLinks, setDocumentLinks] = useState<Array<IDocumentLink>>([]);
  // the DocumentLinkViewModels associated with the current Digital Media
  const [documentLinkViewModels, setDocumentLinkViewModels] = useState<Array<IDocumentLinkViewModel>>([]);
  // whether DocumentLinks data is currently loading
  const [documentLinksDataLoading, setDocumentLinksDataLoading] = useState<boolean>(false);

  // the HyperLinks associated with the current Digital Media
  const [hyperLinks, setHyperLinks] = useState<Array<IHyperLink>>([]);
  // the HyperLinkViewModels associated with the current Digital Media
  const [hyperLinkViewModels, setHyperLinkViewModels] = useState<Array<IHyperLinkViewModel>>([]);
  // whether HyperLinks data is currently loading
  const [hyperLinksDataLoading, setHyperLinksDataLoading] = useState<boolean>(false);

  // the ImageLinks associated with the current Digital Media
  const [imageLinks, setImageLinks] = useState<Array<IImageLink>>([]);
  // the ImageLinkViewModels associated with the current Digital Media
  const [imageLinkViewModels, setImageLinkViewModels] = useState<Array<IImageLinkViewModel>>([]);
  // whether ImageLinks data is currently loading
  const [imageLinksDataLoading, setImageLinksDataLoading] = useState<boolean>(false);

  // the Notes associated with the current Digital Media
  const [notes, setNotes] = useState<Array<INote>>([]);
  // the NoteViewModels associated with the current Digital Media
  const [noteViewModels, setNoteViewModels] = useState<Array<INoteViewModel>>([]);
  // whether NoteLinks data is currently loading
  const [notesDataLoading, setNotesDataLoading] = useState<boolean>(false);

  // the SocialMediaPosts associated with the current Digital Media
  const [socialMediaPosts, setSocialMediaPosts] = useState<Array<ISocialMediaPost>>([]);
  // the SocialMediaPostViewModels associated with the current Digital Media
  const [socialMediaPostViewModels, setSocialMediaPostViewModels] = useState<Array<ISocialMediaPostViewModel>>([]);
  // whether SocialMediaPosts data is currently loading
  const [socialMediaPostsDataLoading, setSocialMediaPostsDataLoading] = useState<boolean>(false);

  // the VideoLinks associated with the current Digital Media
  const [videoLinks, setVideoLinks] = useState<Array<IVideoLink>>([]);
  // the VideoLinkViewModels associated with the current Digital Media
  const [videoLinkViewModels, setVideoLinkViewModels] = useState<Array<IVideoLinkViewModel>>([]);
  // whether VideoLinks data is currently loading
  const [videoLinksDataLoading, setVideoLinksDataLoading] = useState<boolean>(false);


  // Ids and Unsubscribe Callbacks for ancestor Channels of the digital media objects
  const [channelIdsAndUnsubscribeCallbacks, setChannelIdsAndUnsubscribeCallbacks] = useState<IQueryIdsAndUnsubcribeCallbacks>({ objectIds: [], unsubscribeCallbacks: [] });
  // whether data for ancestor Channels has been fetched from the database
  const [ancestorChannels, setAncestorChannels] = useState<Array<IChannel>>([]);
  // whether data for ancestor Channels has been fetched from the database
  const [mapOfChannelsAndUserPermissions, setMapOfChannelsAndUserPermissions] = useState<Map<typeUniqueId, IUserAccessPermissionsForObject | undefined>>(new Map());
  // // whether data for ancestor Channels has been fetched from the database
  // const [ancestorChannelsFetched, setAncestorChannelsFetched] = useState<boolean>(false);

  const [digitalMediaSearchResultsForDisplay, setDigitalMediaSearchResultsForDisplay] = useState<IDigitalMediaSearchResults | undefined>(undefined);


  // use a custom hook to get the Current User information from a CurrentUserContext/Provider higher up in the component tree
  const currentUserContextData: ICurrentUserContextData = useCurrentUserContext();
  displayConsoleLogs && console.log(`%c DigitalMediaSearchResultsPage. currentUser: \n${JSON.stringify(currentUserContextData)}`, 'background: #006; color: #fff');
  const currentUser: IUser | undefined = currentUserContextData.currentUser;

  // Unsubscribes from snapshot queries for all digital media object searches
  function unsubscribeFromAllDigitalMediaSnapshots() {
    // Call the unsubscribe() callbacks for all snapshot requests to unsubscribe from those onSnapshot requests
    mapOfDigitalMediaObjects.forEach((digitalMediaIdsAndUnscribeCallbacks: IQueryIdsAndUnsubcribeCallbacks) => {
      digitalMediaIdsAndUnscribeCallbacks.unsubscribeCallbacks.forEach((unsubscribeCallback: () => void) => {
        // call the unsubscribe callback function
        unsubscribeCallback();
      });
    });
  }

  // Makes query request for a given type of digital media object.
  function requestDigitalMediaObjectsByType(digitalMediaObjectType: enumDigitalMediaType, digitalMediaIdsAndUnscribeCallbacks: IQueryIdsAndUnsubcribeCallbacks | undefined) {
    displayConsoleLogs && console.log(`%cDigitalMediaSearchResultsPage.requestDigitalMediaObjectsByType. digitalMediaObjectType: ${digitalMediaObjectType}; digitalMediaIdsAndUnscribeCallbacks: ${JSON.stringify(digitalMediaIdsAndUnscribeCallbacks)}`, 'background: #606; color: #fff');

    if ((digitalMediaIdsAndUnscribeCallbacks !== undefined) && (digitalMediaIdsAndUnscribeCallbacks.objectIds.length > 0)) {

      // switch on the digital media object type
      switch (digitalMediaObjectType) {
        case enumDigitalMediaType.Audio:
          displayConsoleLogs && console.log(`%cDigitalMediaSearchResultsPage.requestDigitalMediaObjectsByType. Requesting ${digitalMediaObjectType} objects; objectIds: ${new Array([...digitalMediaIdsAndUnscribeCallbacks.objectIds])}`, 'background: #606; color: #fff');
          // subscribe to onShapshot updates, providing realtime updates to the data, an capture the 'unsubscribe' callback method provided by firestore
          getAudioLinksForIds_onSnapshot([...digitalMediaIdsAndUnscribeCallbacks.objectIds], onAudioLinksSnapshotCallback).then((unsubscribeCallbacks: Array<() => void>) => {
            digitalMediaIdsAndUnscribeCallbacks.unsubscribeCallbacks = unsubscribeCallbacks;
          });

          // set indicator that data is being loaded
          setAudioLinksDataLoading(true);
          break;

        case enumDigitalMediaType.Document:
          displayConsoleLogs && console.log(`%cDigitalMediaSearchResultsPage.requestDigitalMediaObjectsByType. Requesting ${digitalMediaObjectType} objects; objectIds: ${new Array([...digitalMediaIdsAndUnscribeCallbacks.objectIds])}`, 'background: #606; color: #fff');
          // subscribe to onShapshot updates, providing realtime updates to the data, an capture the 'unsubscribe' callback method provided by firestore
          getDocumentLinksForIds_onSnapshot([...digitalMediaIdsAndUnscribeCallbacks.objectIds], onDocumentLinksSnapshotCallback).then((unsubscribeCallbacks: Array<() => void>) => {
            digitalMediaIdsAndUnscribeCallbacks.unsubscribeCallbacks = unsubscribeCallbacks;
          });

          // set indicator that data is being loaded
          setDocumentLinksDataLoading(true);
          break;

        case enumDigitalMediaType.HyperLink:
          displayConsoleLogs && console.log(`%cDigitalMediaSearchResultsPage.requestDigitalMediaObjectsByType. Requesting ${digitalMediaObjectType} objects; objectIds: ${new Array([...digitalMediaIdsAndUnscribeCallbacks.objectIds])}`, 'background: #606; color: #fff');
          // subscribe to onShapshot updates, providing realtime updates to the data, an capture the 'unsubscribe' callback method provided by firestore
          getHyperLinksForIds_onSnapshot([...digitalMediaIdsAndUnscribeCallbacks.objectIds], onHyperLinksSnapshotCallback).then((unsubscribeCallbacks: Array<() => void>) => {
            digitalMediaIdsAndUnscribeCallbacks.unsubscribeCallbacks = unsubscribeCallbacks;
          });

          // set indicator that data is being loaded
          setHyperLinksDataLoading(true);
          break;

        case enumDigitalMediaType.Image:
          displayConsoleLogs && console.log(`%cDigitalMediaSearchResultsPage.requestDigitalMediaObjectsByType. Requesting ${digitalMediaObjectType} objects; objectIds: ${new Array([...digitalMediaIdsAndUnscribeCallbacks.objectIds])}`, 'background: #606; color: #fff');
          // subscribe to onShapshot updates, providing realtime updates to the data, an capture the 'unsubscribe' callback method provided by firestore
          getImageLinksForIds_onSnapshot([...digitalMediaIdsAndUnscribeCallbacks.objectIds], onImageLinksSnapshotCallback).then((unsubscribeCallbacks: Array<() => void>) => {
            digitalMediaIdsAndUnscribeCallbacks.unsubscribeCallbacks = unsubscribeCallbacks;
          });

          // set indicator that data is being loaded
          setImageLinksDataLoading(true);
          break;

        case enumDigitalMediaType.Note:
          displayConsoleLogs && console.log(`%cDigitalMediaSearchResultsPage.requestDigitalMediaObjectsByType. Requesting ${digitalMediaObjectType} objects; objectIds: ${new Array([...digitalMediaIdsAndUnscribeCallbacks.objectIds])}`, 'background: #606; color: #fff');
          // subscribe to onShapshot updates, providing realtime updates to the data, an capture the 'unsubscribe' callback method provided by firestore
          getNotesForIds_onSnapshot([...digitalMediaIdsAndUnscribeCallbacks.objectIds], onNotesSnapshotCallback).then((unsubscribeCallbacks: Array<() => void>) => {
            digitalMediaIdsAndUnscribeCallbacks.unsubscribeCallbacks = unsubscribeCallbacks;
          });

          // set indicator that data is being loaded
          setNotesDataLoading(true);
          break;

        case enumDigitalMediaType.SocialMedia:
          displayConsoleLogs && console.log(`%cDigitalMediaSearchResultsPage.requestDigitalMediaObjectsByType. Requesting ${digitalMediaObjectType} objects; objectIds: ${new Array([...digitalMediaIdsAndUnscribeCallbacks.objectIds])}`, 'background: #606; color: #fff');
          // subscribe to onShapshot updates, providing realtime updates to the data, an capture the 'unsubscribe' callback method provided by firestore
          getSocialMediaPostsForIds_onSnapshot([...digitalMediaIdsAndUnscribeCallbacks.objectIds], onSocialMediaPostsSnapshotCallback).then((unsubscribeCallbacks: Array<() => void>) => {
            digitalMediaIdsAndUnscribeCallbacks.unsubscribeCallbacks = unsubscribeCallbacks;
          });

          // set indicator that data is being loaded
          setSocialMediaPostsDataLoading(true);
          break;

        case enumDigitalMediaType.Video:
          displayConsoleLogs && console.log(`%cDigitalMediaSearchResultsPage.requestDigitalMediaObjectsByType. Requesting ${digitalMediaObjectType} objects; objectIds: ${new Array([...digitalMediaIdsAndUnscribeCallbacks.objectIds])}`, 'background: #606; color: #fff');
          // subscribe to onShapshot updates, providing realtime updates to the data, an capture the 'unsubscribe' callback method provided by firestore
          getVideoLinksForIds_onSnapshot([...digitalMediaIdsAndUnscribeCallbacks.objectIds], onVideoLinksSnapshotCallback).then((unsubscribeCallbacks: Array<() => void>) => {
            digitalMediaIdsAndUnscribeCallbacks.unsubscribeCallbacks = unsubscribeCallbacks;
          });

          // set indicator that data is being loaded
          setVideoLinksDataLoading(true);
          break;

        default:
          throw new MdbError(`DigitalMediaSearchResultsPage.requestDigitalMediaObjects(). Unhandled digital media object type: ${digitalMediaObjectType}`);
      }
    }
  }

  // Makes a new request for all digital media objects found within the search results. For each digital media object type, 
  //  1) Unsubscribe from any existing snapshots; 
  //  2) Call method for that digital media object type to get the objects via one or more snapshots, subscribing to those snapshots;
  //  3) Capture the unsubscribe method pointers returned from the call to the method subscribing to the snapshots.
  function requestDigitalMediaObjects() {
    displayConsoleLogs && console.log(`%cDigitalMediaSearchResultsPage.requestDigitalMediaObjects. mapOfDigitalMediaObjects: ${JSON.stringify(mapOfDigitalMediaObjects, mapReplacer)}`, 'background: #606; color: #fff');

    // call method to unsubscribe from all outstanding digital media snapshot queries
    unsubscribeFromAllDigitalMediaSnapshots();

    requestDigitalMediaObjectsByType(enumDigitalMediaType.Audio, mapOfDigitalMediaObjects.get(enumDigitalMediaType.Audio));
    requestDigitalMediaObjectsByType(enumDigitalMediaType.Document, mapOfDigitalMediaObjects.get(enumDigitalMediaType.Document));
    requestDigitalMediaObjectsByType(enumDigitalMediaType.HyperLink, mapOfDigitalMediaObjects.get(enumDigitalMediaType.HyperLink));
    requestDigitalMediaObjectsByType(enumDigitalMediaType.Image, mapOfDigitalMediaObjects.get(enumDigitalMediaType.Image));
    requestDigitalMediaObjectsByType(enumDigitalMediaType.Note, mapOfDigitalMediaObjects.get(enumDigitalMediaType.Note));
    requestDigitalMediaObjectsByType(enumDigitalMediaType.SocialMedia, mapOfDigitalMediaObjects.get(enumDigitalMediaType.SocialMedia));
    requestDigitalMediaObjectsByType(enumDigitalMediaType.Video, mapOfDigitalMediaObjects.get(enumDigitalMediaType.Video));
  }

  // a useEffect hook for to evaluate changes to the digitalMediaSearchResults state variable
  useEffect(() => {
    {
      displayConsoleLogs && console.log(`%cDigitalMediaSearchResultsPage. useEffect for digitalMediaSearchResults. digitalMediaSearchResults: ${JSON.stringify(digitalMediaSearchResults)}`, 'background: #666; color: #fff');

      // if the number of objects (ids) in the search results exceeds the maximum allowed, set flag to show message in a dialog
      // and truncate the number of objects at the maximum
      if (digitalMediaSearchResults) {
        if (digitalMediaSearchResults.searchResults.length > NumericConstants.maxDigitalMediaSearchResults) {
          setShowTooManySearchResultsDialog(true);

          // truncate the number of search results and set into the digitalMediaSearchResultsForDisplay state variable
          setDigitalMediaSearchResultsForDisplay(new DigitalMediaSearchResults([...digitalMediaSearchResults.searchResults.slice(0, NumericConstants.maxDigitalMediaSearchResults - 1)]));
        } else {
          // merely copy the search results and set into the digitalMediaSearchResultsForDisplay state variable
          setDigitalMediaSearchResultsForDisplay(new DigitalMediaSearchResults([...digitalMediaSearchResults.searchResults]));
        }
      }
    }
  }, [digitalMediaSearchResults]);

  // a useEffect hook for to evaluate changes to the digitalMediaSearchResultsForDisplay state variable
  useEffect(() => {
    {
      displayConsoleLogs && console.log(`%cDigitalMediaSearchResultsPage. useEffect for digitalMediaSearchResultsForDisplay. digitalMediaSearchResultsForDisplay: ${JSON.stringify(digitalMediaSearchResultsForDisplay)}`, 'background: #666; color: #fff');

      setChannelIdsAndUnsubscribeCallbacks(prepSearchResultsIdsForChannelsSnapshots(digitalMediaSearchResultsForDisplay));

      setMapOfDigitalMediaObjects(prepSearchResultsIdsForDigitalMediaObjectsSnapshots(digitalMediaSearchResultsForDisplay));
    }
  }, [digitalMediaSearchResultsForDisplay]);

  // a useEffect hook for to evaluate changes to the mapOfDigitalMediaObjects state variable
  useEffect(() => {
    {
      // request fetching of the digital media objects for the object ids returned from a digital media search
      requestDigitalMediaObjects();
    }

    // perform cleanup when the component unmounts
    return () => {
      // call method to unsubscribe from all outstanding digital media snapshot queries
      unsubscribeFromAllDigitalMediaSnapshots();
    }
  }, [mapOfDigitalMediaObjects]);

  // a useEffect hook for to evaluate changes to the channelIdsAndUnsubscribeCallbacks state variable
  useEffect(() => {
    {
      displayConsoleLogs && console.log(`%cDigitalMediaSearchResultsPage. useEffect for channelIdsAndUnsubscribeCallbacks. channelIdsAndUnsubscribeCallbacks.objectIds: ${JSON.stringify(channelIdsAndUnsubscribeCallbacks.objectIds)}`, 'background: #666; color: #fff');

      // subscribe to onShapshot updates, providing realtime updates to the data, an capture the 'unsubscribe' callback method provided by firestore
      getChannelsForIds_onSnapshot([...channelIdsAndUnsubscribeCallbacks.objectIds], onChannelsSnapshotCallback).then((unsubscribeCallbacks: Array<() => void>) => {
        channelIdsAndUnsubscribeCallbacks.unsubscribeCallbacks = unsubscribeCallbacks;
      });
    }

    // perform cleanup when the component unmounts
    return () => {
      // call method to unsubscribe from all outstanding channel snapshot queries
      channelIdsAndUnsubscribeCallbacks.unsubscribeCallbacks.forEach((unsubscribeCallback: () => void) => {
        // call the unsubscribe callback function
        unsubscribeCallback();
      });
    }
  }, [channelIdsAndUnsubscribeCallbacks]);

  /**
   * @function onChannelsSnapshotCallback A callback method to receive firestore data snapshots for dynamic data updates
   * @param {QuerySnapshot<DocumentData>} snapshot A snapshot of data from firestore
   */
  function onChannelsSnapshotCallback(snapshot: QuerySnapshot<DocumentData>): void {

    const channelDataAsJson: Array<IChannelAsJson> = [];

    snapshot.forEach(doc => channelDataAsJson.push({ ...doc.data(), id: doc.id } as IChannelAsJson));

    // create an array of Channel objects from the JSON data
    let channelsData: Array<IChannel> = JsonConverter.arrayFromJSONArray(Channel, channelDataAsJson);

    displayConsoleLogs && console.log(`%cDigitalMediaSearchResultsPage.onChannelsSnapshotCallback(). channelsData: ${JSON.stringify(channelsData)}`, 'background: #F66; color: #fff');

    setAncestorChannels(channelsData);
  }

  // a useEffect hook for to evaluate changes to the ancestorChannels state variable
  useEffect(() => {
    // for each ancestor channel, create a UserPermission object pertaining to the current user, and then
    // build a Map object indexed by the ChannelId and value being the UserPermission object for the channel
    const localMapOfChannelIdsAndUserPermissions: Map<typeUniqueId, IUserAccessPermissionsForObject | undefined> = new Map();

    ancestorChannels.forEach((channel) => {
      // Determine the user's permissions relative to the channel
      const userPermissions: IUserAccessPermissionsForObject | undefined = currentUser && channel ? new UserAccessPermissionsForObject(currentUser.id, channel) : undefined;

      displayConsoleLogs && console.log(`%cDigitalMediaSearchResultsPage. useEffect for ancestorChannels. userPermissions for channel ${channel.id}: ${JSON.stringify(userPermissions)}`, 'background: #F66; color: #fff');

      // add that userPermissions to the Map, using the channel's id as the key
      localMapOfChannelIdsAndUserPermissions.set(channel.id, userPermissions);
    });

    displayConsoleLogs && console.log(`%cDigitalMediaSearchResultsPage. useEffect for ancestorChannels. localMapOfChannelIdsAndUserPermissions: ${JSON.stringify(localMapOfChannelIdsAndUserPermissions, mapReplacer)}`, 'background: #F66; color: #fff');

    setMapOfChannelsAndUserPermissions(localMapOfChannelIdsAndUserPermissions);

  }, [ancestorChannels]);

  /**
   * @function onAudioLinksSnapshotCallback A callback method to receive firestore data snapshots for dynamic data updates
   * @param {QuerySnapshot<DocumentData>} snapshot A snapshot of data from firestore
   */
  function onAudioLinksSnapshotCallback(snapshot: QuerySnapshot<DocumentData>): void {

    // set state variable indicating that dataLoading is no longer taking place
    setAudioLinksDataLoading(false);
    const audioLinkDataAsJson: Array<IAudioLinkAsJson> = [];

    snapshot.forEach(doc => audioLinkDataAsJson.push({ ...doc.data(), id: doc.id } as IAudioLinkAsJson));

    // create an array of AudioLink objects from the JSON data
    let audioLinksData: Array<IAudioLink> = JsonConverter.arrayFromJSONArray(AudioLink, audioLinkDataAsJson);

    // use lodash to sort the array by 'description' in ascending order
    audioLinksData = _.orderBy(audioLinksData, [audioLink => audioLink.description.toUpperCase()], ['asc']);

    setAudioLinks(audioLinksData);
  }

  /**
   * @function onDocumentLinksSnapshotCallback A callback method to receive firestore data snapshots for dynamic data updates
   * @param {QuerySnapshot<DocumentData>} snapshot A snapshot of data from firestore
   */
  function onDocumentLinksSnapshotCallback(snapshot: QuerySnapshot<DocumentData>): void {
    // console.log(`DocumentLinksView:onSnapshotCallback(). Entered callback function.`);
    // set state variable indicating that dataLoading is no longer taking place
    setDocumentLinksDataLoading(false);
    const documentLinkDataAsJson: Array<IDocumentLinkAsJson> = [];
    // console.log(`DocumentLinksView:onSnapshotCallback(). Ready to get doc data.`);
    snapshot.forEach(doc => documentLinkDataAsJson.push({ ...doc.data(), id: doc.id } as IDocumentLinkAsJson));
    // console.log(`DocumentLinksView:onSnapshotCallback(). Completed getting doc data.`);

    // create an array of DocumentLink objects from the JSON data
    let documentLinksData: Array<IDocumentLink> = JsonConverter.arrayFromJSONArray(DocumentLink, documentLinkDataAsJson);

    // use lodash to sort the array by 'description' in ascending order
    documentLinksData = _.orderBy(documentLinksData, [documentLink => documentLink.description.toUpperCase()], ['asc']);

    setDocumentLinks(documentLinksData);
  }

  /**
   * @function onHyperLinksSnapshotCallback A callback method to receive firestore data snapshots for dynamic data updates
   * @param {QuerySnapshot<DocumentData>} snapshot A snapshot of data from firestore
   */
  function onHyperLinksSnapshotCallback(snapshot: QuerySnapshot<DocumentData>): void {
    // console.log(`HyperLinksView:onSnapshotCallback(). Entered callback function.`);
    // set state variable indicating that dataLoading is no longer taking place
    setHyperLinksDataLoading(false);
    const hyperLinkDataAsJson: Array<IHyperLinkAsJson> = [];
    // console.log(`HyperLinksView:onSnapshotCallback(). Ready to get doc data.`);
    snapshot.forEach(doc => hyperLinkDataAsJson.push({ ...doc.data(), id: doc.id } as IHyperLinkAsJson));
    // console.log(`HyperLinksView:onSnapshotCallback(). Completed getting doc data.`);

    // create an array of HyperLink objects from the JSON data
    let hyperLinksData: Array<IHyperLink> = JsonConverter.arrayFromJSONArray(HyperLink, hyperLinkDataAsJson);

    // use lodash to sort the array by 'description' in ascending order
    hyperLinksData = _.orderBy(hyperLinksData, [hyperLink => hyperLink.description.toUpperCase()], ['asc']);

    setHyperLinks(hyperLinksData);
  }

  /**
   * @function onImageLinksSnapshotCallback A callback method to receive firestore data snapshots for dynamic data updates
   * @param {QuerySnapshot<DocumentData>} snapshot A snapshot of data from firestore
   */
  function onImageLinksSnapshotCallback(snapshot: QuerySnapshot<DocumentData>): void {
    displayConsoleLogs && console.log(`%c DigitalMediaSearchResultsPage.onImageLinksSnapshotCallback.`, 'background: #006; color: #fff');

    // console.log(`ImageLinksView:onSnapshotCallback(). Entered callback function.`);
    // set state variable indicating that dataLoading is no longer taking place
    setImageLinksDataLoading(false);
    const imageLinkDataAsJson: Array<IImageLinkAsJson> = [];
    // console.log(`ImageLinksView:onSnapshotCallback(). Ready to get doc data.`);
    snapshot.forEach(doc => imageLinkDataAsJson.push({ ...doc.data(), id: doc.id } as IImageLinkAsJson));
    // console.log(`ImageLinksView:onSnapshotCallback(). Completed getting doc data.`);

    // create an array of ImageLink objects from the JSON data
    let imageLinksData: Array<IImageLink> = JsonConverter.arrayFromJSONArray(ImageLink, imageLinkDataAsJson);

    displayConsoleLogs && console.log(`%c DigitalMediaSearchResultsPage.onImageLinksSnapshotCallback. imageLinksData: ${JSON.stringify(imageLinksData)}`, 'background: #606; color: #fff');

    // use lodash to sort the array by 'description' in ascending order
    imageLinksData = _.orderBy(imageLinksData, [imageLink => imageLink.description.toUpperCase()], ['asc']);

    setImageLinks(imageLinksData);
  }

  /**
   * @function onNotesSnapshotCallback A callback method to receive firestore data snapshots for dynamic data updates
   * @param {QuerySnapshot<DocumentData>} snapshot A snapshot of data from firestore
   */
  function onNotesSnapshotCallback(snapshot: QuerySnapshot<DocumentData>): void {
    // console.log(`NotesView:onSnapshotCallback(). Entered callback function.`);
    // set state variable indicating that dataLoading is no longer taking place
    setNotesDataLoading(false);
    const noteDataAsJson: Array<INoteAsJson> = [];
    // console.log(`NotesView:onSnapshotCallback(). Ready to get doc data.`);
    snapshot.forEach(doc => noteDataAsJson.push({ ...doc.data(), id: doc.id } as INoteAsJson));
    // console.log(`NotesView:onSnapshotCallback(). Completed getting doc data.`);

    // create an array of Note objects from the JSON data
    let notesData: Array<INote> = JsonConverter.arrayFromJSONArray(Note, noteDataAsJson);

    // use lodash to sort the array by 'description' in ascending order
    notesData = _.orderBy(notesData, [note => note.noteContent.toUpperCase()], ['asc']);

    setNotes(notesData);
  }

  /**
   * @function onSocialMediaPostsSnapshotCallback A callback method to receive firestore data snapshots for dynamic data updates
   * @param {QuerySnapshot<DocumentData>} snapshot A snapshot of data from firestore
   */
  function onSocialMediaPostsSnapshotCallback(snapshot: QuerySnapshot<DocumentData>): void {
    // console.log(`SocialMediaPostsView:onSnapshotCallback(). Entered callback function.`);
    // set state variable indicating that dataLoading is no longer taking place
    setSocialMediaPostsDataLoading(false);
    const socialMediaPostDataAsJson: Array<ISocialMediaPostAsJson> = [];
    // console.log(`SocialMediaPostsView:onSnapshotCallback(). Ready to get doc data.`);
    snapshot.forEach(doc => socialMediaPostDataAsJson.push({ ...doc.data(), id: doc.id } as ISocialMediaPostAsJson));
    // console.log(`SocialMediaPostsView:onSnapshotCallback(). Completed getting doc data.`);

    // create an array of SocialMediaPost objects from the JSON data
    let socialMediaPostsData: Array<ISocialMediaPost> = JsonConverter.arrayFromJSONArray(SocialMediaPost, socialMediaPostDataAsJson);

    // use lodash to sort the array by 'description' in ascending order
    socialMediaPostsData = _.orderBy(socialMediaPostsData, [socialMediaPost => socialMediaPost.description.toUpperCase()], ['asc']);

    setSocialMediaPosts(socialMediaPostsData);
  }

  /**
   * @function onVideoLinksSnapshotCallback A callback method to receive firestore data snapshots for dynamic data updates
   * @param {QuerySnapshot<DocumentData>} snapshot A snapshot of data from firestore
   */
  function onVideoLinksSnapshotCallback(snapshot: QuerySnapshot<DocumentData>): void {
    // console.log(`VideoLinksView:onSnapshotCallback(). Entered callback function.`);
    // set state variable indicating that dataLoading is no longer taking place
    setVideoLinksDataLoading(false);
    const videoLinkDataAsJson: Array<IVideoLinkAsJson> = [];
    // console.log(`VideoLinksView:onSnapshotCallback(). Ready to get doc data.`);
    snapshot.forEach(doc => videoLinkDataAsJson.push({ ...doc.data(), id: doc.id } as IVideoLinkAsJson));
    // console.log(`VideoLinksView:onSnapshotCallback(). Completed getting doc data.`);

    // create an array of VideoLink objects from the JSON data
    let videoLinksData: Array<IVideoLink> = JsonConverter.arrayFromJSONArray(VideoLink, videoLinkDataAsJson);

    // use lodash to sort the array by 'description' in ascending order
    videoLinksData = _.orderBy(videoLinksData, [videoLink => videoLink.description.toUpperCase()], ['asc']);

    setVideoLinks(videoLinksData);
  }

  const handleSelectedTabChange = (event: ChangeEvent<{}>, newSelectedDigitalMediaTab: enumDigitalMediaType) => {
    dispatch(setSelectedDigitalMediaType(newSelectedDigitalMediaTab));
  };

  function a11yProps(index: number) {
    return {
      id: `digital-media-tab-${index}`,
      'aria-controls': `digital-media-tabpanel-${index}`,
    };
  }

  function getCurrentUserPermissionsForChannel(channelId: string): IAccessPermissions {
    displayConsoleLogs && console.log(`%cDigitalMediaSearchResultsPage.getCurrentUserPermissionsForChannel(). channelId: ${channelId}`, 'background: #F06; color: #fff');

    const userPermissionsForChannel: IUserAccessPermissionsForObject | undefined = mapOfChannelsAndUserPermissions.get(channelId);
    displayConsoleLogs && console.log(`%cDigitalMediaSearchResultsPage.getCurrentUserPermissionsForChannel(). userPermissionsForChannel (${channelId}): ${JSON.stringify(userPermissionsForChannel)}`, 'background: #F06; color: #fff');

    const accessPermissions: IAccessPermissions = userPermissionsForChannel ?
      new AccessPermissions(userPermissionsForChannel.hasViewPermission, userPermissionsForChannel.hasEditPermission, userPermissionsForChannel.hasAdminPermission)
      :
      new AccessPermissions(false, false, false);

    displayConsoleLogs && console.log(`%cDigitalMediaSearchResultsPage.getCurrentUserPermissionsForChannel(). accessPermissions for channel (${channelId}): ${JSON.stringify(accessPermissions)}`, 'background: #F06; color: #fff');

    return accessPermissions;
  }

  function getAncestorIdsForDigitalMediaSearchResultsItem(searchResultsItem: IDigitalMediaSearchResultItem | undefined): IAncestorIdsForDigitalMediaObject {
    const ancestorTopicItemId: typeUniqueId = searchResultsItem ? searchResultsItem.topicItemId : 'NoAncestorTopicItemId';
    const ancestorTopicId: typeUniqueId = searchResultsItem ? searchResultsItem.topicId : 'NoAncestorTopicId';
    const ancestorChannelId: typeUniqueId = searchResultsItem ? searchResultsItem.channelId : 'NoChannelId';

    const ancestorIds: IAncestorIdsForDigitalMediaObject = {
      ancestorTopicItemId,
      ancestorTopicId,
      ancestorChannelId
    };

    return ancestorIds;
  }

  // set up a useEffect() hook to prepare the AudioLinksViewModel data when source data becomes available or changes
  useEffect(() => {
    let audioLinkViewModelsData: Array<IAudioLinkViewModel> = [];

    displayConsoleLogs && console.log(`%cDigitalMediaSearchResultsPage. useEffect to prepare the audioLinkViewModelsData. audioLinks.length: ${audioLinks.length}; audioLinks: ${JSON.stringify(audioLinks)}`, 'background: #F60; color: #fff');
    displayConsoleLogs && console.log(`%cDigitalMediaSearchResultsPage. useEffect to prepare the audioLinkViewModelsData. mapOfChannelsAndUserPermissions.size: ${mapOfChannelsAndUserPermissions.size}; mapOfChannelsAndUserPermissions: ${JSON.stringify(mapOfChannelsAndUserPermissions, mapReplacer)}`, 'background: #F06; color: #fff');

    // ensure that all necessary data is available to set up the viewModel data
    if ((audioLinks && (audioLinks.length > 0)) &&
      (mapOfChannelsAndUserPermissions && mapOfChannelsAndUserPermissions.size > 0)) {
      audioLinks.forEach((audioLink: IAudioLink) => {
        if (digitalMediaSearchResultsForDisplay) {
          const searchResultsItem: IDigitalMediaSearchResultItem | undefined = digitalMediaSearchResultsForDisplay.searchResults.find(item => item.id === audioLink.id);

          const ancestorIds: IAncestorIdsForDigitalMediaObject = getAncestorIdsForDigitalMediaSearchResultsItem(searchResultsItem);

          const accessPermissions: IAccessPermissions = getCurrentUserPermissionsForChannel(ancestorIds.ancestorChannelId);

          const audioLinkViewModel: IAudioLinkViewModel = new AudioLinkViewModel(audioLink, ancestorIds.ancestorTopicItemId, ancestorIds.ancestorTopicId, ancestorIds.ancestorChannelId, accessPermissions);
          audioLinkViewModelsData.push(audioLinkViewModel);
        }
      });
    }

    displayConsoleLogs && console.log(`%c DigitalMediaSearchResultsPage.useEffect() for audioLinks, digitalMediaSearchResultsForDisplay. audioLinkViewModelsData: ${JSON.stringify(audioLinkViewModelsData)}`, 'background: #606; color: #fff');

    setAudioLinkViewModels(audioLinkViewModelsData);
  }, [audioLinks, digitalMediaSearchResultsForDisplay, mapOfChannelsAndUserPermissions]);


  // set up a useEffect() hook to prepare the DocumentLinksViewModel data when source data becomes available or changes
  useEffect(() => {
    let documentLinkViewModelsData: Array<IDocumentLinkViewModel> = [];

    displayConsoleLogs && console.log(`%cDigitalMediaSearchResultsPage. useEffect to prepare the documentLinkViewModelsData. documentLinks.length: ${documentLinks.length}; documentLinks: ${JSON.stringify(documentLinks)}`, 'background: #F60; color: #fff');
    displayConsoleLogs && console.log(`%cDigitalMediaSearchResultsPage. useEffect to prepare the documentLinkViewModelsData. mapOfChannelsAndUserPermissions.size: ${mapOfChannelsAndUserPermissions.size}; mapOfChannelsAndUserPermissions: ${JSON.stringify(mapOfChannelsAndUserPermissions, mapReplacer)}`, 'background: #F06; color: #fff');

    // ensure that all necessary data is available to set up the viewModel data
    if ((documentLinks && (documentLinks.length > 0)) &&
      (mapOfChannelsAndUserPermissions && mapOfChannelsAndUserPermissions.size > 0)) {
      documentLinks.forEach((documentLink: IDocumentLink) => {
        if (digitalMediaSearchResultsForDisplay) {
          const searchResultsItem: IDigitalMediaSearchResultItem | undefined = digitalMediaSearchResultsForDisplay.searchResults.find(item => item.id === documentLink.id);

          const ancestorIds: IAncestorIdsForDigitalMediaObject = getAncestorIdsForDigitalMediaSearchResultsItem(searchResultsItem);

          const accessPermissions: IAccessPermissions = getCurrentUserPermissionsForChannel(ancestorIds.ancestorChannelId);

          const documentLinkViewModel: IDocumentLinkViewModel = new DocumentLinkViewModel(documentLink, ancestorIds.ancestorTopicItemId, ancestorIds.ancestorTopicId, ancestorIds.ancestorChannelId, accessPermissions);
          documentLinkViewModelsData.push(documentLinkViewModel);
        }
      });
    }

    displayConsoleLogs && console.log(`%c DigitalMediaSearchResultsPage.useEffect() for documentLinks, digitalMediaSearchResultsForDisplay. documentLinkViewModelsData: ${JSON.stringify(documentLinkViewModelsData)}`, 'background: #606; color: #fff');

    setDocumentLinkViewModels(documentLinkViewModelsData);
  }, [documentLinks, digitalMediaSearchResultsForDisplay, mapOfChannelsAndUserPermissions]);


  // set up a useEffect() hook to prepare the HyperLinksViewModel data when source data becomes available or changes
  useEffect(() => {
    let hyperLinkViewModelsData: Array<IHyperLinkViewModel> = [];

    displayConsoleLogs && console.log(`%cDigitalMediaSearchResultsPage. useEffect to prepare the hyperLinkViewModelsData. hyperLinks.length: ${hyperLinks.length}; hyperLinks: ${JSON.stringify(hyperLinks)}`, 'background: #F60; color: #fff');
    displayConsoleLogs && console.log(`%cDigitalMediaSearchResultsPage. useEffect to prepare the hyperLinkViewModelsData. mapOfChannelsAndUserPermissions.size: ${mapOfChannelsAndUserPermissions.size}; mapOfChannelsAndUserPermissions: ${JSON.stringify(mapOfChannelsAndUserPermissions, mapReplacer)}`, 'background: #F06; color: #fff');

    // ensure that all necessary data is available to set up the viewModel data
    if ((hyperLinks && (hyperLinks.length > 0)) &&
      (mapOfChannelsAndUserPermissions && mapOfChannelsAndUserPermissions.size > 0)) {
      hyperLinks.forEach((hyperLink: IHyperLink) => {
        if (digitalMediaSearchResultsForDisplay) {
          const searchResultsItem: IDigitalMediaSearchResultItem | undefined = digitalMediaSearchResultsForDisplay.searchResults.find(item => item.id === hyperLink.id);

          const ancestorIds: IAncestorIdsForDigitalMediaObject = getAncestorIdsForDigitalMediaSearchResultsItem(searchResultsItem);

          const accessPermissions: IAccessPermissions = getCurrentUserPermissionsForChannel(ancestorIds.ancestorChannelId);

          const hyperLinkViewModel: IHyperLinkViewModel = new HyperLinkViewModel(hyperLink, ancestorIds.ancestorTopicItemId, ancestorIds.ancestorTopicId, ancestorIds.ancestorChannelId, accessPermissions);
          hyperLinkViewModelsData.push(hyperLinkViewModel);
        }
      });
    }

    displayConsoleLogs && console.log(`%c DigitalMediaSearchResultsPage.useEffect() for hyperLinks, digitalMediaSearchResultsForDisplay. hyperLinkViewModelsData: ${JSON.stringify(hyperLinkViewModelsData)}`, 'background: #606; color: #fff');

    setHyperLinkViewModels(hyperLinkViewModelsData);
  }, [hyperLinks, digitalMediaSearchResultsForDisplay, mapOfChannelsAndUserPermissions]);


  // set up a useEffect() hook to prepare the ImageLinksViewModel data when source data becomes available or changes
  useEffect(() => {
    let imageLinkViewModelsData: Array<IImageLinkViewModel> = [];

    displayConsoleLogs && console.log(`%cDigitalMediaSearchResultsPage. useEffect to prepare the imagetLinkViewModelsData. imageLinks.length: ${imageLinks.length}; imageLinks: ${JSON.stringify(imageLinks)}`, 'background: #F60; color: #fff');
    displayConsoleLogs && console.log(`%cDigitalMediaSearchResultsPage. useEffect to prepare the imagetLinkViewModelsData. mapOfChannelsAndUserPermissions.size: ${mapOfChannelsAndUserPermissions.size}; mapOfChannelsAndUserPermissions: ${JSON.stringify(mapOfChannelsAndUserPermissions, mapReplacer)}`, 'background: #F06; color: #fff');

    // ensure that all necessary data is available to set up the viewModel data
    if ((imageLinks && (imageLinks.length > 0)) &&
      (mapOfChannelsAndUserPermissions && mapOfChannelsAndUserPermissions.size > 0)) {
      imageLinks.forEach((imageLink: IImageLink) => {
        if (digitalMediaSearchResultsForDisplay) {
          const searchResultsItem: IDigitalMediaSearchResultItem | undefined = digitalMediaSearchResultsForDisplay.searchResults.find(item => item.id === imageLink.id);

          const ancestorIds: IAncestorIdsForDigitalMediaObject = getAncestorIdsForDigitalMediaSearchResultsItem(searchResultsItem);

          const accessPermissions: IAccessPermissions = getCurrentUserPermissionsForChannel(ancestorIds.ancestorChannelId);

          const imageLinkViewModel: IImageLinkViewModel = new ImageLinkViewModel(imageLink, ancestorIds.ancestorTopicItemId, ancestorIds.ancestorTopicId, ancestorIds.ancestorChannelId, accessPermissions);
          imageLinkViewModelsData.push(imageLinkViewModel);
        }
      });
    }

    displayConsoleLogs && console.log(`%c DigitalMediaSearchResultsPage.useEffect() for imageLinks, digitalMediaSearchResultsForDisplay. imageLinkViewModelsData: ${JSON.stringify(imageLinkViewModelsData)}`, 'background: #606; color: #fff');

    setImageLinkViewModels(imageLinkViewModelsData);
  }, [imageLinks, digitalMediaSearchResultsForDisplay, mapOfChannelsAndUserPermissions]);


  // set up a useEffect() hook to prepare the NotesViewModel data when source data becomes available or changes
  useEffect(() => {
    let noteViewModelsData: Array<INoteViewModel> = [];

    displayConsoleLogs && console.log(`%cDigitalMediaSearchResultsPage. useEffect to prepare the noteViewModelsData. notes.length: ${notes.length}; notes: ${JSON.stringify(notes)}`, 'background: #F60; color: #fff');
    displayConsoleLogs && console.log(`%cDigitalMediaSearchResultsPage. useEffect to prepare the noteViewModelsData. mapOfChannelsAndUserPermissions.size: ${mapOfChannelsAndUserPermissions.size}; mapOfChannelsAndUserPermissions: ${JSON.stringify(mapOfChannelsAndUserPermissions, mapReplacer)}`, 'background: #F06; color: #fff');

    // ensure that all necessary data is available to set up the viewModel data
    if ((notes && (notes.length > 0)) &&
      (mapOfChannelsAndUserPermissions && mapOfChannelsAndUserPermissions.size > 0)) {
      notes.forEach((note: INote) => {
        if (digitalMediaSearchResultsForDisplay) {
          const searchResultsItem: IDigitalMediaSearchResultItem | undefined = digitalMediaSearchResultsForDisplay.searchResults.find(item => item.id === note.id);

          const ancestorIds: IAncestorIdsForDigitalMediaObject = getAncestorIdsForDigitalMediaSearchResultsItem(searchResultsItem);

          const accessPermissions: IAccessPermissions = getCurrentUserPermissionsForChannel(ancestorIds.ancestorChannelId);

          const noteViewModel: INoteViewModel = new NoteViewModel(note, ancestorIds.ancestorTopicItemId, ancestorIds.ancestorTopicId, ancestorIds.ancestorChannelId, accessPermissions);
          noteViewModelsData.push(noteViewModel);
        }
      });
    }

    displayConsoleLogs && console.log(`%c DigitalMediaSearchResultsPage.useEffect() for notes, digitalMediaSearchResultsForDisplay. noteViewModelsData: ${JSON.stringify(noteViewModelsData)}`, 'background: #606; color: #fff');

    setNoteViewModels(noteViewModelsData);
  }, [notes, digitalMediaSearchResultsForDisplay, mapOfChannelsAndUserPermissions]);


  // set up a useEffect() hook to prepare the SocialMediaPostsViewModel data when source data becomes available or changes
  useEffect(() => {
    let socialMediaPostViewModelsData: Array<ISocialMediaPostViewModel> = [];

    displayConsoleLogs && console.log(`%cDigitalMediaSearchResultsPage. useEffect to prepare the socialMediaPostViewModelsData. socialMediaPosts.length: ${socialMediaPosts.length}; socialMediaPosts: ${JSON.stringify(socialMediaPosts)}`, 'background: #F60; color: #fff');
    displayConsoleLogs && console.log(`%cDigitalMediaSearchResultsPage. useEffect to prepare the socialMediaPostViewModelsData. mapOfChannelsAndUserPermissions.size: ${mapOfChannelsAndUserPermissions.size}; mapOfChannelsAndUserPermissions: ${JSON.stringify(mapOfChannelsAndUserPermissions, mapReplacer)}`, 'background: #F06; color: #fff');

    // ensure that all necessary data is available to set up the viewModel data
    if ((socialMediaPosts && (socialMediaPosts.length > 0)) &&
      (mapOfChannelsAndUserPermissions && mapOfChannelsAndUserPermissions.size > 0)) {
      socialMediaPosts.forEach((socialMediaPost: ISocialMediaPost) => {
        if (digitalMediaSearchResultsForDisplay) {
          const searchResultsItem: IDigitalMediaSearchResultItem | undefined = digitalMediaSearchResultsForDisplay.searchResults.find(item => item.id === socialMediaPost.id);

          const ancestorIds: IAncestorIdsForDigitalMediaObject = getAncestorIdsForDigitalMediaSearchResultsItem(searchResultsItem);

          const accessPermissions: IAccessPermissions = getCurrentUserPermissionsForChannel(ancestorIds.ancestorChannelId);

          const socialMediaPostViewModel: ISocialMediaPostViewModel = new SocialMediaPostViewModel(socialMediaPost, ancestorIds.ancestorTopicItemId, ancestorIds.ancestorTopicId, ancestorIds.ancestorChannelId, accessPermissions);
          socialMediaPostViewModelsData.push(socialMediaPostViewModel);
        }
      });
    }

    displayConsoleLogs && console.log(`%c DigitalMediaSearchResultsPage.useEffect() for socialMediaPosts, digitalMediaSearchResultsForDisplay. socialMediaPostViewModelsData: ${JSON.stringify(socialMediaPostViewModelsData)}`, 'background: #606; color: #fff');

    setSocialMediaPostViewModels(socialMediaPostViewModelsData);
  }, [socialMediaPosts, digitalMediaSearchResultsForDisplay, mapOfChannelsAndUserPermissions]);

  // set up a useEffect() hook to prepare the VideoLinksViewModel data when source data becomes available or changes
  useEffect(() => {
    let videoLinkViewModelsData: Array<IVideoLinkViewModel> = [];

    displayConsoleLogs && console.log(`%cDigitalMediaSearchResultsPage. useEffect to prepare the videoLinkViewModelsData. videoLinks.length: ${videoLinks.length}; videoLinks: ${JSON.stringify(videoLinks)}`, 'background: #F60; color: #fff');
    displayConsoleLogs && console.log(`%cDigitalMediaSearchResultsPage. useEffect to prepare the videoLinkViewModelsData. mapOfChannelsAndUserPermissions.size: ${mapOfChannelsAndUserPermissions.size}; mapOfChannelsAndUserPermissions: ${JSON.stringify(mapOfChannelsAndUserPermissions, mapReplacer)}`, 'background: #F06; color: #fff');

    // ensure that all necessary data is available to set up the viewModel data
    if ((videoLinks && (videoLinks.length > 0)) &&
      (mapOfChannelsAndUserPermissions && mapOfChannelsAndUserPermissions.size > 0)) {
      videoLinks.forEach((videoLink: IVideoLink) => {
        if (digitalMediaSearchResultsForDisplay) {
          const searchResultsItem: IDigitalMediaSearchResultItem | undefined = digitalMediaSearchResultsForDisplay.searchResults.find(item => item.id === videoLink.id);

          const ancestorIds: IAncestorIdsForDigitalMediaObject = getAncestorIdsForDigitalMediaSearchResultsItem(searchResultsItem);

          const accessPermissions: IAccessPermissions = getCurrentUserPermissionsForChannel(ancestorIds.ancestorChannelId);

          const videoLinkViewModel: IVideoLinkViewModel = new VideoLinkViewModel(videoLink, ancestorIds.ancestorTopicItemId, ancestorIds.ancestorTopicId, ancestorIds.ancestorChannelId, accessPermissions);
          videoLinkViewModelsData.push(videoLinkViewModel);
        }
      });
    }

    displayConsoleLogs && console.log(`%c DigitalMediaSearchResultsPage.useEffect() for videoLinks, digitalMediaSearchResultsForDisplay. videoLinkViewModelsData: ${JSON.stringify(videoLinkViewModelsData)}`, 'background: #606; color: #fff');

    setVideoLinkViewModels(videoLinkViewModelsData);
  }, [videoLinks, digitalMediaSearchResultsForDisplay, mapOfChannelsAndUserPermissions]);


  function handleTooManySearchResultsDialogButtonClick() {
    setShowTooManySearchResultsDialog(false);
  }

  return (
    <>
      <StyledBoxForPaddingAtopPage />
      <GenericPageContainer
        showBackButton={true}
        objectSpecificPageHeaderCategory={PageAndViewTitleStringAssets.objectSpecificCategory_SearchPhrase}
        objectSpecificPageHeaderText={`"${digitalMediaSearchCriteria?.searchPhrase}"`}
        pageTitle={PageAndViewTitleStringAssets.pageTitle_DigitalMediaSearchResults}
        pageSubtitle={`(${digitalMediaSearchResultsForDisplay?.searchResults.length} objects displayed)`}
      >

        {/* Dialog to display message that too many search results were found */}
        <OneButtonDialog
          showDialog={showTooManySearchResultsDialog}
          headerText={MessagesStringAssets.searchResults_TooManySearchResultsFoundHeader}
          bodyText={composeMessageUsingStringAsset(MessagesStringAssets.searchResults_TooManySearchResultsFound, digitalMediaSearchResults ? `(${digitalMediaSearchResults.searchResults.length.toString()}) ` : '', MessagesStringAssets.substitutionKeyword)}
          buttonText={ControlsStringAssets.okButtonText}
          onButtonClick={handleTooManySearchResultsDialogButtonClick}
        />

        {/* {digitalMediaBaseObj && userPermissions && */}
        <>
          {/* For smaller views, display only an icon on each tab; for larger views  display a label and icon on each tab */}
          <StyledTabsForDigitalMediaOptions value={selectedDigitalMediaTab} variant={tabsDisplayFormat} onChange={handleSelectedTabChange} aria-label="Digital Media">
            <Tab value={enumDigitalMediaType.Image} label={tabLabelForImages + ` (${imageLinkViewModels.length})`} icon={<ImageIcon />} {...a11yProps(0)} color='inherit' />
            <Tab value={enumDigitalMediaType.Video} label={tabLabelForVideos + ` (${videoLinkViewModels.length})`} icon={<VideoLibraryIcon />} {...a11yProps(1)} />
            <Tab value={enumDigitalMediaType.SocialMedia} label={tabLabelForSocialMediaPosts + ` (${socialMediaPostViewModels.length})`} icon={<SocialMediaIcon />} {...a11yProps(1)} />
            <Tab value={enumDigitalMediaType.Audio} label={tabLabelForAudioClips + ` (${audioLinkViewModels.length})`} icon={<AudioClipIcon />} {...a11yProps(2)} />
            <Tab value={enumDigitalMediaType.Document} label={tabLabelForDocuments + ` (${documentLinkViewModels.length})`} icon={<DocumentIcon />} {...a11yProps(3)} />
            <Tab value={enumDigitalMediaType.Note} label={tabLabelForNotes + ` (${noteViewModels.length})`} icon={<NoteIcon />} {...a11yProps(4)} />
            <Tab value={enumDigitalMediaType.HyperLink} label={tabLabelForHyperlinks + ` (${hyperLinkViewModels.length})`} icon={<HyperLinkIcon />} {...a11yProps(5)} />
          </StyledTabsForDigitalMediaOptions>

          {selectedDigitalMediaTab === enumDigitalMediaType.Image && <ImageLinksView imageLinksToDisplay={imageLinkViewModels} imageLinksDataLoading={imageLinksDataLoading} allowAddingNewItems={false} />}
          {selectedDigitalMediaTab === enumDigitalMediaType.Video && <VideoLinksView videoLinksToDisplay={videoLinkViewModels} videoLinksDataLoading={videoLinksDataLoading} allowAddingNewItems={false} />}
          {selectedDigitalMediaTab === enumDigitalMediaType.SocialMedia && <SocialMediaPostsView socialMediaPostsToDisplay={socialMediaPostViewModels} socialMediaPostsDataLoading={socialMediaPostsDataLoading} allowAddingNewItems={false} />}
          {selectedDigitalMediaTab === enumDigitalMediaType.Audio && <AudioLinksView audioLinksToDisplay={audioLinkViewModels} audioLinksDataLoading={audioLinksDataLoading} allowAddingNewItems={false} />}
          {selectedDigitalMediaTab === enumDigitalMediaType.Document && <DocumentLinksView documentLinksToDisplay={documentLinkViewModels} documentLinksDataLoading={documentLinksDataLoading} allowAddingNewItems={false} />}
          {selectedDigitalMediaTab === enumDigitalMediaType.Note && <NotesView notesToDisplay={noteViewModels} notesDataLoading={notesDataLoading} allowAddingNewItems={false} />}
          {selectedDigitalMediaTab === enumDigitalMediaType.HyperLink && <HyperLinksView hyperLinksToDisplay={hyperLinkViewModels} hyperLinksDataLoading={hyperLinksDataLoading} allowAddingNewItems={false} />}
        </>
        {/* } */}

      </GenericPageContainer>
    </>
  );

}

export default DigitalMediaSearchResultsPage;