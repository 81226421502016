/** 
 * @enum {enumFirestoreDataRepositoryDataType} Represents the different types of repository types for a Firestore database
 */
export enum enumFirestoreDataRepositoryDataType {
  AudioLink = 'AudioLink',
  Category = 'Category',
  Channel = 'Channel',
  DigitalMedia = 'DigitalMedia',
  DocumentLink = 'DocumentLink',
  EmailRequest = 'EmailRequest',
  HyperLink = 'HyperLink',
  ImageLink = 'ImageLink ',
  Note = 'Note',
  ObjectSharingRequestTracker = 'ObjectSharingRequestTracker',
  PersistenceMetadata = 'PersistenceMetadata',
  SocialMediaPost = 'SocialMediaPost',
  ThemeSpecs = 'ThemeSpecs',
  Topic = 'Topic',
  TopicItem = 'TopicItem',
  User = 'User',
  UserCategories = 'UserCategories',
  UserRedemptionCodeRequest = 'UserRedemptionCodeRequest',
  UserSettings = 'UserSettings',
  VideoLink = 'VideoLink'
}
