/**
 * @class MenuItemStringAssets A collection of string assets that are use in menu items
 */
export class MenuItemStringAssets {

  // AppHeader and User Account Menu Items
  static menuItem_About: string = 'About';
  static menuItem_BackupDataFiles: string = 'Backup Data Files';
  static menuItem_Close: string = 'Close';
  static menuItem_CustomThemes: string = 'Custom Themes';
  static menuItem_ExpungeUser: string = 'Expunge User';
  static menuItem_Home: string = 'Home';
  static menuItem_Login: string = 'Sign in';
  static menuItem_Logout: string = 'Sign out';
  static menuItem_PingSearchMetadataDb: string = 'Ping SearchMetadata DB';
  static menuItem_PopulateSearchMetadataDb: string = 'Populate Search Data';
  static menuItem_UserRedemptionCodeRequests: string = 'User Redemption Code Requests';
  static menuItem_Search: string = 'Search';
  static menuItem_Settings: string = 'Settings';
  static menuItem_SetTheme: string = 'Set Theme';
  static menuItem_TestCloudSql: string = 'Test Cloud SQL';

  // "Navigate To" Menu Items
  static menuItem_NavigateToAncestorChannel: string = 'Ancestor Channel';
  static menuItem_NavigateToAncestorTopic: string = 'Ancestor Topic';
  static menuItem_NavigateToParentTopicItem: string = 'Parent Topic Item';

}