import React, { useState, useEffect } from 'react';
import { Route, Routes, Navigate, useLocation, Location } from "react-router-dom";
import HomePage from '../pages/HomePage/HomePage';
import AboutPage from '../pages/AboutPage/AboutPage';
import CategoriesPage from '../pages/categories/CategoriesPage/CategoriesPage';
import ChannelSharingPage from '../pages/channelSharing/ChannelSharingPage/ChannelSharingPage';
// import DashboardPage from '../pages/DashboardPage/DashboardPage';
import DigitalMediaPage from '../pages/DigitalMediaPage/DigitalMediaPage';
import ExpungeUserPage from '../pages/ExpungeUserPage/ExpungeUserPage';
import LoginPage from '../pages/LoginPage/LoginPage';
import LogoutPage from '../pages/LogoutPage/LogoutPage';
import ManageAudioLinkPage from '../pages/ManageAudioLinkPage/ManageAudioLinkPage';
import ManageCategoryPage from '../pages/categories/ManageCategoryPage/ManageCategoryPage';
import ManageChannelPage from '../pages/channels/ManageChannelPage/ManageChannelPage';
import ManageChannelSharingRequestPage from '../pages/channelSharingRequest/ManageChannelSharingRequestPage/ManageChannelSharingRequestPage';
import ManageChannelSharingUserPage from '../pages/channelSharing/ManageChannelSharingUserPage/ManageChannelSharingUserPage';
import CustomThemesPage from '../pages/themeSpecs/CustomThemesPage/CustomThemesPage';
import ManageHyperLinkPage from '../pages/ManageHyperLinkPage/ManageHyperLinkPage';
import ManageImageLinkPage from '../pages/ManageImageLinkPage/ManageImageLinkPage';
import ManageNotePage from '../pages/ManageNotePage/ManageNotePage';
import ManageSocialMediaPostPage from '../pages/ManageSocialMediaPostPage/ManageSocialMediaPostPage';
import ManageTopicItemPage from '../pages/topicItems/ManageTopicItemPage/ManageTopicItemPage';
import ManageTopicPage from '../pages/topics/ManageTopicPage/ManageTopicPage';
import ManageUserSettingsPage from '../pages/userSettings/ManageUserSettingsPage/ManageUserSettingsPage';
import ManageVideoLinkPage from '../pages/ManageVideoLinkPage/ManageVideoLinkPage';
import PageNotFound from '../pages/PageNotFound/PageNotFound';
import RegistrationPage from '../pages/RegistrationPage/RegistrationPage';
import TopicsPage from '../pages/topics/TopicsPage/TopicsPage';
import TopicItemsPage from '../pages/topicItems/TopicItemsPage/TopicItemsPage';
import { ICurrentUserContextData, useCurrentUserContext } from '../providersAndContexts/currentUser';
import { enumAuthenticationStatus } from '../enums';
import SharingRequestsPage from '../pages/sharingRequests/SharingRequestsPage/SharingRequestsPage';
import ManageCustomThemePage from '../pages/themeSpecs/ManageCustomThemePage/ManageCustomThemePage';
import ManageDocumentLinkPage from '../pages/ManageDocumentLinkPage/ManageDocumentLinkPage';
import AuthActionHandlerPage from '../pages/actionHandlers/AuthActionHandlerPage/AuthActionHandlerPage';
import UpdateEmailAddressPage from '../pages/UpdateEmailAddressPage/UpdateEmailAddressPage';
import BackupDataFilesPage from '../pages/BackupDataFilesPage/BackupDataFilesPage';
import PopulateSearchDataPage from '../pages/PopulateSearchDataPage/PopulateSearchDataPage';
import DigitalMediaSearchCriteriaPage from '../pages/digitalMediaSearch/DigitalMediaSearchCriteriaPage/DigitalMediaSearchCriteriaPage';
import DigitalMediaSearchResultsPage from '../pages/digitalMediaSearch/DigitalMediaSearchResultsPage/DigitalMediaSearchResultsPage';
import SetThemePage from '../pages/themeSpecs/SetThemePage/SetThemePage';
import UserRedemptionCodeRequestsPage from '../pages/userRedemptionCodeRequests/UserRedemptionCodeRequestsPage/UserRedemptionCodeRequestsPage';
import ManageUserRedemptionCodeRequestPage from '../pages/userRedemptionCodeRequests/ManageUserRedemptionCodeRequestPage/ManageUserRedemptionCodeRequestPage';

/**
 * @function RoutesFragment A React function element that renders the appropriate routes (Routes & individual Route elements from react-router-dom), based on whether 
 *   the user is authenticated
 */
export const RoutesFragment: React.FC = (): JSX.Element => {

  // whether to display console logs (displayConsoleLogs && console.log statements)
  const displayConsoleLogs: boolean = false;

  // State of authentication
  const [authenticationStatus, setAuthenticationStatus] = useState<enumAuthenticationStatus>(enumAuthenticationStatus.None);

  // get the currentUserContextData from the CurrentUserContext (CurrentUserProvider) that wraps this component
  const currentUserContextData: ICurrentUserContextData = useCurrentUserContext();

  // for routing, we only need to know the authentication status, regardless of the user
  if (authenticationStatus !== currentUserContextData.authenticationStatus) {
    setAuthenticationStatus(currentUserContextData.authenticationStatus);
  }

  const currentPath: Location = useLocation();
  displayConsoleLogs && console.info(`RoutesFragment. path: ${currentPath.pathname}; authenticationStatus: ${authenticationStatus}`)


  return (
    // Route to the appropriate page, based on the state of user authentication and the URL provided.
    // Logic for the state of user authentication:
    //   1) If the user has not logged in (is an Anonymous user), route to pages available to an anonymous user
    //   2) If the authentication status hasn't been set (is 'None') -OR- user is in the process of logging in (Authentication In Progress), present an initialization page
    //   3) If the user has been authenticated (Authentication Complete), route to the appropriate app page, per the given URL
    <>
      {
        // if we have an Anonymous user...
        (authenticationStatus === enumAuthenticationStatus.AnonymousUser) ?
          // route to pages available to an anonymous user 
          <>
            <Routes>
              {/* if the URL specifies login, route to the Login Page */}
              <Route path="/login" element={< LoginPage />} />
              {/* if the URL specifies to create a new account, route to the Registration Page */}
              <Route path="/createNewAccount" element={< RegistrationPage />} />
              {/* if the URL specifies 'about', route to the About Page */}
              <Route path="/about" element={< AboutPage />} />

              {/* route to the Authentication Action Handler Page */}
              <Route path="/authAction" element={< AuthActionHandlerPage />} />

              {/* if the URL specifies the home page, route to the Home Page */}
              <Route path="/" element={<Navigate replace to="/login" />} />

              {/* otherwise, route to the Page Not Found Page */}
              <Route element={< PageNotFound />} />
            </Routes>
          </>
          :
          // in here, we will have routes for Authentication In Progress and Authentication Complete
          <>
            {
              ((authenticationStatus === enumAuthenticationStatus.None) || (authenticationStatus === enumAuthenticationStatus.AuthenticationInProgress)) ?
                // present a page indicating that initialization is taking place
                <>
                  Initializing...
                </>
                :
                <>
                  {/* Authentication is complete. Provide routes for a user that has logged in. */}
                  <Routes>
                    {/* if the URL is for Login or for Account Creation, redirect route to the Home Page */}
                    <Route path="/login" element={<Navigate replace to="/" />} />
                    <Route path="/createNewAccount" element={<Navigate replace to="/" />} />

                    {/* route to the Home, Login, and About Pages */}
                    <Route path="/" element={< HomePage />} />
                    <Route path="/logout" element={< LogoutPage />} />
                    <Route path="/about" element={< AboutPage />} />

                    {/* route to the Search Digital Media Criteria Page */}
                    <Route path="/digitalMediaSearchCriteria" element={< DigitalMediaSearchCriteriaPage />} />

                    {/* route to the Search Digital Media Results Page */}
                    <Route path="/digitalMediaSearchResults" element={< DigitalMediaSearchResultsPage />} />

                    {/* route to the Set Theme Page */}
                    <Route path="/setTheme" element={< SetThemePage />} />

                    {/* route to the User Settings Page */}
                    <Route path="/settings" element={< ManageUserSettingsPage />} />

                    {/* route to the Update Email Address Page */}
                    <Route path="/updateEmailAddress" element={< UpdateEmailAddressPage />} />

                    {/* route to the Authentication Action Handler Page */}
                    <Route path="/authAction" element={< AuthActionHandlerPage />} />

                    {/* route to the Custom Themes Page */}
                    <Route path="/customThemes" element={< CustomThemesPage />} />
                    {/* route to the Manage Custom Theme Page in Create mode */}
                    <Route path="/customTheme/:id" element={< ManageCustomThemePage />} />
                    {/* route to the Manage Custom Theme Page in Edit mode */}
                    <Route path="/:userId/customTheme" element={< ManageCustomThemePage />} />

                    {/* route to the User Redemption Code Requests Page */}
                    <Route path="/userRedemptionCodeRequests" element={< UserRedemptionCodeRequestsPage />} />
                    {/* route to the Manage User Redemption Code Request Page */}
                    <Route path="/userRedemptionCodeRequest" element={< ManageUserRedemptionCodeRequestPage />} />

                    {/* route to the Populate Search Data Page */}
                    <Route path="/populateSearchData" element={< PopulateSearchDataPage />} />

                    {/* route to the Backup Data Files Page */}
                    <Route path="/backupDataFiles" element={< BackupDataFilesPage />} />

                    {/* route to the Expunge User Page */}
                    <Route path="/expungeUser" element={< ExpungeUserPage />} />

                    {/* <Route path="/dashboard" element={< DashboardPage />} /> */}

                    {/* route to the page that presents all Categories */}
                    <Route path="/:parentId/:parentType/categories" element={< CategoriesPage />} />
                    {/* route to the Manage Category Page in Create mode */}
                    <Route path="/category/:id" element={< ManageCategoryPage />} />
                    {/* route to the Manage Category Page in Edit mode */}
                    <Route path="/:userId/category" element={< ManageCategoryPage />} />

                    {/* The HomePage serves up the collection of Channels, so there's no need to route to a Channels Page */}
                    {/* route to the Manage Channel Page in Create mode */}
                    <Route path="/channel/:id" element={< ManageChannelPage />} />
                    {/* route to the Manage Channel Page in Edit mode */}
                    <Route path="/channel" element={< ManageChannelPage />} />

                    {/* For ChannelSharingPage, 'id' represents the Id of the Channel for sharing (channelId) */}
                    <Route path="/channelSharing/:id" element={<ChannelSharingPage />} />

                    {/* For SharingRequests. No parameters are required, as the page will display all live sharing requests for the current user */}
                    <Route path="/sharingRequests" element={<SharingRequestsPage />} />

                    {/* For ManageChannelSharingRequestPage, 'id' is the Channel Sharing Request Id (objectSharingRequestTrackerId) */}
                    {/* route to the Manage Channel Sharing Request Page in Create mode */}
                    <Route path="/channelSharingRequest/:id" element={< ManageChannelSharingRequestPage />} />
                    {/* route to the Manage Channel Sharing Request Page in Edit mode */}
                    <Route path="/channelSharingRequest/new/:channelId" element={< ManageChannelSharingRequestPage />} />

                    {/* For ManageChannelSharingUserPage, ':channelId' is the channel and 'userId' is the user for which sharing permission will be edited */}
                    {/* route to the Manage Channel Sharing User Page in Edit mode */}
                    <Route path="/channelSharingUser/:channelId/:userId" element={< ManageChannelSharingUserPage />} />

                    {/* <Route path="/:parentId/topics" element={TopicsListPage} /> */}
                    {/* route to the page that presents all Topics */}
                    <Route path="/:parentId/topics" element={< TopicsPage />} />
                    {/* route to the Manage Topic Page in Create mode */}
                    <Route path="/topic/:id" element={< ManageTopicPage />} />
                    {/* route to the Manage Topic Page in Edit mode */}
                    <Route path="/:parentId/topic" element={< ManageTopicPage />} />

                    {/* route to the page that presents all Topic Items */}
                    <Route path="/:parentId/topicItems" element={< TopicItemsPage />} />
                    {/* route to the Manage Topic Item Page in Create mode */}
                    <Route path="/topicItem/:id" element={< ManageTopicItemPage />} />
                    {/* route to the Manage Topic Item Page in Edit mode */}
                    <Route path="/:parentId/topicItem" element={< ManageTopicItemPage />} />

                    {/* route to the page that provides a view area for displaying all Digital Media for a Topic Item */}
                    <Route path="/:parentId/digitalMedia" element={< DigitalMediaPage />} />

                    {/* route to the Audio Link Page in Create mode */}
                    <Route path="/:parentId/audioLink" element={< ManageAudioLinkPage />} />
                    {/* route to the Audio Link Page in Edit mode */}
                    <Route path="/audioLink/:id" element={< ManageAudioLinkPage />} />

                    {/* route to the Document Link Page in Create mode */}
                    <Route path="/:parentId/documentLink" element={< ManageDocumentLinkPage />} />
                    {/* route to the Document Link Page in Edit mode */}
                    <Route path="/documentLink/:id" element={< ManageDocumentLinkPage />} />

                    {/* route to the HyperLink Page in Create mode */}
                    <Route path="/:parentId/hyperLink" element={< ManageHyperLinkPage />} />
                    {/* route to the HyperLink Page in Edit mode */}
                    <Route path="/hyperLink/:id" element={< ManageHyperLinkPage />} />

                    {/* route to the Image Link Page in Create mode */}
                    <Route path="/:parentId/imageLink" element={< ManageImageLinkPage />} />
                    {/* route to the Image Link Page in Edit mode */}
                    <Route path="/imageLink/:id" element={< ManageImageLinkPage />} />

                    {/* route to the Note Page in Create mode */}
                    <Route path="/:parentId/note" element={< ManageNotePage />} />
                    {/* route to the Note Page in Edit mode */}
                    <Route path="/note/:id" element={< ManageNotePage />} />

                    {/* route to the SocialMediaPost Page in Create mode */}
                    <Route path="/:parentId/socialMediaPost" element={< ManageSocialMediaPostPage />} />
                    {/* route to the SocialMediaPost Page in Edit mode */}
                    <Route path="/socialMediaPost/:id" element={< ManageSocialMediaPostPage />} />

                    {/* route to the Video Link Page in Create mode */}
                    <Route path="/:parentId/videoLink" element={< ManageVideoLinkPage />} />
                    {/* route to the Video Link Page in Edit mode */}
                    <Route path="/videoLink/:id" element={< ManageVideoLinkPage />} />

                    {/* otherwise, route to the Page Not Found Page */}
                    <Route element={< PageNotFound />} />
                  </Routes>
                </>
            }
          </>
      }
    </>
  );
}
