import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { enumWorkflowState } from '../../../dataObjects/enums';
import { IUserRedemptionCodeRequest } from '../../../dataObjects/models/userRedemptionCodeRequest';

// export interface ISaveUserRedemptionCodeRequestStatusState {
//   value: enumWorkflowState | null
// }

const initialState: enumWorkflowState | null = null;

export const userRedemptionCodeRequestSaveStatusSlice = createSlice({
  name: 'userRedemptionCodeRequestSaveStatus',
  initialState,
  reducers: {
    // The userRedemptionCodeRequestSaveRequest triggers the saving of a UserRedemptionCodeRequest. It's not really associated with the Store State
    // for the 'saveUserRedemptionCodeRequestStatus' store variable; however, it is used to both trigger the UserRedemptionCodeRequest Save Request -AND-
    // to call the userRedemptionCodeRequestSaveStatusChange() action to set the state to 'Requested'.
    // If we didn't use this design patter, the most likely alternative would require creating another Store State
    // variable (eg, 'saveUserRedemptionCodeRequestData') and create another slice with a userRedemptionCodeRequestSaveRequest action. 
    userRedemptionCodeRequestSaveRequest: (state, action: PayloadAction<IUserRedemptionCodeRequest>) => {
      userRedemptionCodeRequestSaveStatusChange(enumWorkflowState.Requested);
    },
    // action has a payload with an IUserRedemptionCodeRequest object or null
    userRedemptionCodeRequestSaveStatusChange(state, action) {
      // change status of process
      return action.payload;
    },
  },
})

// Action creators are generated for each function in reducer)
export const { userRedemptionCodeRequestSaveRequest, userRedemptionCodeRequestSaveStatusChange } = userRedemptionCodeRequestSaveStatusSlice.actions;

// export the core reducer for the slice
export const userRedemptionCodeRequestSaveStatusReducer = userRedemptionCodeRequestSaveStatusSlice.reducer;

export default userRedemptionCodeRequestSaveStatusSlice.reducer;