import { collection, CollectionReference, DocumentData, Firestore, onSnapshot, query, Query, QuerySnapshot, where } from 'firebase/firestore';
import { enumFirestoreCollectionNames } from '../enums';
import { IFirestoreUserRedemptionCodeRequestRepository_Ext } from '.';
import { NumericConstants } from '../../../../../../assets/numericAssets';
import { enumObjectPersistenceState } from '../../../../../../dataObjects/enums';

/** 
 * @class FirestoreUserRedemptionCodeRequestRepository_Ext Provides extended functionality beyond the FirestoreUserRedemptionCodeRequestRepository class
 * *** IMPORTANT NOTE: FirestoreUserRedemptionCodeRequestRepository_Ext differs from other types of repositories. Instead of working with individual instances
 *   for creating, update, deleting, the FirestorePersistenceMetadataRepository is focused on other features.
 *   Therefore, 
 *     1) FirestoreUserRedemptionCodeRequestRepository_Ext does not extend FirestoreBaseRepository<> as other repository types do; and
 *     2) The FirestoreDataRepositoryFactory cannot be used to instantiate instances of FirestoreUserRedemptionCodeRequestRepository_Ext.
 */
export class FirestoreUserRedemptionCodeRequestRepository_Ext implements IFirestoreUserRedemptionCodeRequestRepository_Ext {

  /**
   * @method Constructor method
   * @param {Firestore} firestoreDb A Firestore DB Context
   */
  constructor(
    firestoreDb: Firestore
  ) {
    this._firestoreDb = firestoreDb;
  }

  /*-----------------------------------------------*/
  /**
   * @property {Firestore} _firestoreDb A reference to the configured Firestore DB
   */
  private _firestoreDb: Firestore;

  /**
   * @method firestoreDb Getter method for _firestoreDb
   */
  get firestoreDb(): Firestore {
    return this._firestoreDb;
  }

  /**
   * @method firestoreDb Setter method for _firestoreDb
   * @param {Firestore} value The value to be used in setting _firestoreDb
   */
  set firestoreDb(value: Firestore) {
    this._firestoreDb = value;
  }

  /*-----------------------------------------------*/
  /**
   * @method getAllUserRedemptionCodeRequests_onSnapshot Makes an onSnapshot() request to firestore for retrieving all User Redemption Code Requests from the database. 
   *     A firebase onSnapshot() request sets up a subscription to firebase to dynamically update the results.
   * @param {(snapshot: QuerySnapshot<DocumentData>) => void} callback A callback function that is enabled to receive 
   *   a snapshot of data from firestore.
   * @returns {Promise<() => void>} A callback for unsubscribing from the firebase onSnapshot request.
   */
  getAllUserRedemptionCodeRequests_onSnapshot(callback: (snapshot: QuerySnapshot<DocumentData>) => void): Promise<() => void> {
      return new Promise<() => void>(async (resolve, reject) => {
      try {
        // whether to display console logs (displayConsoleLogs && console.log statements)
        const displayConsoleLogs: boolean = false;

        displayConsoleLogs && console.log(`%c FirestoreUserRedemptionCodeRequestRepository_Ext.getAllUserRedemptionCodeRequests_onSnapshot.`, 'background: #660; color: #fff');

        // Declare an 'unsubscribeCallback' variable that will hold the unsubscribe callback from a firestore onSnapshot() request and will be returned
        // from this method.  
        // We initialize it to a function that does nothing.
        let unsubscribeCallback: () => void = () => { };

        // get the reference to the Firestore DB instance
        const firestoreDb: Firestore = this._firestoreDb;

        // get a reference to the UserRedemptionCodeRequest collection
        const UserRedemptionCodeRequestCollection: CollectionReference<DocumentData> = collection(firestoreDb, enumFirestoreCollectionNames.UserRedemptionCodeRequestCollection);

        // prepare a query that will get all documents with the recipientEmailAddress and in either the 'New' or 'Modified' state
        const documentQuery: Query<DocumentData> =
          query(UserRedemptionCodeRequestCollection,
            where("objectState", "in", [enumObjectPersistenceState.New, enumObjectPersistenceState.Modified])
          );

        // with the query object, call onSnapshot() to subscribe to dynamic updates, passing the callback function and capturing the firestore
        // 'unsubscribe' callback function
        unsubscribeCallback = await onSnapshot(documentQuery, callback);

        // return the 'unsubscribeCallback' function
        resolve(unsubscribeCallback);
      }
      catch (error: any) {
        reject(error);
      }
    });
  }

  /*-----------------------------------------------*/
  /**
   * @method getUserRedemptionCodeRequestsForEmailAddress_onSnapshot Makes an onSnapshot() request to firestore for retrieving all User Redemption Code Requests from the database associated with 
   *   a given email address. A firebase onSnapshot() request sets up a subscription to firebase to dynamically update the results.
   * @param {string} emailAddress The email address associated with the objects to retrieve.
   * @param {(snapshot: QuerySnapshot<DocumentData>) => void} callback A callback function that is enabled to receive 
   *   a snapshot of data from firestore.
   * @returns {Promise<() => void>} A callback for unsubscribing from the firebase onSnapshot request.
   */
  getUserRedemptionCodeRequestsForEmailAddress_onSnapshot(emailAddress: string, callback: (snapshot: QuerySnapshot<DocumentData>) => void): Promise<() => void> {
      return new Promise<() => void>(async (resolve, reject) => {
      try {
        // whether to display console logs (displayConsoleLogs && console.log statements)
        const displayConsoleLogs: boolean = false;

        displayConsoleLogs && console.log(`%c FirestoreUserRedemptionCodeRequestRepository_Ext.getUserRedemptionCodeRequestsForEmailAddressArray_onSnapshot. emailAddress: ${emailAddress}`, 'background: #660; color: #fff');

        // Declare an 'unsubscribeCallback' variable that will hold the unsubscribe callback from a firestore onSnapshot() request and will be returned
        // from this method.  
        // We initialize it to a function that does nothing.
        let unsubscribeCallback: () => void = () => { };

        // get the reference to the Firestore DB instance
        const firestoreDb: Firestore = this._firestoreDb;

        // get a reference to the UserRedemptionCodeRequest collection
        const UserRedemptionCodeRequestCollection: CollectionReference<DocumentData> = collection(firestoreDb, enumFirestoreCollectionNames.UserRedemptionCodeRequestCollection);

        // There will be one or more queries, depending on the number of Ids provided and the size of each query group.
        // Firestore has a limit on the maximum number of search criteria that can be included as part of an "IN" clause, so
        // the queries will potentially have to be chunked.
        // Establish the size of each query group.
        const queryGroupSize: number = NumericConstants.maxCriteriaForAFirestoreInClause;

        // prepare a query that will get all documents with the recipientEmailAddress and in either the 'New' or 'Modified' state
        const documentQuery: Query<DocumentData> =
          query(UserRedemptionCodeRequestCollection,
            where("recipientEmailAddress", "==", emailAddress),
            where("objectState", "in", [enumObjectPersistenceState.New, enumObjectPersistenceState.Modified])
          );

        // with the query object, call onSnapshot() to subscribe to dynamic updates, passing the callback function and capturing the firestore
        // 'unsubscribe' callback function
        unsubscribeCallback = await onSnapshot(documentQuery, callback);

        // return the 'unsubscribeCallback' function
        resolve(unsubscribeCallback);
      }
      catch (error: any) {
        reject(error);
      }
    });
  }

}
