import React, { PropsWithChildren, useCallback, useEffect, useState } from 'react';
import { DocumentData, QuerySnapshot } from 'firebase/firestore';
import _ from 'lodash';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Container } from '@mui/material';
import { ControlsStringAssets, MessagesStringAssets, PageAndViewTitleStringAssets } from '../../../../assets/stringAssets';
import GenericPageContainer from '../../GenericPageContainer/GenericPageContainer';
import { enumAlertType } from '../../../enums';
import { IUserRedemptionCodeRequest, IUserRedemptionCodeRequestAsJson, UserRedemptionCodeRequest } from '../../../../dataObjects/models/userRedemptionCodeRequest';
import { typeUniqueId } from '../../../../dataObjects/types';
import { getUserRedemptionCodeRequestsForParentId_onSnapshot } from '../../../../firebaseServices/dataServices/dataServiceActions/userRedemptionCodeRequestActions/getUserRedemptionCodeRequestsForParentId_onSnapshot';
import { IStoreState } from '../../../../uiMiddleware-redux/store/IStoreState';
import { enumBeaconType, enumCollectionViewType, enumPersistableObjectType, enumWorkflowState } from '../../../../dataObjects/enums';
import { JsonConverter } from '../../../../dataObjects/utilities/JsonConverter';
import UserRedemptionCodeRequestsListView from '../../../views/userRedemptionCodeRequests/UserRedemptionCodeRequestsListView/UserRedemptionCodeRequestsListView';
import { UserRedemptionCodeRequestsCardGridView } from '../../../views/userRedemptionCodeRequests/UserRedemptionCodeRequestsCardGridView/UserRedemptionCodeRequestsCardGridView';
import TwoButtonAcceptanceDialog from '../../../dialogs/TwoButtonAcceptanceDialog/TwoButtonAcceptanceDialog';
import { Beacon } from '../../../../dataObjects/models/alerts/Beacon';
import { useReduxWorkflowState } from '../../../customHooks';
import ActivityIndicatorDialog from '../../../dialogs/ActivityIndicatorDialog/ActivityIndicatorDialog';
import { IActivityIndicatorData } from '../../../dialogs/ActivityIndicatorData';
import { AlertInfo, IAlertInfo } from '../../../../dataObjects/models/alerts/AlertInfo';
import DataViewControls from '../../../controls/DataViewControls/DataViewControls';
import { useGetCollectionViewType } from '../../../customHooks/useGetCollectionViewType';
import { BoxProps, ContainerProps, styled } from '@mui/system';
import { ICurrentUserContextData, useCurrentUserContext } from '../../../providersAndContexts/currentUser';
import { enumCategoriesAndChannelsDataPreparationStatus } from '../../../enums';
import { useAppDispatch } from '../../../../uiMiddleware-redux/store/configureStore';
import { userRedemptionCodeRequestDeleteRequest, userRedemptionCodeRequestDeleteStatusChange } from '../../../../uiMiddleware-redux/slices/userRedemptionCodeRequest/userRedemptionCodeRequestDeleteStatusSlice';
import { beaconChange } from '../../../../uiMiddleware-redux/slices/beacon/beaconSlice';
import { alertInfoChange } from '../../../../uiMiddleware-redux/slices/alertInfo/alertInfoSlice';
import { getAllUserRedemptionCodeRequests_onSnapshot } from '../../../../firebaseServices/dataServices/dataServiceActions/userRedemptionCodeRequestActions';
// import { useAppDispatch } from '../../../customHooks/useAppDispatch';


/*** Using the Material UI Emotion Styling library, declare 'styled' instances for each area/object. 
 *** NOTE: These must be declared outside of the React Functional Component to ensure that the styled 
 *** objects will be properly rendered within the DOM. 
 ***/

// a styled Box (equivalent to a <div>), providing an area of padding at the top of the page
const StyledBoxForPaddingAtopPage = styled((props: BoxProps) => (
  <Box
    {...props}
  />
))(({ theme }) => ({
  marginTop: theme.spacing(1),
}));

// a styled Box for a Horizontal Divider (a CSS Horizontal Rule <hr/>)
const StyledBoxForHorizontalDivider = styled((props: BoxProps) => (
  <Box
    component='hr'
    {...props}
  />
))(({ theme }) => ({
  width: "100%",
  borderTop: `1px solid ${theme.palette.primary.light}`,
}));

// a styled Container for Data View
const StyledContainerForDataView = styled((props: ContainerProps) => (
  <Container
    disableGutters
    maxWidth={false}
    {...props}
  />
))(({ theme }) => ({
  flexGrow: 1,
  paddingLeft: 0,
  paddingRight: 0,
}));

export interface IUserRedemptionCodeRequestsPageProps extends PropsWithChildren<unknown> {
}

const UserRedemptionCodeRequestsPage: React.FC<IUserRedemptionCodeRequestsPageProps> = (props: IUserRedemptionCodeRequestsPageProps) => {

  UserRedemptionCodeRequestsPage.displayName = 'UserRedemptionCodeRequests Page';

  // whether to display console logs (displayConsoleLogs && console.log statements)
  const displayConsoleLogs: boolean = false;

  const navigate = useNavigate();
  const params = useParams();

  // call custom hook to get the current view type, so the value can be used to initialize the View Type state variable
  const collectionViewType: enumCollectionViewType = useGetCollectionViewType();

  // the UserRedemptionCodeRequests associcated with the current user account
  const [userRedemptionCodeRequests, setUserRedemptionCodeRequests] = useState<Array<IUserRedemptionCodeRequest>>([]);
  // whether we are currently fetching the current topic
  const [fetchingCurrentTopic, setFetchingCurrentTopic] = useState<boolean>(false);
  // whether data is currently loading
  const [dataLoading, setDataLoading] = useState<boolean>(true);
  // if user is deleting a UserRedemptionCodeRequest, the UserRedemptionCodeRequest to delete
  const [userRedemptionCodeRequestToDelete, setUserRedemptionCodeRequestToDelete] = useState<IUserRedemptionCodeRequest | undefined>(undefined);
  // whether to show the Delete Confirmation dialog
  const [showDeleteConfirmationDlg, setShowDeleteConfirmationDlg] = useState<boolean>(false);

  // to assess the status of data preparation for user categories and channels
  const [userCategoriesAndChannelsDataPrepStatus, SetUserCategoriesAndChannelsDataPrepStatus] = useState<enumCategoriesAndChannelsDataPreparationStatus>(enumCategoriesAndChannelsDataPreparationStatus.None);

  // for dispatching redux actions
  const dispatch = useAppDispatch();

  // use a custom hook to get the Current User information from the CurrentUserProvider
  const { currentUser }: ICurrentUserContextData = useCurrentUserContext();
  const userId: typeUniqueId | undefined = currentUser?.id;

  /**
   * @function resetDeleteUserRedemptionCodeRequestStatus Resets the Delete status in Redux state
   */
  const resetDeleteUserRedemptionCodeRequestStatus: () => void = useCallback(() => {
    dispatch(userRedemptionCodeRequestDeleteStatusChange(null));
  }, [dispatch])

  // set up a useEffect() hook to get the userRedemptionCodeRequests for the given parentId and get data updates via onSnapshot
  useEffect(() => {
    // Declare an 'unsubscribe' variable that will hold the unsubscribe callback from a firestore onSnapshot() request.
    // We initialize it to a function that does nothing, so if an onSnapshot() is never requested, we can still call unsubscribe() during cleanup. 
    // After an onShapshot() request, the 'unsubscribe' variable will be set to a callback function issued by firestore.
    let unsubscribeCallback: () => void = () => { };

    displayConsoleLogs && console.log(`%c UserRedemptionCodeRequestsPage. useEffect for [parentId, userId, resetDeleteUserRedemptionCodeRequestStatus]. Ready to request all User Redemption Code Requests`, 'background: #006; color: #fff');

    // subscribe to onShapshot updates for the UserRedemptionCodeRequests, providing realtime updates to the data, and capture the 'unsubscribe' callback method provided by firestore
    // console.log(`UserRedemptionCodeRequestsPage: before initiating snapshot, unsubscribeCallback: ${unsubscribeCallback}`);
    getAllUserRedemptionCodeRequests_onSnapshot(onSnapshotCallback_UserRedemptionCodeRequests).then((unsubscribe: () => void) => {
      unsubscribeCallback = unsubscribe;
      // console.log(`UserRedemptionCodeRequestsPage: after initiating snapshot, unsubscribeCallback: ${unsubscribeCallback}`);
    });

    // perform cleanup when the component unmounts
    return () => {
      // if the user is still logged in...
      // console.log(`UserRedemptionCodeRequestsPage: unmounting (useEffect return). userId: ${userId}`);
      // console.log(`UserRedemptionCodeRequestsPage: unmounting (useEffect return). unsubscribeCallback: ${unsubscribeCallback}`);
      if (userId) {
        // Call the unsubscribe() callback to unsubscribe from the onSnapshot . If it was never 
        // console.log(`UserRedemptionCodeRequestsPage. Ready to call 'unsubscribeCallback'`);
        unsubscribeCallback();
        // console.log(`UserRedemptionCodeRequestsPage. Completed call to 'unsubscribeCallback'`);

        // reset the DeleteTopicStatus in Redux state to return to a clean slate
        resetDeleteUserRedemptionCodeRequestStatus();
      }
    }
  }, [resetDeleteUserRedemptionCodeRequestStatus])

  // use a custom hook to evaluate the redux state for the deleteUserRedemptionCodeRequest workflow and any deleteUserRedemptionCodeRequest error
  const { workflowState: deleteUserRedemptionCodeRequestStatus, errorState: deleteUserRedemptionCodeRequestFailure } = useReduxWorkflowState((state: IStoreState) => state.deleteUserRedemptionCodeRequestStatus, (state: IStoreState) => state.deleteUserRedemptionCodeRequestFailure);

  /**
   * @function onSnapshotCallback_UserRedemptionCodeRequests A callback method to receive firestore UserRedemptionCodeRequests data snapshots for dynamic data updates
   * @param {QuerySnapshot<DocumentData>} snapshot A snapshot of data from firestore
   */
  function onSnapshotCallback_UserRedemptionCodeRequests(snapshot: QuerySnapshot<DocumentData>): void {
    displayConsoleLogs && console.log(`%c UserRedemptionCodeRequestsPage. onSnapshotCallback_UserRedemptionCodeRequests: \n${JSON.stringify(snapshot)}`, 'background: #006; color: #fff');
    // console.log(`UserRedemptionCodeRequestsPage:onSnapshotCallback(). Entered callback function.`);
    // set state variable indicating that dataLoading is no longer taking place
    setDataLoading(false);
    const userRedemptionCodeRequestDataAsJson: Array<IUserRedemptionCodeRequestAsJson> = [];
    // console.log(`UserRedemptionCodeRequestsPage:onSnapshotCallback(). Ready to get doc data.`);
    snapshot.forEach(doc => userRedemptionCodeRequestDataAsJson.push({ ...doc.data(), id: doc.id } as IUserRedemptionCodeRequestAsJson));
    // console.log(`UserRedemptionCodeRequestsPage:onSnapshotCallback(). Completed getting doc data.`);

    // create an array of UserRedemptionCodeRequest objects from the JSON data
    let userRedemptionCodeRequestsData = JsonConverter.arrayFromJSONArray(UserRedemptionCodeRequest, userRedemptionCodeRequestDataAsJson);

    // use lodash to sort the array by 'name' in ascending order
    userRedemptionCodeRequestsData = _.orderBy(userRedemptionCodeRequestsData, [userRedemptionCodeRequest => userRedemptionCodeRequest.recipientName.toUpperCase()], ['asc']);

    setUserRedemptionCodeRequests(userRedemptionCodeRequestsData);
  }

  /**
   * @method handleUserRedemptionCodeRequestDelete Handles requests to delete a Channel
   * @param userRedemptionCodeRequest The UserRedemptionCodeRequest to be deleted
   */
  async function handleUserRedemptionCodeRequestDelete(userRedemptionCodeRequest: IUserRedemptionCodeRequest): Promise<void> {
    // set the userRedemptionCodeRequest to be deleted into local state
    setUserRedemptionCodeRequestToDelete(userRedemptionCodeRequest);

    // set flag to show the delete confirmation dialog
    setShowDeleteConfirmationDlg(true);
  }

  function handleUserRedemptionCodeRequestDeleteConfirmed(): void {
    // using the userRedemptionCodeRequest object to be deleted from the local state, dispatch an action to delete the userRedemptionCodeRequest object
    if (userRedemptionCodeRequestToDelete !== undefined) {
      dispatch(userRedemptionCodeRequestDeleteRequest(userRedemptionCodeRequestToDelete));
    }

    // call method to reset the userRedemptionCodeRequest delete local state details
    userRedemptionCodeRequestDeleteReset();
  }

  function handleUserRedemptionCodeRequestDeleteCanceled(): void {
    // call method to reset the userRedemptionCodeRequest delete local state details
    userRedemptionCodeRequestDeleteReset();
  }

  function userRedemptionCodeRequestDeleteReset() {
    // reset the userRedemptionCodeRequest to be deleted to 'undefined' in local state, since we're done with the object
    setUserRedemptionCodeRequestToDelete(undefined);

    // set flag to hide the delete confirmation dialog
    setShowDeleteConfirmationDlg(false);
  }

  // data to indicate whether an activity indicator should be displayed and, if so, the message
  let activityIndicatorData: IActivityIndicatorData = {
    showActivityIndicator: false,
    activityIndicatorMessage: ''
  }

  // data to indicate whether an alert should be displayed
  let alertInfo: IAlertInfo | undefined = undefined;

  // indicates whether the object has been successfully deleted
  const [successfullyDeletedObject, setSuccessfullyDeletedObject] = useState<boolean>(false);

  // define an effect that will process whenever the successfullyCompletedPage state changes
  useEffect(() => {
    if (successfullyDeletedObject) {
      // dispatch an action to reset the deleteUserRedemptionCodeRequestStatus
      resetDeleteUserRedemptionCodeRequestStatus();

      // notify user via a Beacon notification that the userRedemptionCodeRequest has been deleted
      const beacon: Beacon = new Beacon(undefined, enumBeaconType.Success, MessagesStringAssets.userRedemptionCodeRequest_DeleteConfirmationHeader, MessagesStringAssets.userRedemptionCodeRequest_DeleteSuccess);
      dispatch(beaconChange(beacon));

      // reset the successfullyDeletedObject flag
      setSuccessfullyDeletedObject(false);
    }
  }, [successfullyDeletedObject, dispatch, resetDeleteUserRedemptionCodeRequestStatus]);

  if (dataLoading) {
    // display an activity indicator
    activityIndicatorData.showActivityIndicator = true;
    activityIndicatorData.activityIndicatorMessage = MessagesStringAssets.userRedemptionCodeRequest_Loading;
  } else {
    // if the workflow is in the midst of performing deletion of a userRedemptionCodeRequest, prepare an alert message fragment to display progress message(s)
    if (deleteUserRedemptionCodeRequestStatus !== undefined && deleteUserRedemptionCodeRequestStatus !== null) {
      if (deleteUserRedemptionCodeRequestStatus === enumWorkflowState.Requested || deleteUserRedemptionCodeRequestStatus === enumWorkflowState.InProgress) {
        // display an activity indicator
        activityIndicatorData.showActivityIndicator = true;
        activityIndicatorData.activityIndicatorMessage = MessagesStringAssets.userRedemptionCodeRequest_DeleteRequested;
      } else if (deleteUserRedemptionCodeRequestStatus === enumWorkflowState.Success) {
        // if the successfullyDeletedObject is curently in a 'false' state, we'll want the flag to indicate that the deletion was a success
        if (!successfullyDeletedObject) {
          // set flag to indicate that the object has been successfully deleted, which will force a re-render to allow for cleanup and user notification
          setSuccessfullyDeletedObject(true);
        }
      } else if (deleteUserRedemptionCodeRequestStatus === enumWorkflowState.Failure) {
        // display an alert message
        // alertFragment = generateJsxAlertMessageFragment(MessagesStringAssets.userRedemptionCodeRequest_DeleteFailure, enumJsxAlertMessageDisplayVariants.Failure, false, deleteUserRedemptionCodeRequestFailureMessage, MessagesStringAssets.substitutionKeyword);
        let failureMessage = 'Unknown error';
        if (deleteUserRedemptionCodeRequestFailure && deleteUserRedemptionCodeRequestFailure instanceof Error) {
          failureMessage = deleteUserRedemptionCodeRequestFailure.message;
        }
        alertInfo = new AlertInfo(true, enumAlertType.Error, failureMessage);
        dispatch(alertInfoChange(alertInfo));
      }
    }
  }


  // Establish the page title to be displayed. Once we have retrieved the parent Topic, apply the userRedemptionCodeRequestNameAliasPlural; otherwise, show nothing
  const pageTitle: string = PageAndViewTitleStringAssets.pageTitle_UserRedemptionCodeRequest;

  // Establish the text to be displayed on the '+ New' button
  const addNewButtonText: string = ControlsStringAssets.userRedemptionCodeRequestNew;

  return (
    <>
      {/* only display the page if we have a valid currentUser */}
      {currentUser &&
        <>
          <StyledBoxForPaddingAtopPage />
          <GenericPageContainer
            // maxWidth="xl"
            showBackButton={true}
            pageTitle={pageTitle}
            onCloseAlert={resetDeleteUserRedemptionCodeRequestStatus}
          >

            {/* Present the Data View Controls ("Add new" button & "View Type" toggle button) */}
            <DataViewControls
              addNewItemButtonText={addNewButtonText}
              onClickAddNewItemButton={() => navigate(`/userRedemptionCodeRequest`)}
            >
            </DataViewControls>

            <StyledBoxForHorizontalDivider />

            {/* Container for embedded data view */}
            <StyledContainerForDataView>
              {/* If there are UserRedemptionCodeRequests to display and the current view selection is CardsGridView, display the UserRedemptionCodeRequestsCardGridView */}
              {userRedemptionCodeRequests && currentUser &&
                (collectionViewType === enumCollectionViewType.CardsGridView) &&
                <UserRedemptionCodeRequestsCardGridView userRedemptionCodeRequests={userRedemptionCodeRequests} currentUser={currentUser} onDelete={handleUserRedemptionCodeRequestDelete} />
              }

              {/* If there are UserRedemptionCodeRequests to display and the current view selection is ListView, display the UserRedemptionCodeRequestsListView */}
              {userRedemptionCodeRequests && currentUser &&
                (collectionViewType === enumCollectionViewType.ListView) &&
                <UserRedemptionCodeRequestsListView userRedemptionCodeRequests={userRedemptionCodeRequests} currentUser={currentUser} onDelete={handleUserRedemptionCodeRequestDelete} />
              }
            </StyledContainerForDataView>

            {/* Delete UserRedemptionCodeRequest Confirmation Dialog */}
            <TwoButtonAcceptanceDialog
              showDialog={showDeleteConfirmationDlg}
              headerText={MessagesStringAssets.userRedemptionCodeRequest_DeleteConfirmationHeader}
              bodyText={MessagesStringAssets.userRedemptionCodeRequest_DeleteConfirmation}
              acceptanceButtonText={ControlsStringAssets.confirmButtonText}
              nonAcceptanceButtonText={ControlsStringAssets.cancelButtonText}
              onAcceptance={handleUserRedemptionCodeRequestDeleteConfirmed}
              onNonAcceptance={handleUserRedemptionCodeRequestDeleteCanceled}
            />

            {activityIndicatorData.showActivityIndicator &&
              <ActivityIndicatorDialog activityLabel={activityIndicatorData.activityIndicatorMessage} />
            }

          </GenericPageContainer>
        </>
      }
    </>
  );
}

export default UserRedemptionCodeRequestsPage;
