import React, { PropsWithChildren } from 'react';
import { Typography, TypographyProps } from '@mui/material';
import { styled } from '@mui/styles';


/*** Using the Material UI Emotion Styling library, declare 'styled' instances for each area/object. 
 *** NOTE: These must be declared outside of the React Functional Component to ensure that the styled 
 *** objects will be properly rendered within the DOM. 
 ***/

// a styled Typography for the View Title
const StyledTypographyForViewTitle = styled((props: TypographyProps) => (
  <Typography
    variant="h4"
    {...props}
  />
))(({ theme }) => ({
  color: theme.page.titleFontColor,
  fontWeight: 'bold',

  [theme.breakpoints.up('xs')]: {
    fontSize: '1.2rem',
  },
  [theme.breakpoints.up('sm')]: {
    fontSize: '1.4rem',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '1.55rem',
  },
  [theme.breakpoints.up('lg')]: {
    fontSize: '1.7rem',
  },
  [theme.breakpoints.up('xl')]: {
    fontSize: '1.825rem',
  },
}));

/**
 * @interface IViewTitleProps declares any input properties required for this component.
 */
export interface IViewTitleProps extends PropsWithChildren<unknown> {
  title: string;
}

/**
 * @function ViewTitle is a React functional component, representing a title that will appear on a page.
 * @param {IViewTitleProps} props are the properties passed into the component and, in this case.
 */
export const ViewTitle: React.FC<IViewTitleProps> = (props: IViewTitleProps) => {
  ViewTitle.displayName = 'View Title';

  const { title } = props;

  return (
    <>
      <StyledTypographyForViewTitle >
        {title}
      </StyledTypographyForViewTitle>
    </>
  )
}