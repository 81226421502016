import React, { PropsWithChildren, useCallback, useEffect, useRef, useState } from 'react';
import { ILoginData, LoginData } from '../../../dataObjects/models/registerAndLogin/LoginData';
import { enumBeaconType, enumWorkflowState } from '../../../dataObjects/enums';
import { IStoreState } from '../../../uiMiddleware-redux/store/IStoreState';
import { ControlsStringAssets, MessagesStringAssets, NotificationStringAssets, PageAndViewTitleStringAssets } from '../../../assets/stringAssets';
import GenericPageContainer from '../GenericPageContainer/GenericPageContainer';
import { AlertInfo } from '../../../dataObjects/models/alerts/AlertInfo';
import LoginForm from '../../forms/LoginForm/LoginForm';
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { Beacon } from '../../../dataObjects/models/alerts/Beacon';
import { composeMessageUsingStringAsset } from '../../messages';
import { enumAlertType } from '../../enums';
import { IRegistrationData } from '../../../dataObjects/models/registerAndLogin/RegistrationData';
import { useReduxWorkflowState } from '../../customHooks';
import gsap from 'gsap';
import { styled } from '@mui/styles';
import { Box, BoxProps } from '@mui/material';
import MyDigiBrainLogo from '../../images/MyDigiBrainLogo/MyDigiBrainLogo';
import { IMdbError, MdbError } from '../../../errorObjects/MdbError';
import { enumMdbErrorType } from '../../../errorObjects/enums';
import isEmail from 'validator/lib/isEmail';
import { submitEmailRequestForAccountVerification, submitEmailRequestForPasswordReset, submitEmailRequestForSigninWithEmailLink, submitEmailRequestToServer } from '../../../firebaseServices/dataServices/dataServiceActions/emailRequestActions';
import OneButtonDialog from '../../dialogs/OneButtonDialog/OneButtonDialog';
import { IUserEmailIdAndNameViewModel } from '../../../dataObjects/viewModels/userEmailIdAndName';
import { getUserAccountInfoFromEmailAddressViaHttp } from '../../../firebaseServices/securityServices/userAccount/userAccountActions/getUserAccountInfoFromEmailAddressViaHttp';
import { alertInfoChange } from '../../../uiMiddleware-redux/slices/alertInfo/alertInfoSlice';
import { useAppDispatch, useAppSelector } from '../../../uiMiddleware-redux/store/configureStore';
import { beaconChange } from '../../../uiMiddleware-redux/slices/beacon/beaconSlice';
import { loginStatusChange } from '../../../uiMiddleware-redux/slices/login/loginStatusSlice';
import { loginDataClear, loginRequest } from '../../../uiMiddleware-redux/slices/login/loginDataSlice';
import { loginFailureClear } from '../../../uiMiddleware-redux/slices/login/loginFailureSlice';

const useStyles: () => Record<string, string> = makeStyles((theme: Theme) => ({

  myDigiBrainLogo: {
    margin: theme.spacing(0, 0, 2, 0),
    // animation: `$tennisBallEntrance 3000ms ${theme.transitions.easing.easeInOut}`,

    [theme.breakpoints.up('xs')]: {
      height: '80px',
    },
    [theme.breakpoints.up('sm')]: {
      height: '90px',
    },
    [theme.breakpoints.up('md')]: {
      height: '100px',
    },
    [theme.breakpoints.up('lg')]: {
      height: '110px',
    },
    [theme.breakpoints.up('xl')]: {
      height: '120px',
    },

  },
}));


/*** Using the Material UI Emotion Styling library, declare 'styled' instances for each area/object. 
 *** NOTE: These must be declared outside of the React Functional Component to ensure that the styled 
 *** objects will be properly rendered within the DOM. 
 ***/

// a styled Box (equivalent to a <div>), providing an area to display the overall header
const StyledBoxForPaddingAtopPage = styled((props: BoxProps) => (
  <Box
    {...props}
  />
))(({ theme }) => ({
  marginTop: theme.spacing(2),
}));


export interface ILoginPageProps extends PropsWithChildren<unknown> {

}

const LoginPage: React.FC<ILoginPageProps> = (props: ILoginPageProps) => {

  LoginPage.displayName = "Login Page";

  // whether to display console logs (displayConsoleLogs && console.log statements)
  const displayConsoleLogs: boolean = false;

  displayConsoleLogs && console.log('%c Entered/Refreshed LoginPage component', 'background: #00B; color: #fff');

  const classes: Record<string, string> = useStyles();

  const logoElement: React.RefObject<SVGSVGElement> = useRef<SVGSVGElement>(null);


  const performLogoEntrance: () => void = useCallback((): void => {

    // The starting point for the animation will be to:
    //  - Make the logo invisible (opacity: 0);
    //  - Set its original orientation to 0 (rotate: 0), and 
    //  - Make the ball 10% of its size (scale: 0.10)
    gsap.from(logoElement.current, {
      opacity: 0,
      rotate: 0,
      scale: 0.10,
    });

    // Make the ball fully visible (opacity: 1.0)
    gsap.to(logoElement.current, {
      // scale: 1.0,
      opacity: 1.0,
      duration: 1,
    });

    // Bring the ball to its full size (scale: 1.0)
    gsap.to(logoElement.current, {
      scale: 1.0,
      // opacity: 1.0,
      // rotation: 720,
      duration: 3,
    });

    // REMOVED ROTATION ON 2023-06-20 (after changing from Tennis Ball Logo to MyDigiBrain Logo)
    // // Rotate the ball twice, or 720 degrees (rotation: 720)
    // gsap.to(logoElement.current, {
    //   rotation: 720,
    //   duration: 3,
    // });

  }, []);

  useEffect(() => {
    performLogoEntrance();
  }, [performLogoEntrance]);

  // prepare LoginData (TODO: get data from LocalStorage?)
  const defaultLoginEmail: string | undefined = process.env.REACT_APP_DEFAULT_USER_ACCOUNT_EMAIL;
  const defaultLoginPassword: string | undefined = process.env.REACT_APP_DEFAULT_USER_ACCOUNT_PASSWORD;
  const defaultLoginPhoneNumber: string | undefined = undefined;
  const defaultLoginKeepLoggedIn: boolean = false;
  const defaultLoginData: ILoginData = new LoginData(defaultLoginEmail, defaultLoginPassword, defaultLoginPhoneNumber, defaultLoginKeepLoggedIn);

  const dispatch = useAppDispatch();

  // // alert information, in the case that an alert is to be displayed
  // const [alertInfo, setAlertInfo] = useState<IAlertInfo | undefined>(undefined);

  // the status of login operation
  const [loginStatus, setLoginStatus] = useState<enumWorkflowState | null | undefined>(null);

  // the failure error from login operation
  const [loginFailureError, setLoginFailureError] = useState<IMdbError | null | undefined>(null);

  // flag that indicates whether it has been detected that the user account logging in has NOT been verified
  const [accountNotVerified, setAccountNotVerified] = useState<boolean>(false);

  // whether a login operation is currently in progress
  const [loginInProgress, setLoginInProgress] = useState<boolean>(false);

  // User Registration Data, for the case where a user has just registered (created a new account)
  const [registrationData, setRegistrationData] = useState<IRegistrationData | undefined>(undefined);

  // the email address that was used for the last Submit operation (will be used in case account hasn't yet been verified)
  const [emailAtLastSubmit, setEmailAtLastSubmit] = useState<string | undefined>(undefined);

  // whether to show the problem dialog
  const [showProblemDialog, setShowProblemDialog] = useState<boolean>(false);

  // header text to be displayed in the problem dialog
  const [problemDialogHeader, setProblemDialogHeader] = useState<string>('');

  // message to be displayed in the problem dialog
  const [problemDialogMessage, setProblemDialogMessage] = useState<string>('');

  /**
   * @function clearAlertInfoLocal Clears Alert information from Redux state
   */
  const clearAlertInfoLocal: () => void = useCallback((): void => {
    dispatch(alertInfoChange(null));
  }, [dispatch]);

  // Check Redux state to see if a user just registered and has been redirected to the Login page.
  const userRegistrationData: IRegistrationData | null | undefined = useAppSelector((state: IStoreState) => state.userRegistrationData);
  if (userRegistrationData && !registrationData) {
    setRegistrationData(userRegistrationData);
  }

  useEffect(() => {
    if (registrationData) {
      const newRegistrationMessage: string = composeMessageUsingStringAsset(MessagesStringAssets.registration_Success, registrationData.email, MessagesStringAssets.substitutionKeyword);
      dispatch(alertInfoChange(new AlertInfo(true, enumAlertType.Success, newRegistrationMessage)));
    }

    // cleanup upon unmounting the componenent
    return () => {
      clearAlertInfoLocal();
    }
  }, [clearAlertInfoLocal, dispatch, registrationData])


  // Check for the Login workflowState and errorState values in Redux state. A valid loginStatus indicates that the user is logging in.
  // The presence of a loginFailure error indicates that there was a failure during login.
  const { workflowState: loginUserStatus, errorState: loginFailureInRedux } = useReduxWorkflowState((state: IStoreState) => state.loginStatus, (state: IStoreState) => state.loginFailure);

  // if the login status in Redux state is different from our local state, set our local state
  if (loginStatus !== loginUserStatus) {
    displayConsoleLogs && console.log(`%c LoginPage. loginStatus is being changed to: \n${JSON.stringify(loginUserStatus)}`, 'background: #00B; color: #fff');
    setLoginStatus(loginUserStatus);
  }

  // if no user is logged in or logging in, set local state of loginFailureError to null
  if (loginUserStatus === null && loginFailureInRedux !== null) {
    setLoginFailureError(null);
  } else {
    // if the loginFailureInRedux represents an 'AccountNotVerified' error, create a new MdbError with a specific message
    if (loginFailureInRedux !== undefined && loginFailureInRedux !== null && loginFailureInRedux.errorType === enumMdbErrorType.AccountNotVerified) {
      // if we haven't already processed that the user account has NOT been verified
      if (!accountNotVerified) {
        // create a new MdbError with details from the loginFailureInRedux object, except provide a specific message string
        const loginFailureCopy: IMdbError =
          new MdbError(MessagesStringAssets.login_AccountNotVerified, enumMdbErrorType.AccountNotVerified, loginFailureInRedux.contextInfo, { cause: loginFailureInRedux.cause });
        loginFailureCopy.stack = loginFailureInRedux.stack;

        displayConsoleLogs && console.log(`%c LoginPage. loginFailureError is being changed to: \n${JSON.stringify(loginFailureCopy)}`, 'background: #00B; color: #fff');
        setLoginFailureError(loginFailureCopy);

        // see if we have an email address in the loginFailureInRedux.contextInfo field
        if ((loginFailureInRedux.contextInfo !== undefined) && (typeof loginFailureInRedux.contextInfo === 'string')) {
          if (isEmail(loginFailureInRedux.contextInfo) && (loginFailureInRedux.contextInfo !== emailAtLastSubmit)) {
            setEmailAtLastSubmit(loginFailureInRedux.contextInfo)
          }
        }

        setAccountNotVerified(true);
      }
    } else {
      // else if the login failure error in Redux state is different from our local state, move towards setting our local state
      if (loginFailureError !== loginFailureInRedux) {
        // set the local loginFailureError state to the object obtained from Redux
        displayConsoleLogs && console.log(`%c LoginPage. loginFailureError is being changed to: \n${JSON.stringify(loginFailureInRedux)}`, 'background: #00B; color: #fff');
        setLoginFailureError(loginFailureInRedux);
        // if accountNotVerified flag is currently false, set it to false
        if (accountNotVerified) {
          setAccountNotVerified(false);
        }
      }
    }
  }

  // function (memoized with the useCallback hook) to be called when processing a change to the login operation status
  // NOTE: In the current design of the application, this function (processSuccessLoginStatus) will never be called within this page, because
  //       as soon as the user has been logged in, the React Router will route the user away from the Login Page and to the Home Page.
  //       This function will be left in place to merely maintain consistency in representing all possible states during the login process.
  const processSuccessLoginStatus = useCallback(
    () => {
      // set the loginInProgress state to 'false' if it's not already in that state (don't want an extra render if not necessary)
      if (loginInProgress) {
        setLoginInProgress(false);
      }

      // console.info(`Ready to launch a Beacon from LoginPage.processSuccessLoginStatus()`);

      // notify user via a Beacon notification that the user has successfully logged in
      // dispatch(setBeacon(new Beacon(undefined, enumBeaconType.Success, NotificationStringAssets.heading_Login, MessagesStringAssets.login_Success)));
      const beacon: Beacon = new Beacon(undefined, enumBeaconType.Success, NotificationStringAssets.heading_Login, MessagesStringAssets.login_Success);
      // const beaconStringified: string = JSON.stringify(beacon);
      dispatch(beaconChange(beacon));
      // dispatch(beaconChangeJson(beacon.toJSON()));
      // dispatch(beaconChangeString("Some string for beacon"));

      // dispatch an action to reset the loginStatus
      // dispatch(loginStatusReset());
      dispatch(loginStatusChange(null));
      dispatch(loginDataClear(null));
      dispatch(loginFailureClear(null));
    },
    [loginInProgress, dispatch],
  )

  const processInProgressLoginStatus = useCallback(
    () => {
      // set the loginInProgress state to 'true' if it's not already in that state (don't want an extra render if not necessary)
      if (!loginInProgress) {
        setLoginInProgress(true);
      }
    },
    [loginInProgress],
  )

  const processFailureLoginStatus = useCallback(
    () => {
      const loginFailureMessage: string = composeMessageUsingStringAsset(MessagesStringAssets.login_Failure, loginFailureError ? loginFailureError.message : '', MessagesStringAssets.substitutionKeyword);
      // create an AlertInfo object and dispatch a request to set the AlertInfo into Redux state
      dispatch(alertInfoChange(new AlertInfo(true, enumAlertType.Error, loginFailureMessage)));

      // set the loginInProgress state to 'false' if it's not already in that state (don't want an extra render if not necessary)
      if (loginInProgress) {
        setLoginInProgress(false);
      }
    },
    [dispatch, loginInProgress, loginFailureError],
  )

  const processPerLoginStatus = useCallback(
    () => {
      // console.info(`LoginPage.processPerLoginStatus(). loginStatus=${loginStatus}`);

      if (loginStatus) {
        switch (loginStatus) {
          case enumWorkflowState.Requested:
          case enumWorkflowState.InProgress:
            processInProgressLoginStatus();
            break;

          case enumWorkflowState.Success:
            processSuccessLoginStatus();
            break;

          case enumWorkflowState.Failure:
            processFailureLoginStatus();
            break;
        }
      }
    },
    [loginStatus, processInProgressLoginStatus, processSuccessLoginStatus, processFailureLoginStatus],
  )

  // whenever the 'loginStatus' value changes, perform processing per that value
  useEffect(() => {
    processPerLoginStatus();
  }, [loginStatus, processPerLoginStatus]);


  /**
   * @method handleLoginFormSubmit Handles a submit request from the LoginForm
   * @param event A Submit event
   */
  function handleLoginFormSubmit(loginData: ILoginData): Promise<void> {
    return new Promise(async (resolve, reject) => {
      try {
        // set the email address at last submit
        setEmailAtLastSubmit(loginData.email);

        // dispatch an action to request a login
        // dispatch(loginRequested(loginData));
        dispatch(loginRequest(loginData));
        // const loginDataAsJson: ILoginDataAsJson = loginData.toJSON();
        // dispatch(loginRequest(loginDataAsJson));

        setLoginInProgress(true);

        resolve();
      } catch (error: any) {
        reject(error);
      }
    });
  }

  /**
   * @method handleRequestForAccountVerificationEmail Handles a request to send an Account Verification email to the given email address
   * @param {string} emailAddress The email address for the user to whom the email will be sent
   */
  function handleRequestForAccountVerificationEmail(emailAddress: string): Promise<void> {
    return new Promise(async (resolve, reject) => {
      try {
        displayConsoleLogs && console.log(`%c Entered LoginPage.handleRequestForAccountVerificationEmail(). emailAddress: ${emailAddress}`, 'background: #00B; color: #fff');

        // get userId and name information for a User record that has the emailAddress
        const userEmaiIdAndName: IUserEmailIdAndNameViewModel = await getUserAccountInfoFromEmailAddressViaHttp(emailAddress);

        displayConsoleLogs && console.log(`%c LoginPage.handleRequestForAccountVerificationEmail(). userEmaiIdAndName: ${JSON.stringify(userEmaiIdAndName)}`, 'background: #00B; color: #fff');
        displayConsoleLogs && console.log(`%c LoginPage.handleRequestForAccountVerificationEmail(). Ready to call generateEmailRequestForAccountVerification`, 'background: #00B; color: #fff');

        // call method to submit the Account Verification email request, which in turn will trigger the sending of an email message
        await submitEmailRequestForAccountVerification(userEmaiIdAndName.userId, emailAddress, userEmaiIdAndName.firstName, userEmaiIdAndName.lastName);

        displayConsoleLogs && console.log(`%c LoginPage.handleRequestForAccountVerificationEmail(). Returned from call to submitEmailRequestForAccountVerification.`, 'background: #00B; color: #fff');

        // notify user via a Beacon notification that the email request has been sent
        // dispatch(beaconChange(new Beacon(undefined, enumBeaconType.Success,
        //   MessagesStringAssets.login_VerificationEmailSentConfirmationHeader,
        //   composeMessageUsingStringAsset(MessagesStringAssets.login_VerificationEmailSentConfirmation, emailAddress, MessagesStringAssets.substitutionKeyword))));
        const beacon: Beacon = new Beacon(undefined, enumBeaconType.Success,
          MessagesStringAssets.login_VerificationEmailSentConfirmationHeader,
          composeMessageUsingStringAsset(MessagesStringAssets.login_VerificationEmailSentConfirmation, emailAddress, MessagesStringAssets.substitutionKeyword));
        dispatch(beaconChange(beacon));
        resolve();
      } catch (error: any) {
        reject(error);
      }
    });
  }

  /**
   * @method handleRequestForPasswordResetEmail Handles a request to send a Password Reset email to the given email address
   * @param {string} emailAddress The email address for the user to whom the email will be sent
   */
  function handleRequestForPasswordResetEmail(emailAddress: string): Promise<void> {
    return new Promise(async (resolve, reject) => {
      try {
        displayConsoleLogs && console.log(`%c Entered LoginPage.handleRequestForPasswordResetEmail(). emailAddress: ${emailAddress}`, 'background: #00B; color: #fff');

        // get userId and name information for a User record that has the emailAddress
        const userEmaiIdAndName: IUserEmailIdAndNameViewModel = await getUserAccountInfoFromEmailAddressViaHttp(emailAddress);

        displayConsoleLogs && console.log(`%c LoginPage.handleRequestForPasswordResetEmail(). userEmaiIdAndName: ${JSON.stringify(userEmaiIdAndName)}`, 'background: #00B; color: #fff');
        displayConsoleLogs && console.log(`%c LoginPage.handleRequestForPasswordResetEmail(). Ready to call generateEmailRequestForAccountVerification`, 'background: #00B; color: #fff');

        // call method to submit the Password Reset email request, which in turn will trigger the sending of an email message
        await submitEmailRequestForPasswordReset(userEmaiIdAndName.userId, emailAddress, userEmaiIdAndName.firstName, userEmaiIdAndName.lastName);

        displayConsoleLogs && console.log(`%c LoginPage.handleRequestForPasswordResetEmail(). Returned from call to submitEmailRequestForAccountVerification.`, 'background: #00B; color: #fff');

        // notify user via a Beacon notification that the email request has been sent
        // dispatch(beaconChange(new Beacon(undefined, enumBeaconType.Success,
        //   MessagesStringAssets.login_PasswordResetEmailSentConfirmationHeader,
        //   composeMessageUsingStringAsset(MessagesStringAssets.login_PasswordResetEmailSentConfirmation, emailAddress, MessagesStringAssets.substitutionKeyword))));
        const beacon: Beacon = new Beacon(undefined, enumBeaconType.Success,
          MessagesStringAssets.login_PasswordResetEmailSentConfirmationHeader,
          composeMessageUsingStringAsset(MessagesStringAssets.login_PasswordResetEmailSentConfirmation, emailAddress, MessagesStringAssets.substitutionKeyword));
        dispatch(beaconChange(beacon));

        resolve();
      } catch (error: any) {
        reject(error);
      }
    });
  }

  /**
   * @method handleRequestForSigninWithEmailLinkEmail Handles a request to send a Sign-in With Email Link email to the given email address
   * @param {string} emailAddress The email address for the user to whom the email will be sent
   */
  function handleRequestForSigninWithEmailLinkEmail(emailAddress: string): Promise<void> {
    return new Promise(async (resolve, reject) => {
      try {
        displayConsoleLogs && console.log(`%c Entered LoginPage.handleRequestForSigninWithEmailLinkEmail(). emailAddress: ${emailAddress}`, 'background: #00B; color: #fff');

        // get userId and name information for a User record that has the emailAddress
        const userEmaiIdAndName: IUserEmailIdAndNameViewModel = await getUserAccountInfoFromEmailAddressViaHttp(emailAddress);

        displayConsoleLogs && console.log(`%c LoginPage.handleRequestForSigninWithEmailLinkEmail(). userEmaiIdAndName: ${JSON.stringify(userEmaiIdAndName)}`, 'background: #00B; color: #fff');
        displayConsoleLogs && console.log(`%c LoginPage.handleRequestForSigninWithEmailLinkEmail(). Ready to call generateEmailRequestForAccountVerification`, 'background: #00B; color: #fff');

        // call method to submit the Sign-in With Email Link email request, which in turn will trigger the sending of an email message
        await submitEmailRequestForSigninWithEmailLink(userEmaiIdAndName.userId, emailAddress, userEmaiIdAndName.firstName, userEmaiIdAndName.lastName);

        displayConsoleLogs && console.log(`%c LoginPage.handleRequestForSigninWithEmailLinkEmail(). Returned from call to submitEmailRequestForAccountVerification.`, 'background: #00B; color: #fff');

        // notify user via a Beacon notification that the email request has been sent
        // dispatch(beaconChange(new Beacon(undefined, enumBeaconType.Success,
        //   MessagesStringAssets.login_SigninWithEmailLinkEmailSentConfirmationHeader,
        //   composeMessageUsingStringAsset(MessagesStringAssets.login_SigninWithEmailLinkEmailSentConfirmation, emailAddress, MessagesStringAssets.substitutionKeyword))));
        const beacon: Beacon = new Beacon(undefined, enumBeaconType.Success,
          MessagesStringAssets.login_SigninWithEmailLinkEmailSentConfirmationHeader,
          composeMessageUsingStringAsset(MessagesStringAssets.login_SigninWithEmailLinkEmailSentConfirmation, emailAddress, MessagesStringAssets.substitutionKeyword));
        dispatch(beaconChange(beacon));

        resolve();
      } catch (error: any) {
        reject(error);
      }
    });
  }

  function handleProblemDialogButtonClick() {
    setShowProblemDialog(false);
  }

  return (
    <>
      <StyledBoxForPaddingAtopPage />
      <GenericPageContainer
        maxWidth="sm"
        showBackButton={false}
        pageTitle={PageAndViewTitleStringAssets.pageTitle_Login}
      >
        <>
          <div>
            <MyDigiBrainLogo ref={logoElement} className={classes.myDigiBrainLogo} />
          </div>

          <LoginForm
            loginData={defaultLoginData}
            accountNotVerified={accountNotVerified}
            emailAtLastSubmit={emailAtLastSubmit}
            loginRequestInProgress={loginInProgress}
            onSubmit={handleLoginFormSubmit}
            onRequestAccountVerificationEmail={handleRequestForAccountVerificationEmail}
            onRequestPasswordResetEmail={handleRequestForPasswordResetEmail}
            onRequestSigninWithEmailLinkEmail={handleRequestForSigninWithEmailLinkEmail}
          />

          {/* Dialog to display a problem */}
          <OneButtonDialog
            showDialog={showProblemDialog}
            headerText={problemDialogHeader}
            bodyText={problemDialogMessage}
            buttonText={ControlsStringAssets.okButtonText}
            onButtonClick={handleProblemDialogButtonClick}
          />
        </>
      </GenericPageContainer>
    </>
  );

} // const LoginPage...

export default LoginPage;
