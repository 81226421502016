import React, { PropsWithChildren, useCallback, useEffect, useMemo, useState } from 'react';
import { Box, BoxProps, Typography, TypographyProps } from '@mui/material';
import styled from 'styled-components';


/*** Using the Material UI Emotion Styling library, declare 'styled' instances for each area/object. 
 *** NOTE: These must be declared outside of the React Functional Component to ensure that the styled 
 *** objects will be properly rendered within the DOM. 
 ***/

// a styled Box (equivalent to a <div>), providing an area padding at the top of the view
const DigitalMediaNotAvailableDisplayBox = styled((props: BoxProps) => (
  <Box
    {...props}
  />
))(({ theme }) => ({
  background: `linear-gradient(135deg, ${theme.palette.primary.light}, #BBB, #EEE, ${theme.palette.primary.light}, #BBB, #EEE, ${theme.palette.primary.light})`,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  justifyItems: 'center',
  textAlign: 'center',
  width: '100%',
}));


// a styled Typography for the Display Text
const StyledTypographyForDisplayText = styled((props: TypographyProps) => (
  <Typography
    variant="body1"
    {...props}
  />
))(({ theme }) => ({
  color: theme.page.titleFontColor,
  alignSelf: 'center',
  justifySelf: 'center',
  fontWeight: 'bold',

  [theme.breakpoints.up('xs')]: {
    fontSize: '0.8rem',
  },
  [theme.breakpoints.up('sm')]: {
    fontSize: '0.9rem',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '0.9rem',
  },
  [theme.breakpoints.up('lg')]: {
    fontSize: '1.0rem',
  },
  [theme.breakpoints.up('xl')]: {
    fontSize: '1.0rem',
  },
}));


/**
 * @interface IDigitalMediaNotAvailableDisplayProps declares any input properties required for this component.
 */
export interface IDigitalMediaNotAvailableDisplayProps extends PropsWithChildren<unknown> {
  displayText?: string;
}

/**
 * @function DigitalMediaNotAvailableDisplay is a React functional component for displaying a simple image (via an IImageLink).
 * @param {} props are the properties passed into the component (currently no input properties).
 */
export const DigitalMediaNotAvailableDisplay: React.FC<IDigitalMediaNotAvailableDisplayProps> = (props: IDigitalMediaNotAvailableDisplayProps) => {
  DigitalMediaNotAvailableDisplay.displayName = 'Digital Media Not Available';

  const { displayText } = props;


  return (
    <>
      <DigitalMediaNotAvailableDisplayBox>
        {
          displayText &&
          <StyledTypographyForDisplayText>
            {displayText}
          </StyledTypographyForDisplayText>
        }
      </DigitalMediaNotAvailableDisplayBox>
    </>
  )
}
