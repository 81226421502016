import { Theme } from '@mui/material/styles';
import { DocumentData, QuerySnapshot } from 'firebase/firestore';
import _ from 'lodash';
import { FC, PropsWithChildren, useCallback, useEffect, useState } from 'react';
import fileBasedBuiltInCategorizedThemeSpecs_Json from '../../../assets/data/builtInCategorizedThemeSpecs.json';
import { AppThemesContext, createDefaultThemeSpecsViewModel } from '.';
import { typeUniqueId } from '../../../dataObjects/types';
import { JsonConverter } from '../../../dataObjects/utilities/JsonConverter';
import { CategorizedThemeSpecsViewModel, ICategorizedThemeSpecsViewModel, IThemeSpecsViewModel, ThemeSpecsViewModel } from '../../../dataObjects/viewModels/themeSpecsViewModel';
import { enumAuthenticationStatus } from '../../enums';
import { ICurrentUserContextData, useCurrentUserContext } from '../currentUser';
import { IAppThemesContextData } from './IAppThemesContextData';
import { IThemeSpecs, IThemeSpecsAsJson, ThemeSpecs } from '../../../dataObjects/models/themes/ThemeSpecs';
import { getUserDefinedThemeSpecsForUser_onSnapshot } from '../../../firebaseServices/dataServices/dataServiceActions/themeSpecsActions';
import { IThemeBaseColors, IThemeBaseColorsAsJson, ThemeBaseColors } from '../../../dataObjects/models/themes/ThemeBaseColors';
import { enumThemeMode } from '../../../dataObjects/enums/enumThemeMode';
import { createThemeFromModeAndBaseColors } from '../../../assets/themes/createThemeFromModeAndBaseColors';
import { IUserSettings } from '../../../dataObjects/models/users/UserSettings';
import { PaletteMode } from '@mui/material';
import { enumThemeType } from '../../../dataObjects/enums';
import { IMyDigiBrainTheme } from '../../../assets/themes';

/**
 * @interface IAppThemesProviderProps declares any input properties required for this component.
 */
export interface IAppThemesProviderProps extends PropsWithChildren<unknown> {
}


/**
 * @provider AppThemesProvider A React Provider component that is based on the UserCategoriesAndChannelsContext and can be used to provide a 
 *   React component tree embedded in the provider with information for the current user's Categories and Channels. 
 */
export const AppThemesProvider: FC<IAppThemesProviderProps> = (props: IAppThemesProviderProps) => {

  AppThemesProvider.displayName = "AppThemesProvider";

  // whether to display console logs (console.log statements)
  const displayConsoleLogs: boolean = false;

  displayConsoleLogs && console.log('Entered/Refreshed AppThemesProvider component');

  // define default unsubscribe callback function definition (an empty function that returns 'void')
  const defaultUnsubscribeCallback: () => void = () => { };

  // Define another default unsubscribe callback function definition that is a function which points to an empty function. 
  // This is the type of structure that is required to capture a function pointer in a useState variable.
  // const defaultUnsubscribeCallbackForStateVariable: () => () => void = () => () => { displayConsoleLogs && console.log(`%c Default Unsubscribe Callback`, 'background: #00d; color: #fff'); };
  const defaultUnsubscribeCallbackForStateVariable: () => () => void = useCallback(
    () => () => {
      displayConsoleLogs && console.log(`%c Default Unsubscribe Callback`, 'background: #00d; color: #fff');
    },
    [displayConsoleLogs],
  );

  // fetch the 'children' property from the input properties
  const { children } = props;

  // indicates whether theme preparation has been completed
  const [themePreparationComplete, setThemePreparationComplete] = useState<boolean>(false);

  // // indicates whether a theme needs to be assigned for the current user
  // const [themeNeedsToBeAssignedForCurrentUser, setThemeNeedsToBeAssignedForCurrentUser] = useState<boolean>(false);

  // use a custom hook to get the Current User information from a CurrentUserContext/Provider higher up in the component tree
  const currentUserContextData: ICurrentUserContextData = useCurrentUserContext();
  // const currentUser: IUser | undefined = currentUserContextData.currentUser;
  displayConsoleLogs && console.log(`%c AppThemesProvider.currentUserContextData: \n${JSON.stringify(currentUserContextData)}`, 'background: #600; color: #fff');

  // The Id of current user logged in via Firebase. This value will be 'undefined' if no user is logged in.
  // const [currentUser, setCurrentUser] = useState<IUser | undefined>(currentUserContextData.currentUser);
  const [currentUserId, setCurrentUserId] =
    useState<typeUniqueId | undefined>(currentUserContextData.currentUser ? currentUserContextData.currentUser.id : undefined);

  const [currentUserSettings, setCurrentUserSettings] =
    useState<IUserSettings | undefined>(currentUserContextData.currentUser ? currentUserContextData.currentUser.userSettings : undefined);

  // The themeCategoryId of the current user's UserSettings. Can have the value of undefined if no user is logged in.
  const [currentThemeCategoryId, setCurrentThemeCategoryId] = useState<string | undefined>(undefined);

  // The themeId of the current user's UserSettings. Can have the value of undefined if no user is logged in.
  const [currentThemeId, setCurrentThemeId] = useState<string | undefined>(undefined);

  // The themeMode of the current user's UserSettings. Can have the value of undefined if no user is logged in.
  const [currentThemeMode, setCurrentThemeMode] = useState<string | undefined>(undefined);

  // The authentication status. We won't want to take action until we know whether user authentication has been completed, or 
  // we know that it's an Anonymous User.
  const [authenticationStatus, setAuthenticationStatus] = useState<enumAuthenticationStatus>(currentUserContextData.authenticationStatus);

  // The default ThemeSpecsViewModel to be used if no user has logged in
  const [defaultThemeSpecsViewModel, setDefaultThemeSpecsViewModel] = useState<IThemeSpecsViewModel>(createDefaultThemeSpecsViewModel());

  // The default theme to be used if no user has logged in
  const [defaultTheme, setDefaultTheme] = useState<IMyDigiBrainTheme | undefined>(undefined);

  // The current UI theme for the user
  const [currentTheme, setCurrentTheme] = useState<IMyDigiBrainTheme | undefined>(undefined);

  // Whether User-Defined ThemeSpecs have been retrieved from the database
  const [userDefinedThemeSpecsDataRetrieved, setUserDefinedThemeSpecsDataRetrieved] = useState<boolean>(false);

  // Categorized ThemeSpecs View Models that come standard (built-in) to the app
  const [builtInCategorizedThemeSpecsViewModels, setBuiltInCategorizedThemeSpecsViewModels] = useState<Array<ICategorizedThemeSpecsViewModel> | undefined>(undefined);

  // ThemeSpecs View Models that have been defined by the current user
  const [userDefinedThemeSpecsViewModels, setUserDefinedThemeSpecsViewModels] = useState<Array<IThemeSpecsViewModel> | undefined>(undefined);


  // a useEffect hook that is executed upon mounting of the component
  useEffect(() => {
    displayConsoleLogs && console.log(`%c AppThemesProvider, in useEffect upon mounting. Ready to create defaultTheme. Current defaultTheme: \n${JSON.stringify(defaultTheme)}`, 'background: #600; color: #fff');

    // Create and set the default theme. The default them will be used in two use cases:
    //  1. There is no user logged in (it's an Anonymous User); and
    //  2. A user has logged in, but there is no recognized ThemeId associated with the user.
    createDefaultTheme(defaultThemeSpecsViewModel).then((newlyCreatedDefaultTheme) => {
      // set the default theme
      displayConsoleLogs && console.log(`%c AppThemesProvider, in useEffect upon mounting. Created defaultTheme. Newly created defaultTheme: \n${JSON.stringify(newlyCreatedDefaultTheme)}`, 'background: #600; color: #fff');
      setDefaultTheme(newlyCreatedDefaultTheme);
    }).catch((error) => {
      throw error;
    });

    // fetch and set the Categorized Built-in ThemeSpecs ViewModels
    displayConsoleLogs && console.log(`%c AppThemesProvider, in useEffect upon mounting. Ready to fetch Categorized Built-in ThemeSpecs ViewModels from file.`, 'background: #600; color: #fff');
    const categorizedThemeSpecsViewModels_fromJsonFile: Array<ICategorizedThemeSpecsViewModel> = JsonConverter.arrayFromJSONArray(CategorizedThemeSpecsViewModel, fileBasedBuiltInCategorizedThemeSpecs_Json);
    // set the Built-in Categorized ThemeSpecs ViewModels local state variable after ordering the array by the name field
    setBuiltInCategorizedThemeSpecsViewModels(_.orderBy(categorizedThemeSpecsViewModels_fromJsonFile, [categorizedThemeSpecsViewModel => categorizedThemeSpecsViewModel.name.toUpperCase()], ['asc']));

    // perform cleanup when the component unmounts
    return () => {
    }

  }, []);



  // a useEffect hook to execute when the authenticationStatus changes
  useEffect(() => {
    // If no user is logged in (authentication status is AnonymousUser):
    //  1. Set the currentTheme to be the defaultTheme
    //  2. Indicate that theme preparation has been completed
    if (authenticationStatus === enumAuthenticationStatus.AnonymousUser) {
      displayConsoleLogs && console.log(`%c AppThemesProvider, in useEffect for [authenticationStatus, defaultTheme, currentTheme, themePreparationComplete]. Ready to fetech Built-in ThemeSpecs ViewModels from file. authenticationStatus: \n${JSON.stringify(authenticationStatus)}`, 'background: #600; color: #fff');
      if (currentTheme !== defaultTheme) {
        setCurrentTheme(defaultTheme);
      }

      if (!themePreparationComplete) {
        setThemePreparationComplete(true);
      }
    }

    // perform cleanup when the component unmounts
    return () => {
    }

  }, [authenticationStatus, defaultTheme, currentTheme, themePreparationComplete]);

  // if Current User Id information has changed, set it into local useState variables
  if (currentUserId !== (currentUserContextData.currentUser ? currentUserContextData.currentUser.id : undefined)) {
    setCurrentUserId(currentUserContextData.currentUser ? currentUserContextData.currentUser.id : undefined);
  }

  // if the Current User's Settings information has changed, set it into local useState variables
  if (currentUserSettings !== (currentUserContextData.currentUser ? currentUserContextData.currentUser.userSettings : undefined)) {
    setCurrentUserSettings(currentUserContextData.currentUser ? currentUserContextData.currentUser.userSettings : undefined);
  }

  if (authenticationStatus !== currentUserContextData.authenticationStatus) {
    setAuthenticationStatus(currentUserContextData.authenticationStatus);
  }


  /**
   * @function onUserDefinedThemeSpecsForUserSnapshotCallback A callback method to receive firestore Categories data snapshots for dynamic data updates
   * @param {QuerySnapshot<DocumentData>} snapshot A snapshot of data from firestore
   */
  function onUserDefinedThemeSpecsForUserSnapshotCallback(snapshot: QuerySnapshot<DocumentData>): void {
    displayConsoleLogs && console.info(`AppThemesProvider - in onUserDefinedThemeSpecsForUserSnapshotCallback(). Entering onUserDefinedThemeSpecsForUserSnapshotCallback.`)

    const themeSpecsDataAsJson: Array<IThemeSpecsAsJson> = [];
    snapshot.forEach(doc => themeSpecsDataAsJson.push({ ...doc.data(), id: doc.id } as IThemeSpecsAsJson));

    // create an array of ThemeSpecs objects from the JSON data
    let themeSpecsData: Array<IThemeSpecs> = JsonConverter.arrayFromJSONArray(ThemeSpecs, themeSpecsDataAsJson);

    // use lodash to sort the array by 'name' in ascending order
    themeSpecsData = _.orderBy(themeSpecsData, [themeSpecs => themeSpecs.name.toUpperCase()], ['asc']);

    // convert the ThemeSpecs data into ThemeSpecsViewModel data
    const themeSpecsViewModels: Array<IThemeSpecsViewModel> = ThemeSpecsViewModel.arrayFromThemeSpecsArray(themeSpecsData);

    // set the User-Defined ThemeSpecsViewModel data into state, using lodash to sort the view models by 'name' in ascending order
    // setUserDefinedThemeSpecsViewModels(themeSpecsViewModels);
    setUserDefinedThemeSpecsViewModels(_.orderBy(themeSpecsViewModels, [themeSpecsViewModel => themeSpecsViewModel.name.toUpperCase()], ['asc']));

    // if the flag isn't already set, set flag to indicate that the User-Defined ThemeSpecs data has been retrieved
    if (!userDefinedThemeSpecsDataRetrieved) {
      setUserDefinedThemeSpecsDataRetrieved(true);
    }
  }

  // a useEffect hook that responds to a change in the currentUserId
  useEffect(() => {
    // Declare an 'unsubscribe' variable that will hold the unsubscribe callback from a firestore onSnapshot() request, and hold
    // it within the context of this useEffect hook.
    // We initialize it to a function that does nothing, so if an onSnapshot() is never requested, we can still call unsubscribe() during cleanup. 
    // After an onShapshot() request, the 'unsubscribe' variable will be set to a callback function issued by firestore.
    let unsubscribeCallbackForUserDefinedThemeSpecsSnapshotQuery: () => void = defaultUnsubscribeCallback;

    // if the currentUserId is a valid value (not undefined), it means that the current user has changed, either by a user 
    // logging in or the user being switched from one to another (not sure if that's actually possible, but we'll cover that 
    // use case just in case it is possible).
    // if ((currentUserId !== undefined) && (authenticationStatus === enumAuthenticationStatus.AuthenticationComplete)) {
    if (currentUserId !== undefined) {
      // subscribe to onShapshot updates for User-Defined ThemeSpecs, providing realtime updates to the data, and capture the 'unsubscribe' callback method provided by firestore
      displayConsoleLogs && console.log(`%c In AppThemesProvider.useEffect for [currentUserId, defaultTheme, themePreparationComplete]. About to call getUserCategoriesForUser_onSnapshot()`, 'background: #ddd; color: #030');
      getUserDefinedThemeSpecsForUser_onSnapshot(currentUserId, onUserDefinedThemeSpecsForUserSnapshotCallback).then((unsubscribe: () => void) => {
        displayConsoleLogs && console.log(`%c In AppThemesProvider.useEffect for [currentUserId, defaultTheme, themePreparationComplete]. Completed call to getUserCategoriesForUser_onSnapshot()`, 'background: #ddd; color: #030');

        // capture the unsubscribe callback function so that we can unsubscribe from the snapshot query upon unmounting
        unsubscribeCallbackForUserDefinedThemeSpecsSnapshotQuery = unsubscribe;
      }).catch(error => {
        displayConsoleLogs && console.error(`In AppThemesProvider.useEffect for [currentUserId, defaultTheme, themePreparationComplete]. Error after call to getUserDefinedThemeSpecsForUser_onSnapshot() & setUnsubscribeCallbackForUserData(). \nError: ${error}`)
      });
    } else { // currentUserId === undefined, so there is no current user...
      // since there is no Current User (it's an Anonymous User), we want to reset everything related to a user

      // set User-Defined ThemeSpecs View Models to an empty value since we don't have a current user
      // if (userDefinedThemeSpecsViewModels !== undefined) {
      setUserDefinedThemeSpecsViewModels(undefined);
      // }

      // if the default theme has been set up (should always be true, just a sanity check here), set the current theme to be the default theme
      if (defaultTheme !== undefined) {
        // since there is no current user, set the default theme as the current theme
        setCurrentTheme(defaultTheme);
        // Note: DO NOT set flag to indicate that theme preparation is complete at this point. Doing so will cause the default theme
        //       to be used, even if the a user gets logged in and that user's theme selection is set as the current theme.
      }
    }

    // perform cleanup when the component unmounts
    return () => {
      displayConsoleLogs && console.info(`%c AppThemesProvider.useEffect for [currentUserId, defaultTheme, themePreparationComplete]. Cleanup before unmounting. Ready to call unsubscribeCallback for snapshot queries.`, 'background: #00d; color: #fff')

      // call methods to unsubscribe from the snapshot queries
      unsubscribeCallbackForUserDefinedThemeSpecsSnapshotQuery();
      displayConsoleLogs && console.info(`%c AppThemesProvider.useEffect for [currentUserId, defaultTheme, themePreparationComplete]. Cleanup before unmounting. After call to unsubscribeCallback for snapshot queries.`, 'background: #00d; color: #fff')
    }

    // }, [currentUserId]);
    // }, [currentUserId, defaultTheme, themePreparationComplete]);
  }, [currentUserId, defaultTheme, defaultUnsubscribeCallbackForStateVariable]);
  // }, [currentUserId, defaultTheme, themePreparationComplete, defaultUnsubscribeCallbackForStateVariable, onUserDefinedThemeSpecsForUserSnapshotCallback, unsubscribeCallbackForUserDefinedThemeSpecsData, userDefinedThemeSpecsViewModels, userDefinedThemeSpecsDataRetrieved]);


  // a useEffect hook that responds to a change in the currentUserSettings
  useEffect(() => {
    displayConsoleLogs && console.log(`%c AppThemesProvider, in useEffect for [currentUserSettings]. currentUserSettings: \n${JSON.stringify(currentUserSettings)}`, 'background: #600; color: #fff');
    if (currentUserSettings !== undefined) {
      displayConsoleLogs && console.log(`%c AppThemesProvider, in useEffect for [currentUserSettings]. Ready to set themeCategoryId, themeId & themeMode: \n${JSON.stringify(currentUserSettings)}`, 'background: #600; color: #fff');

      // if the currentThemeCategoryId differs from the user's themeCategoryId, set to the new value
      if (currentThemeCategoryId !== currentUserSettings.themeCategoryId) {
        setCurrentThemeCategoryId(currentUserSettings.themeCategoryId);
      }

      // if the currentThemeId differs from the user's themeId, set to the new value
      if (currentThemeId !== currentUserSettings.themeId) {
        setCurrentThemeId(currentUserSettings.themeId);
      }

      // if the currentThemeMode differs from the user's themeMOde, set to the new value
      if (currentThemeMode !== currentUserSettings.themeMode) {
        setCurrentThemeMode(currentUserSettings.themeMode);
      }
    } else {
      // if the currentThemeId and/or currentThemeMode are something other than undefined, set to undefined
      if (currentThemeId !== undefined) {
        setCurrentThemeId(undefined);
      }
      if (currentThemeMode !== undefined) {
        setCurrentThemeMode(undefined);
      }
    }
  }, [currentUserSettings]);


  // 
  function setCurrentThemeFromThemeCategoryIdThemeIdAndThemeMode(themeCategoryId: string, themeId: string, themeMode: string, defaultThemeToUse: IMyDigiBrainTheme): void {
    displayConsoleLogs && console.log(`%c AppThemesProvider. Entered setCurrentThemeFromThemeCategoryIdAndMode. themeCategoryId: ${themeCategoryId} themeId: ${themeId}; themeMode: ${themeMode}; \ndefaultThemeToUse: ${JSON.stringify(defaultThemeToUse)}`, 'background: #600; color: #fff');

    // we'll try to find the themeSpecs to use for creating a Theme from within either the user-defined or built-in themeSpecs
    let themeSpecsViewModelToUse: IThemeSpecsViewModel | undefined = undefined;

    // if the themeCategoryId is 'Custom', then we'll look for the themeId in the user-defined themes
    if (themeCategoryId === enumThemeType.Custom.toString()) {
      // if the User-Defined ThemeSpecs have been retrieved...
      if (userDefinedThemeSpecsDataRetrieved) {
        // if there are some User-DefinedThemeSpecs...
        if (userDefinedThemeSpecsViewModels !== undefined && userDefinedThemeSpecsViewModels.length > 0) {
          const themeSpecsViewModelFromUserDefined: IThemeSpecsViewModel | undefined =
            userDefinedThemeSpecsViewModels.find(themeSpecs => themeSpecs.id === themeId);

          if (themeSpecsViewModelFromUserDefined !== undefined) {
            themeSpecsViewModelToUse = themeSpecsViewModelFromUserDefined;
          }
        }
      }
    } else {
      // the desired themeSpecs must be in the builtInCategorizedThemeSpecsViewModels
      if (builtInCategorizedThemeSpecsViewModels && builtInCategorizedThemeSpecsViewModels.length > 0) {
        // search for a theme categoryId that matches what was passed in
        const categorizedThemeSpecsViewModelFromBuiltin: ICategorizedThemeSpecsViewModel | undefined =
          builtInCategorizedThemeSpecsViewModels.find(themeCategory => themeCategory.id === themeCategoryId);

        // if we found a matching category...
        if (categorizedThemeSpecsViewModelFromBuiltin !== undefined) {
          // search for the themeId in the collection of themes for the category
          const themeSpecsViewModelFromBuiltin: IThemeSpecsViewModel | undefined =
            categorizedThemeSpecsViewModelFromBuiltin.themes.find(themeSpecs => themeSpecs.id === themeId);

          if (themeSpecsViewModelFromBuiltin !== undefined) {
            themeSpecsViewModelToUse = themeSpecsViewModelFromBuiltin;
          }
        }
      }
    }

    // if we found the themeSpecs to use, create a theme from the themeSpecs
    if (themeSpecsViewModelToUse !== undefined) {

      // create a theme for the user and...
      createThemeFromModeAndBaseColors(themeMode as PaletteMode, themeSpecsViewModelToUse.themeBaseColors).then((userSelectedTheme) => {
        // set the current theme
        setCurrentTheme(userSelectedTheme);
        // indicate that the theme preparation has been completed 
        // Note: We do this here to ensure that we wait for the theme to have been created (wait for the async call to have completed.)
        setThemePreparationComplete(true);
      }).catch((error) => {
        throw error;
      });
    } else {
      // since no matching themeSpecs was found either in Built-in or User-Defined themeSpecs, apply the default theme
      setCurrentTheme(defaultThemeToUse);
      // indicate that the theme preparation has been completed
      // Note: While this appears to be a duplication of the same call from above, the one above was called after a return
      //       from an async call, so we need to separately make this call to indicate that theme preparation is complete.
      setThemePreparationComplete(true);
    }
  }

  // a useEffect hook that responds to a change in the authenticationStatus, currentThemeId, currentThemeMode, and/or defaultTheme
  useEffect(() => {
    displayConsoleLogs && console.log(`%c AppThemesProvider, in useEffect for [currentThemeId, currentThemeMode, defaultTheme]. currentThemeId: ${currentThemeId}; currentThemeMode: ${currentThemeMode}; \defaultTheme: ${JSON.stringify(defaultTheme)}`, 'background: #600; color: #fff');
    // if authentication has completed and the currentThemeId and currentThemeMode both have actual values...
    if ((authenticationStatus === enumAuthenticationStatus.AuthenticationComplete) && (currentThemeCategoryId !== undefined) && (currentThemeId !== undefined) && (currentThemeMode !== undefined) && defaultTheme !== undefined) {
      // call method to set the current theme based on the user's themeId & themeMode
      setCurrentThemeFromThemeCategoryIdThemeIdAndThemeMode(currentThemeCategoryId, currentThemeId, currentThemeMode, defaultTheme);
    }
  }, [authenticationStatus, userDefinedThemeSpecsDataRetrieved, currentThemeCategoryId, currentThemeId, currentThemeMode, defaultTheme]);

  // Prepare the Context object with available information. If themePreparationComplete, provide all values. If not, 
  // provide undefined values.
  const themesContextData: IAppThemesContextData =
  {
    state: {
      themePreparationComplete: themePreparationComplete,
      currentTheme: themePreparationComplete ? currentTheme : undefined,
      defaultThemeSpecsViewModel: defaultThemeSpecsViewModel,
      // builtInThemeSpecsViewModels: themePreparationComplete ? builtInThemeSpecsViewModels : undefined,
      builtInCategorizedThemeSpecsViewModels: themePreparationComplete ? builtInCategorizedThemeSpecsViewModels : undefined,
      userDefinedThemeSpecsViewModels: themePreparationComplete ? userDefinedThemeSpecsViewModels : undefined
    },
    actions: {
      // createDefaultThemeSpecsViewModel: createDefaultThemeSpecsViewModel
    }
  }


  // this provider forwards the value to the AppThemesContext.Provider
  return (
    <AppThemesContext.Provider value={themesContextData}>
      {children}
    </AppThemesContext.Provider>
  )
}

// /**
//  * @function createDefaultThemeSpecsViewModel Creates a default IThemeSpecsViewModel for the application
//  * @returns {IThemeSpecsViewModel} An IThemeSpecsViewModel object
//  */
// function createDefaultThemeSpecsViewModel(): IThemeSpecsViewModel {

//   const themeBaseColorsForViewModel: IThemeBaseColors = new ThemeBaseColors(
//     "#0000aa", // baseColorForPrimary
//     "#3030ff", // baseColorForSecondary
//     "#dd0800", // baseColorForCancel
//     "#f44336", // baseColorForError
//     "#ffc107", // baseColorForHighlight
//     "#995fd3", // baseColorForInfo
//     "#12A561", // baseColorForSuccess
//     "#ffeb3b"  // baseColorForWarning
//   );

//   const defaultThemeSpecsViewModel: IThemeSpecsViewModel = new ThemeSpecsViewModel('default', 'default', themeBaseColorsForViewModel, 'default');

//   return defaultThemeSpecsViewModel;
// }

/**
 * @function createDefaultTheme Creates a default theme for the application
 * @param {IThemeSpecsViewModel} themeSpecsViewModelForDefault
 * @returns {Promise<Theme>} A Promise<Theme> object for asynchronous functionality
 */
function createDefaultTheme(themeSpecsViewModelForDefault: IThemeSpecsViewModel): Promise<IMyDigiBrainTheme> {
  return new Promise<IMyDigiBrainTheme>(async (resolve, reject) => {
    try {
      const themeMode: enumThemeMode = enumThemeMode.Light;

      const themeBaseColorsAsJson: IThemeBaseColorsAsJson = themeSpecsViewModelForDefault.themeBaseColors.toJSON();

      const themeBaseColors: IThemeBaseColors = JsonConverter.fromJSON(ThemeBaseColors, themeBaseColorsAsJson);

      // const theme: Theme = await createThemeFromModeAndBaseColors(themeMode, themeBaseColors);
      const theme: IMyDigiBrainTheme = await createThemeFromModeAndBaseColors(themeMode, themeBaseColors);

      resolve(theme);
    } catch (error: any) {
      // alert(`Error. User registration failed: (${error})`);
      reject(error);
    }
  });
}

